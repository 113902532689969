import { store } from '../../../store';
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';
import { getFieldById } from '../../GetFieldById'
import {
    LBL_EQUAL, LBL_EQUAL_TO, LBL_GREATER_THAN, LBL_LESS_THAN, LBL_GREATER_THAN_EQUAL, LBL_LESS_THAN_EQUAL,
    COMMON_DATE_FORMAT,BLANK_VALUE,ERR_INVALID_DATE
} from '../../../constants/GlobalConstants';
import moment from 'moment';

export const execute = (props) => {
    var fieldProps = props.additionalProperties
    //will compare and disable target button
    var rule = fieldProps.DisableTargetFieldByComparingOtherFieldValues
    const stateData = store.getState();

    //i.e.,{"InitialDate":"DocumentSignedDate", "ExpirationTimeFrameInDays":"DocumentTypeId.ExpirationTimeFrameInDays"}  
    if (rule && rule.OtherFields && rule.OtherFields.Field1 && rule.OtherFields.Field2 && rule.OtherFields.ComparisonOperator) {
        var isNeedToDisableTargetButtons = false;
        var OtherFields = rule.OtherFields;
        var permissionDisabled = rule.IfTrueSetPermissionTypeId;
        var permissionEnabled = rule.IfFalseSetPermissionTypeId;
        var fieldOneObj = getFieldById(OtherFields.Field1); //DocumentExpirationDate
        var fieldTwoObj = BLANK_VALUE;                       //HFProcedureDate

        //Get hidden control value of current section from store
        if (stateData && stateData.caseDetails && stateData.caseDetails.sectionDetails && stateData.caseDetails.sectionDetails.sectionGroup) {
            stateData.caseDetails.sectionDetails.sectionGroup.forEach(item => {
                item && item.fields && item.fields.length > 0 && item.fields.forEach(fielditem => {
                    if (fielditem.mappingId == OtherFields.Field2) {
                        fieldTwoObj = fielditem;
                    }
                });
            });
        }

        if (fieldOneObj && fieldOneObj.value && fieldTwoObj && fieldTwoObj.value) {
            var fieldOneValue = moment(fieldOneObj.value,COMMON_DATE_FORMAT,true);
            var fieldTwoValue = moment(fieldTwoObj.value,COMMON_DATE_FORMAT,true);
            if(fieldOneObj.value == ERR_INVALID_DATE || fieldTwoObj.value == ERR_INVALID_DATE){
                isNeedToDisableTargetButtons = true;
            }
             else if ((OtherFields.ComparisonOperator == LBL_EQUAL || OtherFields.ComparisonOperator == LBL_EQUAL_TO)
                && moment(fieldTwoValue).isSame(fieldOneValue) === true) {
                isNeedToDisableTargetButtons = true;

            } else if (OtherFields.ComparisonOperator == LBL_GREATER_THAN
                && moment(fieldTwoValue).isAfter(fieldOneValue) === true) {
                isNeedToDisableTargetButtons = true;

            }
            else if (OtherFields.ComparisonOperator == LBL_GREATER_THAN_EQUAL
                && moment(fieldTwoValue).isSameOrAfter(fieldOneValue) === true) {
                isNeedToDisableTargetButtons = true;

            }
            else if (OtherFields.ComparisonOperator == LBL_LESS_THAN
                && moment(fieldTwoValue).isBefore(fieldOneValue) === true) {
                isNeedToDisableTargetButtons = true;
            } 
            else if (OtherFields.ComparisonOperator == LBL_LESS_THAN_EQUAL
                && moment(fieldTwoValue).isSameOrBefore(fieldOneValue) === true) {
                isNeedToDisableTargetButtons = true;
            }

            if (isNeedToDisableTargetButtons) {
                rule.TargetFieldId && rule.TargetFieldId.forEach(fieldId => {
                    store.dispatch(updateFieldPermission(fieldId, permissionDisabled));
                })
            } else {
                rule.TargetFieldId && rule.TargetFieldId.forEach(fieldId => {
                    store.dispatch(updateFieldPermission(fieldId, permissionEnabled));
                })
            }
        }

    }

}

const DisableTargetFieldByComparingOtherFieldValues = {
    execute
}
export default DisableTargetFieldByComparingOtherFieldValues