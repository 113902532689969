import { store } from '../../../store'
import XControlUtils from '../../X-ControlUtils'
import * as Constants from '../../../constants/GlobalConstants';
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import { getFieldById } from '../../GetFieldById';

export const execute = (control,onChange) => {
        if (control.value) {
            const rule = control.additionalProperties.ShowColumnOfTargetFieldWhenFieldHasValue
            var fieldValue = null;
            if (control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
                fieldValue = control.value ? control.value[control.additionalProperties.DisplayColumnId] : null; //  TODO: Need to check fieldProps.DisplayColumnId value at run time
            } else {
                fieldValue = control.value
            }
            if (fieldValue == rule[0].FieldValue) {
                var targetField = getFieldById(rule[0].TargetFieldId);
              //  store.dispatch(VisibilityControlAction.showField(rule[0].Column,null,null))
                store.dispatch(VisibilityControlAction.showHideTableControl(rule[0].TargetFieldId,rule[0].Column,true,onChange))
            } 
        }
}

const ShowColumnOfTargetFieldWhenFieldHasValue = {
    execute
}

export default ShowColumnOfTargetFieldWhenFieldHasValue