import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { getSelectionLoader } from '../../reducers/CaseDetailsReducer';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
}));

const AutoSuggest = (props) => {
  const { fieldId, label, error = false, isRequired, onChange,onInputChange, disabled, helperText, transformer } = props;
  var { selections = [], value = {} } = props;
  if (!selections) {
    selections = [];
  }
  const getLoader=useSelector(getSelectionLoader);
  const OPTIONS_LIMIT = 100;
  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  const additionalProperties = props.additionalProperties ? props.additionalProperties : null;
  const classes = useStyles()
  if (value && value != undefined && value != {} && typeof value != 'object') {
    selections.forEach(element => {
      if (element[additionalProperties.DisplayColumnId]) {
        var type = typeof (element[additionalProperties.DisplayColumnId])
        if (type == "string") {
          if (element[additionalProperties.DisplayColumnId] == value) {
            value = element;
          }
        }
        else {
          if (element[additionalProperties.DisplayColumnId] == parseInt(value)) {
            value = element;
          }
        }
      }
    });
  }

  return (
    <Autocomplete
      loading={getLoader}
      filterOptions={filterOptions}
      value={value}
      clearOnEscape={true}
      className={classes.formControl}
      id={fieldId}
      disabled={disabled}
      options={selections}
      getOptionLabel={(options) => options[additionalProperties.DisplayColumnName] || ""}
      isOptionEqualToValue={(options, value) => options[additionalProperties.DisplayColumnName] == value[additionalProperties.DisplayColumnName]}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={(params) => <TextField {...params} variant="outlined" value={value} label={label} error={error} helperText={helperText} />}
    />
  );
}

export default AutoSuggest;