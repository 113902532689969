import { updateIsPopUpOpen, getTraySectionDetails } from "../../../actions/CaseDetailsAction";
import { showDialog } from "../../../actions/DialogAction";
import { getPopupSectionDetails } from "../../../reducers/CaseDetailsReducer";
import { store } from "../../../store";
import { GetTrailingNumbersFromString } from "../../fieldProperties/FormatString";
import { getFieldByMappingId } from "../../GetFieldByMappingId";
import { GetFieldByMappingIdParentId } from "../../GetFieldByMappingIdParentId";

const execute = (control, AddOnLoadONChangeFlag = null) => {
    if (AddOnLoadONChangeFlag) {
        store.dispatch(updateIsPopUpOpen(true));
        const rule = control.additionalProperties.OnClickShowPopup
        var sectionId = rule?.SectionId;
        const caseId = store.getState().caseDetails.caseId;
        const popupSectionDetails = getPopupSectionDetails(store.getState());
        if (popupSectionDetails.length <= 0 || !popupSectionDetails.find(e => {
            return e.sectionId == sectionId
        })) {
            var tableRowIndex = GetTrailingNumbersFromString(control.mappingId);
            console.log(tableRowIndex)
            var caseProductId = "";
            var productHospitalId = "";
            var surgicalLocationId = "";
            var procedurUnitId = "";
            var serviceLineId = "";
            var primarySurgeonId = "";
            rule.DependentFields.forEach(fieldId => {
                if (fieldId.includes("[n]")) {
                    let fieldMappingId = fieldId.replaceAll("[n]", tableRowIndex);
                    let fieldValue = GetFieldByMappingIdParentId(fieldMappingId, control.parentFieldId);
                    if (fieldId.includes("SelectedImplantCaseProductId")) caseProductId = fieldValue?.value;
                    if (fieldId.includes("SelectedImplantProductId")) productHospitalId = fieldValue?.value;
                } else {
                    let fieldValue = getFieldByMappingId(fieldId, control.parentFieldId);
                    if (fieldId.includes("HFSurgicalLocationId")) surgicalLocationId = fieldValue.value;
                    if (fieldId.includes("HFProcedureUnitId")) procedurUnitId = fieldValue.value;
                    if (fieldId.includes("HFServiceLineId")) serviceLineId = fieldValue.value;
                    if (fieldId.includes("HFPrimarySurgeonId")) primarySurgeonId = fieldValue.value;
                }
            });
            var formData = {
                'SectionId': sectionId,
                'CaseId': caseId,
                'CaseProductId': caseProductId,
                'ProductHospitalId': productHospitalId,
                'SurgicalLocationId': surgicalLocationId,
                'ProcedurUnitId': procedurUnitId,
                'ServiceLineId': serviceLineId,
                'PrimarySurgeonId': primarySurgeonId
            };
            store.dispatch(getTraySectionDetails(control.mappingId, formData))
        }
        store.dispatch(showDialog(control.mappingId, true, control))
    }
}

const OnClickShowPopup = {
    execute
}

export default OnClickShowPopup