import request from '../providers/RestServiceProvider'
import { GET_CASES, GET_DASHBOARD_FILTERS, GET_DASHBOARD_PRIMARY_SURGEONS, GET_DASHBOARD_SURGICAL_LOCATIONS, GET_MONTH_CASES } from '../constants/Locations';



export const getCases = async (requestData) => {
    return await request({
        url: GET_CASES,
        method: 'POST',
        data:requestData
    }).then((response) => {

        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    responseData: response.payload
                }
            }
        }
    })
}


export const getMonthCases = async (requestData) => {
    return await request({
        url: GET_MONTH_CASES,
        method: 'POST',
        data:requestData
    }).then((response) => {

        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    responseData: response.payload
                }
            }
        }
    })
}




export const getDashboardPrimarySurgeons = async () => {
    return await request({
        url: GET_DASHBOARD_PRIMARY_SURGEONS,
        method: 'GET',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    selections: response.payload
                }
            }
        }
    })
}

export const getDashboardSurgicalLocations = async () => {
    return await request({
        url: GET_DASHBOARD_SURGICAL_LOCATIONS,
        method: 'GET',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    selections: response.payload
                }
            }
        }
    })
}

export const getDashboardFilters = async () => {
    return await request({
        url: GET_DASHBOARD_FILTERS,
        method: 'GET',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response.payload) {
                var customisedFieldPermission = [];
                var objPatientName = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "PatientFullName");
                if (objPatientName.length > 0) {
                    customisedFieldPermission.push(objPatientName[0]);
                }
                var objPatientDOB = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "PatientDOB");
                if (objPatientDOB.length > 0) {
                    customisedFieldPermission.push(objPatientDOB[0]);
                }
                var objPrimarySurgeon = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "PrimarySurgeonId");
                if (objPrimarySurgeon.length > 0) {
                    customisedFieldPermission.push(objPrimarySurgeon[0]);
                }
                var objSurgicalLocation = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "SurgicalLocationId");
                if (objSurgicalLocation.length > 0) {
                    customisedFieldPermission.push(objSurgicalLocation[0]);
                }
                var objHospitalMrn = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "HospitalMRN");
                if (objHospitalMrn.length > 0) {
                    customisedFieldPermission.push(objHospitalMrn[0]);
                }
                var objCaseId = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "CaseId");
                if (objCaseId.length > 0) {
                    customisedFieldPermission.push(objCaseId[0]);
                }
                response.payload.fieldPermissions = customisedFieldPermission;
                return {
                    responseData: response.payload
                }
            }
        }
    })
}


const dashboardService = {
    getCases,
    getDashboardFilters,
    getDashboardPrimarySurgeons,
    getDashboardSurgicalLocations,
    getMonthCases


}

export default dashboardService;