import { overrideValidationsInFields } from "../../../actions/CaseDetailsAction"
import { store } from "../../../store"
import * as Constants from '../../../constants/GlobalConstants'

const execute = control => {
    const rule = control.additionalProperties.OverrideTargetFieldValidationsWhenFieldHasValue
    var value
    if (control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
        value = control.value ? control.value[fieldProps.DisplayColumnId] : null
    } else {
        value = control.value
    }

    if (rule.FieldValue.includes(value)) {
        store.dispatch(overrideValidationsInFields(rule.TargetFieldId, true))
    }


}


const OverrideTargetFieldValidationsWhenFieldHasValue = {
    execute
}

export default OverrideTargetFieldValidationsWhenFieldHasValue