import axios from 'axios'
import LocalStorageProvider from './LocalStorageProvider'
import https from 'https'
import { store } from '../store';
import { isTokenExpired } from '../utils/Auth';
import AuthAction from '../actions/AuthActions';
import { sessionToggleDialog } from "../actions/SectionAlertAction"
import * as Constants from "../constants/GlobalConstants";
import * as Locations from "../constants/Locations";
import qs from "qs";

/**
 * Create an Axios Client with defaults
 */
/**
 * Request Wrapper with default success/error actions
 */
const request = async function (options, additionalData = null) {

  const currentRequestUrl = options.url;
  let tokenValue = sessionStorage.getItem("REFRESH_TOKEN");

  const getRefereshToken = async () => {
    var request_data = {
      grant_type: Constants.REFRESHTOKEN_GRANT_TYPE,
      refresh_token: tokenValue,
      client_id: Constants.OAUTH_CLIENT_ID,
      client_secret: Constants.OAUTH_CLIENT_SECRET
    };
    const config = {
      "content-type": "application/x-www-form-urlencoded",
      url: `${Locations.KEY_BASE_URL}${Locations.KEY_REFRESH_LOGIN_URL}`,
      method: "POST",
      data: qs.stringify(request_data)
    }
    let res = await axios(config).then((response) => {
      if (response.status == 200 && response.data.access_token && response.data.access_token != null) {
        LocalStorageProvider.saveAuthToken(response.data.access_token, response.data.refresh_token, response.data.expires_in)
        store.dispatch(AuthAction.setExpireTokenStatus(false));
        return response;
      }
    }).catch((e) => {
      //on logout api call off the session popup flag
      if (currentRequestUrl && currentRequestUrl.indexOf(Locations.KEY_LOGOUT_URL) != -1) {
        store.dispatch(sessionToggleDialog(false));
      }
      else if (e.response && e.response.status == 400 || e.response && e.response.status == 401) {
        store.dispatch(sessionToggleDialog(true));
        return e.response.status;
      }
    });
    return res;
  }

  const client = axios.create({
    httpsAgent: new https.Agent(
      {
        rejectUnauthorized: false
      }
    ),
    headers: {
      Authorization: `Bearer ${LocalStorageProvider.getAuthToken()}`,
      'Access-Control-Allow-Origin': '*',
      'Accept': '*/*',
      'X-ServiceHeader': `{ "TenantKey\": \"${additionalData?.TenantKey == null ? LocalStorageProvider?.getTenantKey() : additionalData.TenantKey}\"}`
    }
  });


  client.interceptors.request.use(
    async (config) => {
      if (isTokenExpired()) {
        await getRefereshToken().then((response) => {
          if (response == 400 || response == 401) {
            Promise.reject(error);
          } else if (response.status == 200 && response.data.access_token && response.data.access_token != null) {
            const token = response.data.access_token;
            if (token) {
              config.headers['Authorization'] = 'Bearer ' + token;
            }
          }
        });
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  client.interceptors.response.use(async (response) => {
    return handelResponse(response);
  }, async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status == 401 && !originalRequest._retry) {
        // store.dispatch(sessionToggleDialog(true));
        // Promise.reject(error);
        //  originalRequest._retry = true;
        await getRefereshToken().then((response) => {
          if (response == 400 || response == 401) {
            Promise.reject(error);
          } 
          else if (response.status == 200 && response.data.access_token && response.data.access_token != null) {
            const token = response.data.access_token;
            if (token) {
              // client.headers.common['Authorization'] = 'Bearer ' + token;
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return client(originalRequest);
            }
          }
        });
      }
    }
    //throw unhandled exceptions e.g status code 500
    else if (error.status === undefined && error.message === Constants.NETWORK_ERROR_500) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  });

  // Check if full response requied for certain APIS
  const handelResponse = (response) => {
    const requestURL = response.request.responseURL;
    if (requestURL.includes(Locations.REPORT_DOWNLOAD_URL) || requestURL.includes(Locations.ACKNOWLEDGE_EMR_URL)) {
      return response;
    }
    else {
      return response.data;
    }
  }
  const onSuccess = function (response) {
    return response;
  }

  const onError = function (error) {
    //console.error('Request Failed:', error.config);
    // if (error.response) {
    //   // Request was made but server responded with something
    //   // // other than 2xx
    //   // console.error('Status:', error.response.status);
    //   // console.error('Data:', error.response.data);
    //   // console.error('Headers:', error.response.headers);

    // } else {
    //   // Something else happened while setting up the request
    //   // triggered the error
    // //  console.error('Error Message:', error.message);
    // }

    return Promise.reject(error.response || error.message);
  }


  return client(options)
    .then(onSuccess)
    .catch(onError);
}

export default request;