import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Controls from '../controls/Controls'
import Loader from '../common/Loader';
import { Button as MuiButton, CircularProgress } from '@mui/material'
import {
    LBL_DATA_UPLOAD_SELECTION, LBL_DRY_RUN_STATUS, LBL_MAIN_RUN_STATUS, LBL_ROW_NUMBER, LBL_ROW_STATUS,
    LBL_MESSAGE, ERR_NO_DATA_FOUND, ERR_PLZ_SELECT_A_VALID_CSV_FILE, LBL_SELECT_HEALTH_SYSTEM,
    INFO_USER_CREATED, INFO_USER_UPDATED, FAILED, PASS, REMOVE, ADD, MAINRUN, CREATE
} from '../../constants/GlobalConstants'
import { ICON_RED } from '../../res/values/Colors'
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    getOrgTenants, getAdminDataUploadTypes, uploadDryRunData, updateLoaderState, setError, toggleAccordianPanel,
    resetUploadSelections, uploadMainRunData
} from '../../actions/AdminReportsAction';
import {
    getOrgTenantsData, getAdminDataUploadTypesData, getDryRunData, getMainRunData, getLoadingStatus
} from '../../reducers/AdminReportsReducer';


const useStyles = makeStyles(theme => ({
    container: {
        background: '#fff',
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(12)})`,
        paddingRight: '10px'
    },
    accordianStyle: {
        'background-image': 'linear-gradient(#17689e, #2b94c9)',
        'color': 'white'
    },
    dryRunReportActionContainer: {
        marginTop: '10px',
        marginLeft: '5px'
    },
    gridContainer: {
        marginTop: '5px',
    },
    progressContainer: {
        padding: '10px'
    }
}))


const DataUpload = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showUploadTypeDropdown, setShowUploadTypeDropdown] = React.useState(false);
    const [showFileUploadControl, setShowFileUploadControl] = React.useState(false);
    const [tenantSelectedValue, setTenantSelectedValue] = React.useState('');
    const [uploadTypeSelectedValue, setUploadTypeSelectedValue] = React.useState('');
    const [uploadedFile, setUploadedFile] = React.useState(''); //blob file for showing file name in file control 

    //Grid related params
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        //get default data on pageload       
        dispatch(getOrgTenants());
        dispatch(getAdminDataUploadTypes());
        return () => {
            //reset all changes on page unload     
            dispatch(resetUploadSelections());
        }
    }, []);

    //get data from store using selector hook
    const loading = useSelector(getLoadingStatus);
    const orgTenantsList = useSelector(getOrgTenantsData);
    const adminDataUploadTypesList = useSelector(getAdminDataUploadTypesData);
    const dryRunData = useSelector(getDryRunData);
    const mainRunData = useSelector(getMainRunData);


    // Pagination Handler
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onToggleAccordianPanel = (panelId) => {
        if (props.error == null || props.length == 0) {
            dispatch(toggleAccordianPanel(panelId));
        }
    };

    //will reset all changes in initial stage
    const resetChanges = () => {
        setShowUploadTypeDropdown(false);
        setShowFileUploadControl(false);
        setTenantSelectedValue('');
        setUploadTypeSelectedValue('');
        setUploadedFile('');
        dispatch(resetUploadSelections());

    }

    const onTenantSelection = (e, items) => {
        if (e?.target?.id) {
            setShowUploadTypeDropdown(true);
            setTenantSelectedValue(items["TenantKey"]);
        } else {
            setShowUploadTypeDropdown(false);
            setTenantSelectedValue('');
            setShowFileUploadControl(false);
            setUploadTypeSelectedValue('');
            dispatch(resetUploadSelections());
        }
    }

    const onDataUploadTypeSelection = (e, items) => {
        if (e?.target?.id) {
            setUploadedFile('');
            setShowFileUploadControl(true);
            dispatch(setError(''));
            setUploadTypeSelectedValue(items["adminDataUploadTypeId"]);
        } else {
            setShowFileUploadControl(false);
            setUploadTypeSelectedValue('');
            dispatch(resetUploadSelections());
        }
    }

    const onFileSelection = (event, items) => {
        if (event?.target?.files[0]?.type && event.target.files[0].type.indexOf('csv') === -1) {
            dispatch(setError(ERR_PLZ_SELECT_A_VALID_CSV_FILE));

        } else if (event?.target?.files?.length > 0) {
            setUploadedFile(URL.createObjectURL(event.target.files[0]));
            saveFileDetails(event.target.files[0]);
        }
    }

    const saveFileDetails = (file) => {
        //set loader state true
        dispatch(updateLoaderState(true));

        //prepare file object and send to process
        var fileData = new FormData();
        fileData.append('uploadedFile', file);
        dispatch(uploadDryRunData(fileData, uploadTypeSelectedValue, tenantSelectedValue));

    }

    const DryRunReportGrid = () => {
        const dryRunTableData = dryRunData?.rowLevelDetails ?? [];
        return (
            <>
                <Paper sx={{ width: '100%', overflow: 'hidden', marginLeft: '10px' }}>
                    <TableContainer >
                        <Table stickyHeader aria-label="sticky table"  >
                            <TableHead>
                                <TableRow sx={{
                                    "& th": {
                                        fontSize: "1rem",
                                        color: "rgba(96, 96, 96)",
                                    }
                                }}>
                                    <TableCell align='center'>{LBL_ROW_NUMBER}</TableCell>
                                    <TableCell align='center'>{LBL_ROW_STATUS}</TableCell>
                                    <TableCell align='center'>{LBL_MESSAGE}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dryRunTableData && dryRunTableData.length !== 0 ? dryRunTableData
                                    .map((row, i) => (<RenderDryRunTableBody row={row} />)) :
                                    (<TableRow>
                                        <TableCell component="th" scope="row" colSpan="7" >
                                            {ERR_NO_DATA_FOUND}
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={10}
                    rowsPerPage={0}
                    page={0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                </Paper>
            </>
        )
    }

    const RenderDryRunTableBody = (props) => {
        const { row } = props;

        return (
            <>
                <TableRow hover key={row.rowNo}>
                    <TableCell component="tb" scope="row" align="center">
                        {row.rowNo}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.rowStatus == PASS ? "Passed" : row.rowStatus}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {modifyMessage(row, 'DryRun')}
                    </TableCell>
                </TableRow>

            </>
        );
    }

    //method will modify message for end user based on user action
    const modifyMessage = (row, processStageName) => {
        if (row && row.rowStatus == PASS && row.action == CREATE) {
            row.message = INFO_USER_CREATED;
        } else if (row && row.rowStatus == PASS && (row.action == ADD || row.action == REMOVE)) {
            row.message = INFO_USER_UPDATED;
        } else if (row && row.rowStatus == FAILED && processStageName == MAINRUN) {
            const dryRunList = dryRunData?.rowLevelDetails ?? [];
            const dryRunObject = dryRunList.find(item => item.rowNo == row.rowNo);
            row.message = dryRunObject?.message ?? row.message;
        }
        return row?.message
    }

    const onClickDryRunUploadButton = () => {
        //set loader state true
        dispatch(updateLoaderState(true));
        var fileGuid = dryRunData?.fileId;
        console.log('onClickDryRunUploadButton', fileGuid);
        if (fileGuid != null) {
            dispatch(uploadMainRunData(fileGuid, tenantSelectedValue));
        } else {
            console.log("err")
        }
    }

    const onClickDryRunCancelButton = () => {
        resetChanges();
    }

    const onClickMainRunCloseButton = () => {
        resetChanges();
    }

    const MainRunGrid = () => {
        const mainRunTableData = mainRunData?.rowLevelDetails ?? [];
        return (
            <> <Paper sx={{ width: '100%', overflow: 'hidden', marginLeft: '10px' }}>
                <TableContainer >
                    <Table stickyHeader aria-label="sticky table"  >
                        <TableHead>
                            <TableRow sx={{
                                "& th": {
                                    fontSize: "1rem",
                                    color: "rgba(96, 96, 96)",
                                }
                            }}>
                                <TableCell align='center'>{LBL_ROW_NUMBER}</TableCell>
                                <TableCell align='center'>{LBL_ROW_STATUS}</TableCell>
                                <TableCell align='center'>{LBL_MESSAGE}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ?
                                <TableRow>
                                    <TableCell component="th" scope="row" colSpan="8" >
                                        <Loader />
                                    </TableCell>
                                </TableRow>
                                : ((mainRunTableData && mainRunTableData.length !== 0) ? mainRunTableData
                                    .map((row, i) => (<RenderMainRunTableBody row={row} />)) :
                                    (<TableRow>
                                        <TableCell component="th" scope="row" colSpan="7" >
                                            {ERR_NO_DATA_FOUND}
                                        </TableCell>
                                    </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            </>
        )
    }

    const RenderMainRunTableBody = (props) => {
        const { row } = props;

        return (
            <>
                <TableRow hover key={row.rowNo}>
                    <TableCell component="tb" scope="row" align="center">
                        {row.rowNo}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.rowStatus == PASS ? "Passed" : row.rowStatus}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {modifyMessage(row, 'MainRun')}
                    </TableCell>
                </TableRow>

            </>
        );
    }


    return (
        <div className={classes.container}>
            <div>
                <Accordion expanded={props.expandPanelOne} >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        sx={{
                            'background-image': 'linear-gradient(#17689e, #2b94c9)',
                            'color': 'white'
                        }}
                    >
                        <Typography>{LBL_DATA_UPLOAD_SELECTION}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container className={classes.gridContainer}>

                            <Grid item xs={3}>
                                <div> <Controls.AutoSuggest
                                    fieldId={'TenantTypes'}
                                    name={'TenantTypes'}
                                    label={LBL_SELECT_HEALTH_SYSTEM}
                                    value={tenantSelectedValue}
                                    onChange={(e, values) => { onTenantSelection(e, values) }}
                                    selections={orgTenantsList}
                                    disabled={loading}
                                    additionalProperties={{ "DisplayColumnId": "TenantKey", "DisplayColumnName": "TenantName" }}
                                /></div>
                            </Grid>
                            <Grid item xs={1}>

                            </Grid>
                            <Grid item xs={3} >
                                <div>
                                    {showUploadTypeDropdown &&
                                        <Controls.AutoSuggest
                                            fieldId={'UploadTypes'}
                                            name={'UploadTypes'}
                                            label={'Data Upload'}
                                            value={uploadTypeSelectedValue}
                                            onChange={(e, values) => { onDataUploadTypeSelection(e, values) }}
                                            selections={adminDataUploadTypesList}
                                            disabled={loading}
                                            additionalProperties={{ "DisplayColumnId": "adminDataUploadTypeId", "DisplayColumnName": "adminDataUploadTypeName" }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={1}>

                            </Grid>
                            <Grid item xs={3}>
                                <div>
                                    {showFileUploadControl &&
                                        <Controls.File
                                            fieldId={'adminFileUpload'}
                                            name={'fileUpload'}
                                            label={'Upload File'}
                                            value={uploadedFile}
                                            onChange={(e, values) => { onFileSelection(e, values) }}
                                            disabled={loading}
                                            isShowFilePreview={false}
                                        />
                                    }
                                    {props.error && props.error.length > 0 && (<span style={{ textAlign: 'left', verticalAlign: 'bottom', color: ICON_RED }}>{props.error}</span>)}
                                </div>
                            </Grid>
                            <Grid item xs={1} className={classes.progressContainer}>
                                {loading &&
                                    <CircularProgress size={24} />
                                }
                            </Grid>

                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={props.expandPanelTwo && props.isMandatoryDataForDryRunFilled} >
                    <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        sx={{
                            'background-image': 'linear-gradient(#17689e, #2b94c9)',
                            'color': 'white'
                        }}
                    >
                        <Typography>{LBL_DRY_RUN_STATUS}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {DryRunReportGrid()}
                        <div className={classes.dryRunReportActionContainer}>
                            {/* <Controls.Button
                                label={'Upload'}
                                fieldId={'Upload'}
                                onClick={() => { onClickDryRunUploadButton() }}
                                loader={loading}
                            /> */}
                            <MuiButton
                                size="large"
                                color="primary"
                                variant="contained"
                                onClick={() => { onClickDryRunUploadButton() }}
                                id="Upload"
                                disabled={loading}
                            >
                                {loading && <CircularProgress
                                    size={24}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />}  Upload
                            </MuiButton>
                            <Controls.Button
                                label={'Cancel'}
                                fieldId={'Cancel'}
                                onClick={() => { onClickDryRunCancelButton() }}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={props.expandPanelThree && props.isMandatoryDataForMainRunFilled} >
                    <AccordionSummary
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        sx={{
                            'background-image': 'linear-gradient(#17689e, #2b94c9)',
                            'color': 'white'
                        }}
                    >
                        <Typography>{LBL_MAIN_RUN_STATUS}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {MainRunGrid()}
                        <div className={classes.dryRunReportActionContainer}>
                            <Controls.Button
                                label={'Close'}
                                fieldId={'Close'}
                                onClick={() => { onClickMainRunCloseButton() }}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        error: state.dataUpload.error,
        expandPanelOne: state.dataUpload.expandPanelOne,
        expandPanelTwo: state.dataUpload.expandPanelTwo,
        expandPanelThree: state.dataUpload.expandPanelThree,
        isMandatoryDataForDryRunFilled: state.dataUpload.isMandatoryDataForDryRunFilled,
        isMandatoryDataForMainRunFilled: state.dataUpload.isMandatoryDataForMainRunFilled,
    }
}

export default connect(mapStateToProps)(DataUpload)
