import React from 'react'
import { FormControl, Switch } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

export default function Toggle(props) {

  const { handleChange, checked } = props;
  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 40,
      height: 24,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#2b94c9',
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 20,
      height: 18,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 25 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);
  return (
    <FormControl>
      <Switch size="medium" checked={checked}
        onChange={handleChange} color='primary' />
    </FormControl>
  )
}