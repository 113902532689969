import { updateIsRequiredValidation } from '../../../actions/CaseDetailsAction';
import {
    INPUT_VALUE_OF_TARGET_FIELD_VALUE_COMPARED_TO_PROVIDED_VALUE
} from '../../../constants/GlobalConstants';
import { store } from '../../../store';
import { calculateValue } from '../../CalculateValue';
import { getFieldValueById } from '../../GetFieldValueByID';

export const InputValueOfTargetFieldValueComparedToProvidedValue = (control) => {
    if (control && control.validation) {
        var validation = control.validation
        var checkFieldValidation = validation[INPUT_VALUE_OF_TARGET_FIELD_VALUE_COMPARED_TO_PROVIDED_VALUE] ? validation[INPUT_VALUE_OF_TARGET_FIELD_VALUE_COMPARED_TO_PROVIDED_VALUE] : null;
        var response = null;
        if (checkFieldValidation) {
            if (checkFieldValidation.every((rule) => {
                var targetValue = getFieldValueById(rule.TargetFieldId)
                var calc = calculateValue( targetValue, rule.ProvidedValue, rule.ComparisonOperator)
                if (rule.ExpectedResponse === 'True') {
                    return calc == true
                } else {
                    return calc == false
                }
            })) {
                store.dispatch(updateIsRequiredValidation(control.mappingId, false))
            } else {
                store.dispatch(updateIsRequiredValidation(control.mappingId, true))
            }
        }
    }
    return response;
}