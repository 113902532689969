import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Button from "./Button";
import Toggle from "./Toggle";
import Search from "./SearchBar";
import DatePicker from "./DatePicker";
import DatePickerDashboard from "./DatePickerDashboard";
import TimePicker from "./TimePicker";
import Typography from './Typography';
import AutoSuggest from './AutoSuggest';
import AutoSuggestDashboard from './AutoSuggestDashboard';
import Chip from './Chip';
import ChipHolder from './ChipHolder';
import Label from './Label';
import TableControl from './Table'
import File from './File'
import ImageButton from './ImageButton'
import EmbedHtml from './EmbedHtml'
import Alert from './Alert'
import Div from './Div'
import FilePreview from './DocumentViewer'
import Image from './Image'
import VerticalLabel from './VerticalLabel'
import TrayTable from './TrayTable'
import GreenButton from './GreenButton'
import MultiSelect from './MultiSelect'
import PdfViewer from './PDFViewer'
import AnchorLink from './AnchorLink'
import Snackbar from './Snackbar'

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    Button,
    Toggle,
    Search,
    DatePicker,
    DatePickerDashboard,
    TimePicker,
    Typography,
    AutoSuggest,
    AutoSuggestDashboard,
    Chip,
    ChipHolder,
    Label,
    TableControl,
    File,
    ImageButton,
    EmbedHtml,
    Alert,
    Div,
    FilePreview,
    Image,
    VerticalLabel,
    TrayTable,
    GreenButton,
    MultiSelect,
    PdfViewer,
    AnchorLink,
    Snackbar
}

export default Controls;