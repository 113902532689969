import { store } from '../../../store'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import * as Constants from '../../../constants/GlobalConstants'
import { getFieldById } from '../../GetFieldById'

export const execute = (control) => {
    const fieldProps = control.additionalProperties
    let value
    if (control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
        value = control.value ? (control.value[fieldProps.DisplayColumnId] ? control.value[fieldProps.DisplayColumnId] : control.value) : (fieldProps.DefaultValue ? fieldProps.DefaultValue : null)
    } else {
        value = control.value
    }
    if (value) {
        if (fieldProps.ShowImageOnTargetFieldIfFieldHasValue && fieldProps.ShowImageOnTargetFieldIfFieldHasValue.length > 0) {
            fieldProps.ShowImageOnTargetFieldIfFieldHasValue.forEach(fieldsAction => {
                let thenum = control?.mappingId?.match(/\d+/)[0]
                if (fieldsAction.FieldValue.includes(value.toString())) {
                    store.dispatch(VisibilityControlAction.showField(fieldsAction.TargetFieldId + thenum, null, control.parentFieldId))
                    fieldsAction?.ShowImage?.forEach(image => {
                        store.dispatch(VisibilityControlAction.showField(image));
                    })
                    fieldsAction?.HideImage?.forEach(image => {
                        store.dispatch(VisibilityControlAction.hideField(image));
                    })
                }
            })
        }
        if (fieldProps.HideTargetFieldIfFieldDoesNotHaveValue && fieldProps.HideTargetFieldIfFieldDoesNotHaveValue.length > 0) {
            fieldProps.HideTargetFieldIfFieldDoesNotHaveValue.forEach(field => {
                if (!field.FieldValue.includes(value.toString())) {
                    let thenum = control?.mappingId?.match(/\d+/)[0]
                    field.TargetFieldId.forEach(fieldId => {
                        store.dispatch(VisibilityControlAction.hideField(fieldId + thenum, null, control.parentFieldId));
                    })
                }
            })
        }
    }
}

const ShowImageOnTargetFieldIfFieldHasValue = {
    execute
}

export default ShowImageOnTargetFieldIfFieldHasValue