import { store } from '../../../store';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import { getFieldById } from '../../GetFieldById'
import { ERR_INVALID_DATE, COMMON_DATE_FORMAT, LBL_DAYS } from '../../../constants/GlobalConstants';
import moment from 'moment';

export const execute = (props) => {
    var fieldProps = props.additionalProperties
    var rule = fieldProps.CalculateDateAhead
    
     var inPopUp = rule.inpopup ? rule.inpopup : false;
    //{"InitialDate":"DocumentSignedDate", "ExpirationTimeFrameInDays":"DocumentTypeId.ExpirationTimeFrameInDays"}   
    if (rule && rule.DependentFieldId && rule.DependentFieldId.InitialDate && rule.DependentFieldId.InitialDate != ERR_INVALID_DATE) {
        var dependentFields = rule.DependentFieldId;
        var fieldValue = getFieldById(dependentFields.InitialDate).value;
        if (fieldValue) {
            const documentSignedDate = moment(fieldValue,COMMON_DATE_FORMAT,true);
            var tempKeysArray = dependentFields.ExpirationTimeFrameInDays.split('.')
            var tempObj = getFieldById(tempKeysArray[0])
            if (tempObj && tempObj.value && tempObj.value.ExpirationTimeFrameBl) {
                var expirationTimeFrameInDays = tempObj.value.ExpirationTimeFrameInDays;
                var docExpireDate = moment(documentSignedDate, COMMON_DATE_FORMAT).add(expirationTimeFrameInDays, LBL_DAYS);
                var value = moment(docExpireDate).format(COMMON_DATE_FORMAT);
                // update update in DocmentExpirationDate label
                store.dispatch(VisibilityControlAction.showField(rule.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(rule.TargetFieldId, value || rule.OnInvalidValue));
            } else{
                store.dispatch(VisibilityControlAction.showField(rule.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(rule.TargetFieldId, rule.OnInvalidValue));
            }
        }
    }

}

const CalculateDateAhead = {
    execute
}
export default CalculateDateAhead