import { store } from '../../../store'
import { getFieldById } from '../../GetFieldById';
import { getFieldByMappingId } from '../../GetFieldByMappingId';
import XControlUtils from '../../X-ControlUtils'

export const execute = (control) => {
  const tableRowIndex = control.mappingId.replace(/^\D+/g, "");
  const rule = control.additionalProperties.ShowTargetFieldIfDependentFieldHasAnyValue
  let fieldId = rule.DependentFieldId;
  var dependentFieldId = fieldId.replaceAll("[n]", tableRowIndex);
  if (dependentFieldId.includes('.')) {
    var path = []
    path = dependentFieldId.split('.');
    var getFieldValue = getFieldByMappingId(path[1], path[0]);
  }

  if (getFieldValue.value != undefined && getFieldValue.value != null) {
    for (var targetField of rule.TargetFieldId) {
      var t = targetField.replaceAll("[n]", tableRowIndex)
      store.dispatch(XControlUtils.modifyControl(t, 'isVisible', true, parseInt(tableRowIndex)))
    }
  }
}
const ShowTargetFieldIfDependentFieldHasAnyValue = {
  execute
}
export default ShowTargetFieldIfDependentFieldHasAnyValue