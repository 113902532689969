import React from 'react';
import DocumentTable from './DocumentTable'
import {
    Grid,
    Typography,
    Divider
} from '@mui/material';
import Controls from '../controls/Controls';
import UploadDocumentDialogBoxComponent from './UploadDocumentDialogBoxComponent';
import DocumentActionDialog from './DocumentActionsDialog';
import {
    getDocumentPopUpsByCaseIdSectionId, getDocumentPopUpSelectionControls, getViewDocumentPoupData, getViewDocumentFileStream,
    updateIsPopUpOpen, executeDownloadDocAPI, executeApproveAPI, executeDeleteDocAPI, executeRejectDocumentAPI,
    executeRetryFaxAPI, documentPopUpOpen, documentPopUpSectionLoad, sendSectionReleaseInfo,
} from '../../actions/CaseDetailsAction';
import { getIspopOpen, getDocPopUpOpen } from '../../reducers/CaseDetailsReducer';
import { useDispatch, useSelector } from 'react-redux';
import DialogBoxComponent from '../common/DialogBoxComponent'
import {
    BASE_URL
} from '../../constants/Locations';
import makeStyles from '@mui/styles/makeStyles';
import * as Constants from '../../constants/GlobalConstants';

const useStyles = makeStyles(theme => ({
    uploadDocBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
}))

const documentDialogState = {
    open: false,
    sectionId: null,
    mappingId: null,
    docId: null,
    caseId: null,
    title: '',
    actionTitle: {
        title1: '',
        title2: ''
    }
}

export default function DocumentManagement(props) {
    const { sectionDetails, disabled } = props;
    const [uploadOpen, setUploadOpen] = React.useState(false)
    const [documentDialogInfo, setDocumentDialogInfo] = React.useState(documentDialogState)
    const [rejectReason, setRejectReason] = React.useState('')
    const ispopUpOpen = useSelector(getIspopOpen);
    const isDocPopUpOpen = useSelector(getDocPopUpOpen);
    const dispatch = useDispatch()
    const classes = useStyles()

    // Getting additional propeties by mapping id
    const getAdditionalProperties = (mappingId) => {
        const elementDetails = sectionDetails.sectionHeader.sectionGroup[0].fields.find(item => item.mappingId == mappingId)
        const additionalProperties = JSON.parse(elementDetails.additionalProperties)
        return additionalProperties;
    }

    // This function is used to handle view event in document table
    const handleViewDocument = (item) => {
        const properties = JSON.parse(item.fieldInfo.additionalProperties)
        const sectionId = properties.SectionId
        const docId = item.docId
        const caseId = sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId;
        const mappingId = item.fieldInfo.mappingId
        const serviceAPIUrl = `${BASE_URL}/case/${caseId}/document/${docId}/view`
        dispatch(getViewDocumentPoupData(mappingId, sectionId, caseId, docId))
        dispatch(getViewDocumentFileStream(serviceAPIUrl))
        dispatch(updateIsPopUpOpen(true, true))
        setDocumentDialogInfo({
            ...documentDialogInfo,
            open: false,
            sectionId: sectionId,
            mappingId: mappingId
        })

    }
    
    //This function is used to handle retry fax event in document table
    const handleRetryFax = (item) => {
        const { docId } = item
        const caseId = sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId;
        dispatch(executeRetryFaxAPI(caseId, docId))
        dispatch(sendSectionReleaseInfo())
    }

    //This function is used to handle download event in document table
    const handleDownloadDocument = (item) => {
        const docId = item.docId
        const caseId = sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId;
        const serviceAPIUrl = `${BASE_URL}/case/${caseId}/document/${docId}/download`
        dispatch(executeDownloadDocAPI(serviceAPIUrl))
    }

    //This function is used to handle accept event in document table
    const handleAcceptDocument = (item) => {
        const docId = item
        const caseId = sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId;
        const serviceAPIUrl = `${BASE_URL}/case/${caseId}/document/${docId}/approve`
        dispatch(executeApproveAPI(serviceAPIUrl))
    }

    //This function is used to handle reject pop-up event in document table
    const handleRejectDocument = (item) => {
        const {fieldInfo, docId} = item;
        const properties = JSON.parse(fieldInfo.additionalProperties)
        const sectionId = properties.SectionId
        const caseId = sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId;
        const mappingId = item.fieldInfo.mappingId
        const actionBtn = {
            buttonTextOk: Constants.DOC_REJECT_BTN_LBL,
            buttonTextCancel: Constants.DOC_CANCEL_BTN_LBL
        }
        dispatch(getDocumentPopUpsByCaseIdSectionId(caseId, sectionId, Constants.REJECT_BTN_MAPPING_ID, docId))
        setDocumentDialogInfo({
            ...documentDialogInfo,
            open: true,
            caseId: caseId,
            docId: docId,
            mappingId: mappingId,
            title: Constants.REJECT_POP_UP_HEADER,
            actionTitle: actionBtn
        })
    }

    //This function is used to handle reject event after confirmation
    const confirmRejectDocument = () => {
        var rejectData = new FormData();
        rejectData.append("CaseId", documentDialogInfo.caseId);
        rejectData.append("DocumentId", documentDialogInfo.docId);
        rejectData.append("DocumentRejectionReason", rejectReason);
        dispatch(executeRejectDocumentAPI(rejectData))
        setDocumentDialogInfo(documentDialogState)
        setRejectReason("")
    }

    //This function is used to handle delete pop-up event in document table
    const handleDeleteDocument = (item) => {
        const {fieldInfo, docId} = item;
        const caseId = sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId;
        const mappingId = item.fieldInfo.mappingId
        const actionBtn = {
            buttonTextOk: Constants.DOC_DELETE_BTN_OK,
            buttonTextCancel: Constants.DOC_DELETE_BTN_CANCEL
        }
        setDocumentDialogInfo({
            ...documentDialogInfo,
            open: true,
            caseId: caseId,
            docId: docId,
            mappingId: mappingId,
            title: Constants.DELETE_POP_UP_HEADER,
            actionTitle: actionBtn
        })
    }

    //This function is used to handle delete event after confirmation
    const confirmDeleteDocument = () => {
        const requestPayload = [documentDialogInfo.caseId, '', documentDialogInfo.docId]
        dispatch(executeDeleteDocAPI(requestPayload))
        setDocumentDialogInfo(documentDialogState)
    }

    //This function is used to handle action dialog for reject/delete document
    const handleConfirmDialog = () => {
        if(documentDialogInfo.mappingId == Constants.DELETE_BTN_MAPPING_ID) {
            confirmDeleteDocument()
        }

        if(documentDialogInfo.mappingId == Constants.REJECT_BTN_MAPPING_ID) {
            confirmRejectDocument()
        }
    }

    // This function is used to handle edit pop-up event in document table
    const handleDocEdit = (item) => {
        const {fieldInfo, docId} = item;
        const properties = JSON.parse(fieldInfo.additionalProperties)
        const sectionId = properties.SectionId
        const caseId = sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId;

        const requestPayload = [
            {
                Id: "DocumentTypes",
                additionalData: {
                    HFProcedureUnitId: sectionDetails.sectionData.hfProcedureUnitId,
                    HFServiceLineId: sectionDetails.sectionData.hfServiceLineId,
                    HFSurgicalLocationId: sectionDetails.sectionData.hfSurgicalLocationId,
                }
            },
            {
                Id: "DocumentSupportedFileTypes",
                additionalData: null
            }
        ]

        const serviceAPIUrl = `${BASE_URL}/case/${caseId}/document/${docId}/view`
        dispatch(documentPopUpSectionLoad(false))
        dispatch(getViewDocumentFileStream(serviceAPIUrl, true))
        dispatch(getDocumentPopUpSelectionControls(requestPayload))
        dispatch(getDocumentPopUpsByCaseIdSectionId(caseId, sectionId, Constants.DOC_FILE_SAVE_MAPPING_ID, docId))
        dispatch(documentPopUpOpen(true))
    }

    //This function is used to handle dialog box for upload document
    const handleUploadButtonClick = () => {
        const requestPayload = [
            {
                Id: "DocumentTypes",
                additionalData: {
                    HFProcedureUnitId: sectionDetails.sectionData.hfProcedureUnitId,
                    HFServiceLineId: sectionDetails.sectionData.hfServiceLineId,
                    HFSurgicalLocationId: sectionDetails.sectionData.hfSurgicalLocationId,
                }
            },
            {
                Id: "DocumentSupportedFileTypes",
                additionalData: null
            }
        ]
        const caseId = sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId;
        const addtionalProperties = getAdditionalProperties(Constants.UPLOAD_BTN_MAPPING_ID)
        dispatch(documentPopUpSectionLoad(false))
        dispatch(getDocumentPopUpsByCaseIdSectionId(caseId, addtionalProperties.SectionId, Constants.UPLOAD_BTN_MAPPING_ID))
        dispatch(getDocumentPopUpSelectionControls(requestPayload))
        dispatch(documentPopUpOpen(true))
    }

    // This function is used to hide/show upload button for specific user
    const uploadDcoumnetVisible = () => {
        const elementDetails = sectionDetails.sectionHeader.sectionGroup[0].fields.find(item => item.mappingId == Constants.UPLOAD_BTN_MAPPING_ID)
        if(elementDetails) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <UploadDocumentDialogBoxComponent
                open={isDocPopUpOpen}
                dialogTitle={Constants.UPLOAD_POP_UP_HEADER}
                parentComponent={Constants.UPLOAD_BTN_MAPPING_ID}
                dialogContentText={""}
                maxWidth={""}
                caseId={sectionDetails.sectionHeader.bSheet_HeaderInformation.caseId}
                onCloseDialog={() => {
                    dispatch(documentPopUpOpen(false))
                    dispatch(documentPopUpSectionLoad(false))
                }}
            />
            <DocumentActionDialog
                open={documentDialogInfo.open}
                dialogTitle={documentDialogInfo.title}
                mappingId={documentDialogInfo.mappingId}
                actionTitle={documentDialogInfo.actionTitle}
                inputVal={rejectReason}
                setInputVal={(item) => setRejectReason(item)}
                onCloseDialog={() => {
                    setDocumentDialogInfo(documentDialogState)
                    setRejectReason("")
                }}
                onConfirmDialog={() => handleConfirmDialog()}
            />
            <DialogBoxComponent
                open={ispopUpOpen}
                dialogTitle={Constants.VIEW_POP_UP_HEADER}
                section={documentDialogInfo.sectionId}
                parentComponent={documentDialogInfo.mappingId}
                dialogContentText={""}
                maxWidth={""}
            />
            <Controls.Snackbar />
            <Grid container sx={{ paddingTop: '28px' }} spacing={2}>
                <Grid item xs={12} >
                    <Typography>{Constants.DOC_SECTION_HEADER}</Typography>
                    <Divider/>
                </Grid>
                {uploadDcoumnetVisible() && (
                <Grid item xs={12} className={classes.uploadDocBtnContainer}>
                    <Controls.Button label={Constants.UPLOAD_BTN_LBL} onClick={() => handleUploadButtonClick()} disabled={disabled} />
                </Grid>
                )}
                
                <Grid
                    item xs={12} >
                    <DocumentTable
                        handleViewDocument={(item) => handleViewDocument(item)}
                        handleDownloadDocument={(item) => handleDownloadDocument(item)}
                        handleAcceptDocument={(item) => handleAcceptDocument(item)}
                        handleRejectDocument={(item) => handleRejectDocument(item)}
                        handleDeleteDocument={(item) => handleDeleteDocument(item)}
                        handleRetryFax={(item) => handleRetryFax(item)}
                        handleDocEdit={(item) => handleDocEdit(item)}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </>
    )
}