import { Avatar, Button, Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getNotificationPreferences, saveUserNotificationPreferences } from '../../actions/UserProfileAction';
import { PAPER_BG } from '../../res/values/Colors';
import UserProfileService from '../../services/UserProfileService';
import Controls from '../controls/Controls';
import {
    LBL_HOME_ORGANIZATION, LBL_EMAIL_ADDRESS, LBL_PHONE_NUMBER, LBL_ALTERNATE_PHONE_NUMBER,
    LBL_AFFILIATED_HOSPITALS, LBL_AFFILIATED_SURGEONS, LBL_LAST_LOGGED_IN, LBL_USER_SINCE, LBL_SAVE
} from '../../constants/GlobalConstants';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: PAPER_BG,
        padding: '10px',
        minWidth: 180,
        maxWidth: 360
    },
    usericon: {
        backgroundColor: "#2b94c9 ",
        color: "#fff",
        width: '150px',
        height: '150px',
        marginBottom: '12px',

    },
    caption: {
        color: "#2b94c9",
    },
    useravatar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    userDetailCell: {
        width: '100%',
    },
    userDetailText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}))


const UserInfo = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [userInfo, setUserInfo] = useState({
        firstname: '',
        lastname: '',
        homeorganizationname: '',
        email: '',
        phoneNumber: '',
        alternatePhoneNumber: '',
        authorizations: '',
        usersince: '',
    });

    useEffect(() => {
        UserProfileService.getUserProfileInfo().then((response) => {

            const result = response.responseData;
            setUserInfo({
                firstname: result.UserInfo.FirstName,
                lastname: result.UserInfo.LastName,
                homeorganizationname: result.UserInfo.HomeOrganizationName,
                email: result.UserInfo.EmailAddress,
                phoneNumber: result.UserInfo.PhoneNumber,
                alternatePhoneNumber: result.UserInfo?.AlternatePhoneNumber,
                authorizations: result.UserRoleAuthorizatons,
            })
        })

    }, [])

    const userInfoAuthorizatioinsObject = [...userInfo.authorizations];

    let userAuthorizationList = (userInfoAuthorizatioinsObject.map((x) => x.Authorizations)).reduce((a, b) => { return a.concat(b) }, []);

    const finalHospitalList = [...new Set(userAuthorizationList.map((element) =>
        element.HospitalName))];

    const finalSurgeonlist = [...new Set(userAuthorizationList.map((element) =>
        element.SurgeonName))]

    const handleSavePreferences = () => {
        dispatch(saveUserNotificationPreferences())
    }
    return (
        <div className={classes.root}>
            <Grid container className={classes.useravatar} >
                <Avatar className={classes.usericon} />
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Controls.Typography variant='h5' style={{ color: "#2b94c9" }}>{userInfo.firstname}  </Controls.Typography>
                <Controls.Typography variant='subtitle2'>{userInfo.lastname}</Controls.Typography>
                </div>

            </Grid>
            <Divider style={{ marginTop: '18px' }} />

            <Grid container spacing={2} direction='column' style={{ marginTop: '48px', paddingLeft: '10px'}}>
                <Grid item className={classes.userDetailCell}>
                    <Controls.Typography variant='body2' className={classes.caption}> {LBL_HOME_ORGANIZATION}</Controls.Typography>
                    <Controls.Typography variant='subtitle2' className={classes.userDetailText}>{userInfo.homeorganizationname} </Controls.Typography>
                </Grid>
                <Grid item className={classes.userDetailCell}>
                    <Typography variant='body2' className={classes.caption}> {LBL_EMAIL_ADDRESS}</Typography>
                    <Typography variant='subtitle2' className={classes.userDetailText}>{userInfo.email} </Typography>
                </Grid>
                <Grid item className={classes.userDetailCell}>
                    <Controls.Typography variant='body2' className={classes.caption}> {LBL_PHONE_NUMBER}</Controls.Typography>
                    <Controls.Typography variant='subtitle2' className={classes.userDetailText}> {userInfo.phoneNumber}</Controls.Typography>
                </Grid>
                <Grid item className={classes.userDetailCell}>
                    <Controls.Typography variant='body2' className={classes.caption}> {LBL_ALTERNATE_PHONE_NUMBER}</Controls.Typography>
                    <Controls.Typography variant='subtitle2' className={classes.userDetailText}> {userInfo.alternatePhoneNumber}</Controls.Typography>
                </Grid>
                <Grid item className={classes.userDetailCell}>
                    <Controls.Typography variant='body2' className={classes.caption}> {LBL_AFFILIATED_HOSPITALS} </Controls.Typography>
                    {
                        (finalHospitalList) && (finalHospitalList.map((hospital) => (<Controls.Typography variant='subtitle2' className={classes.userDetailText}> {hospital} </Controls.Typography>
                        )))
                    }
                </Grid>
                <Grid item className={classes.userDetailCell}>
                    <Controls.Typography variant='body2' className={classes.caption} > {LBL_AFFILIATED_SURGEONS}</Controls.Typography>
                    {
                        (finalSurgeonlist) && (finalSurgeonlist.map((surgeon) => (<Controls.Typography variant='subtitle2' className={classes.userDetailText}> {surgeon} </Controls.Typography>
                        )))
                    }
                </Grid>
                <Grid item className={classes.userDetailCell}>
                    <Controls.Typography variant='body2' className={classes.caption} > {LBL_LAST_LOGGED_IN}</Controls.Typography>
                    <Controls.Typography variant='subtitle2' className={classes.userDetailText}> {userInfo.lastlogin}</Controls.Typography>
                </Grid>
                <Grid item className={classes.userDetailCell}>
                    <Controls.Typography variant='body2' className={classes.caption} > {LBL_USER_SINCE}</Controls.Typography>
                    <Controls.Typography variant='subtitle2' className={classes.userDetailText}> {userInfo.usersince}</Controls.Typography>
                </Grid>
            </Grid>
            <Button style={{ marginTop: '64px', color: "#2b94c9", marginLeft: '10px' }} onClick={handleSavePreferences}> {LBL_SAVE}</Button>
        </div >



    )
}

export default UserInfo
