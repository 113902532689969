import React, { Component } from 'react';
import { connect } from 'react-redux';
import { controlByType } from '../common/DashboardControls';
import { getSelectionsForControl } from '../../reducers/CaseDetailsReducer';
import { dashboardFilterFields, getPrimarySurgeonSelections, getSurgicalLocationSelections } from '../../reducers/DashboardReducer';
import { updateDashboardFilter } from '../../actions/DashboardAction'

const mapStateToProps = state => ({
    controlSelections: referenceId => getSelectionsForControl(state, referenceId),
    dashboardFilters: dashboardFilterFields(state),
    primarySurgeonsList: getPrimarySurgeonSelections(state),
    surgicalLocationsList: getSurgicalLocationSelections(state)
})


class DashboardFieldComponent extends Component {

    constructor(props) {
        super(props);
        //this.state = props.searchControlsData;
        this.handleChange = this.handleChange.bind(this);
        this.getControl(
            props.control
        );
    }

    handleChange(event, value, mapping_id) {
        const replacedDashboardFilter = this.props.dashboardFilters

        replacedDashboardFilter.fieldPermissions.map((element) => {
            if (this.props.fieldType == "Date") {
                if (element.fieldMappingId == "PatientDOB") {
                    if (value == undefined) {
                        if(typeof(event) == 'object'){
                            element.value = event;
                        } else{
                            element.value = event == null ? "" : event.target == null ? "" : event.target.value
                        }
                    }
                    else {
                        element.value = value == null ? "" : value;
                    }
                }
            }
            else {
                if (value != undefined && event.target.value == 0 ) {
                    var currentMappingId = event.target.id.split("-")[0];
                    if (element.fieldMappingId == currentMappingId) {
                        element.value = value
                    }
                
                }

                if(value==null && element.fieldMappingId=="PrimarySurgeonId" && mapping_id=="PrimarySurgeonId" ){
                    element.value=null;
                }      
                if(value==null && element.fieldMappingId=="SurgicalLocationId" && mapping_id=="SurgicalLocationId"){
                    element.value=null;
                }     

                if (element.fieldMappingId == event.target.id) {
                    element.value = event.target.value
                }
            }
        })
        this.props.updateDashboardFilter(replacedDashboardFilter);
    }

    getControl(control) {
        let controlValue = controlByType(control.fieldControlName);
        if (controlValue != undefined) {
            let [Control, overrides] =
                controlValue;
            this.Control = Control;
            this.overrides = overrides;
        }
    }

    getSelections = (mappingId) => {
        if (mappingId == "PrimarySurgeonId") {
            return this.props.primarySurgeonsList!=undefined && this.props.primarySurgeonsList!= null ? this.props.primarySurgeonsList.selections : [];
        }
        if (mappingId == "SurgicalLocationId") {
            return this.props.surgicalLocationsList!=undefined && this.props.surgicalLocationsList!= null ? this.props.surgicalLocationsList.selections : [];
        }
        // var selections = this.props.controlSelections(referenceId);
        // if (selections == undefined || selections == null) {
        //     if (this.props.control.parentMappingId) {
        //         selections = this.props.controlSelections(this.props.control.parentMappingId);
        //     }
        // }
        return;
    }

    render() {
        let {
            fieldId,
            fieldName,
            fieldControlName,
            value,
            options,
            format,
            margin,
            label,
            variant,
            fieldMappingId,
            ...otherProps
        } = this.props.control;
        const { Control, overrides } = this;
        var selections = this.getSelections(fieldMappingId);
        return (
            <>
                {Control &&
                    <Control
                        fieldId={fieldMappingId}
                        selections={selections}
                        value={value}
                        label={fieldName}
                        options={options}
                        format={format}
                        margin={margin}
                        variant={variant}
                        onChange={(e, value) => { this.handleChange(e, value, fieldMappingId) }}
                    />
                }
            </>
        );
    }
}
export default connect(
    mapStateToProps,
    { updateDashboardFilter },
)(DashboardFieldComponent);
