import { store } from "../../../../store"
import { checkListByType } from "./RuleManagementChecklist/CheckList"
import SectionAlertAction from '../../../../actions/SectionAlertAction'

export const execute = (rule) => {
    store.dispatch(SectionAlertAction.clearSectionAlert())
    rule.Alerts.map(alert => {
        let check = checkListByType(alert.Name)
        if(check) {
            if (check.execute(rule)) {
                store.dispatch(SectionAlertAction.showSectionAlert(alert.Name, alert.Severity, alert.Message))
            } else {
                store.dispatch(SectionAlertAction.hideSectionAlert(alert.Name, alert.Severity, alert.Message))
            }
        }
    })
}

const Alerts = {
    execute
}

export default Alerts