import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        success: {
            light: "#4caf50",
            main: "#2e7d32",
            dark: "#1b5e20"
        },
        error: {
            light: "#ef5350",
            main: "#d32f2f",
            dark: "#c6282"
        },
    },
});