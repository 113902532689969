//TODO: Need to test where this file is used and why, and how we can handle it with MUI-5
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//import SearchBar from "material-ui-search-bar"; 

//const options = ['Option 1', 'Option 2'];

export default function Search(props) {
  //const [value, setValue] = React.useState("");
 // const [inputValue, setInputValue] = React.useState('');
  const { value } = props;
  const useStyles = makeStyles(theme => ({
      root: {
       height: '30px'
      }
  }))
  const classes = useStyles();

  return (
    <div>      
      <Autocomplete
        value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
       // inputValue={inputValue}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
       // className={classes.root}
        id="searchbar"
       // options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Controllable" />}
      />
        {/* <FormControl>
         <SearchBar className={classes.root} value={value}/>
        </FormControl> */}
    </div>
  );
}
