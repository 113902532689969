import { fieldFieldRecursivelyInChildControl } from './fieldFieldRecursivelyInChildControl';
export const findFieldInGroup = (group, fieldID) => {
 var groupFields=group.fields;
    for (let i = 0; i < groupFields.length; i++) {
        if (groupFields[i].mappingId === fieldID) {
            return groupFields[i];
        }
        if (groupFields[i].childControls.length > 0) {
            var found = fieldFieldRecursivelyInChildControl(groupFields[i], fieldID);
            if (found) return found;
        
        }
    }
}