import { store } from '../../../store';
import round from 'lodash/round';
import moment from 'moment';
import { getFieldValueById } from '../../GetFieldValueByID';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import {ERR_INVALID_DATE} from "../../../constants/GlobalConstants";
export const execute = (props, value) => {
    let fieldProps = props.additionalProperties;
    if (fieldProps.CalculateBMIAgeRatio) {
        var BMIRatio = null;
        var age = null;

        var heightFeet = getFieldValueById(fieldProps.CalculateBMIAgeRatio.DependentFieldId.HeightFeet);
        var heightInches = getFieldValueById(fieldProps.CalculateBMIAgeRatio.DependentFieldId.HeightInches);
        var weight = getFieldValueById(fieldProps.CalculateBMIAgeRatio.DependentFieldId.Weight);
        var dateOfBirth = getFieldValueById(fieldProps.CalculateBMIAgeRatio.DependentFieldId.DOB);
        var OnInvalidValue=fieldProps.CalculateBMIAgeRatio.OnInvalidValue

        heightFeet = (heightFeet!=null && heightFeet !='') ? parseInt(heightFeet) * 12 : 0;
        heightInches = (heightInches!=null && heightInches !='') ? parseInt(heightInches) : 0;
        weight =(weight!=null && weight !='') ? parseFloat(weight) :0 ;

        if (dateOfBirth && dateOfBirth != ERR_INVALID_DATE) {
            const today = moment(new Date());
            const dob = moment(dateOfBirth);
            age = today.diff(dob, 'year'); // in year 
        }
        if (age != null && heightFeet != null && heightInches != null  && weight != null ) {
            var totalHeight = heightFeet + heightInches;
            BMIRatio = round(((weight / (totalHeight * totalHeight)) * 703) / age, 2);
            if(BMIRatio=="Infinity"){
                BMIRatio= OnInvalidValue;  
            }
        } else {
            BMIRatio= OnInvalidValue; 
        }
        
        store.dispatch(updateControlValue(fieldProps.CalculateBMIAgeRatio.TargetFieldId, BMIRatio));

    }
}
const CalculateBMIRatio = {
    execute
}
export default CalculateBMIRatio