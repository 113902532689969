import React from 'react'
//import { Calendar as MuiCalendar } from 'react-calendar'
//import 'react-calendar/dist/Calendar.css';
import makeStyles from '@mui/styles/makeStyles';

import { styled } from "@mui/material/styles";
import { TextField, Button } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: '#fafafc',
    padding: theme.spacing(2),
    borderStyle: 'none',
    '& button': {
      fontSize: '0.8rem',
      textTransform: 'uppercase',
      '& react-calendar__tile--now': {
        background: 'red'
      }

    },

  },

}))

export default function Calendar(props) {
  const classes = useStyles();
  const { value, onChange } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        autoFocus
        displayStaticWrapperAs="desktop"
        label="Week picker"
        value={value}
        onChange={onChange}
        className={classes.root}
        // renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField {...params} />}
        // inputFormat="'Week of' MMM d"
      />      
    </LocalizationProvider>
  )
}
