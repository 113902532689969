import  {getFieldById}  from "../../GetFieldById";
//import { getFieldValueById } from "../../GetFieldValueByID";

import { store } from '../../../store'
import { updateIsRequiredValidation } from '../../../actions/CaseDetailsAction'

const execute = (control) => {
    let validationProps = control.validation;
    let fieldMappingId = control.mappingId;
    var response = null;
    if (validationProps.CountOfTargetFieldValuesGreaterThanProvidedValue) {
        
        validationProps.CountOfTargetFieldValuesGreaterThanProvidedValue.map(rule => {
            let targetFieldVal = '';
            let targetField = getFieldById(rule.TargetFieldId)
            if(targetField.fieldTypeName=='Table'){
                 targetFieldVal = targetField.childControls.length-1;
                 if (targetFieldVal <= parseInt(rule.ProvidedValue)) {
                    store.dispatch(updateIsRequiredValidation(fieldMappingId, true))
                    response = {
                        isRequired: true,
                        status: false,
                        message: ""
                      };
                } else {
                    store.dispatch(updateIsRequiredValidation(fieldMappingId, false))               
                }
            }else{
                 targetFieldVal = targetField.value;
                 if (!targetFieldVal || targetFieldVal.length <= parseInt(rule.ProvidedValue)) {
                    store.dispatch(updateIsRequiredValidation(fieldMappingId, true))
                    response = {
                        isRequired: true,
                        status: false,
                        message: ""
                      };
                } else {
                    store.dispatch(updateIsRequiredValidation(fieldMappingId, false))               
                }
            }
          
        })
    }
    return response;
}

const CountOfTargetFieldValuesGreaterThanProvidedValue = {
    execute
}

export default CountOfTargetFieldValuesGreaterThanProvidedValue