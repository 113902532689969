import { copyRefData, reloadAndCopyReferenceData } from "../../../actions/ControlSelectionsAction"
import { store } from "../../../store"
import { getFieldValueById } from "../../GetFieldValueByID"

export const execute = (control, onLoadFlag,ReloadReferenceDataOnChange) => {
    if (onLoadFlag) {
        let referenceDataId = control.additionalProperties.LoadReferenceData
        let dependentFields = control.additionalProperties.DependentFields
        let refDataDependentFieldVal
        if (dependentFields) {
            dependentFields.map(fieldId => {
                refDataDependentFieldVal = {
                    ...refDataDependentFieldVal,
                    [fieldId]: getFieldValueById(fieldId)
                }
            })
            if(ReloadReferenceDataOnChange){
                store.dispatch(reloadAndCopyReferenceData(control.mappingId, referenceDataId, refDataDependentFieldVal))
            }
         


        } else {
            store.dispatch(copyRefData(control.mappingId, referenceDataId))
        }
    }
}

const LoadReferenceData = {
    execute
}

export default LoadReferenceData