import { updateControlValue } from "../../../actions/CaseDetailsAction"
import { store } from "../../../store"
import { calculateValue } from "../../CalculateValue"
import { getFieldById } from "../../GetFieldById"

const execute = (control, AddOnLoadONChangeFlag = null) => {
    const fieldProps = control.additionalProperties
    let rules = control.additionalProperties.AssignValueToTargetFieldDueToDependentField
    if (AddOnLoadONChangeFlag) {
        if (rules) {
            rules.map(rule => {
                let targetFieldValue = control.value
                var filedID = rule.DependentFieldId;
                var targetFieldId = rule.TargetFieldId;
                var field = getFieldById(filedID);
                var dependentFieldVal = field ? field.value : null
                if (dependentFieldVal) {
                    var checkValue = calculateValue(dependentFieldVal, rule.DependentFieldValue, rule.Operator)
                    checkValue && store.dispatch(updateControlValue(targetFieldId, targetFieldValue));
                }
            });
        }
    }
}

const AssignValueToTargetFieldDueToDependentField = {
    execute
}
export default AssignValueToTargetFieldDueToDependentField