const execute = (control, AddOnLoadONChangeFlag = null) => {
  if (AddOnLoadONChangeFlag) {
    const rule = control.additionalProperties.OnClickOpenPrintDialog
    if (rule) {
        //copy html from target control for print   
        var content = document.getElementById(rule.TargetFieldId);
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write("<style> @media print { table.report-container {  page-break-after: always; } thead.report-header { display: table-header-group; padding: 5px; }   thead.report-header .report-header-cell {border-bottom: 1px solid #CCC;} tfoot.report-footer  { display: table-footer-group; font-weight: bold; text-align: center; padding: 0 5px; } } @media all { #EMRSummary strong { text-decoration: underline } .container { margin-bottom: 5px; padding: 5px 0; font-weight: normal; } .container * { font-size: medium; -webkit-print-color-adjust: exact; } .container [class*='col-'] { vertical-align: top; float: left; } .col-1 {width: 8.33%;} .col-2 {width: 16.66%;} .col-3 {width: 25%;} .col-4 {width: 33.33%;} .col-5 {width: 41.66%;} .col-6 {width: 50%;} .col-7 {width: 58.33%;} .col-8 {width: 66.66%;} .col-9 {width: 75%;} .col-10 {width: 83.33%;} .col-11 {width: 91.66%;} .col-12 {width: 100%;} .pull-right{float:right !important} .pull-left{float:left !important} .text-right{text-align:right !important} .text-left{text-align:left !important} .logo {max-width: 300px; padding: 1px;} .text-primary{color:#17689e} .report-header{font-weight: normal; color: grey; text-decoration: none;}.text-grey{color:#5A5A5A} } </style>");
        pri.document.write("<style> /* EHRSummary CSS */ #EMRSummary strong { text-decoration: underline } #EMRSummary h3 { margin-bottom: 5px } #EMRSummary p { margin: 0 }</style>");
        pri.document.write(content.outerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }
  }
}


const OnClickOpenPrintDialog = {
  execute
}
export default OnClickOpenPrintDialog