import {
  INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE_DUE_TO_DEPENDENT_FIELD_VALUE,
  NUMBERS_ONLY,
  TEXT,
  NUMBER,
  TEXT_ONLY,
  DEPENDENT_FIELD_ID,
  DEPENDENT_FIELD_VALUE
} from '../../../constants/GlobalConstants';
import { getFieldValueById } from '../../GetFieldValueByID';

export const inputLengthEqualToProvidedValueDueToDependentFieldValue = (control) => {
  var fieldLenght = control.value ? control.value.length : 0;
  if (control && control.validation) {
    var validation = control.validation
    var checkDependentFieldsEqualTo = validation[INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE_DUE_TO_DEPENDENT_FIELD_VALUE] ? validation[INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE_DUE_TO_DEPENDENT_FIELD_VALUE] : null;    
    var response = null;
    if (checkDependentFieldsEqualTo != null) {
      checkDependentFieldsEqualTo.forEach(checkDependent => {
        var dependentFieldID = checkDependent[DEPENDENT_FIELD_ID] ? checkDependent[DEPENDENT_FIELD_ID] : null;
        var checkDependentFieldValue = checkDependent[DEPENDENT_FIELD_VALUE] ? checkDependent[DEPENDENT_FIELD_VALUE] : null;
        var providedMaxLength = checkDependent.ProvidedValue ? checkDependent.ProvidedValue : null;

        var dependentFieldValue = '';
        if (dependentFieldID) {
          dependentFieldValue = getFieldValueById(dependentFieldID);
          if (dependentFieldValue == checkDependentFieldValue){
            if(!providedMaxLength.includes(fieldLenght.toString())) {
            response = {
              isRequired: true,
              status: false,
              message: "Invalid zip code!"
            };
          }
        }
        }
      });
    }
  }
  return response;
}