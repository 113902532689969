import {
    updateSelectedSectionName, updateControlValue, updateSelectedSectionId, updateCaseAmendmentBlStatus,
    updateSectionGroupLoaderStatus, clearPopupStoreData, updateCaseEditableMode
} from "../../../actions/CaseDetailsAction"
import { showDialog } from "../../../actions/DialogAction"
import { store } from "../../../store"
// import OnClickAddValueToTargetField from './OnClickAddValueToTargetField';
// import OnClickExecuteServiceAPI from './OnClickExecuteServiceAPI';
import * as Constants from "../../../constants/GlobalConstants";
import RuleFactory from './RuleFactory';

const execute = (control, AddOnLoadONChangeFlag = null) => {
    if (AddOnLoadONChangeFlag) {
        // store.dispatch(showDialog(control.parentContextId, false))
        const rule = control.additionalProperties.OnClickClosePopup
        rule.sort(function (a, b) {
            return a.SequenceNo - b.SequenceNo;
        })

        rule.map(configFields => {
            var keys = Object.keys(configFields)
            var values = Object.values(configFields)
            var controlCpy = {
                ...control,
                additionalProperties: configFields,
            }
            for (var item = 0; item < keys.length; item++) {
                var rule = RuleFactory.getApplicableRule(keys[item]);
                //if rule is already available then execute that rule else put another condition
                if (rule) {
                    rule.execute(controlCpy, true)
                }
                else if (keys[item] == Constants.UPDATE_VALUES_OF_DEPENDENT_FIELDS) {
                    const state = store.getState();
                    var dependentFieldList = state.caseDetails.dependentFieldList;
                    dependentFieldList && dependentFieldList.forEach(fieldId => {
                        var fieldValue = ""+ values[item][fieldId];
                        store.dispatch(updateControlValue(fieldId, fieldValue));
                    })
                }
                else if (keys[item] == Constants.DATA_TO_CLEAR) {
                    values[item].forEach(fieldId => {
                        store.dispatch(updateControlValue(fieldId, null));
                    });

                    //Need to reset store values as well for popup
                    store.dispatch(clearPopupStoreData());
                }
                else if (keys[item] == Constants.RELOAD_SECTION) {
                    //TODO: Need to update rule a/c section url not by sectionid
                    const sectionId = values[item].SectionId ? values[item].SectionId : null;
                    const sectionName = values[item].SectionName ? values[item].SectionName : null;
                    if (sectionId == Constants.CASE_SUMMARY_ID) { //7-Donotcancel
                        store.dispatch(updateSectionGroupLoaderStatus(true));
                        store.dispatch(updateSelectedSectionName(Constants.CASE_SUMMARY_PAGE_URL));
                        store.dispatch(updateSelectedSectionId(sectionId));
                        store.dispatch(updateCaseAmendmentBlStatus(false));
                        store.dispatch(updateCaseEditableMode(false));
                    } else if (sectionId == Constants.SCHEDULING_ID) { //4-cancelcase
                        store.dispatch(updateSectionGroupLoaderStatus(true));
                        store.dispatch(updateSelectedSectionName(Constants.SCHEDULING_PAGE_URL));
                        store.dispatch(updateSelectedSectionId(sectionId));
                        store.dispatch(updateCaseAmendmentBlStatus(false));
                        store.dispatch(updateCaseEditableMode(false));
                    } else if (sectionName == Constants.FINANCIAL_PAGE_URL.replace("/", "")) {
                        store.dispatch(updateSectionGroupLoaderStatus(true));
                        store.dispatch(updateSelectedSectionName(Constants.FINANCIAL_PAGE_URL));
                        store.dispatch(updateSelectedSectionId(sectionId));
                        store.dispatch(updateCaseAmendmentBlStatus(false));
                        store.dispatch(updateCaseEditableMode(false));
                    }
                }
            }

        })
    }
}

const OnClickClosePopup = {
    execute
}

export default OnClickClosePopup