import {copyTextToClipBoard} from "../../Clipboard";

const execute = (control, AddOnLoadONChangeFlag = null) => {
  if (AddOnLoadONChangeFlag) {
    const rule = control.additionalProperties.OnClickCopyContentFromTargetField
    if (rule) {
      copyTextToClipBoard(rule.TargetFieldId);
    }
  }
}


const OnClickCopyContentFromTargetField = {
  execute
}
export default OnClickCopyContentFromTargetField