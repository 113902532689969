import React from 'react';

export const createBlobFromBase64 = (base64str, type) => {

  if (!base64str || base64str.length <= 0) return false;

  const binary = atob(base64str.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([view], {type: type});
  return URL.createObjectURL(blob);
};

export const renderPDF = (fileStream) => {
  const blobUrl = createBlobFromBase64(fileStream, 'application/pdf');
  return <embed className="embed-content" style={{ width: '100%', height: '95%' }} type="application/pdf" src={blobUrl} />
};

export const renderImage = (fileStream) => {
  const blobUrl = createBlobFromBase64(fileStream, 'image/png');
  return <img style={{ width: '100%' }} src={blobUrl}  />
};