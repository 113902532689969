import { store } from '../../../store';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { getFieldValueById } from '../../GetFieldValueByID';
import { getFullAge } from '../../fieldProperties/DateTime';

export const execute = (props) => {
    var fieldProps = props.additionalProperties
    var rule = fieldProps.CalculatePatientAgeInYearsOnProcedureDate
    var dependentFields = rule.DependentFieldId
    var calculatedAgeOnDate = getFullAge(getFieldValueById(dependentFields.PastDate), getFieldValueById(dependentFields.FutureDate))
    store.dispatch(updateControlValue(rule.TargetFieldId, calculatedAgeOnDate));
}
const CalculatePatientAgeInYearsOnProcedureDate = {
    execute
}
export default CalculatePatientAgeInYearsOnProcedureDate