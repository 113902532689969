import Controls from '../controls/Controls'

export const controlByType = fieldControlName => {
    const type = fieldControlName.toLowerCase().trim();
    const controls = {
      text: [Controls.Input, { variant: 'outlined' }],
      password: [Controls.Input, { variant: 'outlined' }],
      number: [
        Controls.Input,
        { variant: 'outlined', type: 'number' },
      ],
      phonenumber: [
        Controls.Input,
        { variant: 'outlined', type: 'phonenumber' },
      ],
      zipcode: [
        Controls.Input,
        { variant: 'outlined', type: 'zipcode'},
      ],
      label: [
        Controls.Label,
        { variant: 'outlined'},
      ],  
      table: [
        Controls.TableControl,
        { variant: 'outlined'},
      ],      
    //   radio: [CaseSectionRadioControl, {}],
       date: [Controls.DatePickerDashboard, { variant: 'outlined' }],
       time: [Controls.TimePicker, { variant: 'outlined' }],
       selection: [Controls.AutoSuggest],       
       selectionkeyvalue: [Controls.AutoSuggestDashboard],
       typeahead: [Controls.AutoSuggest],
    //   productselect: [CaseSectionTableControl, { width: '100%' }],
    //   implantstable: [CaseSectionImplantTableControl, { width: '100%' }],
    //   typeahead: [TypeaheadCollection, { variant: 'outlined' }],
       checkbox: [Controls.Checkbox, {}],
       button: [Controls.Button, {}],
       buttons: [Controls.Button, {}],
       chips: [Controls.ChipHolder,{ variant: 'outlined' }],
       "image buttons" : [Controls.Button, {}], //ToDo: Need to change, once it will be updated in db..
       selectfile : [File, {}]
    };
    return controls[type] || undefined;
  };