import moment from 'moment';
import { BLANK_VALUE, SELECTION_KEY_VALUE, FIELD_DEFAULT_SELECTION_VALUE } from '../../constants/GlobalConstants';
import { validateAge, disableFutureDate, disablePastDate } from './ValidationRules/ValidateFields';
import ValidationEngine from './ValidationRules/ValidationEngine';
import ValidationButtonDisable from './ValidationRules/ValidateTargetButtonDisable';
import { getControlByFieldId } from '../GetControlByFieldId';


/**
    * Will check all validation on the section
    * @param {any} section All details of current section
    * @param {any} status IF false/null then run validation engine else not
    */
export const checkSectionValidation = (section, status = null) => {
    var validationResponse = {
        isSectionCompleted: true,
        selectedButtonsDisable: [], // Contains buttons to disable..
        mappingIds: []  // Contains buttons to enable..
    }
    var isSectionGroupCompleted = true;
    section.sectionGroup && section.sectionGroup.forEach(element => {
       // if (!validationResponse.isSectionCompleted) return;       
        if (element.isVisible) {
            element.fields.forEach(field => {
            //    if (!validationResponse.isSectionCompleted) return;           
                let isTableControl = false;
                if (field.isVisible && field.fieldTypeName != "ImageButtons" && field.fieldTypeName != "Label" && field.fieldTypeName != "ActiveHiddenFields") {
                    var getFieldControl = field.parentFieldId ? getControlByFieldId(field.parentFieldId) : null;
                    isTableControl = getFieldControl && getFieldControl.childControls.length > 1 ? true : false;
                    if (!isTableControl) {
                        if (field.fieldTypeName == "Table" && field.childControls.length > 1) {
                            validationResponse.isSectionCompleted = checkChildControl(field) ? false : true;
                        }
                        if (!status) {
                            var response = ValidationEngine.initializeValidationEngine(field, true, true);
                            if (field.overrideValidationFlag) {
                                response = null
                            }
                            if (response) {
                                validationResponse.isSectionCompleted = false;
                            }
                        }
                        else {
                            validationResponse.isSectionCompleted = false;
                        }
                        if (field.requiredBl && (field.value == BLANK_VALUE || field.value == null)) {
                            validationResponse.isSectionCompleted = false;
                        }
                        if (field.requiredBl && field.fieldTypeName == SELECTION_KEY_VALUE && (field.value == FIELD_DEFAULT_SELECTION_VALUE)) {
                            validationResponse.isSectionCompleted = false;
                        }

                        const disableButtons = ValidationButtonDisable.checkTargetButtonDisable(field);

                        if (disableButtons.targetButtons) {
                            disableButtons.targetButtons.forEach(element => {
                                validationResponse.selectedButtonsDisable.push(element);
                            });
                        }
                        else {
                            if (disableButtons.mappingIds) {
                                disableButtons.mappingIds.forEach(element => {
                                    validationResponse.mappingIds.push(element);
                                });
                            }
                        }
                    }
                    if (!validationResponse.isSectionCompleted){
                        isSectionGroupCompleted = false;
                    }
                }
            });
        }
    });

    if(!isSectionGroupCompleted){
        validationResponse.isSectionCompleted = false;
    }
    return validationResponse;
}

function checkChildControl(field) {
    let filedValdation = false;
    var tableChilds = JSON.parse(JSON.stringify(field.childControls));
    tableChilds.splice(0, 1);
    tableChilds.forEach(item => {
        if (filedValdation) {
            return;
        }
        item.forEach(field => {
            if (filedValdation) {
                return;
            }
            if (field.isVisible && field.fieldTypeName != "ImageButtons" && field.fieldTypeName != "ActiveHiddenFields") {
                var response = ValidationEngine.initializeValidationEngine(field, true, true);
                if (field.overrideValidationFlag) {
                    response = null
                }
                if (field.childControls.length > 1) {
                    field.childControls.forEach(field => {
                        if (filedValdation) {
                            return;
                        }
                        if (field.requiredBl && (field.value == BLANK_VALUE || field.value == null)) {
                            filedValdation = true;
                        }
                        if (field.requiredBl && field.fieldTypeName == SELECTION_KEY_VALUE && (field.value == FIELD_DEFAULT_SELECTION_VALUE)) {
                            filedValdation = true;
                        }
                    });
                }
                if (response) {
                    filedValdation = true;
                }
                if (field.requiredBl && (field.value == BLANK_VALUE || field.value == null)) {
                    filedValdation = true;
                }
                if (field.requiredBl && field.fieldTypeName == SELECTION_KEY_VALUE && (field.value == FIELD_DEFAULT_SELECTION_VALUE)) {
                    filedValdation = true;
                }
            }
        });
    });
    return filedValdation;
}
