import { store } from '../../../store';
import { getFieldById } from "../../GetFieldById";
import { updateControlValue } from '../../../actions/CaseDetailsAction';
//import { showField } from '../../../actions/VisibilityControlAction';

const execute = (props, AddOnLoadONChangeFlag = null) => {
    let additionalProperties = props.additionalProperties;
    const state = store.getState()
    if (props.value && props.value=='1') {
        if (additionalProperties.OnCheckSetValuesFromOtherFields && additionalProperties.OnCheckSetValuesFromOtherFields.length > 0) {
            additionalProperties.OnCheckSetValuesFromOtherFields.map(rule => {
                let controlSelectionVals = rule;
                var keys = Object.keys(controlSelectionVals)
                var values = Object.values(controlSelectionVals)
                for (var key = 0; key < keys.length; key++) {
                    let dependentFieldValue = getFieldById(values[key]);
                    let getFieldDetais= getFieldById(keys[key]);
                    // if(!getFieldDetais.isVisible){
                    //     store.dispatch(showField(keys[key]));
                    // }
                   
                    store.dispatch(updateControlValue(keys[key], dependentFieldValue.value));
                }
            })
        }
    }
    if (props.value && props.value=='0' && AddOnLoadONChangeFlag) {
        if (additionalProperties.OnUnCheckClearDataValues && additionalProperties.OnUnCheckClearDataValues.length > 0) {
            additionalProperties.OnUnCheckClearDataValues.map(rule => {
                let controlSelectionVals = rule;
                store.dispatch(updateControlValue(controlSelectionVals, null));
            })
        }
    }
}

const OnCheckSetValuesFromOtherFields = {
    execute
}

export default OnCheckSetValuesFromOtherFields