import React from 'react';
import styled from 'styled-components';
import { blue, green, grey, red, teal } from '@mui/material/colors';

const colours = {
  Incomplete: '#1C709D21',
  Complete: '#398F4A2B',
  Cancelled: '#DADADA2B',
  Alert: '#FFE5E8',
  Closed: '#9F9F9F52',
};

const Event = styled.div`
  background-color: ${p => colours[p.status] || colours['Complete']};
  border-radius: 5px;
  color:#000000;
  padding: 3px 6px;
  font-weight: 600;
`;

export default ({ event }) => {
  const title = event.title
  const status = title.split(' - ')[1]
  return <Event status={status}>{title}</Event>;
};
