import { PUSH_TO_TARGET, DELETE_FROM_TARGET } from "../constants/ActionTypes"


export const pushToTarget = (replacedControl) => {
    return {
        type: PUSH_TO_TARGET,
        payload: { replacedControl }
    }
}

export const populateTarget = (targetField, data) => {
    return async function (dispatch, getState) {
        const state = getState()
        var sectionDetails = state.caseDetails.sectionDetails
        if (sectionDetails) {
            var replacedControl = sectionDetails
            replacedControl.sectionGroup.forEach(x => {
                let targetControl = x.fields.find(element => element.mappingId == targetField)
                if (targetControl) {
                    targetControl.isUpdated=true;
                    if (targetControl.value) {
                        targetControl.value.push(data)
                    } else {
                        targetControl.value = []
                        targetControl.value.push(data)
                    }
                }
            })
            dispatch(pushToTarget(replacedControl))
        }
    }
}


// Remove from target
export const popFromTarget = (replacedControl) => {
    return {
        type: DELETE_FROM_TARGET,
        payload: { replacedControl }
    }
}

export const removeTarget = (mappingId, id) => {
    return async function (dispatch, getState) {
        const state = getState()
        var sectionDetails = state.caseDetails.sectionDetails
        if (sectionDetails) {
            var replacedControl = sectionDetails
            replacedControl.sectionGroup.forEach(x => {
                let targetControl = x.fields.find(element => element.mappingId == mappingId)
                if (targetControl) {
                    targetControl.isUpdated=true;
                     targetControl.value.splice(id,1)
                }
            })
            dispatch(popFromTarget(replacedControl))
        }
    }
}

//This method wil replace the whole target, instead of specific value
export const replaceTarget = (mappingId, newTargetControl) => {
    return async function (dispatch, getState) {
        const state = getState()
        var sectionDetails = state.caseDetails.sectionDetails
        if (sectionDetails) {
            var replacedControl = sectionDetails
            replacedControl.sectionGroup.forEach(x => {
                let targetControl = x.fields.find(element => element.mappingId == mappingId)
                if (targetControl) {
                    targetControl.isUpdated = true;
                    if (targetControl.value) {
                        targetControl.value = []
                        newTargetControl.forEach(data => {
                            targetControl.value.push(data)
                        })
                    }
                    else {
                        newTargetControl.forEach(data => {
                            targetControl.value.push(data)
                        })
                    }
                }
            })
            dispatch(pushToTarget(replacedControl))
        }
    }
}