import { updateFieldPermission } from "../../../actions/CaseDetailsAction";
import { store } from "../../../store";

const execute = control => {
    const rule = control.additionalProperties.DisableTargetFieldIfNoValue
    rule.TargetFieldId.map(item => {
        if (control.value && control.value!='') {
            store.dispatch(updateFieldPermission(item, rule.IfFalseSetPermissionTypeId));
        } else {
            store.dispatch(updateFieldPermission(item, rule.IfTrueSetPermissionTypeId));
        }
    })
}

const DisableTargetFieldIfNoValue = {
    execute
}

export default DisableTargetFieldIfNoValue
