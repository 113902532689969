import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import { getActiveStatus, getReportsCases } from '../../reducers/ReportsReducer';
import CaseAmendmentTrackerDataTable from './CaseAmendmentTrackerDataTable';
import NoInsuranceAuthorizationProvidedDataTable from './NoInsuranceAuthorizationProvidedDataTable';
import PendingConfirmationNumberDataTable from './PendingConfirmationNumberDataTable';
import PreSurgicalTestingatHospitalDataTable from './PreSurgicalTestingatHospitalDataTable';
import TableA from './TableA';
import * as Constants from '../../constants/GlobalConstants'
import InpatientAdmitTypeDataTable from './InpatientAdmitTypeDataTable';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '../../res/values/Colors';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TablePaginationExtended from './TablePaginationExtended';

const useStyles = makeStyles(theme => ({
    gridTableHeader: {
        backgroundColor: Colors.CAT_TABLE_HEADER
    },
})
);

const TableCellHead = withStyles((theme) => ({
    root: {
        backgroundColor: Colors.CAT_TABLE_HEADER,
        color: theme.palette.common.white,
        minWidth: 80
    },
}))(TableCell);

const DataGrid = () => {
    const classes = useStyles()
    const activeStatus = useSelector(getActiveStatus)
    const casesData = useSelector(getReportsCases)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const DefaultDataGrid = () => {
        return (
            <Paper elevation={2} sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table"  >
                        <TableHead className={classes.gridTableHeader}>
                            <TableRow>
                                <TableCellHead align='center'>{Constants.LBL_PROCEDURE_DATE}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_CASE_ID}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_PATIENT_NAME}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_SURGEON}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_HOSPITAL_NAME}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_FIELD_AMENDED}</TableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan="7" >
                                    {Constants.LBL_NO_CASE_FOUND}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePaginationExtended
                    totalCases={0}
                    pageNumber={0}
                    itemsPerPage={rowsPerPage}
                    isPageChange={false}
                    isRowPerPageChange={false}
                />
            </Paper>
        )
    }

    switch (activeStatus) {
        case 'RepConfirmationDue':
            return <TableA />
            break;
        case 'NoInsuranceAuthorizationProvided':
            return <NoInsuranceAuthorizationProvidedDataTable data={casesData} />
            break;
        case 'IncompleteInsuranceAuthorization':
            return <TableA />
            break;
        case 'PreSurgicalTestingatHospital':
        case 'PreAdmissionTestingAtHospital2':
            return <PreSurgicalTestingatHospitalDataTable data={casesData} />
            break;
        case 'InpatientAdmitType':
            return <InpatientAdmitTypeDataTable data={casesData} />
            break;
        case 'PendingConfirmationNumber':
            return <PendingConfirmationNumberDataTable data={casesData} />
            break;
        case 'CaseAmendmentTracker':
            return <CaseAmendmentTrackerDataTable data={casesData} />
            break;
        default:
            return <DefaultDataGrid />
    }
}

export default DataGrid
