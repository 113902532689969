import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getLoadingStatus, getItemsPerPage, getPageNumber, getTotalCases, getSortData, getTableHeaderConfig } from '../../reducers/ReportsReducer';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import { Link } from 'react-router-dom';
import { Link as MuiLink, TableSortLabel, Switch, Stack, Typography, Box } from '@mui/material';
import { updatePageNumber, getReportsCases, UpdateFieldSorting, SearchByFilters, setSearchFiltersData, getPatientEstimateSentConfirmations, resetPaginationState } from '../../actions/ReportsAction';
import * as Constants from '../../constants/GlobalConstants'
import DoneIcon from '@mui/icons-material/Done';
import makeStyles from '@mui/styles/makeStyles';
import CaseSummaryDialogBoxComponent from '../../components/case/CaseSummaryDialogBoxComponent';
import { updateSectionGroupLoaderStatus, getCaseSummaryDetails, saveVerifyInsuranceData } from '../../actions/CaseDetailsAction';
import { getCaseSummary, getSectionGroupLoader, getInsuranceVerificationDetailAmendedBl } from '../../reducers/CaseDetailsReducer'
import { dashboardFilterFields } from '../../reducers/DashboardReducer';
import FilterControls from '../common/FilterControls';
import { Checkbox } from '@mui/material';
import CaseDialogBox from '../case/CaseDialogBox';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from '../../utils/theme';
import * as Colors from '../../res/values/Colors';
import withStyles from '@mui/styles/withStyles';
import TablePaginationExtended from './TablePaginationExtended';

const useStyles = makeStyles(theme => ({
    verifyInsuranceLink: {
        'align-items': 'center',
        'display': 'flex'
    },
    VerifiedText: {
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': '700',
        'font-size': '0.800rem',
        'line-height': 1.43,
        'letter-spacing': '0.01071em'
    },
    PatientEstimateSentColumnDataText: {
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': 'normal',
        'font-size': '0.875rem',
        'line-height': 1.43,
        'letter-spacing': '0.01071em'
    },
    underLineLinkColor: {
        'color': '#07bc0c'
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            color: "#2e7d32", // Default color
        },

    },
    errorCheckbox: {
        '& .MuiSvgIcon-root': {
            color: "#d32f2f",
        },
    },
    dataTableContainer: {
        maxHeight: `calc(100vh - ${theme.spacing(39)})`
    },
    dataTableHeader: {
        backgroundColor: Colors.CAT_TABLE_HEADER
    },
    sortLabelRoot: {
        color: `${theme.palette.common.white} !important`, // Customize root color
        '&.MuiTableSortLabel-active': {
            color: `${theme.palette.common.white} !important`, // Active state color
        },
    },
    sortLabelIcon: {
        color: `${theme.palette.common.white} !important`, // Customize icon color
        '&.MuiTableSortLabel-active': {
            color: `${theme.palette.common.white} !important`, // Active state color for icon
        },
    },
}))

const TableCellHead = withStyles((theme) => ({
    root: {
        backgroundColor: Colors.CAT_TABLE_HEADER,
        color: theme.palette.common.white,
        minWidth: 80
    },
}))(TableCell);

export default function NoInsuranceAuthorizationProvidedDataTable(props) {

    const { data } = props;
    const loading = useSelector(getLoadingStatus)
    const pageNumber = useSelector(getPageNumber)
    const itemsPerPage = useSelector(getItemsPerPage)
    const totalCases = useSelector(getTotalCases)
    const caseSummary = useSelector(getCaseSummary)
    const isInsuranceVerificationDetailAmendedBl = useSelector(getInsuranceVerificationDetailAmendedBl)
    const sectionGroupLoader = useSelector(getSectionGroupLoader)
    const userPermissions = useSelector(dashboardFilterFields)
    const dispatch = useDispatch()
    const classes = useStyles()
    const [openCaseSummaryDialog, setOpenCaseSummaryDialog] = React.useState(false);
    const [openEstimateSentDialog, setOpenEstimateSentDialog] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [caseId, setCaseId] = React.useState(0);
    const tableHeader = useSelector(getTableHeaderConfig);
    const headCells = tableHeader?.headers;
    //initialize sorting
    var sortRequestData = useSelector(getSortData);
    var sortRequestDataOrder = null
    var sortRequestDataOrderBy = null
    sortRequestData && sortRequestData.forEach(element => {
        sortRequestDataOrder = element ? element.Order : null;
        sortRequestDataOrderBy = element ? element.Field : null;
    });
    var sortOrder = sortRequestDataOrder ? sortRequestDataOrder : Constants.DEFAULT_ORDER;
    var sortOrderBy = sortRequestDataOrderBy ? sortRequestDataOrderBy : Constants.DEFAULT_NOINSURANCEAUTH_ORDER_BY;
    const [order, setOrder] = React.useState(sortOrder);
    const [orderBy, setOrderBy] = React.useState(sortOrderBy);
    
    React.useEffect(() => {
        setOrder(sortOrder);
        setOrderBy(sortOrderBy);
    }, [sortOrderBy]);

    //Method to check if patient estimate column exists
    const checkIfPatientEstimateColumnExists = () => {
        return headCells.some((obj) => obj.Alias === "PatientEstimateSentBl")
    }

    // Handling change event of checkbox
    const handleChange = async () => {
        const { event, caseId } = selectedRow

        setOpenEstimateSentDialog(false)

        // Payload for patient estimate sent confirmation api
        const confirmationApiPayload = {
            ConfirmationTypeId: 8,
            ConfirmationBl: event,
            CaseId: caseId,
        }

        //Calling API to save the new confirmation values and saving to redux state if successful
        dispatch(getPatientEstimateSentConfirmations(caseId, confirmationApiPayload, data))
    }

    const onVerifyInsuranceLinkClick = (caseId) => {
        setCaseId(caseId);
        setOpenCaseSummaryDialog(true);
        dispatch(updateSectionGroupLoaderStatus(true))
        dispatch(getCaseSummaryDetails(caseId))
    };

    const onAlertDialogOkClick = () => {
        dispatch(updateSectionGroupLoaderStatus(true))
        dispatch(getCaseSummaryDetails(caseId))
    };

    const onCaseSummaryVerifyConfirm = (insuranceVerifyRequestData) => {
        dispatch(updateSectionGroupLoaderStatus(true))
        dispatch(saveVerifyInsuranceData(insuranceVerifyRequestData))
    };

    //If clicked on confirmed button changing checkbox using handleChange method
    const onConfirm = () => {
        handleChange();
    }

    //Just hiding dialog if user clicks on Cancel button
    const onCancel = () => {
        setOpenEstimateSentDialog(false)
    }

    const onCloseCaseSummaryPopup = () => {
        setOpenCaseSummaryDialog(false);
        //Code update only for No Insurance Authorization grid
        dispatch(getReportsCases(Constants.NoInsuranceAuthorizationProvided))
    };

    // Method to show Estimate sent confirmation dialog
    const showEstimateSentDialog = (event, caseId, patientName) => {
        setSelectedRow({
            caseId: caseId,
            event: event,
            patientName: patientName
        })
        setOpenEstimateSentDialog(true)
    }

    const CaseSummaryDialogBox = (
        <CaseSummaryDialogBoxComponent
            open={openCaseSummaryDialog}
            // onClose={}
            maxWidth={'lg'}
            dialogTitle={''}
            dialogContentText={''}
            caseId={caseId}
            caseSummaryDetails={caseSummary}
            sectionGroupLoader={sectionGroupLoader}
            isInsuranceVerificationDetailAmendedBl={isInsuranceVerificationDetailAmendedBl}
            userActions={userPermissions?.userActions}
            onCloseCaseSummaryPopup={onCloseCaseSummaryPopup}
            onCaseSummaryVerifyConfirm={onCaseSummaryVerifyConfirm}
            onAlertDialogOkClick={onAlertDialogOkClick}
        />
    )

    //Opening dialog to confirm and unconfirm if the patient estimate was sent
    const openDialog = () => {

        //Checking which action to show based on checkbox value
        const action = selectedRow && selectedRow?.event ? Constants.CONFIRM : Constants.UNCONFIRM;
        const patientName = selectedRow && selectedRow?.patientName;

        //Setting the dialog description dynamically
        const dialogDescription = Constants.dialogDescriptionTemplate(action, patientName);

        return (
            <CaseDialogBox
                open={openEstimateSentDialog}
                dialogTitle={Constants.ESTIMATE_SENT_CONFIRMATION}
                dialogDescription={dialogDescription}
                onConfirm={onConfirm}
                onCancel={onCancel}
            />
        )
    }

    const GenerateInsuranceAuthStatusHtml = (caseId, isVerifiedAllowed, verifiedBl) => {
        return (
            <>
                {verifiedBl ?
                    (<div className={classes.verifyInsuranceLink}><DoneIcon color={'success'} fontSize={'small'}></DoneIcon>
                        <span className={`${classes.VerifiedText} ${classes.underLineLinkColor}`}>Verified&nbsp;-&nbsp;</span><MuiLink className={classes.underLineLinkColor} onClick={() => { onVerifyInsuranceLinkClick(caseId) }} href="#" underline="always" component="button">{'View Details'}</MuiLink></div>)
                    : !isVerifiedAllowed && !verifiedBl ? (<MuiLink onClick={() => { onVerifyInsuranceLinkClick(caseId) }} href="#" underline="always" component="button">{'Pending Verification'}</MuiLink>)
                        : (<MuiLink onClick={() => { onVerifyInsuranceLinkClick(caseId) }} href="#" underline="always" component="button">{'Verify Insurance'}</MuiLink>)

                }
            </>
        )
    }

    const sortByColumnID = (e, newOrderBy) => {
        const isAsc = orderBy === newOrderBy && order.toLowerCase() === 'asc';
        const toggledOrder = isAsc ? 'desc' : 'asc';
        setOrder(toggledOrder);
        setOrderBy(newOrderBy);
        var sortData = [];
        var sortItem = { 'Field': newOrderBy, 'Order': toggledOrder };
        sortData.push(sortItem);
        dispatch(UpdateFieldSorting(sortData));
        dispatch(updatePageNumber(0));

    }

    const ApplyFilter = () => {
        dispatch(resetPaginationState());
        dispatch(SearchByFilters());
    }
    const ResetFilter = () => {
        dispatch(resetPaginationState());
        dispatch(setSearchFiltersData([]));
        dispatch(SearchByFilters());
    }

    const renderPatientEstimateSentBody = (props) => {

        const {
            PatientEstimateSentBl,
            ShowPatientEstimateSentBl,
            PatientEstimateSentConfirmedOnDate,
            PatientEstimateSentConfirmedOnTime,
            PatientEstimateSentConfirmedBy,
            CaseId,
            FieldPermissionTypeId,
            PatientName
        } = props

        const disableCheckBox = FieldPermissionTypeId === Constants.PermissionTypes.DISABLE_FIELD ? true : (FieldPermissionTypeId === Constants.PermissionTypes.ENABLE_FIELD ? false : true);
        const hideCheckBox = FieldPermissionTypeId === Constants.PermissionTypes.HIDE_FIELD ? true : false;

        return (
            <>
                {
                    (ShowPatientEstimateSentBl === true && hideCheckBox === false) &&
                    <ThemeProvider theme={theme}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Checkbox
                                className={PatientEstimateSentBl ? classes.checkbox : classes.errorCheckbox}
                                checked={PatientEstimateSentBl}
                                onChange={(event) => showEstimateSentDialog(event.target.checked, CaseId, PatientName)}
                                disabled={disableCheckBox}
                            />
                            <Typography className={classes.PatientEstimateSentColumnDataText} color={PatientEstimateSentBl ? "success.main" : "error.main"}>{PatientEstimateSentBl ? Constants.YES : Constants.NO}</Typography>
                        </Stack>
                    </ThemeProvider>
                }
                {
                    (PatientEstimateSentBl === true && hideCheckBox === false) &&
                    <>
                        <ThemeProvider theme={theme}>
                            <Typography className={classes.PatientEstimateSentColumnDataText} color={"success.main"}>{PatientEstimateSentConfirmedOnDate}</Typography>
                            <Typography className={classes.PatientEstimateSentColumnDataText} color={"success.main"}>{PatientEstimateSentConfirmedOnTime}</Typography>
                            <Typography className={classes.PatientEstimateSentColumnDataText} color={"success.main"}>{PatientEstimateSentConfirmedBy}</Typography>
                        </ThemeProvider>
                    </>
                }
            </>
        )
    }

    // Render ProcedureAuthorized value
    const showProcedureAuthorized = (value) => {
        const authorizedInfo = value.split('||')
        return (
            <Stack direction='column' spacing={0.5}>
                {authorizedInfo.map(item => (<Box>{item}</Box>))}
            </Stack>
        )
    }

    const RenderTableBody = (props) => {
        const { row, index } = props;

        const isEstimateSentColumnVisible = checkIfPatientEstimateColumnExists();

        return (
            <>
                <TableRow hover   >
                    <TableCell component="tb" scope="row" align="center">
                        {row.PatientName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.PrimarySurgeonName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.HospitalName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureUnitName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureDate.slice(0, 10)}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        <Link to={`case/${parseInt(row.CaseId)}/Financial`}>
                            {row.CaseId}
                        </Link>
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.CaseStatus}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {GenerateInsuranceAuthStatusHtml(row.CaseId, row.IsVerifiedAllowed, row.VerifiedBl)}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        {row.ProcedureAuthorized && showProcedureAuthorized(row.ProcedureAuthorized)}
                    </TableCell>
                    {
                        isEstimateSentColumnVisible &&
                        <TableCell component="tb" scope="row" align="center">
                            {renderPatientEstimateSentBody(row, index)}
                        </TableCell>
                    }
                </TableRow>
            </>
        );
    }

    return (
        <>
            {CaseSummaryDialogBox}
            {openDialog()}
            <FilterControls
                filterMd={3}
                filterLg={2.5}
                loading={loading}
                displayFilters={Constants.INSURANCE_REPORT_FILTER_CONTROLS}
                applyLabel={Constants.APPLY_LABEL}
                resetLabel={Constants.RESET_LABEL}
                applyFilter={(e) => ApplyFilter()}
                resetFilter={(e) => ResetFilter()}
            />
            <Paper elevation={2} sx={{ width: '100%', overflow: 'hidden', marginBottom: 2 }}>
                <TableContainer className={classes.dataTableContainer}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead className={classes.dataTableHeader}>
                            <TableRow>
                                {headCells && headCells.map((headCell) => (
                                    <TableCellHead align='center' >
                                        <TableSortLabel
                                            active={orderBy === headCell.Alias}
                                            direction={orderBy === headCell.Alias ? order.toLowerCase() : 'asc'}
                                            onClick={(e, value) => sortByColumnID(e, headCell.Alias)}
                                            classes={{
                                                root: classes.sortLabelRoot,
                                                icon: classes.sortLabelIcon
                                            }}
                                        >
                                            {headCell.ColumnName}
                                        </TableSortLabel>
                                    </TableCellHead>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ?
                                <TableRow>
                                    <TableCell component="th" scope="row" colSpan="8" >
                                        <Loader loading={loading} />
                                    </TableCell>
                                </TableRow>
                                : ((data && data.length !== 0) ? data
                                    .map((row, i) => (<RenderTableBody row={row} />)) :
                                    (<TableRow>
                                        <TableCell component="th" scope="row" colSpan="8" >
                                            {Constants.LBL_NO_CASE_FOUND}
                                        </TableCell>
                                    </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePaginationExtended
                    totalCases={totalCases}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                />
            </Paper>
            <ToastContainer />
        </>
    );
}
