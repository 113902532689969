import * as React from 'react';
import {
    InputLabel,
    Select,
    MenuItem,
    TablePagination
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Constants from '../../constants/GlobalConstants';
import * as Colors from '../../res/values/Colors';
import { updatePageNumber, updateItemPerpageNumber } from '../../actions/ReportsAction';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    select: {
        height: 30,
        fontSize: '0.875rem',
        '& .MuiSelect-select': { border: 'none', },
        '& fieldset': { border: 'none', },
        '& .MuiOutlinedInput-notchedOutline': { border: 'none', },
        '& .MuiMenu-paper': { maxHeight: '100px !important' },
    },
    selectPageLabel: {
        color: Colors.SELECT_PAGE_TEXT_COLOR,
        fontSize: '0.875rem'
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}))

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
        },
    },
};

export default function TablePaginationExtended(props) {
    const {
        totalCases,
        pageNumber,
        itemsPerPage,
        isPageChange = true,
        isRowPerPageChange = true
    } = props;
    const [numberOfPages, setNumberOfPages] = React.useState(1)
    const classes = useStyles()
    const dispatch = useDispatch()

    React.useEffect(() => {
        let numPage = 1;
        if (totalCases > itemsPerPage) {
            numPage = Math.ceil(totalCases / itemsPerPage)
        }

        setNumberOfPages(numPage)
    }, [itemsPerPage, totalCases]);

    // Pagination Handler
    const handleChangePage = (event, pageNumber) => {
        dispatch(updatePageNumber(pageNumber))
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(updateItemPerpageNumber(parseInt(event.target.value, 10)));
    };

    return (
        <div className={classes.paginationContainer}>
            <InputLabel id="labelSelectPage" className={classes.selectPageLabel}>{Constants.SELECT_PAGE}</InputLabel>
            <Select MenuProps={MenuProps} labelId="labelSelectPage" onChange={(e) => handleChangePage(e, e.target.value)} value={pageNumber} className={classes.select}>
                {Array.from({ length: numberOfPages }, (_, index) => (
                    <MenuItem key={index} value={index}>{index + 1}</MenuItem>
                ))}
            </Select>
            <TablePagination
                rowsPerPageOptions={Constants.DEFAULT_PER_PAGE_OPTIONS}
                component="div"
                count={totalCases}
                rowsPerPage={itemsPerPage}
                page={pageNumber}
                onPageChange={isPageChange && handleChangePage}
                onRowsPerPageChange={isRowPerPageChange && handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
            />
        </div>
    )
}