import moment from "moment"
import { addFields, updateControlValue } from "../../../actions/CaseDetailsAction"
import { store } from "../../../store"
import { calculateValue } from "../../CalculateValue"
import { getFieldById } from "../../GetFieldById"

const execute = (control, AddOnLoadONChangeFlag = null) => {
    const fieldProps = control.additionalProperties
    let rules = control.additionalProperties.OnSelectAddValueToTargetFieldByCondition
    if (AddOnLoadONChangeFlag) {
        if (rules) {
            let value = control.value
            rules.map(rule => {
                if (rule.Condition.every(condition => {
                    if (condition.DependentFieldId.includes('.')) {
                        var nestedKeys = condition.DependentFieldId.split('.')
                        var field = getFieldById(nestedKeys[0]);
                        var dependentFieldVal = field ? field.value : null
                        var dependentNestedFieldVal = dependentFieldVal
                        for (var i = 1; i < nestedKeys.length; i++) {
                            if (dependentNestedFieldVal) {
                                dependentNestedFieldVal = dependentNestedFieldVal[nestedKeys[i]]
                            }
                        }
                        return calculateValue(dependentNestedFieldVal, condition.DependentFieldValue, condition.DependentFieldValueOperator)

                    } else {
                        var field = getFieldById(condition.DependentFieldId);
                        var dependentFieldVal = field ? field.value : null
                        return calculateValue(dependentFieldVal, condition.DependentFieldValue, condition.DependentFieldValueOperator)
                    }
                })) {
                    let dataToDispatch = prepareTargetData(rule.SourceFieldTargetFieldValueMapping, value)
                    store.dispatch(addFields(rule.TargetFieldId, null, dataToDispatch))
                }
            })
        }
        setTimeout(()=>store.dispatch(updateControlValue(control.mappingId, null)),100)
    }
}

const prepareTargetData = (fieldMap, value) => {
    let mapppingKeys = Object.keys(fieldMap)
    let preparedData = []
    mapppingKeys.map(key => {
        var val = null
        if (key.includes('.')) {
            var nestedKeys = key.split('.')
            var dependentNestedFieldVal = value
            for (var i = 1; i < nestedKeys.length; i++) {
                if (dependentNestedFieldVal) {
                    dependentNestedFieldVal = dependentNestedFieldVal[nestedKeys[i]]
                }
            }
            val = dependentNestedFieldVal
        } else {
            val = value[key]
        }

        if (typeof fieldMap[key] == 'string') {
            preparedData.push({ key: fieldMap[key], value: val })
        } else {
            let objectKeys = Object.keys(fieldMap[key])
            let objectValues = Object.values(fieldMap[key])
            for (var i = 0; i < objectKeys.length; i++) {
                if (objectValues[i] == "CurrentDateTime") {
                    var field = getFieldById(objectKeys[i]);
                    if (field && field.additionalProperties) {
                        var format = field.additionalProperties.Format;
                        preparedData.push({ key: objectKeys[i], value:  moment(new Date()).format(format) })
                        
                    }
                }
                else {
                    preparedData.push({ key: objectKeys[i], value: objectValues[i]})
                }
            }
        }
    })
    return preparedData
}


const OnSelectAddValueToTargetFieldByCondition = {
    execute
}

export default OnSelectAddValueToTargetFieldByCondition