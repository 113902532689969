import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { populateTarget } from '../../../actions/TypeaheadAction';
import { showField } from '../../../actions/VisibilityControlAction';
import { store } from '../../../store';
import { getFieldById } from '../../GetFieldById';
import moment from 'moment';

export const execute = (control, AddOnLoadONChangeFlag = null) => {
    const fieldProps = control.additionalProperties
    if (AddOnLoadONChangeFlag) {
        store.dispatch(showField(fieldProps.OnSelectAddValueToTargetField.TargetFieldId))
        let value = control.value
      //   store.dispatch(updateControlValue(control.mappingId, null))
        if (value) {
            if (fieldProps.OnSelectAddValueToTargetField) {
                let dataToDispatch = prepareTargetData(fieldProps.OnSelectAddValueToTargetField.SourceFieldTargetFieldValueMapping, value)
                store.dispatch(populateTarget(fieldProps.OnSelectAddValueToTargetField.TargetFieldId, dataToDispatch))
            }
        }
        setTimeout(()=>store.dispatch(updateControlValue(control.mappingId, null)),100)
    }
}

const prepareTargetData = (fieldMap, value) => {
    let mapppingKeys = Object.keys(fieldMap)
    let preparedData = {}
    mapppingKeys.map(key => {
        if (typeof fieldMap[key] == 'string') {
            preparedData[fieldMap[key]] = value[key]
        } else if (typeof fieldMap[key] == 'object') {
            let objectKeys = Object.keys(fieldMap[key])
            let objectValues = Object.values(fieldMap[key])
            for (var i = 0; i < objectKeys.length; i++) {
                if (objectValues[i] == "CurrentDateTime") {
                    var field = getFieldById(objectKeys[i]);
                    if (field && field.additionalProperties) {
                        var format = field.additionalProperties.Format;
                        preparedData[objectKeys[i]] = moment(new Date()).format(format);
                    }
                }
                else {
                    preparedData[objectKeys[i]] = objectValues[i];
                }
            }
        }
    })
    return preparedData
}

const OnSelectAddValueToTargetField = {
    execute
}

export default OnSelectAddValueToTargetField