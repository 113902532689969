import {
    GET_REPORT_CASES, GET_REPORT_CASES_FAILURE, GET_REPORT_CASES_SUCCESS, SET_ACTIVE_STATUS, SET_REPORT_CASES_COUNT,
    UPDATE_ITEMS_PER_PAGE, UPDATE_PAGE_NUMBER,
    UPDATE_REPORT_SORT_ITEMS,
    SET_FILTERS_DATA,
    SET_FILTERS_REFERENCE_DATA,
    SAVE_ACKNOWLEDGEMENT_LOADING, UPDATE_REPORT_CASES, UPDATE_REPORT_CASES_SUCCESS, UPDATE_REPORT_CASES_FAILURE,
    RESET_PAGINATION_OPTIONS, RESET_REPORTS_CASE_COUNT, GET_CASE_AMENDMENT_SUCCESS, GET_CASE_AMENDMENT_FAILURE,
    SET_ALREADY_ACKNOWLEDGE_EMR
} from "../constants/ActionTypes";
import {
    NoInsuranceAuthorizationProvided, PreSurgicalTestingatHospital, PreAdmissionTestingAtHospital2,
    PendingConfirmationNumber, CaseAmendmentTracker, InpatientAdmitType, SEARCH_FILTER_CONTROL_LIST,
    WQCaseCounts, CASE_AMENDMENT_TRACKER, CMS_INPATIENT_ONLY, PENDING_CONFIRMATION_NUMBER, INSURANCE_AUTHORIZATION,
    PRESURGICAL_TESTING_AT_HOSPITAL, PREADMISSION_TESTING_AT_HOSPITAL
} from '../constants/GlobalConstants'
import ReportService from "../services/ReportsService";

export function getReportsCases(status) {
    return async function (dispatch, getState) {
        try {
            const state = getState();
            const itemsPerPage = state.reports.itemsPerPage;
            const pageNumber = state.reports.pageNumber + 1;
            const reportsCasesCount = state.reports.statusCount;
            dispatch(getCases());
            if (status === CaseAmendmentTracker) {
                ReportService.getCatCases(pageNumber, itemsPerPage)
                    .then(response => {
                        var result = response ? response.responseData : [];
                        reportsCasesCount[status] = result.TotalRecords;
                        const caseData = {
                            ...result,
                            Items: result.Items.map(item => ({
                                ...item,
                                amendmentsInfo: [],
                                open: false,
                                amendmentsLoading: false
                            }))
                        }
                        dispatch(getCasesSuccess(caseData));
                        dispatch(setReportsCasesCount(reportsCasesCount));
                        dispatch(setAlreadyAcknowledgeEMR(false));
                    }).catch(err => {
                        dispatch(getCasesFail(err));
                    })
            }
            else if (status === PendingConfirmationNumber || status === NoInsuranceAuthorizationProvided) {
                var requestData = {};
                var sortData = state.reports.sortBy;
                requestData.itemsPerPage = itemsPerPage;
                requestData.pageNumber = pageNumber;
                requestData.sortBy = sortData ? sortData : [];
                ReportService.getFilteredReportsData(requestData, status)
                    .then(response => {
                        var result = response ? response.responseData : [];
                        dispatch(getCasesSuccess(result));
                        reportsCasesCount[status] = result.TotalRecords;
                        dispatch(updateFilterResponse(SEARCH_FILTER_CONTROL_LIST, result.FilterBy));
                        dispatch(setReportsCasesCount(reportsCasesCount));
                    });
            } else {
                ReportService.getReportsCases(status, pageNumber, itemsPerPage)
                    .then(response => {
                        var result = response ? response.responseData : [];
                        dispatch(getCasesSuccess(result));
                        reportsCasesCount[status] = result.TotalRecords;
                        dispatch(setReportsCasesCount(reportsCasesCount));
                    });
            }

        } catch (err) {
            dispatch(getCasesFail(err));
        }
    };
}

//This `getCATAmendmentsData` function is used to call the API(getCatAmendmentsByIds) to set the state for amendments list and row open/close
export function getCATAmendmentsData(caseId, sectionId, txnId, rowOpen) {
    return async function (dispatch, getState) {
        try {
            const state = getState();
            const cases = state.reports.cases;
            const caseInfoRowOpen = cases.map(info => {
                if(info.CaseId === caseId && info.SectionId === sectionId && info.TxnId === txnId) {
                    return {
                        ...info,
                        open: !rowOpen,
                        amendmentsLoading: !info.amendmentsLoading
                    }
                }
                return info;
            })
            
            dispatch(caseAmendmentSuccess(caseInfoRowOpen))

            // Update the case data with amendments if IDs match
            if(!rowOpen) {
                ReportService.getAmendmentsByCaseId(caseId, sectionId, txnId)
                .then(response => {
                    const result = response ? response.responseData : [];

                    // If already acknowledged or no records display dialog
                    if(result.length === 0) {
                        dispatch(setAlreadyAcknowledgeEMR(true));
                    }

                    const caseData = caseInfoRowOpen.map(item => {
                        if (item.CaseId === caseId && item.SectionId === sectionId && item.TxnId === txnId) {
                            return {
                                ...item,
                                amendmentsInfo: [...result],
                                amendmentsLoading: false
                            }
                        }

                        return item;
                    })
                    dispatch(caseAmendmentSuccess(caseData))
                }).catch(err => {
                    dispatch(caseAmendmentFailure(err))
                })
            }
        } catch (err) {
            dispatch(caseAmendmentFailure(err))
        }
    }
}

//Method to save the patient estimate sent confirmation status using API and redux
export function getPatientEstimateSentConfirmations(caseId, payload, casesData) {
    return async function (dispatch, getState) {
        try {
            dispatch(updateCases());
            ReportService.savePatientEstimateSentConfirmation(caseId, payload).then(response => {
                let result = response ? response.responseData : [];

                //Updating the key value pairs of cases object to immediately update the rows using redux state
                if (result) {
                    casesData.forEach((caseObj) => {
                        if (caseObj.CaseId === result.caseId) {
                            caseObj.PatientEstimateSentConfirmedOnDate = result.confirmedOnDate;
                            caseObj.PatientEstimateSentConfirmedOnTime = result.confirmedOnTime;
                            caseObj.PatientEstimateSentConfirmedBy = result.confirmedBy;
                            caseObj.PatientEstimateSentBl = result.confirmedBl;
                            caseObj.ConfirmationTypeId = result.confirmationTypeId;
                            return;
                        }
                    })

                    //Updating the cases
                    dispatch(updatetCasesSuccess(casesData));
                }
            });
        } catch (err) {
            dispatch(updateCasesFail(err));
        }
    };
}

export function SaveAcknowledgeEmr(acknowledgeEmrIds) {
    return async function (dispatch, getState) {
        const state = getState()
        const itemsPerPage = state.reports.itemsPerPage
        const pageNumber = state.reports.pageNumber + 1
        var reportsCasesCount = state.reports.statusCount
        dispatch(saveAcknowledgementLoading(true));
        try {
            return ReportService.acknowledgeEmr(acknowledgeEmrIds)
                .then(response => {
                    if(response.httpStatusCode === 200) {
                        ReportService.getCatCases(pageNumber, itemsPerPage)
                        .then(response => {
                            var result = response ? response.responseData : [];
                            
                            reportsCasesCount.CaseAmendmentTracker = result.TotalRecords;
                            const caseData = {
                                ...result,
                                Items: result.Items.map(item => ({
                                    ...item,
                                    amendmentsInfo: [],
                                    open: false,
                                    amendmentsLoading: false
                                }))
                            }
                            dispatch(getCasesSuccess(caseData));
                            dispatch(setReportsCasesCount(reportsCasesCount));
                        });
                    } else if(response.httpStatusCode === 204) {
                        dispatch(setAlreadyAcknowledgeEMR(true));
                    }
                })
        } catch (err) {
            dispatch(getCasesFail(err));
        }
    };
}

export function resetReportsCaseCount(data){
    return {
        type: RESET_REPORTS_CASE_COUNT,
        payload: data
    }
}

export function getAllReportsCaseCount(showPreSurgicalTestingatHospital, showPreAdmissionTestingAtHospital) {
    return async function (dispatch, getState) {
        try {
            const storeData = getState();
            const reportsCasesCount = storeData.reports.statusCount;
            var requestData = {};
            requestData.WQCodes = [CASE_AMENDMENT_TRACKER, CMS_INPATIENT_ONLY, PENDING_CONFIRMATION_NUMBER, INSURANCE_AUTHORIZATION];
            if (showPreSurgicalTestingatHospital) {
                requestData.WQCodes = [...requestData.WQCodes, PRESURGICAL_TESTING_AT_HOSPITAL];
            }

            if (showPreAdmissionTestingAtHospital) {
                requestData.WQCodes = [...requestData.WQCodes, PREADMISSION_TESTING_AT_HOSPITAL];
            }

            ReportService.getFilteredReportsData(requestData, WQCaseCounts)
                .then(response => {
                    var result = response ? response.responseData : [];
                    const allCaseCount = result.reduce((acc, curr) => {
                        switch (curr.WorkQueueCode) {
                            case PENDING_CONFIRMATION_NUMBER:
                                acc.PendingConfirmationNumber = curr.CaseCount;
                            case INSURANCE_AUTHORIZATION:
                                acc.NoInsuranceAuthorizationProvided = curr.CaseCount;
                            case PREADMISSION_TESTING_AT_HOSPITAL:
                                acc.PreAdmissionTestingAtHospital2 = curr.CaseCount;
                            case PRESURGICAL_TESTING_AT_HOSPITAL:
                                acc.PreSurgicalTestingatHospital = curr.CaseCount;
                            case CMS_INPATIENT_ONLY:
                                acc.InpatientAdmitType = curr.CaseCount;
                            case CASE_AMENDMENT_TRACKER:
                                acc.CaseAmendmentTracker = curr.CaseCount;
                        }

                        return acc;
                    }, reportsCasesCount)

                    dispatch(setReportsCasesCount(allCaseCount));
                });
        } catch (err) {
            dispatch(getCasesFail(err));
        }
    }
}

export function getCases(data) {
    return {
        type: GET_REPORT_CASES,
        loading: true,
    };
}

export function getCasesSuccess(data) {
    return {
        type: GET_REPORT_CASES_SUCCESS,
        payload: data,
        loading: false,
    };
}

export function getCasesFail(data) {
    return {
        type: GET_REPORT_CASES_FAILURE,
        payload: data,

    };
}

export function updateCases(data) {
    return {
        type: UPDATE_REPORT_CASES,
        loading: true,
    };
}
export function updatetCasesSuccess(data) {
    return {
        type: UPDATE_REPORT_CASES_SUCCESS,
        payload: data,
        loading: false,
    };
}
export function updateCasesFail(data) {
    return {
        type: UPDATE_REPORT_CASES_FAILURE,
        payload: data,
    };
}

export function saveAcknowledgementLoading(data) {
    return {
        type: SAVE_ACKNOWLEDGEMENT_LOADING,
        payload: data,
    };
}

export function setReportsCasesCount(data) {
    return {
        type: SET_REPORT_CASES_COUNT,
        payload: data,

    };
}

export function setActiveStatus(status) {
    return {
        type: SET_ACTIVE_STATUS,
        payload: status,
    };
}

export function updatePageNumberState(pageNumber) {
    return {
        type: UPDATE_PAGE_NUMBER,
        payload: pageNumber,
    };
}

export function updateItemPerpageNumberState(itemsPerPage) {
    return {
        type: UPDATE_ITEMS_PER_PAGE,
        payload: itemsPerPage,
    };
}

export function resetPaginationState(data) {
    return {
        type: RESET_PAGINATION_OPTIONS,
        payload: data
    }
}

export function updatePageNumber(pageNumber) {
    return function (dispatch, getState) {

        const state = getState()

        const status = state.reports.activeStatus
        dispatch(updatePageNumberState(pageNumber));
        dispatch(getReportsCases(status));
    };
}

export function updateItemPerpageNumber(itemsPerPage) {
    return function (dispatch, getState) {

        const state = getState()

        const status = state.reports.activeStatus
        dispatch(updatePageNumberState(0)); //Feature: On change of pagesize the current page should reset to first page
        dispatch(updateItemPerpageNumberState(itemsPerPage));
        dispatch(getReportsCases(status));
    };
}

export function UpdateFieldSorting(sortData) {
    return {
        type: UPDATE_REPORT_SORT_ITEMS,
        payload: sortData
    }
}

export function SearchByFilters() {
    return async function (dispatch, getState) {
        try {
            dispatch(getCases());
            const state = getState();
            const status = state.reports.activeStatus;
            const reportsCasesCount = state.reports.statusCount;
            var requestData = getFilterRequestData(state);
            ReportService.getFilteredReportsData(requestData, status)
                .then(response => {
                    var result = response ? response.responseData : [];
                    dispatch(getCasesSuccess(result));
                    reportsCasesCount[status] = result.TotalRecords;
                    dispatch(updateFilterResponse(SEARCH_FILTER_CONTROL_LIST, result.FilterBy));
                    dispatch(setReportsCasesCount(reportsCasesCount));
                });
        } catch (err) {
            console.log(err);
        }
    };
}

const getFilterRequestData = (state) => {
    let requestData = {};
    let itemsPerPage = state.reports.itemsPerPage;
    let pageNumber = state.reports.pageNumber + 1;
    let sortData = state.reports.sortBy;
    let searchData = state.reports.setFilterData.filter(element => element.Values.length > 0);
    let requestFilter = updateFieldMappings(SEARCH_FILTER_CONTROL_LIST, searchData);
    requestData.itemsPerPage = itemsPerPage;
    requestData.pageNumber = pageNumber;
    requestData.sortBy = sortData ? sortData : [];
    requestData.filterBy = requestFilter ? requestFilter : [];
    return requestData;
}

const updateFieldMappings = (defaultFilter, getFilterValues) => {
    let filtersValue = JSON.parse(JSON.stringify(defaultFilter));
    getFilterValues.map(element => {
        let itemIndex = filtersValue && filtersValue.findIndex((item) => item.displayId == element.Field);
        if (itemIndex >= 0) {
            element.Field = filtersValue[itemIndex].mappingId;
        }
    });
    return getFilterValues;
}

export function updateFilterResponse(defaultFilter, getFilterValues) {
    return async function (dispatch, getState) {
        let filtersValue = JSON.parse(JSON.stringify(defaultFilter));
        getFilterValues.map(element => {
            let itemIndex = filtersValue && filtersValue.findIndex((item) => item.mappingId == element.Field);
            if (itemIndex >= 0) {
                element.Field = filtersValue[itemIndex].displayId;
            }
        });
        dispatch(setSearchFiltersData(getFilterValues));
    }
}

export function setSearchFiltersData(result) {
    return {
        type: SET_FILTERS_DATA,
        payload: result,
    };
}

export function getSearchFiltersReferenceData(displayFilters) {
    return async function (dispatch, getState) {
        try {
            let filterContorlList = [];
            displayFilters.forEach(control => {
                let headerFilters = SEARCH_FILTER_CONTROL_LIST.find(item => item.Field == control.Field);
                headerFilters.additionalData = control.additionalData ? control.additionalData : null;
                filterContorlList.push(headerFilters);
            });
            dispatch(setSearchFiltersData([]));
            var serachFilterControls = [];
            filterContorlList && filterContorlList.forEach(element => {
                let requestObject = {
                    "Id": element.Field,
                    "additionalData": element.additionalData ? { "UICode": element.additionalData } : null
                }
                serachFilterControls.push(requestObject);
            });
            ReportService.getSearchFiltersReferenceData(serachFilterControls)
                .then(response => {
                    var result = response ? response.responseData : [];
                    dispatch(getSearchFiltersReferenceDataSuccess(result));
                });
        } catch (err) {

        }
    };
}

export function getSearchFiltersReferenceDataSuccess(result) {
    return {
        type: SET_FILTERS_REFERENCE_DATA,
        payload: result,
    };
}

export function caseAmendmentSuccess(result) {
    return {
        type: GET_CASE_AMENDMENT_SUCCESS,
        payload: result,
    };
}

export function caseAmendmentFailure(data) {
    return {
        type: GET_CASE_AMENDMENT_FAILURE,
        payload: data,
    }
}

export function setAlreadyAcknowledgeEMR(data) {
    return {
        type: SET_ALREADY_ACKNOWLEDGE_EMR,
        payload: data
    }
}