import { store } from '../store';
import { fieldFieldRecursivelyInChildControl } from './fieldFieldRecursivelyInChildControl';
import { findFieldInGroup } from './findFieldInGroup';

export const GetFieldByMappingIdParentId = (FieldID, parentFieldId = null) => {
  const state = store.getState();
  var sectionDetails = state.caseDetails.sectionDetails;
  var popupSectionDetails = state.caseDetails.popupSectionDetails;
  var field;
  if (sectionDetails) {
    var sectionGrops = sectionDetails.sectionGroup;
    for (let i = 0; i < sectionGrops.length; i++) {
      let group = sectionGrops[i];
      field = findFieldInGroup(group, FieldID);
      if (field) return field;
    }
  }
  if (popupSectionDetails) {
    popupSectionDetails && popupSectionDetails.forEach(element => {
      var sectionGrops = element.sectionGroup;
      for (let i = 0; i < sectionGrops.length; i++) {
        let group = sectionGrops[i];
        field = findFieldInGroup(group, FieldID);
        if (field) return field;
      }
    });
  }
  if (field) {
    return field;
  }
  else {
    var bookingSheet = state.caseDetails.bookingSheetDetails;
    if (bookingSheet) {
      if (parentFieldId) {
        bookingSheet.forEach(section =>
          section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
            .forEach(element => {
              field = fieldFieldRecursivelyInChildControl(element, FieldID);
            })));
      } else {
        for (let j = 0; j < bookingSheet.length; j++) {
          let section = bookingSheet[j];
          var sectionGrops = section.sectionGroup;
          for (let i = 0; i < sectionGrops.length; i++) {
            let group = sectionGrops[i];
            field = findFieldInGroup(group, FieldID);
            if (field) return field;
          }
          if (field) return field;
        }
      }
    }
    return field;
  }
}