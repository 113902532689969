import { store } from '../../../store'
import { getFieldValueById } from '../../GetFieldValueByID';
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';

export const execute = (props) => {
    const fieldProps = props.additionalProperties
    let fieldMappingId = props.mappingId;
    if (fieldProps) {
        if (fieldProps.DisableFieldDueToNoValueInDependentFields.DependentFields && fieldProps.DisableFieldDueToNoValueInDependentFields.DependentFields.length > 0) {
            var checkCondition = false;
            var permissionDisabled = fieldProps.DisableFieldDueToNoValueInDependentFields.IfTrueSetPermissionTypeId;
            var permissionEnabled = fieldProps.DisableFieldDueToNoValueInDependentFields.IfFalseSetPermissionTypeId;

            fieldProps.DisableFieldDueToNoValueInDependentFields.DependentFields.forEach(checkAllFields => {
                if (checkCondition) { return; }
                var fieldValue = getFieldValueById(checkAllFields);
                if (!fieldValue) {
                    checkCondition = true;
                    return;
                }
            })
            if (checkCondition) {
                store.dispatch(updateFieldPermission(fieldMappingId, permissionDisabled));
            } else {
                store.dispatch(updateFieldPermission(fieldMappingId, permissionEnabled));
            }

        }
    }
}

const DisableFieldDueToNoValueInDependentFields = {
    execute
}

export default DisableFieldDueToNoValueInDependentFields   