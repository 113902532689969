import { store } from '../../../store'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import * as Constants from '../../../constants/GlobalConstants'

export const execute = (control) => {
    const fieldProps = control.additionalProperties
    let value
    if(control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
        value = control.value ? (control.value[fieldProps.DisplayColumnId] ? control.value[fieldProps.DisplayColumnId] : control.value) : (fieldProps.DefaultValue ? fieldProps.DefaultValue : null )
    } else {
        value = control.value;
    }
    if (value || (control.fieldTypeName == "ActiveHiddenFields")) {
        if (fieldProps.ShowTargetFieldIfFieldHasValue.length > 0) {
            fieldProps.ShowTargetFieldIfFieldHasValue.forEach(fieldsAction => {
                value = typeof value == 'number' ? value.toString() : value;
                if (fieldsAction.FieldValue.includes(value)) {
                    fieldsAction.TargetFieldId.forEach(fieldId => {
                        store.dispatch(VisibilityControlAction.showField(fieldId))
                    })
                }
            })
        }
    }
}

const ShowTargetFieldIfFieldHasValue = {
    execute
}

export default ShowTargetFieldIfFieldHasValue