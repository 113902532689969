import { store } from '../../../store'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import { getFieldById } from '../../GetFieldById'

export const execute = (control) => {
    const fieldProps = control.additionalProperties

    if (fieldProps.HideTargetFieldIfAdditionalFieldDoesNotHaveValue.length > 0) {
        fieldProps.HideTargetFieldIfAdditionalFieldDoesNotHaveValue.forEach(fieldsAction => {
            var inPopUp= fieldsAction.inpopup!= undefined && fieldsAction.inpopup ? fieldsAction.inpopup : false;
            var isPresent = fieldsAction.AdditionalFields.some(condition => {
                var nestedKeys = condition.AdditionalFieldId.split('.')
                var value = getFieldById(nestedKeys[0]).value
                if (value) {
                    var additionalNestedFieldVal = value
                    for (var i = 1; i < nestedKeys.length; i++) {
                        if (additionalNestedFieldVal) {
                            additionalNestedFieldVal = additionalNestedFieldVal[nestedKeys[i]]
                        }
                    }
                    value = additionalNestedFieldVal
                    return value == condition.AdditionalFieldValue
                } else {
                    return false
                }
            })

            if (!isPresent) {
                fieldsAction.TargetFieldId &&  fieldsAction.TargetFieldId.forEach(fieldId => {
                    store.dispatch(VisibilityControlAction.hideField(fieldId,inPopUp))
                })
            }
        })
    }

    else {
        fieldProps.HideTargetFieldIfAdditionalFieldDoesNotHaveValue.forEach(fieldsAction => {            
                fieldsAction.TargetFieldId && fieldsAction.TargetFieldId.forEach(fieldId => {
                store.dispatch(VisibilityControlAction.hideField(fieldId))
            });       
        });
    }
}

const HideTargetFieldIfAdditionalFieldDoesNotHaveValue = {
    execute
}

export default HideTargetFieldIfAdditionalFieldDoesNotHaveValue