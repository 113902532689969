import RuleFactory from './RuleFactory'

export const initializeEngine = (props, AddOnLoadONChangeFlag = null) => {   
    Object.keys(props.additionalProperties).map(key => {
        var rule = RuleFactory.getApplicableRule(key)
        if (rule) {
            rule.execute(props, AddOnLoadONChangeFlag)
        }
    })
}

const RuleEngine = {
    initializeEngine
}

export default RuleEngine