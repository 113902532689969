import { store } from '../../../store';
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';
import { getFieldById } from '../../../utils/GetFieldById';

export const execute = (props) => {
    const fieldProps = props.additionalProperties   
    if (fieldProps && fieldProps.DisableTargetFieldIfFieldHasValue) {
        var permissionDisabled = fieldProps.DisableTargetFieldIfFieldHasValue.IfTrueSetPermissionTypeId;
        var permissionEnabled = fieldProps.DisableTargetFieldIfFieldHasValue.IfFalseSetPermissionTypeId;
        
        fieldProps.DisableTargetFieldIfFieldHasValue.TargetFieldId.forEach(targetFieldId => {           
            var checkWithFieldValue=fieldProps.DisableTargetFieldIfFieldHasValue.FieldValue;

            if (checkWithFieldValue.includes(props.value)) {           
                store.dispatch(updateFieldPermission(targetFieldId, permissionDisabled));
            } 
            else {
                store.dispatch(updateFieldPermission(targetFieldId, permissionEnabled));
            }
        })
    }
}

const DisableTargetFieldIfFieldHasValue = {
    execute
}

export default DisableTargetFieldIfFieldHasValue   