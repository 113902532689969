import request from "../providers/RestServiceProvider";
import * as Constants from "../constants/GlobalConstants";
import * as Locations from "../constants/Locations";
import qs from "qs";



export const forgetPasswordData = async (forgetData) => {
    return await request({
        url: Locations.FORGET_PASSWORD_URL,
        "content-type": "application/x-www-form-urlencoded",
        method: "POST",
        data: qs.stringify(forgetData),
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    }).catch(err => {
        return {
          status: 'Failed',
          responseData: err.data,
        }
      });
};


export const resetPasswordData = async (resetData) => {
    return await request({
        url: Locations.RESET_PASSWORD_URL,
        "content-type": "application/x-www-form-urlencoded",
        method: "POST",
        data: qs.stringify(resetData),
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    });
};



const PasswordService = {
    resetPasswordData,
    forgetPasswordData,
}

export default PasswordService;
