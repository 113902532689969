import { showField } from '../../../actions/VisibilityControlAction';
import { getSelectionsForControl } from '../../../reducers/CaseDetailsReducer';
import { store } from '../../../store';
import { getFieldById } from '../../GetFieldById';

const execute = (props) => {
    let additionalProperties = props.additionalProperties
    const state = store.getState()
    if (additionalProperties.OnSelectionShowTargetField) {
        additionalProperties.OnSelectionShowTargetField.map(rule => {
            let control = getFieldById(rule.TargetFieldId)
            if (control) {
            var displayColumnId = control.additionalProperties.LoadReferenceData;
            let controlSelectionVals = getSelectionsForControl(state, displayColumnId)           
            if (rule.TargetFieldAtleastAvailableValueCount) {
                if (controlSelectionVals) {
                    if (controlSelectionVals.length >= parseInt(rule.TargetFieldAtleastAvailableValueCount)) {
                        store.dispatch(showField(rule.TargetFieldId))
                    }
                }
            }
        }
        })
    }
}

const OnSelectionShowTargetField = {
    execute
}

export default OnSelectionShowTargetField