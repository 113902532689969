const { SHOW_FORM_CONTROL, HIDE_FORM_CONTROL, CLEAR_FIELD_VALUE, SHOW_SECTION_GROUP, HIDE_SECTION_GROUP, UPDATE_ADDITIONAL_PROPS_IN_POPUP } = require("../constants/ActionTypes")
import { BLANK_VALUE, TEXT, PHONE_NUMBER, ZIPCODE } from '../constants/GlobalConstants';
import { getFieldById } from '../utils/GetFieldById';
export function showFormControlVisibility(replacedControl) {
  return {
    type: SHOW_FORM_CONTROL,
    payload: replacedControl,
  }
}

export function hideFormControlVisibility(replacedControl) {
  return {
    type: HIDE_FORM_CONTROL,
    payload: replacedControl,
  }
}

const updatePropsInPopup = (replacedControl) => {
  return {
    type: UPDATE_ADDITIONAL_PROPS_IN_POPUP,
    payload: replacedControl
  }
}
export function showField(fieldId, inPopUp, parentFieldId) {
  return async function (dispatch, getState) {
    const state = getState()
    if (parentFieldId != null && parentFieldId != undefined) {
      var field;
      var section = state.caseDetails.sectionDetails
      section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
        .forEach(element => {
          if (element.parentFieldId) {
            section.sectionGroup.forEach(x => x.fields.filter(field => field.fieldId == element.parentFieldId)
              .forEach(ChildElement => {
                ChildElement.childControls.map(item => {
                  item.forEach(itemField => {
                    if (itemField.fieldId == parentFieldId) {
                      itemField.childControls.forEach(ChildField => {
                        if (ChildField.mappingId == fieldId) {
                          ChildField.isVisible = true;
                          field = ChildField;
                        }
                        if (field) {
                          return field;
                        }
                      });
                    }
                  }
                  )
                });
              })
            )
          }
          else {
            element.childControls.map(item => {
              item.forEach(control => {
                if (control.mappingId == fieldId) {
                  control.isVisible = true;
                  field = control;
                }
              })
            });
          }
        }));
    }
    if (inPopUp) {
      var replacedControl = state.caseDetails.popupSectionDetails;
      replacedControl.forEach(sections => {
        sections.sectionGroup.forEach(x => {
          x.fields.filter(element => element.mappingId == fieldId)
            .forEach(element => element.isVisible = true)
        })
      });
      //  dispatch(updatePropsInPopup(replacedControl))
    } else {
      var sectionDetails = state.caseDetails.sectionDetails
      if (sectionDetails) {
        var replacedControl = sectionDetails;
        replacedControl.sectionGroup.forEach(x => x.fields.filter(element => element.mappingId == fieldId)
          .forEach(element => {
            if (element != undefined && element.value == null && element.additionalProperties && element.additionalProperties.DefaultValue != undefined) {
              element.value = element.additionalProperties.DefaultValue;
            }
            element.isVisible = true;
          }
          ));
        dispatch(showFormControlVisibility(replacedControl))
      }
    }
  }
}
export const showHideTableControl = (TargetFieldId, columnMappingId, isvisible, onChange) => {
  return async function (dispatch, getState) {
    const state = getState()
    var replacedControl = state.caseDetails.sectionDetails;
    replacedControl.sectionGroup.forEach(element => {
      element.fields.forEach(control => {
        if (control.fieldTypeName == "Table" && control.mappingId == TargetFieldId) {
          if (control.childControls && control.childControls.length > 1) {
            var getChilds = JSON.parse(JSON.stringify(control.childControls))
            control["childControls"] = [];
            control["ChildCount"] = [];
            var childFields = []

            //get default child controls for spinne column row
            var columnFieldChildControls = []
            getChilds && getChilds.forEach(innerArray => {
              var spineLevelFieldArray = innerArray && innerArray.find(x => x.mappingId == columnMappingId);//SelectedProcedureSpineLevels
              if (spineLevelFieldArray && Object.keys(spineLevelFieldArray).length > 0) {
                columnFieldChildControls = spineLevelFieldArray.childControls;
              }
            })



            var i = 0;
            getChilds.forEach(listItem => {
              var childs = []
              var updatedColumnMappingId = i > 0 ? columnMappingId + i : columnMappingId;
              listItem.forEach(item => {
                if (item.parentFieldId == control.fieldId && item.mappingId == updatedColumnMappingId) {
                  item.isVisible = isvisible;
                  if (item.childControls.length == 0 && columnFieldChildControls.length > 0) {
                    item.childControls = columnFieldChildControls;
                    item.childControls[0].mappingId = columnFieldChildControls[0].mappingId + i; //SpineFromLevelId
                    item.childControls[1].mappingId = columnFieldChildControls[1].mappingId + i; //SpineToLevelId                  
                  }
                  if (!isvisible && item.childControls.length > 0) {
                    item.childControls[0].value = null;
                    item.childControls[1].value = null;
                  }
                  childs.push(item);
                } else {
                  if (item.parentFieldId == control.fieldId && item.mappingId != updatedColumnMappingId) {
                    childs.push(item);
                  }
                }
              });
              childFields.push(childs);
              i++;
            });

            control.ChildCount = childFields[0];
            control.childControls = childFields;

          }
          else {
            control["childControls"] = [];
            control["ChildCount"] = [];
            var childFields = []

            element.fields.forEach(item => {
              if (item.mappingId == columnMappingId) {
                item.isVisible = isvisible;
                childFields.push(item);
              } else {
                if (item.parentFieldId == control.fieldId && item.mappingId != columnMappingId) {
                  childFields.push(item);
                }
              }
            })

            childFields.forEach(child => {
              child["childControls"] = [];
              element.fields.forEach(childField => {
                if (childField.parentFieldId == child.fieldId) {
                  child.childControls.push(childField);
                }
              })
            })

            control.ChildCount = childFields;
            control.childControls.push(childFields);
          }
        }
      });
    });
    dispatch(hideFormControlVisibility(replacedControl));
  }
}

export const showFieldInTayTable = (fieldId, inPopUp, parentFieldId) => {
  return async function (dispatch, getState) {
    const state = getState()
    if (inPopUp) {
      var popupControl = state.caseDetails.popupSectionDetails;
      popupControl.forEach(replacedControl => {
        replacedControl.sectionGroup.forEach(x => {
          x.fields.filter(element => element.mappingId == parentFieldId)
            .forEach(element => {
              element.childControls.map(item => {
                item.forEach(control => {
                  if (control.mappingId == fieldId) {
                    control.isVisible = true;
                    control.isUpdated = true;
                  }
                })
              });
            })
        })
      });
      dispatch(updatePropsInPopup(popupControl))
    }
  }
}

export const hideFieldInTayTable = (fieldId, inPopUp, parentFieldId) => {
  return async function (dispatch, getState) {
    const state = getState()
    if (inPopUp) {
      var popupControl = state.caseDetails.popupSectionDetails;
      popupControl.forEach(replacedControl => {
        replacedControl.sectionGroup.forEach(x => {
          x.fields.filter(element => element.mappingId == parentFieldId)
            .forEach(element => {
              element.childControls.map(item => {
                item.forEach(control => {
                  if (control.mappingId == fieldId) {
                    control.isVisible = false;
                    control.isUpdated = true;
                    if (control.fieldTypeName == TEXT) {
                      control.value = BLANK_VALUE
                    }
                    else {
                      control.value = null;
                    }
                  }
                })
              });
            })
        })
      });
      dispatch(updatePropsInPopup(popupControl))
    }
  }
}
export const hideField = (fieldId, inPopUp, parentFieldId) => {
  return async function (dispatch, getState) {
    const state = getState()
    if (parentFieldId != null && parentFieldId != undefined) {
      var field;
      var section = state.caseDetails.sectionDetails
      section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
        .forEach(element => {
          if (element.parentFieldId) {
            section.sectionGroup.forEach(x => x.fields.filter(field => field.fieldId == element.parentFieldId)
              .forEach(ChildElement => {
                ChildElement.childControls.map(item => {
                  item.forEach(itemField => {
                    if (itemField.fieldId == parentFieldId) {
                      itemField.childControls.forEach(ChildField => {
                        if (ChildField.mappingId == fieldId) {
                          ChildField.isVisible = false;
                          ChildField.isUpdated = true; //update this property in redux to get latest value in api although control is not visiable 
                          field = ChildField;
                        }
                        if (field) {
                          return field;
                        }
                      });
                    }
                  }
                  )
                });
              })
            )
          }
          else {
            element.childControls.map(item => {
              item.forEach(control => {
                if (control.mappingId == fieldId) {
                  control.isVisible = false;
                  control.isUpdated = true;
                  field = control;
                }
              })
            });
          }
        }));
    }
    if (inPopUp) {
      var replacedControl = state.caseDetails.popupSectionDetails;
      var popupControl = state.caseDetails.popupSectionDetails;
      popupControl.forEach(replacedControl => {
        replacedControl.sectionGroup.forEach(x => {
          x.fields.filter(element => element.mappingId == fieldId)
            .forEach(element => {
              element.isVisible = false
              element.isUpdated = true;
              if (element.fieldTypeName == TEXT || element.fieldTypeName == ZIPCODE || element.fieldTypeName == PHONE_NUMBER) {
                element.value = BLANK_VALUE
              }
              else {
                element.value = null;
              }
            })
        })
      });
      dispatch(updatePropsInPopup(replacedControl))
    } else {
      var sectionDetails = state.caseDetails.sectionDetails
      if (sectionDetails) {
        var replacedControl = sectionDetails
        replacedControl.sectionGroup.forEach(x => x.fields.filter(element => element.mappingId == fieldId)
          .forEach(element => {
            element.isVisible = false
            element.isUpdated = true;
            if (element.fieldTypeName == TEXT || element.fieldTypeName == ZIPCODE || element.fieldTypeName == PHONE_NUMBER) {
              element.value = BLANK_VALUE
            }
            else {
              element.value = null;
            }
          }))
        dispatch(hideFormControlVisibility(replacedControl))
      }
    }
  }
}

export const clearFieldValue = (fieldId) => {// this is in progress will remove it later.
  return async function (dispatch, getState) {
    dispatch(clearFieldValueSuccess(fieldId))
  }
}

export function clearFieldValueSuccess(data) {
  return {
    type: CLEAR_FIELD_VALUE,
    payload: data,
  }
}

export function showHideSectionGroup(rule) {
  return async function (dispatch, getState) {
    if (rule) {
      if (rule.ShowSectionGroup) {
        dispatch(showSectionGroup(rule.ShowSectionGroup))
      }
      if (rule.HideSectionGroup) {
        dispatch(hideSectionGroup(rule.HideSectionGroup))
      }
    }
  }
}


export function showSectionGroup(data) {
  return {
    type: SHOW_SECTION_GROUP,
    payload: data,
  };
}

export function hideSectionGroup(data) {
  return {
    type: HIDE_SECTION_GROUP,
    payload: data,
  };
}

const VisibilityControlAction = {
  showField,
  hideField,
  clearFieldValue,
  hideSectionGroup,
  showSectionGroup,
  showHideSectionGroup,
  showHideTableControl,
  hideFieldInTayTable,
  showFieldInTayTable
}

export default VisibilityControlAction
