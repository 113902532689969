import { store } from "../store"
import { getFieldValueById } from "./GetFieldValueByID";

const getSelectedRefData = (fielAdditionaldPrps, selections) => {
    let primaryReferenceData=fielAdditionaldPrps.PrimaryReferenceData;
    let targetFieldMappingid=fielAdditionaldPrps.LoadReferenceData;
    let filterColumnId=fielAdditionaldPrps.FilterColumnId;
    let dependentFields=fielAdditionaldPrps.DependentFields;
    let dependentFieldValue= getFieldValueById(dependentFields);   
    let copyRefData=selections[primaryReferenceData];
    let newData=copyRefData.filter(item => {  return item[filterColumnId]==dependentFieldValue});
    let newselectionData={ [targetFieldMappingid]: newData };    
    return newselectionData ? newselectionData : null
}
const GetSelectionValueByCondition = {
    getSelectedRefData
}
export default GetSelectionValueByCondition