import React, { Component } from 'react';
import axios from 'axios'
import qs from 'query-string';
import { Button, Typography, Card, CircularProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../res/images/logo-login-blue.svg';
import * as constants from '../../constants/GlobalConstants';
import LocalStorageProvider from '../../providers/LocalStorageProvider';
import {
  REP_CONFIRMATION_PRODUCTS_URL
} from '../../constants/Locations';


const Detail = ({ label, value }) => (
  <Typography>
    <Label>{label}:</Label>
    {value}
  </Typography>
);

const Container = styled.div`
  width: 500px;
  margin: auto;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
`;

const StyledCard = styled(Card)`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self: center;
  margin: 25px 0;
`;

const StyledContent = styled(Typography)`
  padding-top: 10px;
`;

const Label = styled.div`
  display: inline-block;
  width: 130px;
  font-weight: bold;
`;

const Banner = styled.img`
  width: 100%;
  max-width: 275px;
  height: 165px;
`;

const CardTitle = ({ loading, displayStatusCode }) => {
  if (loading) return <Typography variant="h5">{constants.LBL_APPROVING}</Typography>;
  if (displayStatusCode) return <Typography variant="h5">{displayStatusCode} </Typography>;
  return <Typography variant="h5">{constants.LBL_ERROR_APPROVING}</Typography>;
};

const CardContent = ({ loading, error, productDataObj, onClickViewCaseBtn }) => {
  if (loading) return <StyledCircularProgress />;
  if (error) return <StyledContent>{error}</StyledContent>;


  const productLabel = productDataObj && productDataObj.productList && productDataObj.productList.length > 1 ? constants.LBL_PRODUCTS : constants.LBL_PRODUCT;
  var productNamesLabel = "";
  if (productDataObj && productDataObj.productList && productDataObj.productList.length > 0) {
    productNamesLabel = productDataObj.productList.map(function (productObj) {
      return productObj.productName;
    }).join(', ');
  }

  let queryParams = new URLSearchParams(productDataObj?.viewCaseUrl);
  let keyValue = queryParams.get("key");
  const passThroughURL = `/case/${productDataObj.caseId}/PatientInformation?returnUrl=${true}&key=${keyValue}`;
  LocalStorageProvider.setReturnURL(passThroughURL);

  return (
    <StyledContent>
      <Detail label={constants.LBL_CASE_ID} value={productDataObj.caseId} />
      <Detail label={productLabel} value={productNamesLabel} />
      <Detail label={constants.LBL_PRIMARY_REP} value={productDataObj.primaryRep || ""} />
      <Detail label={constants.LBL_CONFIRMING_REP} value={productDataObj.confirmingRep || ""} />
      <Detail label={constants.LBL_HOSPITAL} value={productDataObj.hospitalName} />
      <Detail label={constants.LBL_SURGEON_NAME} value={productDataObj.surgeonName || ""} />
      <Detail label={constants.LBL_SURGERY_DATE} value={productDataObj.procedureDate} />
      <Detail label={constants.LBL_SURGERY_TIME} value=
      {(productDataObj.procedureTime && productDataObj.procedureTime == "12:00 AM") ? "TBD" : productDataObj.procedureTime} />
      <Button
        color="primary"
        variant="contained"
        style={{ width: '100%', marginTop: 25 }}
        onClick={() => onClickViewCaseBtn(productDataObj.caseId)}
      >
        {constants.LBL_VIEW_CASE}
      </Button>
    </StyledContent>
  );
};

class RepConfirmation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      error: null,
      displayStatusCode: null,
      productDataObj: null,
    };
  }

  componentDidMount() {
    const { location: { search }, } = this.props;

    if (!search) {
      this.setState({
        loading: false,
        error: constants.ERR_SOMETHING_WENT_WRONG_TO_UPDATE_PRODUCTS,
        message: null,
        displayStatusCode: null,
        productDataObj: null,
      });
      return;
    }
    var queryParameters = qs.parse(search);



    axios
      .get(REP_CONFIRMATION_PRODUCTS_URL, { params: queryParameters })
      .then(({ data }) => {
        if (data.httpStatusCode == 200 && data.payload) {
          this.setState({
            loading: false,
            error: data.payload.statusCode != constants.PRODUCTS_CONFIRMED ? data.payload.message : null,
            displayStatusCode: data.payload.displayStatusCode,
            productDataObj: data.payload.statusCode == constants.PRODUCTS_CONFIRMED ? data.payload : null,
          });
        } else {
          this.setState({
            loading: false,
            error: constants.ERR_SOMETHING_WENT_WRONG_TO_UPDATE_PRODUCTS,
            displayStatusCode: null,
            productDataObj: null,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: constants.ERR_SOMETHING_WENT_WRONG_TO_UPDATE_PRODUCTS,
          displayStatusCode: null,
          productDataObj: null,
        });
      });

  }


  onClickViewCaseBtn = caseId => {
    if (!caseId) {
      alert(constants.ERR_CASE_ID_NOT_AVAILABLE);
      return;
    }
    this.props.history.push(`/case/${caseId}/PatientInformation`);
  };

  render() {

    return (
      <Container>
        <div className="logo">
          <Banner src={logo} />
        </div>
        <StyledCard>
          <CardTitle {...this.state} />
          <CardContent onClickViewCaseBtn={this.onClickViewCaseBtn} {...this.state} />
        </StyledCard>
      </Container>
    );
  }
}

// const mapStateToProps = state => ({
//   caseDetails: state.caseDetails
// });

export default withRouter(RepConfirmation);
