import React, { Children } from 'react';
import { Divider, Typography, Grid } from '@mui/material';
import FieldComponent from './FieldComponent';
import { DELETE_POSITION_AND_BED_TYPE } from '../../constants/GlobalConstants';
import * as Colors from '../../res/values/Colors'
import Loader from '../common/Loader'
import DocumentTable from '../case/DocumentTable'

class SectionGroup extends React.Component {
  sectionGroupById = control => {
    if (control.isVisible && control.displayOrder != 0 && control.additionalProperties 
      && parseInt(control.additionalProperties.ControlSize) > 0 && control.parentFieldId == null) {
        
      return (
        <Grid
          item
          md={parseInt(control.additionalProperties.ControlSize)}
          lg={parseInt(control.additionalProperties.ControlSize)}
          xs={12}
          style={{ padding: '8px' }}
          key={control.fieldId}
          className="sectionGroup"
        >
          <FieldComponent
            key={control.fieldId}
            control={control}
            fieldType={control.fieldTypeName}
            isRequired={control.requiredBl}
            disabled={this.props.disabled}
            groupDisplayOrder={this.props.group.displayOrder}
            validateSectionControls={(control) => this.props.validateSectionControls ? this.props.validateSectionControls(control) : null}
          />
        </Grid>
      )
      
    } else if (control.isVisible && control.additionalProperties && control.fieldTypeName == "ActiveHiddenFields"){
     return(
       <>
        <FieldComponent
        key={control.fieldId}
        control={control}
        fieldType={control.fieldTypeName}
        isRequired={control.requiredBl}
        disabled={this.props.disabled}
        groupDisplayOrder={this.props.group.displayOrder}
        validateSectionControls={(control) => this.props.validateSectionControls ? this.props.validateSectionControls(control) : null}
        />       
       </>
     )
    }
    else {
      return null;
    }
  };

  renderTable(childControls) {
    return childControls.map((item, index) => {
      return item.map(control => {
        if (control.mappingId != DELETE_POSITION_AND_BED_TYPE || (control.mappingId == DELETE_POSITION_AND_BED_TYPE && index != 0)) {
          return (
            <Grid 
              item
              md={parseInt(control.additionalProperties.ControlSize)}
              lg={parseInt(control.additionalProperties.ControlSize)}
              xs={12}
              style={{ padding: '8px' }}
              key={control.fieldId}
              index={index}
            >
              <FieldComponent
                key={control.fieldId}
                control={control}
                fieldType={control.fieldType}
                isRequired={control.requiredBl}
                disabled={this.props.disabled}
                index={index}
                validateSectionControls={(control) => this.props.validateSectionControls ? this.props.validateSectionControls(control) : null}
              />
            </Grid>
          )
        }
      });
    });
  }

  sectionHeaderHtml=(sectionGroupId, sectionTitle, isPopup=false)=>{
    //For popup and create case, we don't need section header title bcs popup we have it's own
    if( !isPopup && sectionGroupId != 44){
      return(
        <>
        <Typography variant="subtitle1" style={{ color: Colors.SECTION_HEADING_DARK, paddingTop: '3%' }}>{sectionTitle}</Typography>

        <Divider />
      </>
    )
    }
    else{
      return null;
    }
  }

  render() {
    const { props } = this;
    const { others, isPopup } = props;
    const controls = props.group.fields
      .map(this.sectionGroupById)
      .filter(Boolean);
      
    return (
      <>
        { this.sectionHeaderHtml(props.group.sectionGroupId, props.label, isPopup) }
        <Grid container>
          {controls}
        </Grid>
      </> 
    );
  }
};

export default SectionGroup;
