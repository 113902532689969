import { store } from '../../../store'
import { updateIsRequiredValidation } from '../../../actions/CaseDetailsAction'
import * as Constants from '../../../constants/GlobalConstants'

export const execute = (control) => {
    const fieldProps = control.additionalProperties;
    let value;
    if (control.fieldTypeName === Constants.SELECTION 
        || control.fieldTypeName === Constants.SELECTION_KEY_VALUE 
        || control.fieldTypeName === Constants.TYPEAHEAD) {
        value = control.value ? control.value[fieldProps.DisplayColumnId] : null
    } else {
        value = control.value
    }

    if (value) {
        if (fieldProps.MakeTargetFieldARequiredField.length > 0) {
            fieldProps.MakeTargetFieldARequiredField.forEach(fieldsAction => {
                if (fieldsAction.FieldValue.includes(value.toString())) {
                    fieldsAction.TargetFieldId.forEach(fieldId => {
                        store.dispatch(updateIsRequiredValidation(fieldId, true))
                    })
                }               
            })
        }
    }
}

const MakeTargetFieldARequiredField = {
    execute
}

export default MakeTargetFieldARequiredField