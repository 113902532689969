import { store } from '../store';


export const getFieldDetailsById = (FieldID, parentFieldId = null) => {
    const state = store.getState();
    var sectionDetails = state.caseDetails.sectionDetails;
    var popupSectionDetails = state.caseDetails.popupSectionDetails;
    var field;
    if (sectionDetails) {
        sectionDetails.sectionGroup && sectionDetails.sectionGroup.forEach(group => {


            group.fields.forEach(control => {
                if (control.fieldId === FieldID) {
                    field = control;
                }               
            });
        });
    }
    if (popupSectionDetails) {
        popupSectionDetails && popupSectionDetails.forEach(element => {
            element.sectionGroup && element.sectionGroup.forEach(group => {
                group.fields.forEach(control => {
                    if (control.fieldId === FieldID) {
                        field = control;
                    }
                });
            });
        });
    }
    if (field) {
        return field;
    }
    else {
        var bookingSheet = state.caseDetails.bookingSheetDetails;
        if (bookingSheet) {

            if (parentFieldId) {
               // if (bookingSheet) {
                    const replacedControl = bookingSheet;
                    replacedControl.forEach(section =>
                        section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
                          .forEach(element => {
                            if (element.parentFieldId) {
                              section.sectionGroup.forEach(x => x.fields.filter(field => field.fieldId == element.parentFieldId)
                                .forEach(ChildElement => {
                                  ChildElement.childControls.map(item => {
                                    item.forEach(itemField => {
                                      if (itemField.fieldId == parentFieldId) {
                                        itemField.childControls.forEach(ChildField => {
                                          if (ChildField.fieldId == FieldID) {                                         
                                            field = ChildField;
                                          }
                                          if (field) {
                                            return field;
                                        }
                                        });
                                      }
                                    }
                                    )
                                  });
                                })
                              )
                            }
                            else {
                              element.childControls.map(item => {
                                item.forEach(control => {
                                  if (control.mappingId == FieldID) {
                                    field = control;
                                  }
                                })
                              });
                            }
                          })));
                    // replacedControl.forEach(section =>
                    //     section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
                    //         .forEach(element => {
                    //             element.childControls.map(item => {
                    //                 item.forEach(control => {
                    //                     if (control.mappingId == FieldID) {
                    //                         field = control;
                    //                     }
                    //                 }
                    //                 )
                    //             });
                    //         })));
              //  }
                // if (bookingSheet) {
                //     const replacedControl = bookingSheet;
                //     replacedControl.forEach(section =>
                //         section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
                //             .forEach(element => {
                //                 element.childControls.map(item => {
                //                     item.forEach(control => {
                //                         if (control.mappingId == FieldID) {
                //                             field = control;
                //                         }
                //                     }
                //                     )
                //                 });
                //             })));
                // }
            } else {
                bookingSheet.forEach(section => {
                    section.sectionGroup.forEach(group => {
                        group.fields.forEach(control => {
                            if (control.mappingId === FieldID) {
                                field = control;

                            }
                        });
                    });
                });
            }
        }
        return field;
    }
}