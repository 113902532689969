import React, { useEffect, useState } from 'react'
import { Grid, Card, CircularProgress, StyledEngineProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { styled, ThemeProvider } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import DoctorImg from '../../res/images/doctor.jpg'
import Logo from '../../res/images/medtel_logo_full.png'
import MedtelBanner from '../../res/images/medtel_banner.png'
import Controls from '../controls/Controls'
import { useForm, Form } from '../common/useForm'
import { Redirect, useHistory } from 'react-router-dom'
import * as Colors from '../../res/values/Colors'
import * as Dimens from '../../res/values/Dimens'
import AuthActions from '../../actions/AuthActions'
import { useDispatch } from "react-redux";
import { SURGICAL_DASHBOARD, LOGIN, TERMS_AND_CONDITION, FORGOT_PASSWORD, KEY_FAILED, SUCCESS, FAILED, } from '../../constants/GlobalConstants';

import { Link } from 'react-router-dom'
import PasswordService from '../../services/PasswordService'
import { notify } from '../../actions/SnackbarAction'

const theme = createTheme();

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width: '100vw',
    },
    bannerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    displayBanner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${MedtelBanner})`
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        width: '70%',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '35%',
        marginLeft: '50%',
        transform: 'translate(-50%, -40%)'
    },
    logo: {
        height: '15%',
        width: '35%'
    },

    muiCard: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: Dimens.BORDER_RADIUS,
        border: `${Dimens.BORDER_WIDTH} black solid`,
        padding: `${Dimens.LOGIN_CARD_PADDING_VERTICAL} ${Dimens.LOGIN_CARD_PADDING_HORIZONTAL}`,
    },
    form: {
        '& .MuiButton-root': {
            marginLeft: '50%',
            marginTop: '0.5em',
            transform: 'translate(-50%,0%)',
            borderRadius: Dimens.BORDER_RADIUS,
            padding: `${Dimens.LOGIN_BUTTON_PADDING_VERTICAL} ${Dimens.LOGIN_BUTTON_PADDING_HORIZONTAL}`,
        },
        '& .MuiButton-label': {
            textTransform: 'uppercase',
        },

        '& .MuiTextField-root': {
            width: '100%',
            marginTop: '1em'
        }
    },
    formTitle: {
        margin: Dimens.LOGIN_MARGIN_TOP,
        fontSize: Dimens.TEXT_SIZE_LARGE,
        marginTop: Dimens.LOGIN_MARGIN_TOP
    },
    formTerms: {
        fontSize: Dimens.TEXT_SIZE_REGULAR,
        marginTop: Dimens.LOGIN_MARGIN_TOP
    }
}))




const initialLoginValues = {
    Email: ''
}

function ForgotPassword() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false)
    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        if ('Email' in fieldValues)
            temp.Email = (/$^|.+@.+..+/).test(fieldValues.Email) ? "" : "Email is not valid."

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange
    } = useForm(initialLoginValues, true, validate)

    let history = useHistory()

    function handleSubmit(e) {
        e.preventDefault()
        var formData = [];
        setLoading(true)
        const forgetData = {
            emailAddress: values.Email

        }
        formData.push(forgetData);
        
       
        PasswordService.forgetPasswordData(forgetData)
            .then((response) => {
                if (response.status === SUCCESS && !loading) {
                    dispatch(notify(
                        'Successfully',
                        'An email was sent to you with a link to reset your password',
                        'success'
                    ))
                    setForgotPassword(true)          
                    setLoading(false);
                    
                } else if (response.status === FAILED) {
                    dispatch(notify(
                        'Failed',
                        'Email address not found',
                        'error'
                    ))
                    setLoading(false);
                 
                
                }
    
            })
     
    }

    if (forgotPassword == true) {
        return <Redirect to={'/login'} />
    }

    return (
        <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
        <div>
            <Grid container className={classes.root}>
                <Grid item md={6} lg={6} className={classes.bannerContainer}>
                    <div className={classes.displayBanner} />
                </Grid>
                <Grid item xs={6} className={classes.bannerContainer}>
                    <div className={classes.paper}>


                        <Card className={classes.muiCard}
                            elevation={0}
                        >
                            <Controls.Typography
                                className={classes.formTitle}
                            >
                                {FORGOT_PASSWORD}
                            </Controls.Typography>
                            <Form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <Controls.Input
                                    size='small'
                                    label="Email"
                                    name="Email"
                                    fieldTypeName="email"
                                    value={values.Email}
                                    onChange={handleInputChange}
                                    error={errors.Email ? true : false}
                                    helperText={errors.Email}
                                />


                                <Controls.Button
                                    label='Submit'
                                    size='small'
                                    type="submit"
                                    disabled={loading} 
                                    loader={loading && (
                                      <CircularProgress
                                        size={24}  
                                        style={{marginLeft:'-30px'}}
                                      /> )} 
                  
                                />

                            </Form>
                            <Controls.Typography
                                paragraph
                                className={classes.formTerms}
                            >{TERMS_AND_CONDITION}</Controls.Typography>
                        </Card>
                        <Controls.Typography
                            paragraph
                        >
                            <Link to='/login'>{LOGIN}</Link>
                        </Controls.Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
        </ThemeProvider>
    </StyledEngineProvider>
    )
}
export default ForgotPassword;
