import moment from 'moment';
import { FORMAT_CHECK, DATE, LABEL } from "../../../constants/GlobalConstants";
import { store } from '../../../store'
import { updateControlValue } from '../../../actions/CaseDetailsAction'

export const execute = (props) => {
    if (props) {
        if (props.fieldTypeName == DATE || props.fieldTypeName == LABEL) {
            var fieldValue = props.value ? props.value : null;
            var parentFieldId = props.parentFieldId ? props.parentFieldId : null;
            var mappingId = props.mappingId;
            if (props.additionalProperties && props.additionalProperties.Format && fieldValue) {
                var dateToValidate = moment(fieldValue, props.additionalProperties.Format).format(props.additionalProperties.Format);
                store.dispatch(updateControlValue(mappingId, dateToValidate,parentFieldId));
            }
        }
    }
}

const Format = {
    execute
}

export default Format