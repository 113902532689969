import { store } from '../../../store'
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';
import * as Constants from '../../../constants/GlobalConstants';
import { getFieldById } from '../../GetFieldById';
export const execute = (props) => {
    const fieldProps = props.additionalProperties
    //  const fieldProps = { "ControlSize": "6", "MaxLength": "100", "DisableTargetFieldIfNoValueByCondition": [{ "Condition": [{ "DependentFieldId": "CancellationReason", "DependentFieldValue": ["1", "7", "8"], "DependentFieldValueOperator": "EqualTo" }], "DisableTargetFieldIfNoValue": { "TargetFieldId": ["CancelCaseYesBtn"], "IfTrueSetPermissionTypeId": "5", "IfFalseSetPermissionTypeId": "6" } }] };
    if (fieldProps && fieldProps.DisableTargetFieldIfNoValueByCondition) {
        var checkCondition = false;
        fieldProps.DisableTargetFieldIfNoValueByCondition.forEach(item => {
            var permissionDisabled = item.DisableTargetFieldIfNoValue.IfTrueSetPermissionTypeId;
            var permissionEnabled = item.DisableTargetFieldIfNoValue.IfFalseSetPermissionTypeId;
            var targetFields = item.DisableTargetFieldIfNoValue.TargetFieldId;
            item.Condition.forEach(condition => {
                var dependentField = getFieldById(condition.DependentFieldId);
                if (dependentField) {
                    var fieldValue = null;
                    if (dependentField.fieldTypeName === Constants.SELECTION || dependentField.fieldTypeName === Constants.SELECTION_KEY_VALUE || dependentField.fieldTypeName === Constants.TYPEAHEAD) {
                        fieldValue = dependentField.value ? dependentField.value[fieldProps.DisplayColumnId] : null; //  TODO: Need to check fieldProps.DisplayColumnId value at run time
                    } else {
                        fieldValue = dependentField.value
                    }

                    var checkRule = condition.DependentFieldValue.some((rule) => {
                        return rule == fieldValue
                    })
                    if (checkRule) {
                        checkCondition = true;;
                    }
                }
            });
            if (!props.value && checkCondition) {
                targetFields.forEach(fieldMappingId => {
                    store.dispatch(updateFieldPermission(fieldMappingId, permissionDisabled));
                });
            } else {
                targetFields.forEach(fieldMappingId => {
                    store.dispatch(updateFieldPermission(fieldMappingId, permissionEnabled));
                });
            }
        })
    }
}

const DisableTargetFieldIfNoValueByCondition = {
    execute
}

export default DisableTargetFieldIfNoValueByCondition   