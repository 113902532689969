import { store } from '../../../store';
import { updateIsRequiredValidation } from '../../../actions/CaseDetailsAction';
import { getFieldById } from '../../GetFieldById';

export const execute = (control) => {
    const fieldProps = control.additionalProperties;
    const parentFieldId = control.parentFieldId;
    let datastring = control.mappingId;

    let myArray = datastring.split(/([0-9]+)/)
    var fieldIndex = myArray[1] != undefined ? myArray[1] : null;

    if (fieldProps.MakeTargetFieldARequiredFieldOnSelection) {
        let isFieldValue = false;
        let targetFieldList = fieldProps?.MakeTargetFieldARequiredFieldOnSelection?.TargetFieldId;
        for (let i = 0; i < targetFieldList.length; i++) {
            const fieldMappingId = targetFieldList[i];
            let targetFieldValue = getfieldValue(fieldMappingId, fieldIndex, parentFieldId);
            if (targetFieldValue) {
                isFieldValue = true;
            }
        }

        for (let j = 0; j < targetFieldList.length; j++) {
            const fieldMappingId = targetFieldList[j];
            let targetFieldValue = getfieldValue(fieldMappingId, fieldIndex, parentFieldId);
            var targetFieldMappingIdWithIndex = fieldIndex ? fieldMappingId + fieldIndex : fieldMappingId;
            if (!targetFieldValue && isFieldValue) {
                store.dispatch(updateIsRequiredValidation(targetFieldMappingIdWithIndex, true, parentFieldId));
            } else {
                store.dispatch(updateIsRequiredValidation(targetFieldMappingIdWithIndex, false, parentFieldId));
            }
        }
    }
}
const getfieldValue = (fieldMappingId, fieldIndex, parentFieldId) => {
    var targetFieldMappingIdWithIndex = fieldIndex ? fieldMappingId + fieldIndex : fieldMappingId;
    var targetFieldObject = getFieldById(targetFieldMappingIdWithIndex, parentFieldId);
    var targetFieldValue = targetFieldObject.value != undefined ? targetFieldObject.value : null;
    return targetFieldValue;
}

const MakeTargetFieldARequiredFieldOnSelection = {
    execute
}

export default MakeTargetFieldARequiredFieldOnSelection