import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "../constants/ActionTypes";

const initialState = {
  isOpen: '',
  severity: '',
  time: '',
  title: '',
  message: '',
}

const ACTION_HANDLERS = {
  [OPEN_SNACKBAR]: (state, action) => {
    return {
      ...state,
      isOpen: true,
      severity: action.severity,
      time: action.time,
      title: action.title,
      message: action.message
    }
  },
  [CLOSE_SNACKBAR]: (state, action) => {
    return {
      ...state,
      isOpen: false,
    }
  }
}
export default function snackbar(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}