import LocalStorageProvider from "../providers/LocalStorageProvider"

export const isLoggedIn = () => {
    if (LocalStorageProvider.getAuthToken()) {
        return true
    } else {
        return false
    }
}

function decodeToken(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export const isTokenExpired = () => {
    try {
        const authToken = sessionStorage.getItem('Authorization');
        if (authToken) {
            const decodedToken = decodeToken(authToken);
            const expirationTime = decodedToken.exp * 1000;
            return expirationTime < Date.now();
        }
    } catch (err) {
        return false;
    }
};