import {
  INPUT_VALUE_EQUAL_TO_PROVIDED_VALUE
} from '../../../constants/GlobalConstants';

export const inputValueEqualToProvidedValue = (control) => {
  let fieldValue = control.value && control.value==true ? 1: 0;
  if (control && control.validation) {
    var validation = control.validation
    var checkFieldValidation = validation[INPUT_VALUE_EQUAL_TO_PROVIDED_VALUE] ? validation[INPUT_VALUE_EQUAL_TO_PROVIDED_VALUE] : null;
    var response = null;
    if (checkFieldValidation) {
      if (fieldValue) {
        let checkRule = checkFieldValidation.some((rule) => {
          return rule.ProvidedValue == fieldValue
        })
        if (!checkRule) {
          response = {
            isRequired: true,
            status: false,
            message: "Invalid value!"
          };
        }
      } else{
        response = {
          isRequired: true,
          status: false,
          message: "Invalid value!"
        };
      }
    }
  }
  return response;
}