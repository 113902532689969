import { store } from '../store';
import AuthAction from '../actions/AuthActions'

const Logout = (sessionType) => {  
   
   store.dispatch({ type: "DESTROY_SESSION" });
   store.dispatch(AuthAction.resetComponent());
   store.dispatch(AuthAction.userLogout(sessionType));
   sessionStorage.clear();
}

export default Logout
