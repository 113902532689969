import * as actionTypes from '../constants/ActionTypes';
import {
  SELECTION, PATIENT_INFO_SECTION_ID, SELECTION_KEY_VALUE, TYPEAHEAD, CANCEL_CASE_SECTION_ID,
  CREATE_CASE_SECTION_ID, BLANK_VALUE, TEXT, PHONE_NUMBER, ZIPCODE, DOCUMENT_DATE_FORMAT, CANCEL_CASE_PAGE_URL,
  SELECT_FILE, DEFAULT_PERMISSION, LBL_CASEID, NO_CONTENT_404, NO_CONTENT_FOUND, MULTI_SELECT, ERR_FAILED_TO_SAVE_CASE_RETRY, ERR_FAILED_TO_SAVE_CASE_CANCEL, NETWORK_ERROR_500,
  DOC_MANAGEMENT_PAGE_URL, DELETE_SUCCESS_MSG, UPDATE_DOC_FAILURE_MSG, DOC_FILE_SAVE_MAPPING_ID
} from '../constants/GlobalConstants';
import caseDetailsService, { initiateIntegrationSync } from '../services/CaseDetailsService';
import update from 'immutability-helper';
import { store } from '../store';
import { getFieldById } from '../utils/GetFieldById';
import { getControlByFieldId } from '../utils/GetControlByFieldId';
import GetSelectionValue from '../utils/GetSelectionValue';
import { getFieldValueById } from '../utils/GetFieldValueByID';
import moment from 'moment';
import { createBlobFromBase64 } from '../utils/rendertypes';
import LocalStorageProvider from '../providers/LocalStorageProvider'
import GetSelectionValueByCondition from '../utils/GetSelectionValueByCondition';
import { history } from "../utils/history";
import VisibilityControlAction from './VisibilityControlAction';
import { checkSectionValidation } from '../utils/ruleEngine/ValidateSection';
import * as R from 'ramda';
import { notify } from "./SnackbarAction";


export function clearCaseDetails() {
  return {
    type: actionTypes.CLEAR_CASE_DETAILS,
  };
}

export function updateClickedFieldId(fieldId) {
  return {
    type: actionTypes.UPDATE_CLIECKED_FIELD_ID,
    payload: fieldId
  }
}
export function updateIsPopUpOpen(status, fullScreenBl=false) {
  return {
    type: actionTypes.UPDATE_IS_POPUP_OPEN,
    payload: {status, fullScreenBl}
  }
}

export function updateCaseAmendmentBlStatus(status) {
  return {
    type: actionTypes.UPDATE_CASE_AMENDMENT_BL_STATUS,
    payload: status
  }
}

export function updateCaseEditableMode(mode) {
  return {
    type: actionTypes.UPDATE_CASE_EDITABLE_MODE,
    payload: mode
  }
}

export function getNavigationSource(source) {
  return {
    type: actionTypes.NAVIGATION_SOURCE,
    payload: source
  }
}

export function getCreateCaseSection(Id) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      var result = null;
      var selections = [];
      dispatch(clearCaseDetails());
      await caseDetailsService.getSectionDetails(CREATE_CASE_SECTION_ID)
        .then(response => {
          result = response;
          dispatch(selectionControlSuccess(result.responseData));
        });
    } catch (err) {
      dispatch(getSectionDetailsFailure(err));
    }
  };
}

export function createCase() {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      var formData = [];
      var createCaseFields = state.caseDetails.sectionDetails;
      createCaseFields.sectionGroup.forEach(element => {
        for (var i = 0; i < element.fields.length; i++) {
          if (element.fields[i].fieldTypeName == "SelectionKeyValue") {
            var obj = {
              "fieldMappingId": element.fields[i].mappingId,
              "fieldValue": element.fields[i].value ? JSON.stringify(element.fields[i].value) : null,
              "fieldTypeName": element.fields[i].fieldTypeName
            }
            formData.push(obj);
          }
          else {
            var obj = {
              "fieldMappingId": element.fields[i].mappingId,
              "fieldValue": element.fields[i].value,
              "fieldTypeName": element.fields[i].fieldTypeName
            }
            formData.push(obj);
          }
        }
      });
      await caseDetailsService.createCase(formData)
        .then(async (response) => {
          if (response) {
            //only for integration
            if (!R.isNil(response.responseData?.patientResourceId) && response.responseData?.patientResourceId !== "") {
              dispatch(displayPatientMappingMessage(true));
              await initiateIntegrationSync(response.responseData?.caseId, response.responseData?.patientResourceId).then((response) => {
                dispatch(displayPatientMappingMessage(false));
              })
                .catch(err => {
                  if (err === NETWORK_ERROR_500) {
                    dispatch(displayPatientMappingMessage(false));
                  }
                });
            }

            dispatch(setCreateCaseState(true));
            dispatch(updateCaseId(response.responseData.caseId));
            dispatch(isCreateCaseBl(false))
            dispatch(getBookingSheetDetails(response.responseData.bookingSheetId, response.responseData.caseId));
            dispatch(updateAsyncLoadFlag(false));

          }
        });
    } catch (err) {
      dispatch(createCaseFailure(err));
    }
  };
}

export function createCaseSuccess(data) {
  return {
    type: actionTypes.CREATE_CASE_SUCCESS,
    payload: data,
  };
}

export function createCaseFailure(data) {
  return {
    type: actionTypes.CREATE_CASE_FAILURE,
    payload: data,
  };
}

export function resetCreateCaseFailureStatus(data) {
  return {
    type: actionTypes.CREATE_CASE_FAILURE_RESET,
    payload: data,
  };
}


//cancel case
export function getCancelCaseSection(CANCEL_CASE_SECTION_ID, caseId = '') {
  return async function (dispatch, getState) {
    try {

      var result = null;
      await caseDetailsService.getSectionDetails(CANCEL_CASE_SECTION_ID, caseId)
        .then(response => {
          result = response;
          dispatch(selectionControlSuccess(result.responseData));
          dispatch(updateCaseEditableMode(false));
        });
    } catch (err) {
      dispatch(getSectionDetailsFailure(err));
    }
  };
}





export function updateAsyncLoadFlag(data) {
  return {
    type: actionTypes.UPDATE_ASYNC_LOAD_FLAG,
    payload: data,
  };
}

export function displayPatientMappingMessage(data) {
  return {
    type: actionTypes.DISPLAY_PATIENT_MAPPING_MESSAGE,
    payload: data,
  };
}


export function getBookingSheetDetails(Id, caseId, sectionUrl) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      var result = [];

      await caseDetailsService.getBookingSheetSections(Id, caseId)
        .then(response => {
          if (response === 401) {
            //the user trying to access the case is unauthorized for doing so.
            dispatch(updateIfUserIsAuthenticated(false));
          }
          else {
            result = response;
            var sectionList = [];
            if (result.responseData) {
              result.responseData.forEach(element => {
                var sectionItem = {
                  id: element.sectionId,
                  label: element.sectionName,
                  isSectionComplete: element.sectionCompleteBl,
                  showCountBl: element.showCountBl,
                  count: element.count,
                  showSectionStatusBl: element.showSectionStatusBl,
                  status: "",
                  url: '/' + element.sectionName.replace(/\s/g, ''),
                  isSelected: element.sectionId == PATIENT_INFO_SECTION_ID ? true : false,
                  showAlertBl: element.showAlertBl,
                  alertStatusBl: element.alertStatusBl
                }
                sectionList.push(sectionItem);
                element.sectionGroup = [];
              });
            }

            if (sectionList && sectionList.length > 0) {
              //set selected section details
              var sectionId = '';
              var sectionInfo = sectionList.filter((item) => item.url == sectionUrl)[0]; //filter url by sectionid
              if (sectionInfo) {
                sectionId = sectionInfo.id;
                sectionUrl = sectionInfo.url;
              } else {
                history.push('/case/' + caseId + sectionList[0].url);
                sectionId =  sectionList[0].id;
                sectionUrl =  sectionList[0].url;
              }
              

              dispatch(getBookingSheetSectionListSuccess(sectionList));
              if (sectionId) { //not received if browser refreshed on cancel case
                dispatch(getBookingSheetSuccess(result.responseData));
                dispatch(getSectionDetails(sectionId, caseId));
                dispatch(updateCaseView(false));
                dispatch(updateSelectedSectionId(sectionId)); //set sectionId in store  
                dispatch(updateSelectedSectionName(sectionUrl));  // //set section-url in store
                if(sectionUrl === DOC_MANAGEMENT_PAGE_URL){
                  dispatch(getDocumentDetailsByCaseId(caseId, sectionId)) 
                }
              }

            }
          }

        });

    } catch (err) {
      dispatch(getBookingSheetSectionListFailure())
    }
  };
}

export function getSectionDetails(Id, caseId) {
  return async function (dispatch, getState) {
    const state = getState();
    // caseId = state.caseDetails.caseId;
    if (caseId == undefined) {
      return;
    }
    try {

      var currentSection = null;
      var bookingSheet = state.caseDetails.bookingSheetDetails;
      bookingSheet.forEach(element => {
        if (element.sectionId == Id) {
          currentSection = element;
        }
      });

      dispatch(getSectionDetailsAsynchronously(Id, caseId));
      dispatch(updateAsyncLoadFlag(false));

    } catch (err) {
      dispatch(getSectionDetailsFailure(err));
    }
  };
}

export function getSectionDetailsAsynchronously(sectionId, caseId) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      caseId = state.caseDetails.caseId;
      var bookingSheet = state.caseDetails.bookingSheetDetails;
      var result = [];
      await caseDetailsService.getSectionDetailsBySectionIdCaseId(sectionId, caseId)
        .then(response => {
          result = response;
          bookingSheet.forEach(item => {
            if (item.sectionId == sectionId) {
              item.sectionGroup = result.responseData.sectionGroup;
            }
          })
          dispatch(updateHeaderInformation(result.responseData.bSheet_HeaderInformation));
          dispatch(selectionControlSuccess(result.responseData));
          if(!R.isNil(result.responseData.showSectionStatusBl)) {
            dispatch(updateSectionCompletionStatus({sectionId: sectionId, isSectionComplete: result.responseData.sectionCompleteBl}))
          }
        });
      dispatch(getBookingSheetSuccess(bookingSheet));
    } catch (err) {
    }
  };
}

export function updateHeaderInformation(headerInformation) {
  return {
    type: actionTypes.UPDATE_HEADER_INFORMATION,
    payload: headerInformation,
  };
}

const findReferenceData = (props) => {
  let referenceId = props.PrimaryReferenceData;
  let dependentFields = props.PrimaryReferenceDataDependentFields;
  return { 'referenceId': referenceId, 'dependentFields': dependentFields };
}

export function selectionControlSuccess(data, ispopup = false,callBack=null) {
  return async function (dispatch, getState) {
    try {
      if (data) {
        //set inner fields as array list
        var selections = [];
        data.sectionGroup.forEach(element => {
          element.fields.forEach(control => {
            selections[selections.length] = control;
          });
        });
        const selectionControls = Object.values(selections).filter(
          control => {
            if (control.fieldTypeName == SELECTION_KEY_VALUE || control.fieldTypeName === TYPEAHEAD
              || control.fieldTypeName === SELECTION || control.fieldTypeName === SELECT_FILE || control.fieldTypeName === MULTI_SELECT) {
              return true;
            }
          })

        var selectionsToRequest = [];
        selectionControls.map(selection => {
          selectionsToRequest.push(selection);
        })

        var requestArray = [];
        selectionsToRequest.forEach(element => {
          let getRequiredFiedls = null;
          let referenceId = element.additionalProperties.LoadReferenceDataByFilter ? element.additionalProperties.LoadReferenceDataByFilter.PrimaryReferenceData : element.additionalProperties.LoadReferenceData;
          let dependentFields = element.additionalProperties.DependentFields;
          let getRefdata = element.additionalProperties?.LoadReferenceDataByFilterInTables;
          if (getRefdata != undefined) getRequiredFiedls = findReferenceData(getRefdata);
          var dependentObj = {};
          if (getRequiredFiedls) {
            referenceId = getRequiredFiedls.referenceId;
            dependentFields = getRequiredFiedls.dependentFields;
          }
          if (dependentFields && dependentFields.length > 0) {
            dependentFields.forEach(dependentFieldId => {
              var controlItem = getFieldById(dependentFieldId);
              controlItem = (!controlItem) ? selections.filter(element => element.mappingId == dependentFieldId)[0] : controlItem;

              if (controlItem != undefined && controlItem.value == null && controlItem.additionalProperties && controlItem.additionalProperties.DefaultValue != undefined) {
                controlItem.value = controlItem.additionalProperties.DefaultValue;
                controlItem.isUpdated = true;
              }
              if (controlItem && controlItem.mappingId && controlItem.value) {
                if (typeof (controlItem.value) == 'string') {
                  dependentObj[controlItem.mappingId] = controlItem.value
                }
                else if (typeof (controlItem.value) == 'object' && controlItem.value[controlItem.additionalProperties.DisplayColumnId]) {
                  dependentObj[controlItem.mappingId] = controlItem.value[controlItem.additionalProperties.DisplayColumnId] + ""
                }
              }
            });
          }
          if (dependentObj && Object.keys(dependentObj).length === 0) {
            dependentObj = null;
          }
          let obj = {
            "Id": referenceId,
            "additionalData": dependentObj
          }
          if (requestArray.length > 0) {
            const check_result = requestArray.find(({ Id }) => Id === referenceId);
            if (check_result == undefined) {
              requestArray.push(obj);
            }
          } else {
            requestArray.push(obj);
          }
        });
        caseDetailsService.getSelectionForControls(requestArray)
          .then(response => {
            var selectionData = { ...response };
            data.sectionGroup.forEach(element => {
              element.fields.forEach(control => {
                if (control.additionalProperties.LoadReferenceDataByFilter) {
                  // debugger;
                  let fielAdditionaldPrpps = control.additionalProperties.LoadReferenceDataByFilter;
                  let filterdRefData = GetSelectionValueByCondition.getSelectedRefData(fielAdditionaldPrpps, selectionData);
                  selectionData = { ...selectionData, ...filterdRefData };
                }
                if (control.additionalProperties && control.additionalProperties.DefaultValue != undefined) {
                  if (control['value'] == null || control['value'] == BLANK_VALUE) {
                    if (control.fieldTypeName === SELECTION || control.fieldTypeName === SELECTION_KEY_VALUE || control.fieldTypeName === TYPEAHEAD || control.fieldTypeName === MULTI_SELECT) {
                      control['value'] = GetSelectionValue.getSelectedValue(control.additionalProperties.DisplayColumnId, control.additionalProperties.LoadReferenceData, control.additionalProperties.DefaultValue, selectionData)
                      control['isUpdated'] = true;
                    }
                  } else {
                    if (control.fieldTypeName === SELECTION || control.fieldTypeName === SELECTION_KEY_VALUE || control.fieldTypeName === TYPEAHEAD || control.fieldTypeName === MULTI_SELECT) {
                      if (control['value'] !== null && typeof (control['value']) == 'string') {
                        control['value'] = GetSelectionValue.getSelectedValue(control.additionalProperties.DisplayColumnId, control.additionalProperties.LoadReferenceData, control['value'], selectionData)
                      }
                    }
                  }
                }
                else {
                  if (control.fieldTypeName === SELECTION || control.fieldTypeName === SELECTION_KEY_VALUE || control.fieldTypeName === TYPEAHEAD || control.fieldTypeName === MULTI_SELECT) {
                    if (control['value'] != null && typeof (control['value']) == 'string') {
                      control['value'] = GetSelectionValue.getSelectedValue(control.additionalProperties.DisplayColumnId, control.additionalProperties.LoadReferenceData, control['value'], selectionData)
                    }
                  }
                }
              });
            });

            if (ispopup) {
              const state = getState();
              var sectionSelections = state.caseDetails.selection;
              var sectionDetails = {
                selections: { ...selectionData, ...sectionSelections }
              }
              dispatch(dispatchPopupSelectionControl(sectionDetails));            
            } else {
              var sectionDetails = {
                selections: selectionData,
                section: data
              }
              dispatch(dispatchSelectionControl(sectionDetails))
            }
            if (typeof callBack === 'function') callBack();
          });
      }
    }
    catch (err) {
      console.error(err)
    }
  }
}

export function dispatchSelectionControl(data) {
  return {
    type: actionTypes.CONTROL_SELECTION_UPDATE_SUCCESS,
    payload: data,
  };
}
export function dispatchPopupSelectionControl(data) {
  return {
    type: actionTypes.POPUP_CONTROL_SELECTION_UPDATE_SUCCESS,
    payload: data,
  };
}

export function getSectionDetailsSuccess(data) {
  return {
    type: actionTypes.GET_SECTION_DETAILS_SUCCESS,
    payload: data,
    sectionGroupLoader: false,

  };
}

export function updateSectionGroupLoaderStatus(data) {
  return {
    type: actionTypes.UPDATE_SECTION_GROUP_LOADER_STATUS,
    payload: data,
    sectionGroupLoader: data,
  };
}

export function updateDisableNavigationStatus(data) {
  return {
    type: actionTypes.DISABLE_NAVIGATION_SECTION,
    payload: data,

  };
}

export function getSectionDetailsFailure(data) {
  return {
    type: actionTypes.GET_SECTION_DETAILS_FAILURE,
    payload: data,
  };
}

export function getBookingSheetSuccess(data) {
  return {
    type: actionTypes.GET_BOOKING_SHEET_SUCCESS,
    payload: data,
    sectionGroupLoader: false,

  };
}
export function updateControlValueInPopup(data) {
  return {
    type: actionTypes.UPDATE_CONTROL_VAL_IN_POPUP,
    payload: data,
  };
}

export function getBookingSheetFailure(data) {
  return {
    type: actionTypes.GET_BOOKING_SHEET_FAILURE,
    payload: data,
  };
}

const updateTablefields = (tableControl, mappingId, value) => {
  for (let j = 0; j < tableControl.childControls.length; j++) {
    let item = tableControl.childControls[j];
    if (item.mappingId == mappingId) {
      item.value = value;
      item.isUpdated = true;
      return true;
    }
    for (let i = 0; i < item.length; i++) {
      if (item[i].mappingId == mappingId) {
        item[i].value = value;
        item[i].isUpdated = true;
        return true;
      }
    }
  };
  return tableControl;
}

export function updateControlValue(mappingId, value, parentFieldId, fireEffects = true) {
  return function updateControlValue(dispatch, getState) {
    const state = getState();
    var sectionDetails = state.caseDetails.sectionDetails;
    var bookingSheetDetails = state.caseDetails.bookingSheetDetails;
    const popupSection = state.caseDetails.popupSectionDetails;
    if (popupSection) {
      popupSection.map(section => {
        section.sectionGroup.forEach(element => {
          element.fields.forEach(field => {
            if (field.mappingId == mappingId) {
              isBookingSheetField = false
              if ((field.fieldTypeName == TEXT || field.fieldTypeName == ZIPCODE || field.fieldTypeName == PHONE_NUMBER) && value == null) {
                field.value = BLANK_VALUE
              }
              else {
                field.value = value
                field.isUpdated = true
              }
            }
            if (field.fieldId == parentFieldId) {
              field = updateTablefields(field, mappingId, value);
            }
          });
        })
      })
      dispatch(updateControlValueInPopup(popupSection))
    }

    if (sectionDetails && (sectionDetails.sectionId == CREATE_CASE_SECTION_ID || sectionDetails.sectionId == CANCEL_CASE_SECTION_ID)) {
      const replacedControl = sectionDetails;
      replacedControl.sectionGroup.forEach(x => x.fields.filter(element => element.mappingId == mappingId)
        .forEach(element => {
          element.value = value
          element.isUpdated = true
        }));
      const payload = {
        replacedControl,
        fireEffects,
      };
      dispatch(updateControl(payload));
    }
    else {
      if (parentFieldId) {
        if (bookingSheetDetails) {
          const replacedControl = bookingSheetDetails;
          replacedControl.forEach(section =>
            section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
              .forEach(element => {
                if (element.parentFieldId) {
                  section.sectionGroup.forEach(x => x.fields.filter(field => field.fieldId == element.parentFieldId)
                    .forEach(ChildElement => {
                      ChildElement.childControls.map(item => {
                        item.forEach(field => {
                          if (field.fieldId == parentFieldId) {
                            field.childControls.forEach(ChildField => {
                              if (ChildField.mappingId == mappingId) {
                                ChildField.value = value
                                ChildField.isUpdated = true
                              }
                            });
                          }
                        }
                        )
                      });
                    })
                  )
                }
                else {
                  element.childControls.map(item => {
                    item.forEach(field => {
                      if (field.mappingId == mappingId) {
                        field.value = value
                        field.isUpdated = true
                      }
                    })
                  });
                }
              })));
          dispatch(getBookingSheetSuccess(replacedControl));
        }
      }
      else {
        var isBookingSheetField = false
        if (bookingSheetDetails) {
          const replacedControl = bookingSheetDetails;
          replacedControl.forEach(section =>
            section.sectionGroup.forEach(element => {
              element.fields.forEach(field => {
                if (field.mappingId == mappingId) {
                  isBookingSheetField = true
                  if ((field.fieldTypeName == TEXT || field.fieldTypeName == ZIPCODE || field.fieldTypeName == PHONE_NUMBER) && value == null) {
                    field.value = BLANK_VALUE
                    field.isUpdated = true
                  } else {
                    field.value = value;
                    field.isUpdated = true
                  }
                }
              })
            }))
          if (isBookingSheetField) {
            dispatch(getBookingSheetSuccess(replacedControl))
          }
        }
      }
    }
  }
}

export function updateControlLabel(mappingId, label, parentFieldId, fireEffects = true) {
  return function updateControlLabel(dispatch, getState) {
    const state = getState();
    var sectionDetails = state.caseDetails.sectionDetails
    var bookingSheetDetails = state.caseDetails.bookingSheetDetails
    if (sectionDetails && sectionDetails.sectionId == CREATE_CASE_SECTION_ID) {
      const replacedControl = sectionDetails;
      replacedControl.sectionGroup.forEach(x => x.fields.filter(element => element.mappingId == mappingId)
        .forEach(element => {
          element.defaultFieldName = label
          element.isUpdated = true
        }));
      const payload = {
        replacedControl,
        fireEffects,
      };
      dispatch(updateControl(payload));
    }
    else {
      if (parentFieldId) {
        if (bookingSheetDetails) {
          const replacedControl = bookingSheetDetails;
          replacedControl.forEach(section =>
            section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
              .forEach(element => {
                if (element.parentFieldId) {
                  section.sectionGroup.forEach(x => x.fields.filter(field => field.fieldId == element.parentFieldId)
                    .forEach(ChildElement => {
                      ChildElement.childControls.map(item => {
                        item.forEach(field => {
                          if (field.fieldId == parentFieldId) {
                            field.childControls.forEach(ChildField => {
                              if (ChildField.mappingId == mappingId) {
                                ChildField.defaultFieldName = label
                                ChildField.isUpdated = true
                              }
                            });
                          }
                        }
                        )
                      });
                    })
                  )
                }
                else {
                  element.childControls.map(item => {
                    item.forEach(field => {
                      if (field.mappingId == mappingId) {
                        field.defaultFieldName = label
                        field.isUpdated = true
                      }
                    })
                  });
                }
              })));
          dispatch(getBookingSheetSuccess(replacedControl));
        }
      }
      else {
        var isBookingSheetField = false
        if (bookingSheetDetails) {
          const replacedControl = bookingSheetDetails;
          replacedControl.forEach(section =>
            section.sectionGroup.forEach(element => {
              element.fields.forEach(field => {
                if (field.mappingId == mappingId) {
                  isBookingSheetField = true
                  field.defaultFieldName = label
                  field.isUpdated = true
                }
              })
            }))
          if (isBookingSheetField) {
            dispatch(getBookingSheetSuccess(replacedControl))
          }
        }
        if (!isBookingSheetField) {
          //Didn't find the field in bookingsheet. Now we will search it in popup sections for matches
          const replacedControl = state.caseDetails.popupSectionDetails
          replacedControl.map(section => {
            section.sectionGroup.forEach(element => {
              element.fields.forEach(field => {
                if (field.mappingId == mappingId) {
                  isBookingSheetField = false
                  field.defaultFieldName = label
                  field.isUpdated = true
                }
              });
            })
          })
          dispatch(updateControlValueInPopup(replacedControl))
        }
      }
    }
  }
}

export function updateControl(control) {
  return {
    type: actionTypes.UPDATE_CONTROL_VALUE,
    payload: control,
  };
}

export function saveSection(sectionId, actionId) {
  return async function saveSection(dispatch, getState) {
    const state = getState();
    const caseId = parseInt(state.caseDetails.caseId);
      var bookingSheet = state.caseDetails.bookingSheetDetails;
      var currentSection = state.caseDetails.sectionDetails;
      var allSections = state.caseDetails.sections;
      var sectionStatus = [];
      allSections.forEach(element => {
        if (element.showSectionStatusBl) {

          var obj = {
            "SectionId": element.id,
            "isSectionComplete": element.isSectionComplete,
            "CurrentSectionBl": element.id == sectionId ? true : false
          }
          sectionStatus.push(obj);
        }
      });

      var FormData = [];
      bookingSheet.forEach(element => {
        if (element.sectionId == sectionId) {
          element.sectionGroup = currentSection.sectionGroup;
        }
      });
      currentSection.sectionGroup.forEach(element => {
        for (var i = 0; i < element.fields.length; i++) {
          if (element.fields[i].isUpdated) {
            if (element.fields[i].fieldTypeName == "Table" && element.fields[i].childControls.length > 1) {
              var tableValue = element.fields[i].childControls
              var finalArr = []
              for (var j = 1; j < tableValue.length; j++) {
                var arr = []
                tableValue[j].map(cols => {
                  var requestControlvalue = "";
                  if (cols.fieldTypeName == MULTI_SELECT && !Array.isArray(cols.value)) {
                    requestControlvalue = cols.value ? JSON.parse(cols.value) : [];
                  } else {
                    requestControlvalue = cols.value;
                  }
                  var obj1 = {
                    fieldValue: requestControlvalue,
                    fieldMappingId: cols.mappingId && cols.mappingId.replace(/[0-9]/g, ''),
                    fieldTypeName: cols.fieldTypeName
                  }

                  obj1.fieldMappingId && arr.push(obj1)
                  const dataCreator = (childControl) => {
                    var obj = {
                      fieldValue: childControl.value,
                      fieldMappingId: childControl.mappingId && childControl.mappingId.replace(/[0-9]/g, ''),
                      fieldTypeName: childControl.fieldTypeName
                    }
                    obj.fieldMappingId && arr.push(obj)
                    childControl.map(grandChildControl => {
                      var obj2 = {
                        fieldValue: grandChildControl.value,
                        fieldMappingId: grandChildControl.mappingId && grandChildControl.mappingId.replace(/[0-9]/g, ''),
                        fieldTypeName: grandChildControl.fieldTypeName
                      }
                      obj2.fieldMappingId && arr.push(obj2)
                    })
                    if (childControl.childControls && childControl.childControls.length > 0) {
                      dataCreator(childControl.childControls)
                    }
                  }

                  if (cols.childControls && cols.childControls.length > 0) {
                    dataCreator(cols.childControls)
                  }
                })
                finalArr.push(arr)
              }
              var obj = {
                "fieldMappingId": element.fields[i].mappingId,
                "fieldValue": finalArr ? JSON.stringify(finalArr) : null,
                "fieldTypeName": element.fields[i].fieldTypeName
              }
              FormData.push(obj);
            }
            else if (element.fields[i].fieldTypeName == "Table" && element.fields[i].childControls.length == 1) {
              var obj = {
                "fieldMappingId": element.fields[i].mappingId,
                "fieldValue": null,
                "fieldTypeName": element.fields[i].fieldTypeName
              }
              FormData.push(obj);

            }
            else if (element.fields[i].fieldTypeName == "Chips") {
              var obj = {
                "fieldMappingId": element.fields[i].mappingId,
                "fieldValue": element.fields[i].value ? JSON.stringify(element.fields[i].value) : null,
                "fieldTypeName": element.fields[i].fieldTypeName
              }
              FormData.push(obj);

            }
            else if (element.fields[i].fieldTypeName === "Typeahead" || element.fields[i].fieldTypeName == "SelectionKeyValue") {
              if (element.fields[i].isVisible) {
                var obj = {
                  "fieldMappingId": element.fields[i].mappingId,
                  "fieldValue": element.fields[i].value ? JSON.stringify(element.fields[i].value) : null,
                  "fieldTypeName": element.fields[i].fieldTypeName
                }
                FormData.push(obj);
              }
            }
            else {
              if (element.fields[i].fieldTypeName != "Table") {  //element.fields[i].isVisible &&
                var value = element.fields[i].value ? element.fields[i].value : null;
                if (value && typeof value != "string") {
                  value = value + ""
                }
                var obj = {
                  "fieldMappingId": element.fields[i].mappingId,
                  "fieldValue": value,
                  "fieldTypeName": element.fields[i].fieldTypeName
                }
                FormData.push(obj);
              }
            }
          }
          else if (element.fields[i].fieldTypeName == "ActiveHiddenFields" || element.fields[i].mappingId == "PatientId" || element.fields[i].mappingId == "EncounterId") {
            var obj = {
              "fieldMappingId": element.fields[i].mappingId,
              "fieldValue": element.fields[i].value ? element.fields[i].value + "" : null,
              "fieldTypeName": element.fields[i].fieldTypeName
            }
            FormData.push(obj);
          }
        }
      });

      await caseDetailsService.saveCaseSection(FormData, caseId, sectionId, sectionStatus)
      .then(response => {
        var result = response;
        dispatch(updateCaseAmendmentBlStatus(false));
        dispatch(getBookingSheetSuccess(bookingSheet));
        dispatch(updateBookingSheetFormData(FormData));
        dispatch(getSectionDetails(sectionId + 1));
        dispatch(updateCaseEditableMode(false));

        if (actionId == "2") {
          window.location.reload(); //TODO: Need to handle it by redux-store data 
        }
      }).catch(async (err) => {
        dispatch(sendSectionReleaseInfo());
        dispatch(saveSectionDetailsFailure(err));
        dispatch(updateActionButtonLoaderStatus(false));
        dispatch(updateCaseEditableMode(false));
        state.caseDetails.dependentFieldList.forEach((mappingId) => {
          dispatch(updateControlValue(mappingId, null))
        })
        dispatch(VisibilityControlAction.showField(state.caseDetails.clickedFieldID))
        
        window.scrollTo(0,0)
        throw "failed to save case"
      });
  };
}

export const getSelectionFieldValue = (controlItem) => {
  let fieldValue = '';
  if (controlItem.value) {
    if (typeof (controlItem.value) === 'object' && controlItem.value[controlItem.additionalProperties.DisplayColumnId]) {
      fieldValue = controlItem.value[controlItem.additionalProperties.DisplayColumnId];
    }
    else {
      fieldValue = controlItem.value;
    }
  }
  return fieldValue;
}

export function getSectionFormData(sectionObject) {
  const state = store.getState();
  var FormData = [];
  var sectionData;
  var tableName = "";
  if (sectionObject == state.caseDetails.sectionDetails) {
    sectionData = sectionObject.sectionGroup.map(section => section)
    tableName = "Table"
  }
  else if (sectionObject == state.caseDetails.popupSectionDetails) {
    sectionData = sectionObject[0].sectionGroup.filter((group) => group.sectionGroupName == "Tray Details");
    tableName = "Table2"
  }
  if (sectionData != null && tableName != "") {
    sectionData.forEach(element => {
      for (var i = 0; i < element.fields.length; i++) {
        if (element.fields[i].isUpdated) {
          if (element.fields[i].fieldTypeName == tableName && element.fields[i].childControls.length > 1) {
            var tableValue = element.fields[i].childControls
            var finalArr = []
            for (var j = 1; j < tableValue.length; j++) {
              var arr = []
              tableValue[j].map(cols => {
                let obj1 = {};
                if (cols.fieldTypeName == "SelectionKeyValue" && tableName == "Table2") {
                  var selectionfieldValue = getSelectionFieldValue(cols);
                  obj1 = {
                    fieldValue: selectionfieldValue,
                    fieldMappingId: cols.mappingId && cols.mappingId.replace(/[0-9]/g, ''),
                    fieldTypeName: cols.fieldTypeName
                  }
                }
                else {
                  obj1 = {
                    fieldValue: cols.value,
                    fieldMappingId: cols.mappingId && cols.mappingId.replace(/[0-9]/g, ''),
                    fieldTypeName: cols.fieldTypeName
                  }
                }
                obj1.fieldMappingId && arr.push(obj1);
                const dataCreator = (childControl) => {
                  var obj = {
                    fieldValue: childControl.value,
                    fieldMappingId: childControl.mappingId && childControl.mappingId.replace(/[0-9]/g, ''),
                    fieldTypeName: childControl.fieldTypeName
                  }
                  obj.fieldMappingId && arr.push(obj)
                  childControl.map(grandChildControl => {
                    var obj2 = {
                      fieldValue: grandChildControl.value,
                      fieldMappingId: grandChildControl.mappingId && grandChildControl.mappingId.replace(/[0-9]/g, ''),
                      fieldTypeName: grandChildControl.fieldTypeName
                    }
                    obj2.fieldMappingId && arr.push(obj2)
                  })
                  if (childControl.childControls && childControl.childControls.length > 0) {
                    dataCreator(childControl.childControls)
                  }
                }
                if (cols.childControls && cols.childControls.length > 0) {
                  dataCreator(cols.childControls)
                }
              })
              finalArr.push(arr)
            }
            var obj = {
              "fieldMappingId": element.fields[i].mappingId,
              "fieldValue": finalArr ? JSON.stringify(finalArr) : null,
              "fieldTypeName": element.fields[i].fieldTypeName
            }
            FormData.push(obj);
          }
          else if (element.fields[i].fieldTypeName == tableName && element.fields[i].childControls.length == 1) {
            var obj = {
              "fieldMappingId": element.fields[i].mappingId,
              "fieldValue": null,
              "fieldTypeName": element.fields[i].fieldTypeName
            }
            FormData.push(obj);
          }
          else if (element.fields[i].fieldTypeName == "Chips") {
            var obj = {
              "fieldMappingId": element.fields[i].mappingId,
              "fieldValue": element.fields[i].value ? JSON.stringify(element.fields[i].value) : null,
              "fieldTypeName": element.fields[i].fieldTypeName
            }
            FormData.push(obj);
          }
          else if (element.fields[i].fieldTypeName === "Typeahead" || element.fields[i].fieldTypeName == "SelectionKeyValue") {
            if (element.fields[i].isVisible) {
              var obj = {
                "fieldMappingId": element.fields[i].mappingId,
                "fieldValue": element.fields[i].value ? JSON.stringify(element.fields[i].value) : null,
                "fieldTypeName": element.fields[i].fieldTypeName
              }
              FormData.push(obj);
            }
          }
          else {
            if (element.fields[i].fieldTypeName != tableName) {  //element.fields[i].isVisible &&
              var value = element.fields[i].value ? element.fields[i].value : null;
              if (value && typeof value != "string") {
                value = value + ""
              }
              var obj = {
                "fieldMappingId": element.fields[i].mappingId,
                "fieldValue": value,
                "fieldTypeName": element.fields[i].fieldTypeName
              }
              FormData.push(obj);
            }
          }
        }
        else if (element.fields[i].fieldTypeName == "ActiveHiddenFields" || element.fields[i].mappingId == "PatientId" || element.fields[i].mappingId == "EncounterId") {
          var obj = {
            "fieldMappingId": element.fields[i].mappingId,
            "fieldValue": element.fields[i].value ? element.fields[i].value + "" : null,
            "fieldTypeName": element.fields[i].fieldTypeName
          }
          FormData.push(obj);
        }
      }
    });
  }
  if (FormData != [] && sectionObject == state.caseDetails.popupSectionDetails) {
    var finalFormData = FormData.filter((x) => x.fieldMappingId == "CaseImplantTrays")
    return finalFormData[0];
  }
  return FormData;
}

export function saveTrayWithSection(sectionId, actionId) {
  return async function saveTrayWithSection(dispatch, getState) {
    const state = getState();
    const caseId = parseInt(state.caseDetails.caseId);
    try {
      var bookingSheet = state.caseDetails.bookingSheetDetails;
      var currentSection = state.caseDetails.sectionDetails;
      var popupSectionDetails = state.caseDetails.popupSectionDetails;
      var allSections = state.caseDetails.sections;
      var sectionStatus = [];
      allSections.forEach(element => {
        if (element.showSectionStatusBl) {
          var obj = {
            "SectionId": element.id,
            "isSectionComplete": element.isSectionComplete,
            "CurrentSectionBl": element.id == sectionId ? true : false
          }
          sectionStatus.push(obj);
        }
      });
      bookingSheet.forEach(element => {
        if (element.sectionId == sectionId) {
          element.sectionGroup = currentSection.sectionGroup;
        }
      });
      var currentFormData = getSectionFormData(currentSection);
      var trayFormData = getSectionFormData(popupSectionDetails);
      currentFormData.push(trayFormData);
      await caseDetailsService.saveCaseSection(currentFormData, caseId, sectionId, sectionStatus)
        .then(response => {
          var result = response;
          dispatch(updateCaseAmendmentBlStatus(false));
          dispatch(getBookingSheetSuccess(bookingSheet));
          dispatch(updateBookingSheetFormData(currentFormData));
          dispatch(getSectionDetails(sectionId + 1));
          dispatch(updateCaseEditableMode(false));
          if (actionId == "2") {
            window.location.reload(); //TODO: Need to handle it by redux-store data 
          }
        });
    } catch (err) {
      dispatch(saveSectionDetailsFailure(err));
    }
  };
}

export function updateBookingSheetFormData(data) {
  return {
    type: actionTypes.UPDATE_BOOKINGSHEET_FORM_DATA,
    payload: data
  }
}
export function saveSectionDetailsSuccess(data) {
  return {
    type: actionTypes.SAVE_SECTION_DETAILS_SUCCESS,
    payload: data,
  };
}

export function saveSectionDetailsFailure(data) {
  return {
    type: actionTypes.SAVE_SECTION_DETAILS_FAILURE,
    payload: data,
  };
}

export function resetSaveSectionDetailsFailureCount() {
  return {
    type: actionTypes.SAVE_SECTION_DETAILS_FAILURE_RESET_COUNT,
  };
}

export function updateSectionStatus(sectionId, status) {
  return function updateSectionStatus(dispatch, getState) {
    const state = getState();
    const sectionsList = state.caseDetails.sections;
    sectionsList.forEach(element => {
      if (element.id == sectionId) {
        element.isSelected = status;
      }
    });
    dispatch(updateSectionStatusSuccess(sectionsList));
  }
}

export function updateSectionCompletionStatus(data) {
  return {
    type: actionTypes.UPDATE_SECTION_COMPLETION_STATUS,
    payload: data,
  };
}

export function updateSectionStatusSuccess(sectionsList) {
  return {
    type: actionTypes.UPDATE_SECTION_STATUS,
    payload: sectionsList,
  };
}

export function getBookingSheetSectionListSuccess(sectionsList) {
  return {
    type: actionTypes.UPDATE_BOOKING_SHEET_SECTION_LIST,
    payload: sectionsList,
    caseNavigationLoader: false,
    sectionGroupLoader: false
  };
}

export function getBookingSheetSectionListFailure() {
  return {
    type: actionTypes.UPDATE_BOOKING_SHEET_SECTION_LIST,
    caseNavigationLoader: false,
  };
}

export function updateDisableStatus(actionList) {
  return function updateDisableStatus(dispatch, getState) {
    const state = getState();
    var storeActionsList = state.caseDetails.actionList;
    if (actionList) {
      actionList.forEach(item => {
        storeActionsList.forEach(element => {
          if (element.mappingId == item.Id) {
            element.isLocked = item.status;
          }
        });
      });
      //update in store
      dispatch(updateDisableStatusSucess(storeActionsList));
    }
  }
}

export function updateDisableStatusSucess(actionList) {
  return {
    type: actionTypes.UPDATE_ACTION_BUTTONS_PROPERTIES,
    payload: actionList
  }
}

export function updateSelectedSectionId(id) {
  return {
    type: actionTypes.UPDATE_SECTION_ID,
    payload: id,
  };
}

export function updateSelectedSectionName(name) {
  return {
    type: actionTypes.UPDATE_SECTION_NAME,
    payload: name,
  };
}

export function updateIsRequiredValidation(fieldId, isRequired, parentFieldId = null, inPopUp = false) {
  const isRequiredValidation = (replacedControl) => {
    return {
      type: actionTypes.UPDATE_IS_REQUIRED_VALIDATION,
      payload: replacedControl
    }
  }
  return async function (dispatch, getState) {
    const state = getState()
    if (inPopUp) {
      var popupControl = state.caseDetails.popupSectionDetails;
      popupControl.forEach(replacedControl => {
        replacedControl.sectionGroup.forEach(x => {
          x.fields.filter(element => element.mappingId == fieldId)
            .forEach(element => element.requiredBl = isRequired)
        })
      });
    } else {
      var isCaseDetails = state.caseDetails.sectionDetails;
      if (isCaseDetails) {
        var replacedControl = isCaseDetails;

        //

        if (parentFieldId) {
          replacedControl.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
            .forEach(element => {
              if (element.parentFieldId) {
                replacedControl.sectionGroup.forEach(x => x.fields.filter(field => field.fieldId == element.parentFieldId)
                  .forEach(ChildElement => {
                    ChildElement.childControls.map(item => {
                      item.forEach(field => {
                        if (field.fieldId == parentFieldId) {
                          field.childControls.forEach(ChildField => {
                            if (ChildField.mappingId == fieldId) {
                              ChildField.requiredBl = isRequired;
                            }
                          });
                        }
                      }
                      )
                    });
                  })
                )
              }
              else {
                element.childControls.map(item => {
                  item.forEach(field => {
                    if (field.mappingId == fieldId) {
                      field.requiredBl = isRequired;
                    }
                  })
                });
              }
            }));

        } else {
          replacedControl.sectionGroup.forEach(x => {

            x.fields.filter(element => element.mappingId == fieldId)
              .forEach(element => element.requiredBl = isRequired)

          });
        }
      }
      //
      // replacedControl.sectionGroup.forEach(x => {
      //   if (parentFieldId) {
      //     var parentField = x.fields.filter(parentElement => parentElement.fieldId == parentFieldId)
      //     if (parentField && parentField.length > 0) {
      //       parentField[0].childControls.forEach(x => {
      //         x.filter(element => element.mappingId == fieldId)
      //           .forEach(item => item.requiredBl = isRequired)
      //       });
      //     }
      //   } else {
      //     x.fields.filter(element => element.mappingId == fieldId)
      //       .forEach(element => element.requiredBl = isRequired)
      //   }
      // });
      dispatch(isRequiredValidation(replacedControl))
    }
  }
}

export function clearSection(section) {
  return function clearSection(dispatch, getState) {
    const state = getState();
    try {
      var bookingSheet = state.caseDetails.bookingSheetDetails;
      section.forEach(sectionId => {
        bookingSheet.forEach(element => {
          if (element.sectionId == sectionId) {
            element.sectionGroup.forEach(element => {
              element.fields.forEach(field => {
                var fieldToClear = field.fieldTypeName;
                if (fieldToClear == TEXT || fieldToClear == ZIPCODE || fieldToClear == PHONE_NUMBER) {
                  field.value = BLANK_VALUE;
                }
                else {
                  field.value = null;
                }
              });
            });
          }
        });
      });
      dispatch(getBookingSheetSuccess(bookingSheet));
    } catch (err) {
    }

  }
}

const updateProps = (replacedControl) => {
  return {
    type: actionTypes.UPDATE_ADDITIONAL_PROPS,
    payload: replacedControl
  }
}

const updatePropsInPopup = (replacedControl) => {
  return {
    type: actionTypes.UPDATE_ADDITIONAL_PROPS_IN_POPUP,
    payload: replacedControl
  }
}

export function updatePropsValue(data) {
  return function updatePropsValue(dispatch, getState) {
    const state = getState()
    var sectionDetails = state.caseDetails.sectionDetails
    if (sectionDetails) {
      var replacedControl = sectionDetails
      data.forEach(detail => {
        replacedControl.sectionGroup.forEach(x => {
          let targetControl = x.fields.find(element => element.mappingId === detail.mappingId)
          if (targetControl) {
            detail.valueToUpdate.forEach(item => {
              var prop = item.prop;
              targetControl.additionalProperties[prop] = item.value;
            });
          }
        })
        dispatch(updateProps(replacedControl))
      })
    }
  }
}

const getSingleSectionData = (sectionData) => {
  return {
    type: actionTypes.GET_SINGLE_SECTION_DATA,
    payload: sectionData
  }
}

export function getTraySectionDetails(parentContext, requestData, isPreserveReferenceData = false) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      var result = []
      caseDetailsService.getTraySectionDetails(requestData)
        .then(response => {
          result = response

          result.responseData.sectionGroup.map(sectionGroup => {
            sectionGroup.fields.map(field => {
              field['parentContextId'] = parentContext
            })
          })
          if (!isPreserveReferenceData) {
            dispatch(selectionControlSuccess(result.responseData, true, () => dispatch(getSingleSectionData(result.responseData))));
          }
        })
    } catch (err) {
      dispatch(getSingleSectionData(err))
    }
  }
}

export function getSingleSectionDetails(parentContext, Id, isPreserveReferenceData = false) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      var result = []
      caseDetailsService.getSectionDetails(Id)
        .then(response => {
          result = response

          result.responseData.sectionGroup.map(sectionGroup => {
            sectionGroup.fields.map(field => {
              field['parentContextId'] = parentContext
            })
          })

          dispatch(getSingleSectionData(result.responseData))
          if (!isPreserveReferenceData) {
            dispatch(selectionControlSuccess(result.responseData, true));
          }
        })
    } catch (err) {
      dispatch(getSingleSectionData(err))
    }
  }
}

export function addFields(mappingId, fieldToIncrement, mappingIdValue, parentFieldId = null) {
  return function addFields(dispatch, getState) {
    const state = getState();
    var sectionDetails = state.caseDetails.sectionDetails;
    var popupSectionDetails = state.caseDetails.popupSectionDetails;
    const replacedControl = sectionDetails;
    var value = null;
    var field;
    if (popupSectionDetails) {
      popupSectionDetails && popupSectionDetails.forEach(element => {
        element.sectionGroup && element.sectionGroup.forEach(group => {
          group.fields.forEach(field => {
            if (field.mappingId === mappingId) {
              let rowsToAdded = addFieldToChidControles(field, mappingIdValue, parentFieldId);
              let idToIncrement = fieldToIncrement ? fieldToIncrement : field.mappingId;
              field.childControls.push(rowsToAdded);
              dispatch(updateControlValue(idToIncrement, value, parentFieldId));
            }
          });
        });
      });
    }

    if (replacedControl) {
      if (parentFieldId) {
        sectionDetails.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentFieldId)
          .forEach(element => {
            if (element.parentFieldId) {
              sectionDetails.sectionGroup.forEach(x => x.fields.filter(field => field.fieldId == element.parentFieldId)
                .forEach(ChildElement => {
                  ChildElement.childControls.map(item => {
                    item.forEach(itemField => {
                      if (itemField.fieldId == parentFieldId) {
                        itemField.childControls.forEach(ChildField => {
                          if (ChildField.mappingId == mappingId) {
                            field = ChildField;
                            let rowsToAdded = addFieldToChidControles(ChildField, mappingIdValue, parentFieldId);
                            let idToIncrement = fieldToIncrement ? fieldToIncrement : ChildField.mappingId;
                            ChildField.childControls.push(rowsToAdded);
                            dispatch(updateControlValue(idToIncrement, value, parentFieldId));
                          }
                          if (field) {
                            return field;
                          }
                        });
                      }
                    }
                    )
                  });
                })
              )
            }
            else {
              element.childControls.map(item => {
                item.forEach(control => {
                  if (control.mappingId == FieldID) {
                    field = control;
                  }
                })
              });
            }
          }));

      } else {
        replacedControl.sectionGroup.forEach(group => {
          group.fields.forEach(field => {
            if (field.mappingId == mappingId) {

              let rowsToAdded = addFieldToChidControles(field, mappingIdValue, parentFieldId);
              let idToIncrement = fieldToIncrement ? fieldToIncrement : field.mappingId;


              field.childControls.push(rowsToAdded);
              dispatch(updateControlValue(idToIncrement, value, parentFieldId));
            }
          });
        });

      }
    }

  }
}
function addFieldToChidControles(field, mappingIdValue, parentFieldId = null) {
  var value = null
  var rowsToAdded = [];
  var childRowsToAdded = [];
  field.ChildCount.forEach(element => {
    const obj = element;
    childRowsToAdded = [];
    if (obj.isVisible && obj.childControls && obj.childControls.length > 0) {
      obj.childControls.forEach(childControl => {
        const newChildObj = update(childControl, {
          mappingId: { $set: childControl.mappingId + field.childControls.length },
          parentMappingId: { $set: childControl.mappingId },
          value: { $set: value }
        });
        childRowsToAdded.push(newChildObj);
      })
    }
    if (mappingIdValue) {
      // TODO: this is just to test, will remove it later
      var objectValue = null
      var defaultValue = null
      mappingIdValue.forEach(item => {
        var controlItem = getFieldById(item.key, parentFieldId);
        if (controlItem) {
          childRowsToAdded.forEach(childRow => {
            if (childRow.fieldId == controlItem.fieldId) {
              childRow.value = item.value
            }
          })
          if (controlItem.parentFieldId == obj.fieldId) {
            // objectValue = objectValue ? objectValue : "test" + field.childControls.length;
            defaultValue = defaultValue ? defaultValue + "|" + item.value : item.value
            objectValue = defaultValue
          }
          else if (controlItem.fieldId == obj.fieldId) {
            objectValue = item.value
          }
        }
      });
      const newObj = update(obj, {
        mappingId: { $set: obj.mappingId + field.childControls.length },
        parentMappingId: { $set: obj.mappingId },
        value: { $set: objectValue },
        childControls: { $set: childRowsToAdded }
      });

      rowsToAdded.push(newObj);
    }
    else {
      const newObj = update(obj, {
        mappingId: { $set: obj.mappingId + field.childControls.length },
        parentMappingId: { $set: obj.mappingId },
        value: { $set: null }
      });
      rowsToAdded.push(newObj);
    }
  });
  return rowsToAdded;

}
export function deleteFields(control, index) {
  return function addFields(dispatch, getState) {
    const state = getState();
    var sectionDetails = state.caseDetails.sectionDetails
    const replacedControl = sectionDetails
    if (replacedControl) {
      replacedControl.sectionGroup.forEach(group => {
        group.fields.forEach(field => {
          if (field.fieldId == control.parentFieldId) {
            //array.splice(index, 1);
            field.childControls.splice(index, 1);
          }
        });
      });
      dispatch(updateProps(replacedControl))
    }

  }
}

export function addFieldSuccess(data) {
  return {
    type: actionTypes.ADD_DYNAMIC_FIELD_SUCCESS,
    payload: data,
  };
}

export function updateCaseView(data) {
  return {
    type: actionTypes.UPDATE_CASE_VIEW,
    payload: data,
  };
}

export function updateCaseId(data) {
  return {
    type: actionTypes.UPDATE_CASE_ID,
    payload: data,
  };
}

export function isCreateCaseBl(bl) {
  return {
    type: actionTypes.IS_CREATE_CASE,
    payload: bl,
  };
}

function getFieldDefaultPermission(fieldMappingId, permissionTypeId) {
  if (!fieldMappingId)
    return permissionTypeId;

  var fieldDetailObj = getFieldById(fieldMappingId); //get field details from redux store
  if (!fieldDetailObj || !fieldDetailObj.defaultPermissionTypeId)
    return permissionTypeId;

  if (permissionTypeId && permissionTypeId == DEFAULT_PERMISSION) {
    permissionTypeId = fieldDetailObj.defaultPermissionTypeId;
  }
  return permissionTypeId;

}

export function updateFieldPermission(mappingId, permissionTypeId) {
  return function updateFieldPermission(dispatch, getState) {
    const state = getState()
    //get default permission of field if get <DEFAULT_PERMISSION> text in rule permission
    permissionTypeId = getFieldDefaultPermission(mappingId, permissionTypeId);
    var isBookingSheetField = false
    var sectionDetails = state.caseDetails.sectionDetails
    if (sectionDetails) {
      var replacedControl = sectionDetails
      replacedControl.sectionGroup && replacedControl.sectionGroup.forEach(x => {
        let targetControl = x.fields.find(element => element.mappingId == mappingId)
        if (targetControl) {
          isBookingSheetField = true
          targetControl.permissionTypeId = permissionTypeId;
        }
      })
      if (isBookingSheetField) {
        dispatch(updateProps(replacedControl))
      }
    }
    if (!isBookingSheetField) {
      //Didn't find the field in bookingsheet. Now we will search it in popup sections for matches
      const replacedControl = state.caseDetails.popupSectionDetails
      replacedControl.map(section => {
        section.sectionGroup.forEach(element => {
          element.fields.forEach(field => {
            if (field.mappingId == mappingId) {
              isBookingSheetField = false
              field.permissionTypeId = permissionTypeId;
            }
          });
        })
      })
      dispatch(updatePropsInPopup(replacedControl))
    }
  }
}

export function callServiceAPI(data) {
  return {
    type: actionTypes.EXECUTE_SERVICE_API,
    payload: data,
  };
}

export function updatePopupLoaderStatus(data) {
  return {
    type: actionTypes.UPDATE_POPUP_LOADER_STATUS,
    payload: data,
  };
}

export function executeUploadDocumnetAPI(serviceAPIName, serviceAPIData, setRespons) {
  return function executeUploadDocumnetAPI(dispatch, getState) {
    const state = getState();
    const sectionId = state.caseDetails.loadedSectionId || 0;
    var docSignDate = serviceAPIData[2].DocumentSignedDate || serviceAPIData[2].DocumentSignatureDate;

    var fileData = new FormData();
    fileData.append('Document', serviceAPIData[0].DocFileSelection);
    fileData.append('DocumentTypeId', serviceAPIData[1].DocumentTypeId || BLANK_VALUE);
    fileData.append('CaseId', state.caseDetails.caseId);
    fileData.append('documentDescription', serviceAPIData[4].DocDescription || BLANK_VALUE);
    fileData.append('documentNote', serviceAPIData[5].DocNotes || BLANK_VALUE);
    fileData.append('documentTag', false);
    fileData.append('documentSignedDate', docSignDate != null && moment(docSignDate).isValid() === true ? moment(docSignDate).format(DOCUMENT_DATE_FORMAT) : BLANK_VALUE);
    fileData.append('documentExpirationDate', serviceAPIData[3].DocumentExpirationDate != null && moment(serviceAPIData[3].DocumentExpirationDate).isValid() === true ? moment(serviceAPIData[3].DocumentExpirationDate).format(DOCUMENT_DATE_FORMAT) : BLANK_VALUE);
    fileData.append('SectionId', parseInt(sectionId));

    caseDetailsService.getDocumentAPIDetails(serviceAPIName, fileData)
      .then(response => {
        dispatch(updateCaseEditableMode(false));
        dispatch(updatePopupLoaderStatus(false));

        window.location.reload(); //TODO: Need to handle it by redux-store data
      });
  }
}

export function executeDocumentUploadAPI(serviceAPIName, serviceAPIData, setRespons) {
  return function executeDocumentUploadAPI(dispatch, getState) {
    const state = getState();
    const sectionId = state.caseDetails.loadedSectionId || 0;
    var docSignDate = serviceAPIData.DocumentSignedDate;
    dispatch(updatePopupLoaderStatus(true))
    var fileData = new FormData();
    fileData.append('Document', serviceAPIData.DocFileSelection);
    fileData.append('DocumentTypeId', serviceAPIData.DocumentTypeId || BLANK_VALUE);
    fileData.append('CaseId', state.caseDetails.caseId);
    fileData.append('documentDescription', serviceAPIData.DocDescription || BLANK_VALUE);
    fileData.append('documentNote', serviceAPIData.DocNotes || BLANK_VALUE);
    fileData.append('documentTag', false);
    fileData.append('IsPacketBl', serviceAPIData.IsPacketBl);
    fileData.append('documentSignedDate', docSignDate != null && moment(docSignDate).isValid() === true ? moment(docSignDate).format(DOCUMENT_DATE_FORMAT) : BLANK_VALUE);
    fileData.append('documentExpirationDate', serviceAPIData.DocumentExpirationDate != null && moment(serviceAPIData.DocumentExpirationDate).isValid() === true ? moment(serviceAPIData.DocumentExpirationDate).format(DOCUMENT_DATE_FORMAT) : BLANK_VALUE);
    fileData.append('SectionId', parseInt(sectionId));
    fileData.append('PacketDetails', serviceAPIData.PacketDetails);

    caseDetailsService.getDocumentAPIDetails(serviceAPIName, fileData)
      .then(response => {
        dispatch(updateCaseEditableMode(false));
        dispatch(documentPopUpOpen(false))
        dispatch(updatePopupLoaderStatus(false));

        window.location.reload(); //TODO: Need to handle it by redux-store data
      });
  }
}

export function executeEHRServiceAPI(serviceAPIName, requstFieldData, setRespons) {
  return function executeEHRServiceAPI(dispatch, getState) {
    const state = getState()
    var checkFieldValue = {};
    requstFieldData.forEach(configFields => {
      if (configFields == "CaseId") {
        checkFieldValue["caseId"] = state.caseDetails.caseId.toString();
      }
      if (configFields == "EMRTemplateId") {
        var fieldValue = getFieldValueById(configFields);
        checkFieldValue["templateId"] = fieldValue;
      }
    })

    caseDetailsService.getEHRAPIDetails(serviceAPIName, checkFieldValue)
      .then(response => {
        var result = response.responseData;
        if (result.payload) {
          setRespons.forEach(configFields => {
            dispatch(updateControlValue(configFields, result.payload));
          });
        }

      });
  }
}

export function executeCancelCaseAPI(serviceAPIName, requstFieldData, setRespons) {
  return function executeCancelCaseAPI(dispatch, getState) {
    var state = getState()
    var checkFieldValue = {};
    dispatch(updateSectionGroupLoaderStatus(true));
    requstFieldData.forEach(configFields => {
      if (configFields == "CaseId") {
        checkFieldValue["caseId"] = state.caseDetails.caseId;
      } else {
        var fieldValue = getFieldValueById(configFields);

        if (configFields == "CancellationReason") {
          checkFieldValue["cancelledReasonId"] = fieldValue ? fieldValue : null;
        }
        if (configFields == "PatientPlansToRescheduleBl") {
          checkFieldValue["patientPlansToReschedule"] = fieldValue == 1 ? true : false;
        }
        if (configFields == "CancellationComment") {
          checkFieldValue["comment"] = fieldValue ? fieldValue : null;
        }
      }
      checkFieldValue["txnId"] = null;

    })

    caseDetailsService.getCancelCaseAPIDetails(serviceAPIName, checkFieldValue)
      .then(response => {
        var result = response.responseData;
        if (result.payload) {
          dispatch(updateSelectedSectionName(CANCEL_CASE_PAGE_URL));
          dispatch(updateSelectedSectionId(CANCEL_CASE_SECTION_ID));
          dispatch(updateCaseAmendmentBlStatus(false));
          dispatch(updateCaseEditableMode(false));
        }
      });
  }
}

export function executeDownloadDocAPI(serviceAPIUrl) {
  return function executeDownloadDocAPI(dispatch, getState) {
    const state = getState();
    caseDetailsService.downloadDocument(serviceAPIUrl)
      .then(response => {
        if (response) {
          if (response.httpStatusCode == NO_CONTENT_404) {
            dispatch(updateDocumentAlertPopupStatus(true));
          }
          if (response.payload) {
            const a = document.createElement("a");
            a.href = createBlobFromBase64(response.payload.fileStream, response.payload.documentOrignalFileExtension);
            //a.href = createBlobFromBase64("VGVzdCBkb2N1bWVudA==", ".txt");
            //a.download = response.payload.description + Document.FileType.toString().toLocaleLowerCase();
            a.download = response.payload.documentOriginalFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
      });
  }
}

export function executeViewDocAPI(serviceAPIUrl) {
  return function executeViewDocAPI(dispatch, getState) {
    const state = getState()
    caseDetailsService.viewDocument(serviceAPIUrl)
      .then(response => {
        console.log('response', response);
      });
  }
}

export function getViewDocumentPoupData(parentContext, sectionId, caseId, documentId) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      var result = []
      caseDetailsService.getSectionDetailsBySectionIdCaseId(sectionId, caseId, documentId)
        .then(response => {
          if (response.responseData.httpStatusCode == NO_CONTENT_404) {
            dispatch(updateIsPopUpOpen(false));
            dispatch(updateDocumentAlertPopupStatus(true));
          } else {
            response.responseData.sectionGroup.map(sectionGroup => {
              sectionGroup.fields.map(field => {
                field['parentContextId'] = parentContext
              })
            })
            dispatch(getViewDocumentPoupDataSuccess(response.responseData));
          }
        })
    } catch (err) {
      console.log(err);
      dispatch(getViewDocumentPoupDataFailure(err));
    }
  }
}

export function getViewDocumentPoupDataSuccess(data) {
  return {
    type: actionTypes.GET_VIEW_DOCUMENT_DATA_SUCCESS,
    payload: data,
    sectionGroupLoader: false,

  };
}

export function getViewDocumentPoupDataFailure(data) {
  return {
    type: actionTypes.GET_VIEW_DOCUMENT_DATA_FAILURE,
    payload: data,
  };
}

export function updateDocPreviewStatus(data) {
  return {
    type: actionTypes.UPDATE_DOC_PREVIEW_STATUS,
    payload: data,
  };
}

export function getViewDocumentFileStream(serviceAPIUrl) {
  return function getViewDocumentFileStream(dispatch, getState) {
    try {
      const state = getState()
      dispatch(updateDocPreviewStatus(false))
      caseDetailsService.downloadDocument(serviceAPIUrl)
        .then(response => {
          if (response.payload) {
            dispatch(getViewDocumentFileStreamSuccess(response.payload));
            dispatch(updateDocPreviewStatus(true))
          }
        });
    } catch (err) {
      console.log(err);
      dispatch(GET_VIEW_DOCUMENT_DATA_FAILURE(err));
    }
  }
}

export function getViewDocumentFileStreamSuccess(data) {
  return {
    type: actionTypes.GET_VIEW_DOCUMENT_FILESTREAM_SUCCESS,
    payload: data,
    sectionGroupLoader: false,

  };
}

export function getViewDocumentFileStreamFailure(data) {
  return {
    type: actionTypes.GET_VIEW_DOCUMENT_FILESTREAM_FAILURE,
    payload: data,
  };
}

export function executeDeleteDocAPI(fieldData) {
  return async function executeDeleteDocAPI(dispatch, getState) {
    const state = getState()
    const sectionId = state.caseDetails.loadedSectionId || BLANK_VALUE;
    await caseDetailsService.deleteDocAPI(fieldData[0], fieldData[2], sectionId)
      .then(response => {
        if (response != null) {
          dispatch(notify("Report", DELETE_SUCCESS_MSG, "success"));
          setTimeout(() => { window.location.reload(); }, 3000) //TODO: Need to handle it by redux-store data
          return true;
        }
      });
  }
}

export function executeApproveAPI(serviceAPIUrl, fieldData, setRespons) {
  return async function executeApproveAPI(dispatch, getState) {
    const state = getState()
    const sectionId = state.caseDetails.loadedSectionId || BLANK_VALUE;
    await caseDetailsService.approveDocAPI(serviceAPIUrl, sectionId)
      .then(response => {
        if (response != null) {
          window.location.reload(); //TODO: Need to handle it by redux-store data
          return true;
        }
      });
  }
}

export function executeRejectDocumentAPI(docRejectionModel) {
  return function executeRejectDocumentAPI(dispatch, getState) {
    const state = getState();
    docRejectionModel.append("SectionId", state.caseDetails.loadedSectionId || BLANK_VALUE);
    caseDetailsService.rejectDocumentAPI(docRejectionModel)
      .then(response => {
        var result = response;
        console.log("response and result", response, result);
        window.location.reload(); //TODO: Need to handle it by redux-store data
      });
  }
}

export function executeRetryFaxAPI(caseId, documentId) {
  return function executeRetryFaxAPI(dispatch, getState) {
    const state = getState();
    const sectionId = state.caseDetails.loadedSectionId || BLANK_VALUE;
    caseDetailsService.RetryFaxAPI(caseId, parseInt(documentId), parseInt(sectionId))
      .then(response => {
        var result = response;
        dispatch(updateCaseEditableMode(false));
        console.log("response and result", response, result);
        window.location.reload(); //TODO: Need to handle it by redux-store data
      });
  }
}

export function executeServiceAPI(serviceAPIName, serviceAPIData) {
  return function executeServiceAPI(dispatch, getState) {
    const state = getState()
    var fileData = new FormData();
    // fileData.append('Document', serviceAPIData[0].DocFileSelection);
    // fileData.append('DocumentTypeId', serviceAPIData[1].DocumentTypeId || 1);
    // fileData.append('CaseId', state.caseDetails.caseId);
    // fileData.append('documentDescription', serviceAPIData[4].DocDescription || 'Sample Desc');
    // fileData.append('documentNote', serviceAPIData[5].DocNotes || null);
    // fileData.append('documentTag', false);
    //fileData.append('documentSignedDate', serviceAPIData[2].DocumentSignedDate != null ? moment(serviceAPIData[2].DocumentSignedDate).format('MM/DD/YYYY hh:mm:si') : moment(new Date()).format('MM/DD/YYYY hh:mm:si') );
    //fileData.append('documentExpirationDate', serviceAPIData[3].DocumentExpirationDate != null ? moment(serviceAPIData[3].DocumentExpirationDate).format('MM/DD/YYYY hh:mm:si')  : moment(new Date()).format('MM/DD/YYYY hh:mm:si') );

    caseDetailsService.getServiceAPIDetails(serviceAPIName, fileData)
      .then(response => {
        var result = response;
        //  updateControlValue("EMRSummary", response.responseData.documentId);
        //dispatch(callServiceAPI(serviceAPIName, serviceAPIData))   // Todo: Need to create a reducer to update state
      });
  }
}

export function updateTableControlValue(mappingId, value, parentFieldId, index) {
  return function updateTableControlValue(dispatch, getState) {
    const state = getState();
    var bookingSheetDetails = state.caseDetails.bookingSheetDetails;
    const popupSection = state.caseDetails.popupSectionDetails;
    var isPopupSection = false;
    if (popupSection) {
      popupSection.map(section => {
        section.sectionGroup.forEach(element => {
          element.fields.forEach(field => {
            if (field.mappingId == mappingId) {
              isPopupSection = true;
              if ((field.fieldTypeName == TEXT || field.fieldTypeName == ZIPCODE || field.fieldTypeName == PHONE_NUMBER) && value == null) {
                field.value = BLANK_VALUE;
              }
              else {
                field.value = value;
                field.isUpdated = true;
              }
            }
            if (field.fieldId == parentFieldId) {
              isPopupSection = true;
              field = updateTablefields(field, mappingId, value);
            }
          });
        })
      })
      isPopupSection && dispatch(updateControlValueInPopup(popupSection));
    }
    if (!isPopupSection && bookingSheetDetails) {
      const replacedControl = bookingSheetDetails;
      var parentField = getControlByFieldId(parentFieldId);
      if (parentField.parentFieldId) {
        replacedControl.forEach(section =>
          section.sectionGroup.forEach(x => x.fields.filter(element => element.fieldId == parentField.parentFieldId)
            .forEach(parentField => {
              parentField.childControls[index].forEach(secondChild => {
                if (secondChild.fieldId == parentFieldId) {
                  secondChild.childControls.map(item => {
                    // item.forEach(field => {
                    if (item.mappingId == mappingId) {
                      item.value = value
                    }
                    // }
                    // )
                  });
                }
              })
            })));
        dispatch(getBookingSheetSuccess(replacedControl));
      }
    }
  }
}

export const overrideValidationsInFields = (targetFields, overrideValidationFlag) => {
  const overrideFieldValidationErrors = (replacedControl) => {
    return {
      type: actionTypes.OVERRIDE_VALIDATION_ENGINE,
      payload: replacedControl
    }
  }
  return async function (dispatch, getState) {
    const state = getState()
    var sectionDetails = state.caseDetails.sectionDetails
    targetFields.map(targetFieldMappingId => {
      if (sectionDetails) {
        var replacedControl = sectionDetails
        replacedControl.sectionGroup.forEach(x => {
          let targetControl = x.fields.find(element => element.mappingId == targetFieldMappingId)
          if (targetControl) {
            targetControl['overrideValidationFlag'] = overrideValidationFlag
          }
        })
        dispatch(overrideFieldValidationErrors(replacedControl))
      }
    })
  }
}

export function setCreateCaseState(data) {
  return {
    type: actionTypes.SET_CREATE_CASE,
    payload: data,
  };
}

export function updateActionButtonLoaderStatus(status) {
  return {
    type: actionTypes.ACTION_BUTTON_LOADER_STATUS,
    status
  }
}

export function clearPopupStoreData() {
  return {
    type: actionTypes.CLEAR_POPUP_STORE_DATA

  };
}

export function updateSignalRSectionLockStatus(sectionLockedData) {
  return {
    type: actionTypes.UPDATE_SIGNAL_R_LOCK_STATUS,
    payload: sectionLockedData
  }
}

export function updateSignalRHubConnection(hubConnection) {
  return {
    type: actionTypes.UPDATE_SIGNAL_R_HUB_CONNECTION,
    payload: hubConnection
  }
}

export function updateIfUserIsAuthenticated(boolean) {
  return {
    type: actionTypes.UPDATE_IF_USER_ID_AUTH,
    payload: boolean
  }
}

export function saveRepUserUnConfirmedProducts(caseDataObj) {
  return async function (dispatch, getState) {
    // const state = getState();     
    await caseDetailsService.saveRepUserUnConfirmedProducts(caseDataObj)
      .then(response => {
        if (response && response.responseData) {
          window.location.reload(); //TODO: Need to handle it by redux-store data
        }

      })
      .catch(err => console.error(err));
  };
}

export function sendSectionReleaseInfo() {
  return function sendSectionReleaseInfo(dispatch, getState) {
    const state = getState();
    const hubConnection = state.caseDetails.hubConnection;
    const caseId = state.caseDetails.caseId;
    const sectionId = state.caseDetails.loadedSectionId;
    console.log("Release lock for sectionId= ", sectionId + ", CaseId= " + caseId)

    const caseObj = {
      CaseId: caseId,
      SectionId: (typeof sectionId === 'string') ? parseInt(sectionId) : sectionId,
      CurrentUserDetails: {TenantKey: LocalStorageProvider.getTenantKey()}
    };

    if (hubConnection && hubConnection._connectionStarted) {
      try {
        console.log("caseObj: ", caseObj)
        hubConnection.invoke('ReleaseCaseSectionLock', caseObj);
        console.log("release section lock status api called, ReleaseCaseSectionLock");
      }
      catch (e) {
        console.log(e);
      }
    }
    else {
      console.log('No hubConnection to server yet, sendSectionReleaseInfo');

    }
  }
}

export function removeUserFromgroup() {
  return function removeUserFromgroup(dispatch, getState) {
    const state = getState();
    const hubConnection = store.getState().caseDetails.hubConnection;
    const caseId = store.getState().caseDetails.caseId;
    if (hubConnection && hubConnection._connectionStarted && caseId) {
      try {

        const groupName = LocalStorageProvider.getTenantKey() + '_' + caseId;
        hubConnection.invoke('RemoveFromCaseGroup', "" + groupName);
        console.log("group removed", groupName);
      }
      catch (e) {
        console.log(e);
      }
    }
    else {
      console.log('No hubConnection to server yet, removeUserFromgroup');

    }
  }
}

export function executeActivityTrackingAPI(serviceAPIUrl, dependentFields, additonalFields) {
  return function executeActivityTrackingAPI(dispatch, getState) {
    const state = getState()
    var caseId = "";
    var activityType = "";

    //set dependent fields value
    for (var field of dependentFields) {
      if (field == LBL_CASEID) {
        caseId = store.getState().caseDetails.caseId;
      }
    }

    //set activity type param for api to get text accordingly
    activityType = additonalFields && additonalFields.ActivityType ? additonalFields.ActivityType : activityType;

    //call case detail service
    caseDetailsService.executeActivityTracking(serviceAPIUrl, caseId, activityType)
      .then(response => {
        console.log('response', response);
      });
  }
}

export function updateSelectedInsuranceDependantFields(dependentFields) {
  return {
    type: actionTypes.UPDATE_SELECTED_INSURANCE_DEPENDENT_FIELD,
    payload: dependentFields
  }
}

export function getCaseSummaryDetails(caseId) {
  return function getCaseSummaryDetails(dispatch, getState) {
    try {
      const state = getState();
      caseDetailsService.getCaseSummaryDetails(caseId)
        .then(response => {
          if (response) {
            dispatch(getCaseSummaryDetailsSuccess(response));
          }
        });
    } catch (err) {
      console.log(err);
      dispatch(getCaseSummaryDetailsFailure(err));
    }
  }
}

export function getCaseSummaryDetailsSuccess(data) {
  return {
    type: actionTypes.GET_CASE_SUMMARY_SUCCESS,
    payload: data

  };
}

export function getCaseSummaryDetailsFailure(error) {
  return {
    type: actionTypes.GET_CASE_SUMMARY_FAILURE,
    payload: error,
  };
}

export function saveVerifyInsuranceData(data) {
  return function saveVerifyInsuranceData(dispatch, getState) {
    try {
      const state = getState();
      caseDetailsService.saveVerifyInsuranceData(data)
        .then(response => {
          if (response && !response?.CaseAmendedByOtherUser) {
            dispatch(saveVerifyInsuranceDataSuccess(response));
            dispatch(getCaseSummaryDetails(response?.CaseId));
          }
          else {
            dispatch(saveVerifyInsuranceDataSuccess(response));
          }
        });
    } catch (err) {
      console.log(err);
      dispatch(saveVerifyInsuranceDataFailure(err));
    }
  }
}

export function saveVerifyInsuranceDataSuccess(data) {
  return {
    type: actionTypes.SAVE_VERIFY_INSURANCE_SUCCESS,
    payload: data

  };
}

export function saveVerifyInsuranceDataFailure(error) {
  return {
    type: actionTypes.SAVE_VERIFY_INSURANCE_FAILURE,
    payload: error,
  };
}
export function getDataBySearchCriteria(rule, value) {
  return function getDataBySearchCriteria(dispatch, getState) {
    const state = getState()
    var requestArray = [];
    var filedMinLenght = rule.MinimumInputCount;
    let mappingId = rule.ReferenceDataName;
    let dependentField = rule.DependentFields;
    let inputValue = value.trimStart();
    let obj = {
      "Id": mappingId,
      "additionalData": { [dependentField]: inputValue }
    }
    requestArray.push(obj);
    var removeData = {
      selections: { ...state.caseDetails.selection, ...{ [mappingId]: [] } },
      sectionId: state.caseDetails.loadedSectionId
    }
    var isselectionExists = state.caseDetails.selection[mappingId];
    if (inputValue.length == 0 && isselectionExists != undefined) {
      dispatch(dispatchSearchedSelectionControl(removeData));
    }
    if (inputValue != '' && inputValue.length >= filedMinLenght) {
      dispatch(setSelectionLoader(true));
      caseDetailsService.getSelectionForControls(requestArray)
        .then(response => {
          var sectionDetails = {
            selections: { ...state.caseDetails.selection, ...response },
            sectionId: state.caseDetails.loadedSectionId
          }
          dispatch(dispatchSearchedSelectionControl(sectionDetails))
        });
    }
  }
}

export function dispatchSearchedSelectionControl(data) {
  return {
    type: actionTypes.CONTROL_SEARCHED_SELECTION_UPDATE_SUCCESS,
    payload: data,
  };
}

export function setSelectionLoader(data) {
  return {
    type: actionTypes.SELECTION_LOADER_STATUS_UPDATE,
    payload: data,
  };
}

export function updateDocumentAlertPopupStatus(boolean) {
  return {
    type: actionTypes.UPDATE_DOCUMENT_ALERT_POPUP_STATUS,
    payload: boolean
  }
}

export function updateDocSectionDetails(data) {
  return {
    type: actionTypes.UPDATE_DOC_SECTION_DETAILS,
    payload: data,
  };
}

export function updateDoCPopUpDetails(data) {
  return {
    type: actionTypes.UPDATE_DOC_POPUP_DETAILS,
    payload: data,
  };
}

export function updateDoCPopUpSelectionControls(data) {
  return {
    type: actionTypes.UPDATE_DOC_POPUP_SELECTION_CONTROLS,
    payload: data,
  };
}

export function getDocumentDetailsByCaseId(caseId, sectionId) {
  return function getDocumentDetailsByCaseId(dispatch) {
    try {
      caseDetailsService.getDocumentDetails(caseId, sectionId)
        .then(response => {
          if (response) {
            //Setting the isVisible property true as default for document section group
            const docDetailResponse = response.sectionHeader.sectionGroup.reduce((acc, value, inx) => {
              if(!Object.hasOwn(value, 'isVisible')){
                value['isVisible'] = true;
                acc.sectionHeader.sectionGroup[inx] = value;
              }
              return acc;
            }, response)
            dispatch(updateDocSectionDetails(docDetailResponse))
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
}

export function documentPopUpSectionLoad(data) {
  return {
    type: actionTypes.UPDATE_DOC_POP_UP_SECTION_LOAD,
    payload: data,
  };
}

export function getDocumentPopUpsByCaseIdSectionId(caseId, sectionId, mappingId, documentId = 0) {
  return function getDocumentPopUpsByCaseIdSectionId(dispatch) {
    try {
      const dataObj = {"DocumentId": documentId}
      caseDetailsService.getPopUpDetailsByCaseIdSectionId(caseId, sectionId, dataObj)
        .then(response => {
          if (response) {
            dispatch(updateDoCPopUpDetails(response))
            dispatch(documentPopUpSectionLoad(true))
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
}

export function getDocumentPopUpSelectionControls(requestArray) {
  return function getDocumentPopUpSelectionControls(dispatch) {
    try {
      caseDetailsService.getSelectionForControls(requestArray)
        .then(response => {
          if (response) {
            dispatch(updateDoCPopUpSelectionControls(response))
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
}

export function documentPopUpOpen(data) {
  return {
    type: actionTypes.UPDATE_DOC_POP_UP_OPEN,
    payload: data,
  };
}

export function parseDocumentByCaseIdDocId(caseId, docId, data) {
  return function parseDocumentByCaseIdDocId(dispatch) {
    try {
      dispatch(updatePopupLoaderStatus(true))
      caseDetailsService.editDocumentByCaseIdDocId(caseId, docId, data)
        .then(response => {
          dispatch(sendSectionReleaseInfo());
          dispatch(updateCaseEditableMode(false));
          dispatch(updatePopupLoaderStatus(false))

          if(response.status == 400) {
            dispatch(notify("Update Document Error", response?.data?.exception?.exceptionMessage, "error"));
          } else {
            dispatch(documentPopUpOpen(false))
            window.location.reload();
          }
        })
    } catch (err) {
      dispatch(updateCaseEditableMode(false));
      dispatch(documentPopUpOpen(false))
      dispatch(updatePopupLoaderStatus(false))
      dispatch(notify("Update Document Error", UPDATE_DOC_FAILURE_MSG, "error"));
    }
  }
}