import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import sanitize from 'sanitize-html';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        'font-size': '1rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': '400',
        'line-height': '1.5',
        'letter-spacing': '0.00938em',
        'width': '100%'
    }
}))

export default function EmbedHtml(props) {
    var { fieldId, name, value, disabled, ...other } = props;
    const classes = useStyles();
    const sanitizedHtml = sanitize(props.value, { 
        allowedTags: sanitize.defaults.allowedTags.concat(['img']),
        allowedAttributes: { '*': ['class'], 'img': ['src'] } 
    });

    return (
        <div
            id={fieldId}
            className={classes.root}
            disabled={disabled}

        > <div dangerouslySetInnerHTML={{ __html: props.value ? sanitizedHtml : "Loading..." }} />
        </div>
    )
}
