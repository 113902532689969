import {CONTROL_SELECTION_UPDATE_SUCCESS,
    CONTROL_SELECTION_UPDATE_FAILURE} from '../constants/ActionTypes';


const initialState = {
selection:null
}

const ACTION_HANDLERS = {   



 [CONTROL_SELECTION_UPDATE_FAILURE]: (state, action) => ({
        ...state,
        [action.payload.controlId]: {
          loading: false,
          error: action.payload.err,
        },
      }),
}

export default function controlSelections(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
      
