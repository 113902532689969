import React from 'react';
import { Paper } from '@mui/material';
import DataGrid from './DataGrid';

const ReportsRightSection = () => {
    return (
        <>
            <DataGrid />
        </>
    )
}

export default ReportsRightSection
