import { store } from '../../../store'
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';

export const execute = (props) => {
    const fieldProps = props.additionalProperties
    if (fieldProps && fieldProps.DisableTargetFieldIfNoInputSelected) {
        var permissionDisabled = fieldProps.DisableTargetFieldIfNoInputSelected.IfTrueSetPermissionTypeId;
        var permissionEnabled = fieldProps.DisableTargetFieldIfNoInputSelected.IfFalseSetPermissionTypeId;
        fieldProps.DisableTargetFieldIfNoInputSelected.TargetFieldId.forEach(item => {
            if (!props.value) {
                store.dispatch(updateFieldPermission(item, permissionDisabled));
            } else {
                store.dispatch(updateFieldPermission(item, permissionEnabled));
            }
        })
    }
}

const DisableTargetFieldIfNoInputSelected = {
    execute
}

export default DisableTargetFieldIfNoInputSelected   