import { Box, CircularProgress, Fade } from '@mui/material'
import React from 'react'

const Loader = (obj) => {
    return (
        <Box style={{ display: 'flex',justifyContent: "center",alignItems: "center"}}>
         <Fade
          in={obj.loading}
          style={{
            transitionDelay: obj.loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
            <CircularProgress size={24} />
        </Fade>
            
        </Box>
    )
}

export default Loader
