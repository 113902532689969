import Month from 'react-big-calendar/lib/Month';
import styled from 'styled-components';

export const StyledMonthView = styled(Month)`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 10px;
  padding: 16px;
  font-family: 300; 
  border-radius: 0px;
  background-color: fcfcfc;

  && {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  /* Month view headers */
  .rbc-header {
    border-bottom: none;
    text-align: left;
    color: grey;
  }
  .rbc-header + .rbc-header {
    border-left: none;
  }

  /* Month row */
  .rbc-month-row + .rbc-month-row {
    border-top: none;
  }

  /* Month view day cell */
  .rbc-day-bg + .rbc-day-bg {
    border-left: none;
  }

  /* Date cell, contains text for date */
  .rbc-date-cell {
    text-align: left;
    color: grey;
    padding: 5px;
  }

  .rbc-row-slot > .rbc-event--global {
  background-color: #fbab18;
  }

 .rbc-row-slot > .rbc-event--regional {
  background-color: #6ebe4a;
 }
  .rbc-event, .rbc-day-slot .rbc-background-event{
     background-color: #fff;
     margin: 0px !important;
     padding: 0px !important;

  }
`;

