import LoginService, { getUserInfoByToken } from '../services/LoginService'
import * as ActionTypes from '../constants/ActionTypes'
import * as Constants from '../constants/GlobalConstants'
import LocalStorageProvider from '../providers/LocalStorageProvider'
import { useForm } from '../components/common/useForm';

const login = (values) => {
    return dispatch => {
        LoginService.getIdentityAccessToken(values)
            .then(response => {

                if (response.status === 'Success') {
                    LocalStorageProvider.saveAuthToken(response.responseData.access_token, response.responseData.refresh_token, response.responseData.expires_in)
                    LoginService.getAllTenants().then(tenantResponse => {

                        //show error if account is suspended at tenant level
                        if (tenantResponse.responseData.error && tenantResponse.responseData.error == "user_account_suspended") {
                            LocalStorageProvider.deleteUserInfo();
                            dispatch(loginFailedAction(tenantResponse.responseData))

                        }
                        else if (tenantResponse.responseData.length > 1) {
                            dispatch(multiTenantAction(tenantResponse.responseData));
                        }
                        else if (tenantResponse.responseData.length == 1) {
                            LocalStorageProvider.saveTenantKey(tenantResponse.responseData[0]);
                            LocalStorageProvider.saveTenantIconUrl(tenantResponse.responseData[0]);
                            getUserInfo(tenantResponse.responseData);
                        }

                    })
                } else {
                    dispatch(loginFailedAction(response.responseData))
                }
            });

        const getUserInfo = (responseData = null) => {

            LoginService.getTenantUserInfo()
                .then(tenantResponse => {
                    //show error if account is suspended at user level 
                    if (tenantResponse.responseData.error && tenantResponse.responseData.error == "user_account_suspended") {
                        LocalStorageProvider.deleteUserInfo();
                        dispatch(loginFailedAction(tenantResponse.responseData))
                    }
                    else {
                        if (responseData) {
                            dispatch(singleTenantAction(responseData));
                        }
                        LocalStorageProvider.saveTenantUserInfo(tenantResponse.responseData);
                    }
                })

        }

        const multiTenantAction = payload => {
            return {
                type: ActionTypes.LOGIN_MULTITENANTS,
                payload,
                time: new Date()
            }
        }

        const singleTenantAction = payload => {
            return {
                type: ActionTypes.LOGIN_SINGLETENANT,
                payload,
                time: new Date()
            }
        }

        const loginFailedAction = payload => {
            return {
                type: ActionTypes.LOGIN_FAILED,
                payload,
                time: new Date()
            }
        }    
    }
}

//get and set user tenant detail in session storage
const getUserInfo = () => {

    LoginService.getTenantUserInfo()
        .then(tenantResponse => {
            LocalStorageProvider.saveTenantUserInfo(tenantResponse.responseData);
        })

}

const saveTenantInfoOnSelection = (values) => {
    LocalStorageProvider.saveTenantKey(values)
    LocalStorageProvider.saveTenantIconUrl(values)
    return {
        type: ActionTypes.SAVE_SELECTED_TENANT,
        payload: values.TenantId,

    }
}


const getAccessToken = values => {
    return dispatch => {
        LoginService.getAccessToken(values)
            .then(response => {
                LocalStorageProvider.saveAuthToken(response.responseData.access_token, response.responseData.refresh_token)
                dispatch(saveAuthToken(response.responseData, values))
            })
        const saveAuthToken = (payload, values) => {
            return {
                type: ActionTypes.SAVE_SELECTED_TENANT,
                payload,
                selectedTenant: values.tenantId
            }
        }
    }
}

const resetComponent = () => {
    return dispatch => {
        const resetComponent = () => {
            return {
                type: ActionTypes.RESET_COMPONENT
            }
        }
        dispatch(resetComponent())
    }
}

const refreshAccessToken = (refreshToken) => {
    return dispatch => {

        const { values } = useForm({ refresh_token: refreshToken })
        LoginService.getIdentityRefreshAccessToken(values)
            .then(response => {
                if (response.status === 'Success') {
                    console.log("refressh token", response);
                    LocalStorageProvider.saveAuthToken(response.responseData.access_token, response.responseData.refresh_token, response.responseData.expires_in)
                    dispatch(setExpireTokenStatus(false))
                }

            })
    }
}

const setExpireTokenStatus = (status) => {
    return {
        type: ActionTypes.EXPIRE_TOKEN_STATUS,
        payload: status,
        expireTokenStatus: status,
    };
}

const userLogout = (sessionType) => {
    return dispatch => {
        LoginService.userLogout(sessionType);
    }
}

const AuthAction = {
    login,
    getAccessToken,
    resetComponent,
    refreshAccessToken,
    setExpireTokenStatus,
    getUserInfo,
    saveTenantInfoOnSelection,
    userLogout
}

export default AuthAction;