import { store } from '../../../store'
import { updateControlValue } from '../../../actions/CaseDetailsAction'
import { getFieldById } from '../../GetFieldById'

export const execute = (props, AddOnLoadONChangeFlag = null) => {
    const fieldProps = props.additionalProperties
    let rule = fieldProps.OnChangeDataToClearDueToDependentFieldValue
    if (AddOnLoadONChangeFlag && rule) {
        var filedID = rule.DependentFieldId;
        var fieldValue = rule.DependentFieldValue;
        var field = getFieldById(filedID);
        var dependentFieldVal = field ? field.value : null
        if (dependentFieldVal == fieldValue) {
            var targetFieldId = rule.TargetFieldId;
            targetFieldId.forEach(fieldId => {
                store.dispatch(updateControlValue(fieldId, null));
            })
        }
    }
}
const OnChangeDataToClearDueToDependentFieldValue = {
    execute
}
export default OnChangeDataToClearDueToDependentFieldValue