import React from 'react';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Stack,
    Typography,
    Box
} from '@mui/material';
import * as Colors from '../../res/values/Colors';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    getDocumentSectionDetails
} from '../../reducers/CaseDetailsReducer';
import { useDispatch, useSelector } from 'react-redux';
import Controls from '../controls/Controls';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import * as constants from '../../constants/GlobalConstants';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    redFont: {
        color: Colors.ICON_RED
    },
    greenFont: {
        color: Colors.ICON_GR
    },
    tableFont: {
        wordBreak: 'break-word',
        '-webkit-hyphens': 'auto',
        '-moz-hyphens': 'auto',
        hyphens: 'auto'
    },
    dataTableContainer: {
        maxHeight: `calc(100vh - ${theme.spacing(32)})`
    },
    dataTableHeader: {
        backgroundColor: Colors.DOC_TABLE_HEADER
    },
    tblBodyCell: {
        paddingRight: '5px !important',
        paddingLeft: '5px !important'
    },
    tblHeaderEnd: {
        paddingLeft: '16px !important'
    },
    docTypeCell: {
        minWidth: '15%',
        paddingLeft: '16px !important'
    },
    descriptionCell: {
        minWidth: '30%'
    },
    uploadCell: {
        minWidth: '15%'
    },
    faxCell: {
        minWidth: '15%'
    },
    statusCell:{
        minWidth: '15%'
    },
    actionCell: {
        minWidth: '10%'
    }
})
);

const TableCellHead = withStyles((theme) => ({
    root: {
        backgroundColor: Colors.DOC_TABLE_HEADER,
        color: theme.palette.common.white,
        minWidth: 80,
        paddingRight: '5px',
        paddingLeft: '5px'
    },
}))(TableCell);

export default function DocumentTable(props) {
    const { disabled, handleViewDocument, handleDownloadDocument, handleAcceptDocument, handleRejectDocument, handleDeleteDocument,
        handleRetryFax, handleDocEdit
    } = props
    const classes = useStyles()
    const documentDetails = useSelector(getDocumentSectionDetails)
    const viewBtnProperties = { Image: ["ViewDoc"] }
    const downloadBtnProperties = { Image: ["DownloadDoc"] }
    const deleteBtnProperties = { Image: ["Delete"] }

    // Getting additional properties and details of field by mapping id
    const getFieldProperties = (mappingId) => {
        const fieldInfo = {}
        if (documentDetails?.sectionHeader) {
            const elementDetails = documentDetails.sectionHeader.sectionGroup[0].fields.find(item => item.mappingId == mappingId)
            const additionalProperties = JSON.parse(elementDetails.additionalProperties)
            fieldInfo.element = elementDetails
            fieldInfo.additionalProperties = additionalProperties
        }
        return fieldInfo
    }

    //This function is used to render expired document label
    const DocumentExpired = () => {
        return (
            <>
                <Stack direction="row" spacing={1}>
                    <ErrorIcon className={classes.redFont} />
                    <Typography className={classes.redFont}>{constants.DOC_EXPIRED}</Typography>
                </Stack>
                <Typography className={classes.redFont}>{constants.DOC_EXPIRED_TEXT}</Typography>
            </>
        )
    }

    // This function is used to render document status
    const DocumentStatus = (props) => {
        const { details } = props;

        return (
            <>
                {details.docDetailsDocumentStatus == 'Approved' ?
                    (
                        <>
                            <Stack direction="row" spacing={1}>
                                <CheckCircleIcon className={classes.greenFont} />
                                <Typography className={classes.greenFont}>{constants.DOC_ACCEPTED}</Typography>
                            </Stack>
                            <Typography>{details.documentUploadedDate}</Typography>
                            <Typography>{moment(details.documentUploadedTime, constants.ALLOWED_TIME_FORMATES[3]).format(constants.COMMON_TIME_FORMAT)}</Typography>
                            <Typography>{details.docDetailsDocumentStatusByUsername}</Typography>
                        </>
                    ) : (
                        <>
                            <Stack direction="row" spacing={1}>
                                <ErrorIcon className={classes.redFont} />
                                <Typography className={classes.redFont}>{constants.DOC_REJECTED}</Typography>
                            </Stack>
                            <Typography className={classes.redFont}>{details.docDetailsDocumentRejectionReason}</Typography>
                            <Typography className={classes.redFont}>{details.documentUploadedDate}</Typography>
                            <Typography className={classes.redFont}>{moment(details.documentUploadedTime, constants.ALLOWED_TIME_FORMATES[3]).format(constants.COMMON_TIME_FORMAT)}</Typography>
                            <Typography className={classes.redFont}>{details.docDetailsDocumentStatusByUsername}</Typography>
                        </>
                    )
                }
            </>
        )
    }

    // This function is used to render action links in document table
    const ActiveLinks = (props) => {
        const buttonsToDisable = [constants.APPROVE_BTN_MAPPING_ID, constants.REJECT_BTN_MAPPING_ID, constants.RETRYFAX_BTN_MAPPING_ID, constants.EDITDOC_BTN_MAPPING_ID]
        const { mappingId, label, documentId } = props;
        const button = documentDetails.sectionHeader.sectionGroup[0].fields.filter(item => item.mappingId == mappingId)
        const hidden = button.length > 0 ? button[0].permissionTypeId == constants.PermissionTypes.HIDE_FIELD : true;
        let linkDisabled = button.length > 0 ? button[0].permissionTypeId == constants.PermissionTypes.DISABLE_FIELD : true;
        if (buttonsToDisable.includes(mappingId) && disabled) {
            linkDisabled = true
        }

        const linkInfo = {
            fieldInfo: button[0],
            docId: documentId
        }

        switch (mappingId) {
            case constants.APPROVE_BTN_MAPPING_ID:
                return <Controls.AnchorLink label={label} hide={hidden} disabled={linkDisabled} onClick={(e) => {
                    e.preventDefault()
                    handleAcceptDocument(documentId)
                }} />
            case constants.REJECT_BTN_MAPPING_ID:
                return <Controls.AnchorLink label={label} hide={hidden} disabled={linkDisabled} onClick={() => handleRejectDocument(linkInfo)} />
            case constants.RETRYFAX_BTN_MAPPING_ID:
                return <Controls.AnchorLink label={label} hide={hidden} disabled={linkDisabled} onClick={(e) => {
                    e.preventDefault()
                    handleRetryFax(linkInfo)
                }} />
            case constants.EDITDOC_BTN_MAPPING_ID:
                return <Controls.AnchorLink label={label} hide={hidden} disabled={linkDisabled} onClick={() => handleDocEdit(linkInfo)} />
        }
    }

    // This function is used to render action buttons in document table
    const ActiveButtons = (props) => {
        const { mappingId, additionalProperties, documentId } = props;
        const button = documentDetails.sectionHeader.sectionGroup[0].fields.filter(item => item.mappingId == mappingId)
        const hidden = button.length > 0 ? button[0].permissionTypeId == constants.PermissionTypes.HIDE_FIELD : true;
        let buttonDisabled = button.length > 0 ? button[0].permissionTypeId == constants.PermissionTypes.DISABLE_FIELD : true;
        if (mappingId == constants.DELETE_BTN_MAPPING_ID && disabled) {
            buttonDisabled = true
        }

        const btnInfo = {
            fieldInfo: button[0],
            docId: documentId
        }

        switch (mappingId) {
            case constants.VIEW_BTN_MAPPING_ID:
                return (
                    <>
                        {!hidden && (
                            <Controls.ImageButton
                                additionalProperties={additionalProperties}
                                disabled={buttonDisabled}
                                onClick={() => handleViewDocument(btnInfo)}
                            />
                        )}
                    </>
                )
            case constants.DOWNLOAD_BTN_MAPPING_ID:
                return (
                    <>
                        {!hidden && (
                            <Controls.ImageButton
                                additionalProperties={additionalProperties}
                                disabled={buttonDisabled}
                                onClick={() => handleDownloadDocument(btnInfo)}
                            />
                        )}
                    </>
                )
            case constants.DELETE_BTN_MAPPING_ID:
                return (
                    <>
                        {!hidden && (
                            <Controls.ImageButton
                                additionalProperties={additionalProperties}
                                disabled={buttonDisabled}
                                onClick={() => handleDeleteDocument(btnInfo)}
                            />
                        )}
                    </>
                )
        }
    }

    // This function is used to show/hide column in document table
    const displayColumn = (mappingId) => {
        const label = documentDetails?.sectionHeader.sectionGroup[0].fields.find(item => item.mappingId == mappingId)
        return label;
    }

    // This function is used to render packet includes info
    const PacketIncludes = (props) => {
        const { packetDetails, fileName } = props
        const details = JSON.parse(packetDetails)
        const lblSignedDate = getFieldProperties(constants.DOC_SIGNED_DATE_LBL_MAPPING_ID)

        return (
            <Stack spacing={2}>
                <Typography>{constants.LBL_PACKET_INCLUDES}</Typography>
                {(fileName !== "" && fileName !== null) && (
                    <Typography>{`${constants.LBL_DOC_FILE_NAME}: ${fileName}`}</Typography>
                )}
                {details?.length > 0 && details.map(item =>
                    <Box>
                        {item.PacketDocumentExpiredBl && <DocumentExpired />}
                        <Typography>{`${item.PacketDocumentTypeName}(Pages ${item.PacketPageRange})`}</Typography>
                        {item.PacketDocumentSignedDate && (
                            <Typography>{lblSignedDate?.element?.fieldName} : {item.PacketDocumentSignedDate && moment(new Date(item.PacketDocumentSignedDate)).format(constants.COMMON_DATE_FORMAT)}</Typography>
                        )}
                    </Box>
                )}
            </Stack>
        )
    }

    // This function is used to render fax status
    const FaxStatus = (props) => {
        const { details } = props;
        switch(details.docDetailsFaxStatus){
            case constants.DOC_FAX_SUCCESS:
                return (
                    <Stack direction="row" spacing={1}>
                        <CheckCircleIcon className={classes.greenFont} />
                        <Typography>{details.docDetailsFaxStatus}</Typography>
                    </Stack>
                )
            case constants.DOC_FAX_TRANSMITTING:
                return <Typography>{details.docDetailsFaxStatus}</Typography>
            case constants.DOC_FAX_FAILED:
                return (
                    <Stack direction="row" spacing={1}>
                        <CancelIcon className={classes.redFont} />
                        <Typography>{details.docDetailsFaxStatus}</Typography>
                    </Stack>
                )
        }
    }

    return (
        <>
            {documentDetails?.sectionData && (
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="Document Table" classes={classes.tableFont} size="small">
                        <TableHead className={classes.dataTableHeader}>
                            <TableRow>
                                <TableCellHead className={classes.tblHeaderEnd}>{constants.LBL_DOC_TYPE}</TableCellHead>
                                <TableCellHead>{constants.LBL_DOC_DESCRIPTION}</TableCellHead>
                                <TableCellHead>{constants.LBL_DOC_UPLOADED_BY}</TableCellHead>
                                {displayColumn(constants.FAX_DETAILS_MAPPING_ID) && <TableCellHead>{constants.LBL_DOC_FAX_STATUS}</TableCellHead>}
                                <TableCellHead>{constants.LBL_DOC_STATUS}</TableCellHead>
                                <TableCellHead className={classes.tblHeaderEnd}>{constants.LBL_DOC_ACTIONS}</TableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(documentDetails?.sectionData && documentDetails.sectionData.documents.length > 0) ? documentDetails.sectionData.documents.map(item => (
                                <TableRow>
                                    <TableCell className={`${classes.tblBodyCell} ${classes.docTypeCell}`}>
                                        <Typography>{item.selectedDocumentTypeName}</Typography>
                                        {item.isPacketBl && item.docDetailsDocumentStatus !== constants.DOC_REJECTED && <ActiveLinks mappingId={constants.EDITDOC_BTN_MAPPING_ID} label={constants.EDIT_BTN_LBL} documentId={item.docDetailsDocumentId} />}
                                    </TableCell>
                                    <TableCell className={`${classes.tblBodyCell} ${classes.descriptionCell}`}>
                                        <Stack>
                                            {item.documentExpiredBl && <DocumentExpired />}
                                            {item.isPacketBl ? <PacketIncludes packetDetails={item.packetDetails} fileName={item.docDetailsFileName} /> : (
                                                <>
                                                    {(item.docDetailsFileName !== "" && item.docDetailsFileName !== null) && (
                                                        <Typography marginBottom={2}>{`${constants.LBL_DOC_FILE_NAME}: ${item.docDetailsFileName}`}</Typography>
                                                    )}
                                                    {(item.docDetailsDescription !== "" && item.docDetailsDescription !== null) && (
                                                        <Typography>{`${constants.LBL_DOC_DESCRIPTION}: ${item.docDetailsDescription}`}</Typography>
                                                    )}
                                                    {(item.docDetailsNotes !== "" && item.docDetailsNotes !== null) && (
                                                        <Typography>{`${constants.LBL_DOC_NOTES}: ${item.docDetailsNotes}`}</Typography>
                                                    )}
                                                    {item.selectedDocumentSignedDate && (
                                                        <>
                                                            <Typography> {getFieldProperties(constants.DOC_SIGNED_DATE_LBL_MAPPING_ID)?.element?.fieldName} : {item.selectedDocumentSignedDate}</Typography>
                                                        </>
                                                    )}

                                                </>
                                            )}

                                        </Stack>
                                    </TableCell>
                                    <TableCell className={`${classes.tblBodyCell} ${classes.uploadCell}`}>
                                        <Stack>
                                            <Typography>{item.docDetailsUploadedByUsername}</Typography>
                                            <Typography>{item.docDetailsDocumentDecisionDate}</Typography>
                                            <Typography>{moment(item.docDetailsDocumentDecisionTime, constants.ALLOWED_TIME_FORMATES[3]).format(constants.COMMON_TIME_FORMAT)}</Typography>
                                        </Stack>
                                    </TableCell>
                                    {displayColumn(constants.FAX_DETAILS_MAPPING_ID) && (
                                        <TableCell className={`${classes.tblBodyCell} ${classes.faxCell}`}>
                                            <Stack>
                                                <FaxStatus details={item} />
                                                <Typography>{item.docDetailsFaxedByUsername}</Typography>
                                                <Typography>{item.docDetailsFaxedDate}</Typography>
                                                <Typography>{moment(item.docDetailsFaxedTime, constants.ALLOWED_TIME_FORMATES[3]).format(constants.COMMON_TIME_FORMAT)}</Typography>
                                                <ActiveLinks mappingId={constants.RETRYFAX_BTN_MAPPING_ID} label={constants.RETRYFAX_BTN_LBL} documentId={item.docDetailsDocumentId} />
                                            </Stack>
                                        </TableCell>
                                    )}
                                    <TableCell className={`${classes.tblBodyCell} ${classes.statusCell}`}>
                                        {item.docDetailsDocumentStatus == constants.DOC_STATUS_PENDING_REVIEW ? (
                                            <>
                                                <Typography>{item.docDetailsDocumentStatus}</Typography>
                                                <Stack direction="row" spacing={1}>
                                                    <ActiveLinks mappingId={constants.APPROVE_BTN_MAPPING_ID} label={constants.ACCEPT_BTN_LBL} documentId={item.docDetailsDocumentId} />
                                                    <ActiveLinks mappingId={constants.REJECT_BTN_MAPPING_ID} label={constants.REJECT_BTN_LBL} documentId={item.docDetailsDocumentId} />
                                                </Stack>
                                            </>
                                        ) : <DocumentStatus details={item} />}

                                    </TableCell>
                                    <TableCell className={`${classes.tblBodyCell} ${classes.actionCell}`}>
                                        <Stack direction="row">
                                            <ActiveButtons
                                                additionalProperties={viewBtnProperties}
                                                mappingId={constants.VIEW_BTN_MAPPING_ID}
                                                documentId={item.docDetailsDocumentId}
                                            />
                                            <ActiveButtons
                                                additionalProperties={downloadBtnProperties}
                                                mappingId={constants.DOWNLOAD_BTN_MAPPING_ID}
                                                documentId={item.docDetailsDocumentId}
                                            />
                                            <ActiveButtons
                                                additionalProperties={deleteBtnProperties}
                                                mappingId={constants.DELETE_BTN_MAPPING_ID}
                                                documentId={item.docDetailsDocumentId}
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>)) : (
                                <TableRow>
                                    <TableCell colSpan="6" align="center">
                                        <Typography>{constants.LBL_RECORDS_NOT_FOUND}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    )
}