import React from 'react'
import Controls from './Controls'
import { Divider } from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
    chipContainer: {
        marginTop: theme.spacing(1)
    }
}))

function ChipHolder(props) {
    const classes = useStyles();
    const fieldProps = props.additionalProperties;
    const chipDeleteButtonRuleResult = checkChipDeleteButtonRule(fieldProps);
    const rule = fieldProps.DisplayFieldNameAndFieldValue === false ? false : true;
    return (
        <div>
            <Controls.Typography>
                {rule && props.label}
            </Controls.Typography>
            <Divider />
            <div className={classes.chipContainer}>
                {props.value ? props.value.map((chip, index) => (
                    <Controls.Chip
                        label={chip[props.additionalProperties.DisplayColumnName]}
                        id={index}
                        key={index}
                        disabled={props.disabled || ((chipDeleteButtonRuleResult && chip[fieldProps.DisableDelete.FieldToCheckValue] === true) ? true : false)}
                        onDelete={props.onDelete}
                        onClick={props.onClick}
                        variant='outlined'
                    />)) : null}
            </div>
        </div>
    )
}

const checkChipDeleteButtonRule = (fieldProps) => {
    return fieldProps.DisableDelete && fieldProps.DisableDelete.ControlDelete === true && fieldProps.DisableDelete.FieldToCheckValue;
}

export default ChipHolder
