import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getLoadingStatus, getItemsPerPage, getPageNumber, getTotalCases, getSortData, getTableHeaderConfig } from '../../reducers/ReportsReducer';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import { Link } from 'react-router-dom';
import { updatePageNumber, updateItemPerpageNumber, UpdateFieldSorting, SearchByFilters, setSearchFiltersData, resetPaginationState } from '../../actions/ReportsAction';
import * as Constants from '../../constants/GlobalConstants';
import TableSortLabel from '@mui/material/TableSortLabel';
import FilterControls from '../common/FilterControls';
import * as Colors from '../../res/values/Colors';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import TablePaginationExtended from './TablePaginationExtended';


const useStyles = makeStyles(theme => ({
    dataTableContainer: {
        maxHeight: `calc(100vh - ${theme.spacing(39)})`
    },
    dataTableHeader: {
        backgroundColor: Colors.CAT_TABLE_HEADER
    },
    sortLabelRoot: {
        color: `${theme.palette.common.white} !important`, // Customize root color
        '&.MuiTableSortLabel-active': {
            color: `${theme.palette.common.white} !important`, // Active state color
        },
    },
    sortLabelIcon: {
        color: `${theme.palette.common.white} !important`, // Customize icon color
        '&.MuiTableSortLabel-active': {
            color: `${theme.palette.common.white} !important`, // Active state color for icon
        },
    },
}))

const TableCellHead = withStyles((theme) => ({
    root: {
        backgroundColor: Colors.CAT_TABLE_HEADER,
        color: theme.palette.common.white,
        minWidth: 80
    },
}))(TableCell);

export default function PendingConfirmationNumberDataTable(props) {
    const { data } = props;
    const dispatch = useDispatch();
    const loading = useSelector(getLoadingStatus);
    const pageNumber = useSelector(getPageNumber);
    const itemsPerPage = useSelector(getItemsPerPage);
    const totalCases = useSelector(getTotalCases);
    const tableHeader = useSelector(getTableHeaderConfig);
    const headCells = tableHeader?.headers;
    const classes = useStyles()

    //initialize sorting
    var sortRequestData = useSelector(getSortData);
    var sortRequestDataOrder = null
    var sortRequestDataOrderBy = null
    sortRequestData && sortRequestData.forEach(element => {
        sortRequestDataOrder = element ? element.Order : null;
        sortRequestDataOrderBy = element ? element.Field : null;
    });

    var sortOrder = sortRequestDataOrder ? sortRequestDataOrder : Constants.DEFAULT_ORDER;
    var sortOrderBy = sortRequestDataOrderBy ? sortRequestDataOrderBy : Constants.DEFAULT_ORDER_BY;
    var columnsToHideSortIcon = Constants.COLUMNS_TO_HIDE_SORT_ICON;

    const [order, setOrder] = React.useState(sortOrder);
    const [orderBy, setOrderBy] = React.useState(sortOrderBy);
    const [columnsToHideSort, setColumnsToHideSort] = React.useState(columnsToHideSortIcon);
    React.useEffect(() => {
        setOrder(sortOrder);
        setOrderBy(sortOrderBy);
        setColumnsToHideSort(columnsToHideSortIcon);

    }, [sortOrderBy, columnsToHideSort]);

    const sortByColumnID = (e, newOrderBy) => {
        if (columnsToHideSort.includes(newOrderBy)) {
            e.preventDefault();
            return
        }
        const isAsc = orderBy === newOrderBy && order === 'asc';
        const toggledOrder = isAsc ? 'desc' : 'asc';
        setOrder(toggledOrder);
        setOrderBy(newOrderBy);
        var sortData = [];
        var sortItem = { 'Field': newOrderBy, 'Order': toggledOrder };
        sortData.push(sortItem);
        dispatch(UpdateFieldSorting(sortData));
        dispatch(updatePageNumber(0));
    }
    const ApplyFilter = () => {
        dispatch(resetPaginationState());
        dispatch(SearchByFilters());
    }
    const ResetFilter = () => {
        dispatch(resetPaginationState());
        dispatch(setSearchFiltersData([]));
        dispatch(SearchByFilters());
    }

    const RenderTableBody = (props) => {
        const { row } = props;
        return (
            <>
                <TableRow hover   >
                    <TableCell component="tb" scope="row" align="center">
                        {row.PatientName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.PrimarySurgeonName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.HospitalName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureUnitName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        <Link to={`case/${parseInt(row.CaseId)}/PatientInformation`}>
                            {row.CaseId}
                        </Link>
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureDate.slice(0, 10)}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureTime}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.AdmissionTypeName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.CaseStatus}
                    </TableCell>
                    <TableCell component="tb" scope="row" style={{ wordBreak: "break-all" }}>
                        {row.SchedulingCommentsForHospitalUseOnly}
                    </TableCell>
                </TableRow>
            </>
        );
    }
    return (
        <>
            <FilterControls
                filterMd={3}
                filterLg={2.5}
                loading={loading}
                displayFilters={Constants.SCHEDULING_REPORT_FILTER_CONTROLS}
                applyLabel={Constants.APPLY_LABEL}
                resetLabel={Constants.RESET_LABEL}
                applyFilter={(e) => ApplyFilter()}
                resetFilter={(e) => ResetFilter()}
            />
            <Paper elevation={2} sx={{ width: '100%', overflow: 'hidden', marginBottom: 2 }}>
                <TableContainer className={classes.dataTableContainer}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead className={classes.dataTableHeader}>
                            <TableRow>
                                {headCells && headCells.map((headCell) => (
                                    <TableCellHead align='center' >
                                        <TableSortLabel
                                            active={orderBy === headCell.Alias}
                                            direction={orderBy === headCell.Alias ? order : 'asc'}
                                            onClick={(e, value) => sortByColumnID(e, headCell.Alias)}
                                            hideSortIcon={columnsToHideSort.includes(headCell.Alias) ? true : false}
                                            style={{
                                                cursor: columnsToHideSort.includes(headCell.Alias) ? 'default' : 'pointer',
                                                pointerEvents: columnsToHideSort.includes(headCell.Alias) ? 'none' : 'auto'
                                            }}
                                            classes={{
                                                root: classes.sortLabelRoot,
                                                icon: classes.sortLabelIcon
                                            }}
                                        >
                                            {headCell.ColumnName}
                                        </TableSortLabel>
                                    </TableCellHead>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ?
                                <TableRow>
                                    <TableCell component="th" scope="row" colSpan="10" >
                                        <Loader loading={loading} />
                                    </TableCell>
                                </TableRow>
                                : ((data && data.length !== 0) ? data
                                    .map((row, i) => (<RenderTableBody row={row} />)) :
                                    (<TableRow>
                                        <TableCell component="th" scope="row" colSpan="10" >
                                            {Constants.LBL_NO_CASE_FOUND}
                                        </TableCell>
                                    </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePaginationExtended
                    totalCases={totalCases}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                />
            </Paper>
        </>
    );
}