import {EQUAL_TO,NOT_EQUAL_TO,GREATER_THAN,LESS_THAN} from '../constants/GlobalConstants';
export const calculateValue = (param1, param2, operator) => {
        switch (operator) {
            case "+":
                return param1 + param2;
            case "-":
                return param1 - param2;
            case "*":
                return param1 * param2;
            case "/":
                return param1 / param2;
            case GREATER_THAN:
                return param1 > param2;
            case LESS_THAN:
                return param1 < param2;
            case EQUAL_TO:
                return param1 == param2;
            case NOT_EQUAL_TO:
                return param1 != param2;        
    }
}