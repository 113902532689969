import React from "react";
import "./App.css";
import "./index.css";
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material";
import Authentication from "./hoc/Authentication";
import { Route, Switch } from "react-router-dom";
import Home from "./components/common/Home";
import Case from "./components/case/Case";
import Dashboard from "./components/dashboard/Dashboard";
import UserProfile from "./components/user/UserProfile";
import Reports from "./components/reports/Reports";
import DataManagementTabs from "./components/dataupload/DataManagementTabs";


const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiAppBar: {
      root: {
        transform: "translateZ(0)",
      },
    },
  },
}));

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Authentication>
            <Switch>
              <Home>
                <Route exact path="/case/:caseId/:sectionName?" component={Case}></Route>
                <Route exact path="/case" component={Case}></Route>
                <Route exact path="/dashboard" component={Dashboard}></Route>
                <Route exact path="/" component={Dashboard}></Route>
                <Route exact path="/userprofile" component={UserProfile}></Route>
                <Route exact path="/reports" component={Reports}></Route>
                <Route exact path="/reports/:caseStatusid" component={Reports}></Route>
                <Route exact path="/datamanagement" component={DataManagementTabs}></Route>              
              </Home>
            </Switch>
        </Authentication>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
