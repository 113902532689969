import { format } from 'date-fns';
import moment from 'moment';
import {
    TIME, ALLOWED_TIME_FORMATES, VALID_TIME_CHECK, ERR_INVALID_TIME_FORMAT
} from "../../../constants/GlobalConstants";
var response = null;


export const ValidTimeCheck = (control) => {
    if (control) {
        if (control.fieldTypeName == TIME) {
            var fieldValue = control.value ? control.value : null;
            if (control && control.validation) {
                var validation = control.validation
                var ValidTimeCheck = validation[VALID_TIME_CHECK] ? validation[VALID_TIME_CHECK] : null;

                var response = null;
                if (ValidTimeCheck && ValidTimeCheck.Format) {  //["hh:mm a", "HH:mm:ss"] 
                   
                    var timeToValidate = moment(fieldValue, ValidTimeCheck.Format, true).isValid();
                    if (timeToValidate != Boolean(ValidTimeCheck.ExpectedResponse)) {
                        response = {
                            status: false,
                            isButtonDisable: false,
                            message: ' '//ERR_INVALID_TIME_FORMAT
                        };

                    }

                    return response;
                }
            }
        }
    }
}