import {
  CONTROL_SELECTION_RELOAD_SUCCESS, COPY_REFERENCE_DATA, RELOAD_AND_COPY_REFERENCE_DATA,POPUP_CONTROL_SELECTION_UPDATE_SUCCESS
} from '../constants/ActionTypes';
import caseDetailsService from '../services/CaseDetailsService';
import { store } from '../store';
import { getFieldById } from '../utils/GetFieldById';
import GetSelectionValueByCondition from '../utils/GetSelectionValueByCondition';

export function controlSelectionUpdateSuccess(selections) {
  return {
    type: CONTROL_SELECTION_RELOAD_SUCCESS,
    payload: {
      selections,
    },
  };
}


export const copyRefData = (mappingId, referenceDataId) => {
  const copyReferenceData = (control) => {
    return {
      type: COPY_REFERENCE_DATA,
      payload: control
    }
  }
  return async function (dispatch, getState) {
    const state = getState()
    var sectionDetails = state.caseDetails.sectionDetails
    if (sectionDetails) {
      var replacedControl = sectionDetails
      var updateSelection =[];
      var selectionData=store.getState().caseDetails.selection
      replacedControl.sectionGroup.forEach(x => x.fields.filter(element => element.mappingId == mappingId)
        .forEach(element => {
          if(element.additionalProperties.LoadReferenceDataByFilter){
            let fielAdditionaldPrpps=element.additionalProperties.LoadReferenceDataByFilter;
            let filterdRefData=  GetSelectionValueByCondition.getSelectedRefData(fielAdditionaldPrpps,selectionData);                            
           if(filterdRefData)
           { 
            updateSelection["selections"] ={...selectionData,...filterdRefData};
              element["selections"] = filterdRefData[referenceDataId];        
           }            
          } else{            
          element["selections"] = store.getState().caseDetails.selection[referenceDataId]
        }
        }))
      dispatch(copyReferenceData(replacedControl))    
      updateSelection.selections && dispatch(dispatchSelectionControl(updateSelection));
    }
  }
}

export function dispatchSelectionControl(data) {
  return {
    type: POPUP_CONTROL_SELECTION_UPDATE_SUCCESS,
    payload: data,
  };
}

export const reloadAndCopyReferenceData = (mappingId, referenceDataId, refDataDependentFieldVal) => {
  return async function (dispatch, getState) {
    let controlArr = []
    controlArr.push(getFieldById(mappingId))
    try {
      var requestObject = {};
      var requestArray = [];
      var isReferenceDataNeeded = false;
      controlArr.forEach(element => {
        let dependentFields = element.additionalProperties.DependentFields;
        var obj = {}
        dependentFields.forEach(field => {
          if (refDataDependentFieldVal[field]) {
            obj[field] =  refDataDependentFieldVal[field] + ""
          }
          else {
            obj[field] =  null;
          }
        });
        if (obj != null) {
          isReferenceDataNeeded = true;
        }
        requestObject = {
          "Id": referenceDataId,
          "additionalData": obj
        }
        requestArray.push(requestObject);
      });
      if (isReferenceDataNeeded) {
        caseDetailsService.getSelectionForControls(requestArray)
          .then(response => {
            dispatch(
              controlSelectionUpdateSuccess(response),
            )
            copyRefData(mappingId, referenceDataId)
          })
      }
    } catch (err) {
      console.log(mappingId+err)
    }
  }
}

