import { store } from '../../../store';
import { clearSection } from '../../../actions/CaseDetailsAction';

const execute = (props) => {
    let additionalProperties = props.additionalProperties;
    const state = store.getState()
    if (props.value) {
        if (additionalProperties.OnChangeSectionDataToClear) {
            additionalProperties.OnChangeSectionDataToClear.map(rule => {
                let sectionId = rule;
                 var keys = Object.keys(sectionId)
                 var values = Object.values(sectionId)
                     store.dispatch(clearSection(values));
            })
        }
    }
}

const OnChangeSectionDataToClear = {
    execute
}

export default OnChangeSectionDataToClear