import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Typography
} from '@mui/material';
import { Form } from '../common/useForm'
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import SectionGroupComponentDialogBoxUploadDoc from './SectionGroupComponentDialogBoxUploadDoc';
import { BoldTextByTagName } from '../../utils/CommonFunctions';
import sanitize from 'sanitize-html';
import CloseIcon from '@mui/icons-material/Close';
import OnClickClosePopup from '../../utils/ruleEngine/AdditionPropertyRules/OnClickClosePopup';
import { Box } from '@mui/system';
import { LBL_VIEW_DOCUMENT } from '../../constants/GlobalConstants';
import UploadDocumentMockJSONData from '../../Json/UploadDocumentMockJSONData.json'
import Controls from '../controls/Controls'
import * as Colors from '../../res/values/Colors';
import * as Constant from '../../constants/GlobalConstants';

const useStyles = makeStyles(theme => ({
  dialogActionContainer: {
    'justify-content': 'flex-start',
    'margin-left': '14px'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  closeIcon: {
    marginRight: '14px'
  },
  redFont: {
    color: Colors.ICON_RED
  },
}))

function mapStateToProps(state) {
  return {
    popupDialogContent: state?.caseDetails?.docUploadPopUpDetails,
    fullScreen: state?.caseDetails?.fullScreenBl
  }
}

function DocumentActionDialog(props) {
  const { open = false, section, dialogTitle, dialogContentText, maxWidth, isShowOkButton = false, isShowCancelButton = false,
    onCloseDialog, onConfirmDialog, confirmButtonText, confirmButtonColor, fullScreen, mappingId, actionTitle, inputVal, setInputVal } = props;
  const classes = useStyles()

  const getFieldProperties = (mappingId) => {
    const fieldInfo = {}
    if(props.popupDialogContent && props.popupDialogContent.sectionHeader.sectionName == Constant.REJECT_DOC_SECTION_NAME) {
      const elementDetails = props.popupDialogContent.sectionHeader.sectionGroup[0].fields.find(item => item.mappingId == mappingId)
      const additionalProperties = JSON.parse(elementDetails.additionalProperties)
      fieldInfo.element = elementDetails
      fieldInfo.additionalProperties = additionalProperties
    }
    return fieldInfo
  }

  const getDialogContent = (mappingId) => {
    const contentDetails = getFieldProperties(Constant.DOC_REJECTION_MAPPING_ID)

    switch(mappingId) {
      case Constant.REJECT_BTN_MAPPING_ID:
        return (
          <Controls.Input
            name={contentDetails?.element?.fieldName}
            label={contentDetails?.element?.fieldName}
            value={inputVal}
            isRequired={true}
            disabled={false}
            maxLength={contentDetails?.additionalProperties?.MaxLength}
            onChange={(e) => setInputVal(e.target.value)}
          />
        )
      case Constant.DELETE_BTN_MAPPING_ID:
        return <Typography className={classes.redFont}>{Constant.DELETE_CONFIRM_MSG}</Typography>
    }
  }

  return (
    <>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle className={classes.title}>{dialogTitle}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText dangerouslySetInnerHTML={{ __html: dialogContentText ? sanitize(dialogContentText) : "" }} />
          {getDialogContent(mappingId)}
        </DialogContent>
        <DialogActions className={classes.dialogActionContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onConfirmDialog()}
                  disabled={mappingId == Constant.REJECT_BTN_MAPPING_ID && inputVal == ''}
                >
                  {actionTitle.buttonTextOk}
                </Button>
              <Button
                variant="contained"
                color={confirmButtonColor || "primary"}
                onClick={() => onCloseDialog()}
              >
                {actionTitle.buttonTextCancel}
              </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps)(DocumentActionDialog)