import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import dashboardService from '../../services/DashboardService';
import { Box, Checkbox, Collapse, IconButton, Typography, } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogBox from './DialogBox';
import AcknowledgementDialogBox from './AcknowledgementDialogBox';
import { getLoadingStatus, getItemsPerPage, getPageNumber, getTotalCases, getSaveAcknowledgementLoadingStatus, getAlreadyAcknowledged } from '../../reducers/ReportsReducer';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import { SaveAcknowledgeEmr, getCATAmendmentsData, getReportsCases } from '../../actions/ReportsAction';
import { Link } from 'react-router-dom';
import * as Constants from '../../constants/GlobalConstants';
import * as Colors from '../../res/values/Colors';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import TablePaginationExtended from './TablePaginationExtended';

const useStyles = makeStyles(theme => ({
    loader: {
        margin: 0
    },
    innerTableContainer: {
        maxHeight: '300px',
        overflow: 'auto'
    },
    innerTableCell: {
        backgroundColor: Colors.AMENDMENTS_LIST_HEADER,
        minWidth: '300px'
    },
    innerTableHeader: {
        backgroundColor: Colors.AMENDMENTS_LIST_HEADER,
        position: 'relative',
        zIndex: 0
    },
    catTableContainer: {
        maxHeight: `calc(100vh - ${theme.spacing(32)})`
    },
    catTableHeader: {
        backgroundColor: Colors.CAT_TABLE_HEADER
    },
}))

const TableCellHead = withStyles((theme) => ({
    root: {
        backgroundColor: Colors.CAT_TABLE_HEADER,
        color: theme.palette.common.white,
        minWidth: 80
    },
}))(TableCell);

export default function CaseAmendmentTrackerDataTable(props) {
    const { data } = props;
    const [userActionAcknowledgeEmr, setUserActionAcknowledgeEmr] = React.useState('');
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [acknowledgeEmrIds, setAcknowledgeEmrIds] = React.useState({});
    const loading = useSelector(getLoadingStatus)
    const saveAcknowledgementLoading = useSelector(getSaveAcknowledgementLoadingStatus)
    const pageNumber = useSelector(getPageNumber)
    const itemsPerPage = useSelector(getItemsPerPage)
    const totalCases = useSelector(getTotalCases)
    const alreadyAcknowledged = useSelector(getAlreadyAcknowledged)
    const [checked, setChecked] = React.useState(-1);
    const dispatch = useDispatch()
    const classes = useStyles()

    React.useEffect(() => {
        dashboardService.getDashboardFilters()
            .then((response) => {
                const useraction = response.responseData.userActions
                useraction.forEach((element) => {
                    if (element.userActionName == Constants.LBL_ACKNOWLEDGE_EHR) {
                        setUserActionAcknowledgeEmr(element.allowedActionBl)
                    }
                })
            })
    }, [])

    // Table handler
    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setChecked(-1)
    };

    const handleYes = () => {
        dispatch(SaveAcknowledgeEmr(acknowledgeEmrIds))?.then(handleClose)
    };

    // This `addLineBreak` function is used to update the string with a line break if it contains `||`
    const addLineBreak = (str) => {
        let value = str;
        if ((str && str.includes('||'))) {
            value = str.split('||').map((item, index) => (
                <React.Fragment key={index}>
                    {item}
                    <br />
                </React.Fragment>
            ))
        }

        return value;
    }

    const getCaseReport = () => {
        dispatch(getReportsCases(Constants.CaseAmendmentTracker))
    }

    const openDialog = (
        <DialogBox
            open={dialogOpen}
            handleYes={handleYes}
            handleClose={handleClose}
            loading={saveAcknowledgementLoading}
        />
    )

    const acknowledgeDialog = (
        <AcknowledgementDialogBox
            open={alreadyAcknowledged}
            handleClose={getCaseReport}
        />
    )

    // `RenderAmendmentListsInfo` is used to render the amendments data upon caret icon click
    const RenderAmendmentListsInfo = (props) => {
        const { row } = props;
        return (
            <>
                <Box sx={{ margin: 3 }}>
                    <Stack direction="row" spacing={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong> {Constants.LBL_AMENDED_BY} </strong> {row.CreatedBy}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>{Constants.LBL_AMENDED_DATE_AND_TIME} </strong> {moment(new Date(row.CreatedOn)).format(Constants.DOCUMENT_DATE_FORMAT)}
                        </Typography>
                    </Stack>
                </Box>
                <Box sx={{ margin: 3 }}>
                    <TableContainer
                        component={Paper}
                        className={classes.innerTableContainer}
                    >
                        <Table size="small" stickyHeader>
                            <TableHead className={classes.innerTableHeader}>
                                <TableRow>
                                    <TableCell className={classes.innerTableCell}>
                                        {Constants.LBL_FIELD_AMENDED}
                                    </TableCell>
                                    <TableCell className={classes.innerTableCell}>
                                        {Constants.LBL_OLD_VALUE}
                                    </TableCell>
                                    <TableCell className={classes.innerTableCell}>
                                        {Constants.LBL_NEW_VALUE}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.amendmentsInfo.length > 0 && row.amendmentsInfo.map(info => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {info.fieldName}
                                        </TableCell>
                                        <TableCell>{addLineBreak(info.oldValue)}</TableCell>
                                        <TableCell>{addLineBreak(info.newValue)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </>
        )
    }

    const RenderTableBody = (props) => {
        const { row, index } = props;

        const handleChange = (e) => {
            setChecked(checked < 0 ? index : -1)
            const acknowledgeEmrParams = {
                caseId: row.CaseId,
                sectionId: row.SectionId,
                txnId: row.TxnId
            };
            setAcknowledgeEmrIds(acknowledgeEmrParams);
            handleClickOpen()
        }

        const handleGetAmendmentsByIds = (CaseId, SectionId, TxnId, Open) => {
            dispatch(getCATAmendmentsData(CaseId, SectionId, TxnId, Open))
        }

        return (
            <>
                <TableRow hover   >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleGetAmendmentsByIds(row.CaseId, row.SectionId, row.TxnId, row.open)}
                        >
                            {row.open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </TableCell>
                    {
                        (userActionAcknowledgeEmr) &&
                        <TableCell align="center"  >
                            {row.AllowedEHRAcknowledgementBl && <Checkbox
                                color="primary"
                                checked={checked === index}
                                onChange={handleChange}
                            />}
                        </TableCell>
                    }

                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureDate.slice(0, 10)}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        <Link to={`case/${parseInt(row.CaseId)}/PatientInformation`}>
                            {row.CaseId}
                        </Link>
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.PatientName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.SurgeonName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.HospitalName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureUnitName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.HospitalMRN}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.SectionName}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: 0 }} colSpan={12}>
                        <Collapse in={row.open} timeout="auto" unmountOnExit>
                            {row.amendmentsLoading ? (
                                <Box className={classes.loader}>
                                    <LinearProgress />
                                </Box>
                            ) : (
                                <>
                                    {row.amendmentsInfo.length > 0 && <RenderAmendmentListsInfo row={row} />}
                                </>
                            )}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <>
            {openDialog}
            {acknowledgeDialog}
            <Paper elevation={2} sx={{ width: '100%', overflow: 'hidden', marginBottom: 2 }}>
                <TableContainer className={classes.catTableContainer}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead className={classes.catTableHeader}>
                            <TableRow>
                                <TableCellHead align='center' sx={{ minWidth: '30px !important' }}></TableCellHead>
                                {userActionAcknowledgeEmr && <TableCellHead align='center'>{Constants.LBL_ACKNOWLEDGEMENT_IN_EMR}</TableCellHead>}
                                <TableCellHead align='center'>{Constants.LBL_PROCEDURE_DATE}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_CASE_ID}</TableCellHead>
                                <TableCellHead align='center' sx={{ minWidth: '150px !important' }}>{Constants.LBL_PATIENT_NAME}</TableCellHead>
                                <TableCellHead align='center' sx={{ minWidth: '150px !important' }}>{Constants.LBL_SURGEON}</TableCellHead>
                                <TableCellHead align='center' sx={{ minWidth: '150px !important' }}>{Constants.LBL_HOSPITAL_NAME}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_PROCEDURE_UNIT}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_MRN_HOSPITAL}</TableCellHead>
                                <TableCellHead align='center'>{Constants.LBL_PAGE_AMENDED}</TableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ?
                                <TableRow>
                                    <TableCell component="th" scope="row" colSpan="9" >
                                        <Loader loading={loading} />
                                    </TableCell>
                                </TableRow>
                                : ((data.length !== 0) ? data.map((row, i) => (<RenderTableBody row={row} index={i} key={row.TxnId, row.sectionId} />)) :
                                    (<TableRow>
                                        <TableCell component="th" scope="row" colSpan="10" >
                                            {Constants.LBL_NO_CASE_FOUND}
                                        </TableCell>
                                    </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePaginationExtended
                    totalCases={totalCases}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                />
            </Paper>
        </>
    );
}
