import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import { formatTime } from '../../utils/fieldProperties/DateTime';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  }
}))

const setCharAt = (str, index, chr) => {
  if (index > str.length - 1) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
};

const getFormatChars = () => {
  return {
    S: '[1|0]',
    A: '[0-5]',
    '9': '[0-9]',
    T: /[a|p]/i,
  };
};

const beforeMaskedValueChange = (newState, oldState, userInput) => {
  var { value } = newState;
  var selection = newState.selection;
  var cursorPosition = selection ? selection.start : null;

  if (userInput > 2 && cursorPosition === 3 && value[0] === '1') {
    value = setCharAt(value, 1, '2');
  }

  if (userInput == 1 && cursorPosition === 1 && value[1] > 2) {
    value = setCharAt(value, 1, '0');
  }

  if (userInput == 0 && cursorPosition === 3 && value[0] === '0') {
    value = setCharAt(value, 1, '1');
  }

  if (userInput == 0 && cursorPosition === 1 && value[1] === '0') {
    value = setCharAt(value, 1, '1');
  }

  return {
    value,
    selection,
  };
};

export default function TimePicker(props) {

  const { name, label, value, format, margin, id, variant, disabled, isRequired, helperText, onChange,error = false, ...other } = props
  const classes = useStyles()

  return (
    <>
      <InputMask
        mask="S9:A9 TM"
        defaultValue={formatTime(value)}
        formatChars={getFormatChars()}
        beforeMaskedValueChange={beforeMaskedValueChange}
        alwaysShowMask={'true'}
        disabled={disabled}
        onChange={onChange}
      >
        {() => (
          <TextField
            format={format}
            margin={margin}
            id={name}
            label={label}
            variant={variant}
            className={classes.root}
            error={error}
            helperText = {error && helperText}
            inputProps={{ style: { textTransform: 'capitalize' } }}
            disabled={disabled}
           // {...other}
          />
        )}
      </InputMask>
    </>
  )
}
