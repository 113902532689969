import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import Loader from '../common/Loader';

const DialogBox = (props) => {

    const { open, handleYes, handleClose, loading } = props;
    return(
        <>

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle >
                    "Acknowledgement in EMR?"
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Are you sure you want to acknowledge this case amendment? Click Yes to confirm the acknowledgement, click No to return to tracker filter
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={handleClose} loading={loading}>No</LoadingButton>
                    <LoadingButton onClick={handleYes} loading={loading}>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogBox
