import {store} from '../../store';
import {  
    TEXT,
    NUMBER,
    TEXT_ONLY,
    NUMBERS_ONLY,
    MAX_LENGTH_KEY,
    MIN_LENGTH_KEY,
    TEXT_FORMAT,
    CONFIGURE_MAX_LENGTH_BY_FIELD_VALUE,
    CONFIGURE_MAX_LENGTH_NOT_EQUALTO_FIELD_VALUE,
    DEPENDENT_FIELD_ID,
    DEPENDENT_FIELD_VALUE} from '../../constants/GlobalConstants';

import {getFieldValueById} from  '../GetFieldValueByID';

export const checkLength = (control) => {
    try {
        var ResultData = allowedMaxMinLength(control);     
    return ResultData ? ResultData : null;
      } catch (error) {        
        //console.log(control);
      }    
};

export const allowedMaxMinLength = (control) => {
    var inputFieldMaxLength = null;
    if (control && control.additionalProperties) {
        var additionalProperties= control.additionalProperties
        var checkDependentFields = additionalProperties[CONFIGURE_MAX_LENGTH_BY_FIELD_VALUE] ? additionalProperties[CONFIGURE_MAX_LENGTH_BY_FIELD_VALUE] : null;
        var checkDependentFieldsNotEqualTo = additionalProperties[CONFIGURE_MAX_LENGTH_NOT_EQUALTO_FIELD_VALUE] ? additionalProperties[CONFIGURE_MAX_LENGTH_NOT_EQUALTO_FIELD_VALUE]: null;
      
        if (checkDependentFields != null && checkDependentFieldsNotEqualTo != null) {
           
            checkDependentFields.forEach(checkDependent => {
            
            var dependentFieldID = checkDependent[DEPENDENT_FIELD_ID] ? checkDependent[DEPENDENT_FIELD_ID] : null;
            var checkDependentFieldValue = checkDependent[DEPENDENT_FIELD_VALUE] ? checkDependent[DEPENDENT_FIELD_VALUE] : null;
            var  providedMaxLength = checkDependent[MAX_LENGTH_KEY] ? checkDependent[MAX_LENGTH_KEY] : null;
            var  textFormat = checkDependent[TEXT_FORMAT] ? checkDependent[TEXT_FORMAT] : TEXT;
            var dependentFieldValue = '';           
            if(dependentFieldID) {
                dependentFieldValue = getFieldValueById(dependentFieldID);
                if (dependentFieldValue == checkDependentFieldValue && providedMaxLength != null) {
                    inputFieldMaxLength=providedMaxLength;
                }
            }
            });
  
           
        checkDependentFieldsNotEqualTo.forEach(checkDependent => {        
        var dependentFieldID = checkDependent[DEPENDENT_FIELD_ID] ? checkDependent[DEPENDENT_FIELD_ID] : null;
        var checkDependentFieldValue = checkDependent[DEPENDENT_FIELD_VALUE] ? checkDependent[DEPENDENT_FIELD_VALUE] : null;
        var providedMaxLength = checkDependent[MAX_LENGTH_KEY] ? checkDependent[MAX_LENGTH_KEY] : null;
        var  textFormat = checkDependent[TEXT_FORMAT] ? checkDependent[TEXT_FORMAT] : TEXT;
        var dependentFieldValue = '';           
        if(dependentFieldID) {
            dependentFieldValue = getFieldValueById(dependentFieldID);
            if (dependentFieldValue != checkDependentFieldValue && providedMaxLength != null) {
                inputFieldMaxLength=providedMaxLength;
            }
        }
        });

        }else {
            inputFieldMaxLength = additionalProperties[MAX_LENGTH_KEY] ? additionalProperties[MAX_LENGTH_KEY] : null;         
        }
    }
    return inputFieldMaxLength;
}