import { getFieldValueById } from "../../../../GetFieldValueByID"
import moment from 'moment';

export const execute = (rule) => {
    var dependentFields = rule.Alerts.filter(item => item.Name == "LegalGuardianFormNotSubmitted");
    const hFProcedureDate = moment(getFieldValueById(dependentFields[0].DependentFields.Field1));
    const patientDOB = moment(getFieldValueById(dependentFields[0].DependentFields.Field2));
    const gaurdianFormAvailable = getFieldValueById(dependentFields[0].DependentFields.Field3);
    var diffDays = hFProcedureDate.diff(patientDOB, 'years');
    if(diffDays != null && gaurdianFormAvailable != null)
    {
        if(diffDays < 18 && gaurdianFormAvailable == 0)
        {
            return true;
        }
    }
    return false
}

const LegalGuardianFormNotSubmitted = {
    execute
}

export default LegalGuardianFormNotSubmitted