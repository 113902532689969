
import {
  CLEAR_DASHBOARD_TO_INITIAL_STATE,
  UPDATE_CALENDAR_START_DATE_END_DATE,
  GET_CASES_SUCCESS,
  GET_CASES_FAIL,
  GET_DASHBOARD_FILTERS_SUCCESS, GET_DASHBOARD_FILTERS_FAIL,
  IS_DASHBOARD_BL,
  UPDATE_DASHBOARD_FILTER_VALUE,
  GET_MONTH_CASES_SUCCESS,
  GET_MONTH_CASES_FAIL,
  TOGGLE_TO_MONTH_VIEW,
  GET_CASES,
  UPDATE_DASHBOARD_CASE_LOADER,
  GET_MONTH_CASES,
  GET_DASHBOARD_PRIMARY_SURGEONS_SUCCESS,
  GET_DASHBOARD_PRIMARY_SURGEONS_FAIL,
  GET_DASHBOARD_SURGICAL_LOCATION_SUCCESS,
  GET_DASHBOARD_SURGICAL_LOCATION_FAIL
} from '../constants/ActionTypes';
import { SEARCH_FILTER_CONTROL_LIST } from '../constants/GlobalConstants';
import dashboardService from '../services/DashboardService';
import { setSearchFiltersData } from './ReportsAction';

export function getDashboardCases(ItemPerPage, PageNumber, startDate, endDate, resetFilter = false, isFilter = false,caseStatusId = null, patientId = null, caseId = null, mrn = null, primarySurgeonId = null, surgicalLocationId = null, patientDOB = null, eventName = null) {
  return async function (dispatch, getState) {
    try {
      dispatch(updateLoaderState(true));
      const state = getState();
      let requestData = null;
      requestData = getFilterRequestData(state, ItemPerPage, PageNumber, startDate, endDate, resetFilter,isFilter, caseStatusId, patientId, caseId, mrn, primarySurgeonId, surgicalLocationId, patientDOB, eventName);
      dashboardService.getCases(requestData)
        .then(response => {
          var result = response ? response.responseData : [];
          dispatch(setSearchFiltersData([]));
          dispatch(getCasesSuccess(result));
          dispatch(updateFilterResponse(SEARCH_FILTER_CONTROL_LIST, result.FilterBy));
        }).catch((err) => {
          dispatch(dashboardSearchBl(true))
          dispatch(getCasesFail(err));
        });
    } catch (err) {
      dispatch(getCasesFail(err));
    }
  };
}

export function getDashboardMonthCases(ItemPerPage, PageNumber, selected_start_date, selected_end_date, resetFilter = false, caseStatusId = null, patientId = null, caseId = null, mrn = null, primarySurgeonId = null, surgicalLocationId = null) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      let requestData = null;
      requestData = getFilterRequestData(state, ItemPerPage, PageNumber, selected_start_date, selected_end_date, resetFilter, caseStatusId, patientId, caseId, mrn, primarySurgeonId, surgicalLocationId);
      dashboardService.getMonthCases(requestData)
        .then(response => {
          var result = response ? response.responseData : [];
          dispatch(getMonthCasesSuccess(result));
          dispatch(updateLoaderState(false));
          dispatch(updateFilterResponse(SEARCH_FILTER_CONTROL_LIST, result.FilterBy));
        });
    } catch (err) {
      dispatch(getCasesFail(err));
    }
  };
}

export function clearDashboardData() {
  return {
    type: CLEAR_DASHBOARD_TO_INITIAL_STATE
  }
}

export function dashboardSearchBl(bl) {
  return {
    type: IS_DASHBOARD_BL,
    payload: bl
  }
}

export function updateDashboardFilter(updatedDashboardFilter) {
  return {
    type: UPDATE_DASHBOARD_FILTER_VALUE,
    payload: updatedDashboardFilter
  }
}

export function updateCalendarStartEndDate(dates) {
  return {
    type: UPDATE_CALENDAR_START_DATE_END_DATE,
    payload: dates,

  }
}

export function getCases(data) {
  return {
    type: GET_CASES,
    payload: data

  };
}

export function updateLoaderState(data) {
  return {
    type: UPDATE_DASHBOARD_CASE_LOADER,
    loading: data

  };
}

export function getCasesSuccess(data) {
  return {
    type: GET_CASES_SUCCESS,
    payload: data,
    loading: false,
  };
}

export function getCasesFail(data) {
  return {
    type: GET_CASES_FAIL,
    payload: data,
    loading: false
  };
}

export function getMonthCases(data) {
  return {
    type: GET_MONTH_CASES,
    payload: data,
    loading: true
  };
}

export function getMonthCasesSuccess(data) {
  return {
    type: GET_MONTH_CASES_SUCCESS,
    payload: data,
    loading: false,
    dashboardToggle: false
  };
}

export function getMonthCasesFail(data) {
  return {
    type: GET_MONTH_CASES_FAIL,
    payload: data,
    loading: false
  };
}

export function toggleToMonthView(data) {
  return {
    type: TOGGLE_TO_MONTH_VIEW,
    payload: data,

  };
}

export function getDashboardFilters() {
  return async function (dispatch, getState) {
    try {

      dashboardService.getDashboardFilters()
        .then(response => {
          var result = response;
          result.responseData.fieldPermissions.map(element => element.value = "")

          dispatch(getDashboardFiltersSuccess(result.responseData));
        }).catch((err) => {
          dispatch(getDashboardFiltersFail(err));
        });

    } catch (err) {
      dispatch(getDashboardFiltersFail(err));
    }
  };
}

export function getDashboardFiltersSuccess(data) {
  return {
    type: GET_DASHBOARD_FILTERS_SUCCESS,
    payload: data,
  };
}

export function getDashboardFiltersFail(data) {
  return {
    type: GET_DASHBOARD_FILTERS_FAIL,
    payload: data,
  };
}

export function getDashboardPrimarySurgeons() {
  return async function (dispatch, getState) {

    dashboardService.getDashboardPrimarySurgeons()
      .then(data => {
        dispatch(getDashboardPrimarySurgeonsSuccess(data))
      }).catch((err) => {
        dispatch(getDashboardPrimarySurgeonsFail(err));
      });
  };
}

export function getDashboardPrimarySurgeonsSuccess(data) {
  return {
    type: GET_DASHBOARD_PRIMARY_SURGEONS_SUCCESS,
    payload: data,
  };
}

export function getDashboardPrimarySurgeonsFail(data) {
  return {
    type: GET_DASHBOARD_PRIMARY_SURGEONS_FAIL,
    payload: data,
  };
}

export function getDashboardSurgicalLocations() {
  return async function (dispatch, getState) {

    dashboardService.getDashboardSurgicalLocations()
      .then(data => {
        dispatch(getDashboardSurgicalLocationsSuccess(data))
      }).catch((err) => {
        dispatch(getDashboardSurgicalLocationsFail(err));
      });
  };
}

export function getDashboardSurgicalLocationsSuccess(data) {
  return {
    type: GET_DASHBOARD_SURGICAL_LOCATION_SUCCESS,
    payload: data,
  };
}

export function getDashboardSurgicalLocationsFail(data) {
  return {
    type: GET_DASHBOARD_SURGICAL_LOCATION_FAIL,
    payload: data,
  };
}

const getFilterRequestData = (
  state,
  ItemPerPage,
  PageNumber,
  startDate,
  endDate,
  resetFilter,
  isFilter,
  caseStatusId = null,
  patientId = null,
  caseId = null,
  mrn = null,
  primarySurgeonId = null,
  surgicalLocationId = null,
  patientDOB = null,
  eventName = null
) => {
  let requestData = {};
  let searchData = state.reports.setFilterData.filter(element => element.Values.length > 0);
  let requestFilter = updateFieldMappings(SEARCH_FILTER_CONTROL_LIST, searchData);
  requestData.ItemPerPage = ItemPerPage;
  requestData.PageNumber = PageNumber;
  requestData.ProcedureStartDate = startDate;
  requestData.ProcedureEndDate = endDate;
  requestData.PatientId = patientId;
  requestData.CaseId = caseId;
  requestData.Mrn = mrn;
  requestData.PrimarySurgeonId = primarySurgeonId;
  requestData.Hospital = surgicalLocationId;
  requestData.CaseStatusId = caseStatusId;
  requestData.PatientDOB = patientDOB;
  requestData.SearchBl = eventName ? true : false;
  if (!eventName) {
    requestData.FilterBy = resetFilter ? [] : isFilter && requestFilter.length > 0 ? requestFilter : null;
  }
  return requestData;
}

const updateFieldMappings = (defaultFilter, getFilterValues) => {
  let filtersValue = JSON.parse(JSON.stringify(defaultFilter));
  getFilterValues.map(element => {
    let itemIndex = filtersValue && filtersValue.findIndex((item) => item.displayId == element.Field);
    if (itemIndex >= 0) {
      element.Field = filtersValue[itemIndex].mappingId;
    }
  });
  return getFilterValues;
}

export function updateFilterResponse(defaultFilter, getFilterValues) {
  return async function (dispatch, getState) {
    let filtersValue = JSON.parse(JSON.stringify(defaultFilter));
    getFilterValues.map(element => {
      let itemIndex = filtersValue && filtersValue.findIndex((item) => item.mappingId == element.Field);
      if (itemIndex >= 0) {
        element.Field = filtersValue[itemIndex].displayId;
      }
    });
    dispatch(setSearchFiltersData(getFilterValues));
  }
}
