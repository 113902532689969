import { store } from '../../../store';
import { getFullAge } from '../../fieldProperties/DateTime';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { validateDate } from '../ValidationRules/DateValidations';
import { NUMBER_ONLY } from '../../../constants/GlobalConstants';

export const execute = (props, value) => {
    let fieldProps = props.additionalProperties;
    if (fieldProps.Format && fieldProps.Format == "PhoneNumberOnly" && value != null && value != "") {
        var numbers = NUMBER_ONLY;
        if (value.match(numbers)) {
             if(fieldProps.MaxLength){
                if(value.length <= parseInt(fieldProps.MaxLength)){
                    return true;
                }
                else{
                    return false;
                }
            } 
            return true;          
        }
        else {
            return false;
        }

    }

    //return true;
}
const FormatProperty = {
    execute
}
export default FormatProperty