import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Error} from '@mui/icons-material';
import { BoldTextByTagName } from '../../utils/CommonFunctions'

const useStyles = makeStyles(theme => ({
    root: {
        'font-size': '1rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': '400',
        'line-height': '1.5',
        'letter-spacing': '0.00938em',
        'width': '100%'
    },
    verifyLabelContent: {
        'background-color': '#EDFAFF',
        'width': 'max-content',       
        'padding': '5px',  
        'font-size': '1rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-style':'italic',
        'align-items': 'center',
        'display': 'flex'
    },
    verifyLabelContentSec: {
        'padding': '4px',  
        'font-size': '0.9rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-style':'italic',
        'align-items': 'center',
        
    },
    iconLabelContentSec: {
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'align-items': 'center',
        'font-size': '1rem',
        'font-weight': '400',
        'line-height': '1.5',
        'letter-spacing': '0.00938em',
        'width': '100%',
        'display': 'flex'
    }
}))

export default function Label(props) {
    const muiColorPalette=	['error', 'info','primary','secondary','success', 'warning', 'string']
    var { fieldId, name, value, disabled, ...other } = props;
    const classes = useStyles()
    var displayVal = value && typeof value == 'object' ? value.name : value
    const fieldProps = props?.additionalProperties;
    const rule = fieldProps?.DisplayFieldNameAndFieldValue;
    const fontColor = fieldProps?.FontColorCode && muiColorPalette.indexOf(fieldProps.FontColorCode)==-1 ? "#" + fieldProps.FontColorCode : fieldProps?.FontColorCode;
    var displayLabelValue = "";
    if (rule && displayVal) {
        displayLabelValue = props.fieldName + ": " + displayVal
    } else {
        displayLabelValue = displayVal;
    }
   

    if (displayLabelValue && displayLabelValue.indexOf('<b>') != -1) {        
       return BoldTextByTagName(displayLabelValue);
    }
   else if (displayLabelValue && displayLabelValue.indexOf('<green_text>') != -1) { 
    const start = `<green_text>`;
    const end = `</green_text>`;
    const startText = displayLabelValue.split(start)[0];
    const middleText = displayLabelValue.split(start)[1].split(end)[0];
    const endText = displayLabelValue.split(end)[1];
    return (
        <p  className={classes.verifyLabelContent}>
            <CheckCircleIcon color={'success'}>
            </CheckCircleIcon>
            <label               
                style={{ color: fontColor || '#121212'}}
                id={fieldId}               
                disabled={disabled}
            >{startText}
            </label>
            <label                             
                style={{ color: '#07bc0c' }}        
                disabled={disabled}
            >{middleText}
            </label>
            <span>&nbsp;</span>
            <label               
                style={{ color: fontColor || '#121212'}}            
                disabled={disabled}
            >{endText}
            </label>

        </p>
    )
    }
   else if (displayLabelValue && displayLabelValue.indexOf('<green_lbl>') != -1) { 
        const start = `<green_lbl>`;
        const end = `</green_lbl>`;
        const startText = displayLabelValue.split(start)[0];
        const middleText = displayLabelValue.split(start)[1].split(end)[0];
        const endText = displayLabelValue.split(end)[1];
        return (
            <div  className={classes.verifyLabelContentSec}>
                <CheckCircleIcon color={'success'} fontSize={'small'}>
                </CheckCircleIcon>
                <label               
                    style={{ color: fontColor || '#121212'}}
                    id={fieldId}               
                    disabled={disabled}
                >{startText}
                </label>
                <label                             
                    style={{ color: '#07bc0c' }}        
                    disabled={disabled}
                >{middleText}
                </label>
                <span>&nbsp;</span>
                <label               
                    style={{ color: fontColor || '#121212'}}            
                    disabled={disabled}
                >{endText}
                </label>    
            </div>
        )
        }
    else if (displayLabelValue && displayLabelValue.indexOf('<error>') != -1) {
        const start = `<error>`;
        const end = `</error>`;
        const startText = displayLabelValue.split(start)[0];
        const middleText = displayLabelValue.split(start)[1].split(end)[0];
        const endText = displayLabelValue.split(end)[1];
        return (
            <div className={classes.iconLabelContentSec}>
                <Error color={'error'}>
                </Error>
                <label
                    style={{ color: fontColor || '#121212',marginLeft:'4px' }}
                    id={fieldId}
                   
                    disabled={disabled}
                >{middleText}
                </label>
            </div>
        )
    }
    else if (displayLabelValue && displayLabelValue.indexOf('<success>') != -1) {
        const start = `<success>`;
        const end = `</success>`;
        const startText = displayLabelValue.split(start)[0];
        const middleText = displayLabelValue.split(start)[1].split(end)[0];
        const endText = displayLabelValue.split(end)[1];
        return (
            <div className={classes.iconLabelContentSec}>
                <CheckCircleIcon color={'success'}>
                </CheckCircleIcon>
                <label
                    style={{ color: fontColor || '#121212',marginLeft:'4px' }}
                    id={fieldId}
                   
                    disabled={disabled}
                >{middleText}
                </label>
            </div>
        )
    }
    else {

        return (
            <>
                <label                  
                   style={{ color: fontColor || '#121212'}} 
                    id={fieldId}
                    className={classes.root}
                    disabled={disabled}
                > {displayLabelValue}
                </label>

            </>
        )
    }
}
