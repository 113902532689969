import React from "react";
import Header from "./Header";
import SideNavbar from "./SideNavbar";

function Home(props) {
  return (
    <div>
      <Header />
      <SideNavbar />
      {props.children}
    </div>
  );
}

export default Home;
