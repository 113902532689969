import { store } from '../store';


export const getControlByFieldId = (FieldID) => {
    const state = store.getState();
    var sectionDetails = state.caseDetails.sectionDetails;
    var field;
    if (sectionDetails) {
        sectionDetails.sectionGroup.forEach(group => {
            group.fields.forEach(control => {
                if (control.fieldId === FieldID) {
                    field = control;
                }
            });
        });
    }
    if (field) {
        return field;
    }    
}