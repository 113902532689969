import * as Constants from "../constants/GlobalConstants";

export function saveAuthToken(authToken, refreshToken, expireTime) {
  sessionStorage.setItem(Constants.KEY_AUTHENTICATION_TOKEN, authToken);
  sessionStorage.setItem(Constants.KEY_REFRESH_TOKEN, refreshToken);
}

export function saveUserInfo(responseData) {
  sessionStorage.setItem(Constants.LOGIN_DISPLAY_NAME, responseData.name);
  sessionStorage.setItem(
    Constants.LOGIN_USER_NAME,
    responseData.preferred_username
  );
  sessionStorage.setItem(Constants.LOGIN_USER_ID, responseData.sub);
}

export function saveTenantKey(responseData) {
  sessionStorage.setItem(Constants.LOGIN_TENANT_KEY, responseData.TenantKey);
}

export function saveTenantIconUrl(responseData) {
  sessionStorage.setItem(Constants.LOGIN_TENANT_IMAGE_URL, responseData.TenantIconUrl);
}


export function saveTenantUserInfo(responseData) {
  sessionStorage.setItem(Constants.LOGIN_DISPLAY_NAME, responseData.FirstName + " " + responseData.LastName);
}

const getAuthToken = () => {
  return sessionStorage.getItem(Constants.KEY_AUTHENTICATION_TOKEN);
};

const getRefreshToken = () => {
  return sessionStorage.getItem(Constants.KEY_REFRESH_TOKEN);
};

const setReturnURL = (returnURL) => {
  return sessionStorage.setItem(Constants.KEY_RETURN_URL, returnURL);
};

const getReturnURL = () => {
  return sessionStorage.getItem(Constants.KEY_RETURN_URL);
};

const getLoginUserDisplayName = () => {
  return sessionStorage.getItem(Constants.LOGIN_DISPLAY_NAME);
};

const getLoginUserID = () => {
  return sessionStorage.getItem(Constants.LOGIN_USER_ID);
};

const getTenantKey = () => {
  return sessionStorage.getItem(Constants.LOGIN_TENANT_KEY) ?? "";
};

const getTenantIconUrl = () => {
  return sessionStorage.getItem(Constants.LOGIN_TENANT_IMAGE_URL) ?? "";
};

const setSectionId = (sectionId) => {
  return sessionStorage.setItem(Constants.CASE_SECTION_ID, sectionId);
};

const getSectionId = () => {
  return sessionStorage.getItem(Constants.CASE_SECTION_ID) ?? "";
};

export function deleteUserInfo() {
  sessionStorage.clear();
}

const LocalStorageProvider = {
  saveAuthToken,
  saveUserInfo,
  getAuthToken,
  getRefreshToken,
  setReturnURL,
  getReturnURL,
  getLoginUserDisplayName,
  getLoginUserID,
  saveTenantKey,
  saveTenantIconUrl,
  getTenantKey,
  getTenantIconUrl,
  deleteUserInfo,
  saveTenantUserInfo,
  setSectionId,
  getSectionId

};

export default LocalStorageProvider;
