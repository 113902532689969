import { store } from '../../../store';
import { updatePropsValue } from '../../../actions/CaseDetailsAction';
import { calculateValue } from '../../CalculateValue';
import { SELECTION, SELECTION_KEY_VALUE, TYPEAHEAD } from '../../../constants/GlobalConstants';

export const execute = (props) => {
    let fieldProps = props.additionalProperties;
    var fieldValue;
    if (props.fieldTypeName === SELECTION || props.fieldTypeName === SELECTION_KEY_VALUE || props.fieldTypeName === TYPEAHEAD) {
        fieldValue = props.value ? (props.value.constructor === Object ?  props.value[props.additionalProperties.DisplayColumnId] : props.value ) : null
    } else {
        fieldValue = control.value
    }
    if (fieldProps.ConfigurePropertiesOfTargetField) {
         if (fieldProps.ConfigurePropertiesOfTargetField.length > 0) {
            fieldProps.ConfigurePropertiesOfTargetField.forEach(ConfigFields => {
                var checkResult = "";
                var fieldValueOperator = ConfigFields.FieldValueOperator;
                var checkByFieldValue = ConfigFields.FieldValue;
                var targetFieldProperties = ConfigFields.TargetFieldProperties;
                checkResult = calculateValue(fieldValue, checkByFieldValue, fieldValueOperator);
                if (checkResult) {
                    var finalData = [];                        
                        var key = Object.keys(targetFieldProperties)
                        var value = Object.values(targetFieldProperties)
                        for(var i=0;i<key.length;i++){
                            var FieldProps = {
                                prop: "",
                                value: ""
                            };
                            FieldProps.prop = key[i];
                            FieldProps.value = value[i];
                            finalData.push(FieldProps);
                        }
                     ;

                    var data = [{
                        mappingId: ConfigFields.TargetFieldId,
                        valueToUpdate: finalData
                    }];
                    store.dispatch(updatePropsValue(data));
                }
            })
        }
    }
}
const ConfigurePropertiesOfTargetField = {
    execute
}
export default ConfigurePropertiesOfTargetField