import { store } from "../store"

const getSelectedValue = (displayId, referenceId, value, selections) => {
    let controlSelections = selections[referenceId]
    return controlSelections ? controlSelections.find(selection => selection[displayId] == value) : null
}

const GetSelectionValue = {
    getSelectedValue
}

export default GetSelectionValue