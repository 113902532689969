import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Snackbar as MUISnackbar } from "@mui/material";
import { closeNotification } from "../../actions/SnackbarAction";
import { Alert, AlertTitle } from '@mui/material';

export default function Snackbar() {
  const dispatch = useDispatch();

  const { isOpen, title, message, severity, time } = useSelector(
    state => state.snackbar
  )

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeNotification());
  }

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={isOpen == true ? true : false}
      autoHideDuration={4000}
      key={time}
      onClose={handleClose}>
      <Alert
        severity={severity}
        onClose={handleClose}>
        <AlertTitle>{title}</AlertTitle>
        {message}</Alert>
    </MUISnackbar>
  );
}