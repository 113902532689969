import { updateFieldPermission } from "../../../actions/CaseDetailsAction";
import { store } from "../../../store";
import { getFieldValueById } from '../../GetFieldValueByID';
import { ERR_INVALID_DATE, COMMON_DATE_FORMAT,DATE } from '../../../constants/GlobalConstants';
import moment from 'moment';

const execute = control => {
    if (control && control.fieldTypeName && control.fieldTypeName == DATE) {
        const rule = control.additionalProperties.DisableTargetFieldIfFieldDateGreaterThanCurrentDate
        var permissionDisabled = rule.IfTrueSetPermissionTypeId;
        var fieldValue = getFieldValueById(control.mappingId);
        if (fieldValue && fieldValue != ERR_INVALID_DATE) {
            // i.e., documentSignedDate = dateToValidate
            const todayDate = moment().format(COMMON_DATE_FORMAT);
            var dateToValidate = moment(fieldValue).format(COMMON_DATE_FORMAT);
            if (dateToValidate && (moment(dateToValidate).isAfter(todayDate) === true)) {
                rule.TargetFieldId.map(fieldId => {
                    store.dispatch(updateFieldPermission(fieldId, permissionDisabled));
                })
            }

        }
    }
}

const DisableTargetFieldIfFieldDateGreaterThanCurrentDate = {
    execute
}

export default DisableTargetFieldIfFieldDateGreaterThanCurrentDate
