
import ValidationFactory from './ValidateFactory'
export const initializeValidationEngine = (control, AddOnLoadONChangeFlag = null, flag = null) => {
  const validations = control.validation
  var result = null;

  if (!control.overrideValidationFlag) {
    if (validations) {
      for (const [key] of Object.entries(validations)) {
        var result = ValidationFactory.getApplicableValidation(key, control, AddOnLoadONChangeFlag)
        if (result) {
          return result;
        }
      }
    } else {
      return null
    }
  }
}

const ValidationEngine = {
  initializeValidationEngine
}

export default ValidationEngine