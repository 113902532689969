import React, { useEffect, useState } from 'react'
import { Grid, Card, CircularProgress, StyledEngineProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { styled, ThemeProvider } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import DoctorImg from '../../res/images/doctor.jpg'
import Logo from '../../res/images/medtel_logo_full.png'
import MedtelBanner from '../../res/images/medtel_banner.png'
import Controls from '../controls/Controls'
import { useForm, Form } from '../common/useForm'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import * as Colors from '../../res/values/Colors'
import * as Dimens from '../../res/values/Dimens'
import AuthActions from '../../actions/AuthActions'
import { useDispatch } from "react-redux";
import { SURGICAL_DASHBOARD, LOGIN, TERMS_AND_CONDITION, FORGOT_PASSWORD, KEY_FAILED, FORGET_PASSWORD, RESET_PASSWORD, SUCCESS, FAILURE } from '../../constants/GlobalConstants';

import { Link } from 'react-router-dom'
import PasswordService from '../../services/PasswordService'
import { notify } from '../../actions/SnackbarAction'

const theme = createTheme();

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width: '100vw',
    },
    bannerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    displayBanner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${MedtelBanner})`
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        width: '70%',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '35%',
        marginLeft: '50%',
        transform: 'translate(-50%, -40%)'
    },
    logo: {
        height: '15%',
        width: '35%'
    },

    muiCard: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: Dimens.BORDER_RADIUS,
        border: `${Dimens.BORDER_WIDTH} black solid`,
        padding: `${Dimens.LOGIN_CARD_PADDING_VERTICAL} ${Dimens.LOGIN_CARD_PADDING_HORIZONTAL}`,
    },
    form: {
        '& .MuiButton-root': {
            marginLeft: '50%',
            marginTop: '0.5em',
            transform: 'translate(-50%,0%)',
            borderRadius: Dimens.BORDER_RADIUS,
            padding: `${Dimens.LOGIN_BUTTON_PADDING_VERTICAL} ${Dimens.LOGIN_BUTTON_PADDING_HORIZONTAL}`,
        },
        '& .MuiButton-label': {
            textTransform: 'uppercase',
        },

        '& .MuiTextField-root': {
            width: '100%',
            marginTop: '1em'
        }
    },
    formTitle: {
        margin: Dimens.LOGIN_MARGIN_TOP,
        fontSize: Dimens.TEXT_SIZE_LARGE,
        marginTop: Dimens.LOGIN_MARGIN_TOP
    },
    formTerms: {
        fontSize: Dimens.TEXT_SIZE_REGULAR,
        marginTop: Dimens.LOGIN_MARGIN_TOP
    }
}))



const initialLoginValues = {
    Password: '',
    ConfirmPassword: ''
}

function ResetPassword() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [resetPassword, setResetPassword] = useState(false)
    const [loading, setLoading] = useState(false);


    const {
        values, handleInputChange
    } = useForm(initialLoginValues)

    let location = useLocation()



    function handleSubmit(e) {

        const token = location.search.split("?token=")[1]
        e.preventDefault()
        var formData = [];
        setLoading(true);
        const resetData = {

            ResetToken: token,
            NewPassword: values.Password,
            ConfirmPassword: values.ConfirmPassword,
        }
        formData.push(resetData);
        PasswordService.resetPasswordData(resetData)
            .then((response) => {
                if (response.status === SUCCESS && !loading) {
                    dispatch(notify(
                        'Successfully',
                        'Password successfully reset',
                        'success'
                    ))
                    setResetPassword(true)
                    setLoading(false);
                } else if (response.status === FAILED) {
                    dispatch(notify(
                        'Failed',
                        'Reset password failed',
                        'error'
                    ))
                    setLoading(false);

                }
                
            }).catch((err)=>{
                dispatch(notify(
                    'Failed',
                    'Reset password failed',
                    'error'
                ))
                setLoading(false)
            })


    }

    if (resetPassword == true) {
        return <Redirect to={'/login'} />
    }

    return (
        <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
        <div>
            <Grid container className={classes.root}>
                <Grid item md={6} lg={6} className={classes.bannerContainer}>
                    <div className={classes.displayBanner} />
                </Grid>
                <Grid item xs={6} className={classes.bannerContainer}>
                    <div className={classes.paper} >


                        <Card className={classes.muiCard}
                            elevation={0}
                        >
                            <Controls.Typography
                                className={classes.formTitle}
                            >
                                {RESET_PASSWORD}
                            </Controls.Typography>
                            <Form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <Controls.Input
                                    size='small'
                                    label="Password"
                                    fieldTypeName="password"
                                    name="Password"
                                    value={values.Password}
                                    onChange={handleInputChange}

                                />
                                <Controls.Input
                                    size='small'
                                    label="Confirm Password"
                                    fieldTypeName="password"
                                    name="ConfirmPassword"
                                    value={values.ConfirmPassword}
                                    onChange={handleInputChange}

                                />


                                <Controls.Button
                                    label='Reset'
                                    size='small'
                                    type="submit"
                                    disabled={loading} 
                                    loader={loading && (
                                      <CircularProgress
                                        size={24}  
                                        style={{marginLeft:'-30px'}}
                                      /> )}
                                />

                            </Form>
                            <Controls.Typography
                                paragraph
                                className={classes.formTerms}
                            >{TERMS_AND_CONDITION}</Controls.Typography>
                        </Card>

                    </div>
                </Grid>
            </Grid>
        </div>
        </ThemeProvider>
    </StyledEngineProvider>
    )
}
export default ResetPassword;
