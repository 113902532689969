import { store } from '../../../store';
import { getFullAge } from '../../fieldProperties/DateTime';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { validateDate } from '../ValidationRules/DateValidations';

export const execute = (props) => {
    let fieldProps = props.additionalProperties;
    if (fieldProps.CalculateAge) {
        var getAge =null;
        if (props.value) {            
            var isValidDate = validateDate(props.value);
            if (isValidDate) {
                getAge= fieldProps.CalculateAge.OnInvalidValue;
            }else{
                getAge = getFullAge(props.value);
            }            
            store.dispatch(updateControlValue(fieldProps.CalculateAge.TargetFieldId, getAge));
        }else{
            store.dispatch(updateControlValue(fieldProps.CalculateAge.TargetFieldId, fieldProps.CalculateAge.OnInvalidValue)); 
        }
    }
}
const CalculateAge = {
    execute
}
export default CalculateAge