import React from 'react'
import { connect, useDispatch } from "react-redux";
import { List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Calendar from '../controls/Calendar';
import { useSelector } from 'react-redux';
import NotificationItem from '../controls/NotificationItem';
import moment from 'moment'
import { updateLoaderState, updateCalendarStartEndDate } from '../../actions/DashboardAction';
import { dashboardToggle, getLoading, startDate } from '../../reducers/DashboardReducer';
import { DASHBOARD_START_DATE_FORMAT, DASHBOARD_END_DATE_FORMAT, NOTIFICATION_DATE_FORMAT,
    DASHBOARD_DEFAULT_PAGE_NUMBER, DASHBOARD_DEFAULT_ITEM_PER_PAGE } from '../../constants/GlobalConstants';
import CaseStatusSnapshot from './CaseStatusSnapshot';


const useStyles = makeStyles(theme => ({
    root: {
        positon: 'relative',
        margin: 'auto',
    },
    calendar: {
        backgroundColor: '#fff',
        zIndex: '1',
    },
    notificationView: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }
}))

function Sidebar(props) {   
    const notification = useSelector(
        state => state.notification
    )
    const calendarDate = useSelector(startDate)
    const monthViewToggle = useSelector(dashboardToggle)
    const loadinState = useSelector(getLoading)
    const dispatch = useDispatch();


    const classes = useStyles();

    const compare = (notification1, notification2) => {
        const time1 = notification1.time;
        const time2 = notification2.time;


        let comparison = 0;
        if (time1 > time2) {
            comparison = -1;
        } else if (time1 < time2) {
            comparison = 1;                 
        }
        return comparison;
    }
    notification.sort(compare)



    const onClickHandler = (value) => {
        // const currentMonth = moment(calendarDate).format('MM')
        // const selectedMonth = moment(value).format('MM')
        // if(monthViewToggle && currentMonth!=selectedMonth){
        //  //  dispatch(updateLoaderState(true))
        // }
        
        const selected_start_date = moment(value).format(DASHBOARD_START_DATE_FORMAT);
        const selected_end_date = moment(value).format(DASHBOARD_END_DATE_FORMAT);
        const dates = { selected_start_date, selected_end_date };
        props.updateCalendarStartEndDate(dates);
       
        if (monthViewToggle == false) {
            props.getDashboardCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, selected_start_date, selected_end_date);
        }


    }



    return (
        <div className={classes.root}>
            <div className={classes.calendar}>
                <Calendar onChange={(value) => { onClickHandler(value) }} value={calendarDate} />
            </div>
            <CaseStatusSnapshot />
            <div className={classes.root}><label><b></b></label></div>
            <List className={classes.notificationView}>
                {notification.map((objNotification) => (
                    <NotificationItem
                        notificationImage={objNotification.image}
                        notificationTitle={objNotification.title}
                        notificationMessage={objNotification.message}
                        key={moment(objNotification.time).format(NOTIFICATION_DATE_FORMAT)}
                    />
                ))}
            </List>
        </div>
    )
}

export default connect(null, { updateCalendarStartEndDate })(Sidebar);
