import moment from 'moment';
import * as Constants from "../../../constants/GlobalConstants";
import { CANCELLED, CLOSED } from '../../../constants/CaseStatuses';
import { store } from '../../../store';

var response = null;


export const validateDate = (fieldValue) => {
  if (fieldValue) {
    var dateToValidate = moment(fieldValue,Constants.COMMON_DATE_FORMAT,true);
    
    if ((fieldValue == Constants.ERR_INVALID_DATE) || (dateToValidate && dateToValidate == Constants.ERR_INVALID_DATE )
    || moment(dateToValidate).isBefore("01/01/1900")
    || (fieldValue instanceof Date && isNaN(fieldValue)) //If input value is of 'Date' type
    ) {
      response = {
        status: false,
        isButtonDisable: false,
        message: Constants.ERR_INVALID_DATE_FORMAT
      };
      return response;
    }
  }
}

export const disableFutureDate = (fieldValue) => {
  if (fieldValue) {
    const todayDate = moment().format(Constants.COMMON_DATE_FORMAT);
    var dateToValidate = moment(fieldValue,Constants.COMMON_DATE_FORMAT,true);
    var isValidDate = validateDate(dateToValidate);
    if (isValidDate) {
      return isValidDate;
    } else {
      if (dateToValidate && (moment(dateToValidate).isAfter(todayDate) === true)) {
        response = {
          status: false,
          message: Constants.INFO_SELECTED_DATE_CANNOT_GREATER_THAN_CURRENT
        };
        return response;
      }
    }
  }
}

export const disablePastDate = (fieldValue) => {

  const state = store.getState();
  var sectionDetailsState = state.caseDetails.sectionDetails
  if (!(sectionDetailsState.caseStatusId == CLOSED || sectionDetailsState.caseStatusId == CANCELLED)) {
    if (fieldValue) {
      const todayDate = moment().format(Constants.COMMON_DATE_FORMAT);
      var dateToValidate = moment(fieldValue).format(Constants.COMMON_DATE_FORMAT);
      var isValidDate = validateDate(dateToValidate);
      if (isValidDate) {
        return isValidDate;
      } else {
        if (dateToValidate && (moment(todayDate).isAfter(dateToValidate) === true && moment(todayDate).isSame(dateToValidate) === false)) {
          response = {
            status: false,
            message: Constants.INFO_SELECTED_DATE_SAME_OR_GREATER_THAN_CURRENT
          };
          return response;
        }
      }
    }
  }

}

export const validateAge = (fieldValue) => {
  const todayDate = moment().format(Constants.COMMON_DATE_FORMAT);
  var dateToValidate = moment(fieldValue).format(Constants.COMMON_DATE_FORMAT);
  var Ageyears = moment().diff(moment(fieldValue), Constants.LBL_YEARS, false);
  var Agedays = moment().diff(moment(fieldValue).add(Ageyears, Constants.LBL_YEARS), Constants.LBL_DAYS, false);
  if (Ageyears > 120) {
    response = {
      status: false,
      message: Constants.INFO_AGE_CANNOT_GREATER_THEN_120
    };
    return response;
  }

  if (Ageyears == 120 && Agedays > 0) {
    response = {
      status: false,
      message: Constants.INFO_AGE_CANNOT_GREATER_THEN_120
    };
    return response;
  }
}
