import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/RootReducer';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk';

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});
// to create store..
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
