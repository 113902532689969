import { store } from '../../../store';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { SELECT_FILE, BLANK_VALUE } from "../../../constants/GlobalConstants";
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import { getSelectionsForControl } from '../../../reducers/CaseDetailsReducer';
import { copyRefData, reloadAndCopyReferenceData } from "../../../actions/ControlSelectionsAction"


export const ValidFileTypeSelected = (control) => {
    var response = null;
    if (control && control.value && control.validation) {
        if (control.fieldTypeName == SELECT_FILE) {

            const stateData = store.getState();
            var validationObj = control.validation
            var errorMessage = "";
            var inPopUp = true;
            var referenceDataId = control.additionalProperties.LoadReferenceData;
            var documentSupportedFileTypes = getSelectionsForControl(stateData, referenceDataId)           
            var isShowErrorMessage = false;

            if (validationObj && validationObj.ValidFileTypeSelected && validationObj.ValidFileTypeSelected.DialogMessage
                && validationObj.ValidFileTypeSelected.DialogMessage.DefaultText) {
                errorMessage = validationObj.ValidFileTypeSelected.DialogMessage.DefaultText;
            }

            if (control.value && !ValidateFile(control.value, documentSupportedFileTypes)) {
                isShowErrorMessage = true;
            }

            if (isShowErrorMessage) {
                //"TargetFieldId": "InvalidValidErrorLbl"
                store.dispatch(VisibilityControlAction.showField(validationObj.ValidFileTypeSelected.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(validationObj.ValidFileTypeSelected.TargetFieldId, errorMessage || BLANK_VALUE));

                //TODO:Later we will use this obj and remove lbl part
                response = {
                    status: false,
                    isButtonDisable: false,
                    message: errorMessage
                };

            } else {
                store.dispatch(VisibilityControlAction.hideField(validationObj.ValidFileTypeSelected.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(validationObj.ValidFileTypeSelected.TargetFieldId, BLANK_VALUE));
               
            }

        }
    }
    return response;
}

function ValidateFile(fileObject, documentSupportedFileTypes) {
    var isValidFile = false;
    if (fileObject) {
        var fileName = fileObject.name;
        if (fileName.length > 0) {
            for (var index = 0; index < documentSupportedFileTypes.length; index++) {
                var sCurExtension = documentSupportedFileTypes[index].DocumentSupportedFileTypeName;
                if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    isValidFile = true;
                    break;
                }
            }
        }
    }

    return isValidFile;
}