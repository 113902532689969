import * as actionTypes from '../constants/ActionTypes';
import { BLANK_VALUE, TEXT, PHONE_NUMBER, 
  ZIPCODE, ACTIVEHIDDENFIELDS, ERR_SOMETHING_WENT_WRONG_TO_UPDATE_PRODUCTS } from '../constants/GlobalConstants';

const initialState = {
  actionButtonLoaderStatus: false,
  bookingSheetDetails: null,
  sectionDetails: null,
  popupSectionDetails: [],
  viewDocumentDetail: null,
  loadedSectionId: null,
  loadedSectionName: '',
  isAsyncLoad: true,
  isCreateCaseState: false,
  controls: {},
  caseId: null, //Will be replaced with the real case ID
  isCreateCase: true,
  selection: null,
  isPopupOpen: false,
  fullScreenBl: false,
  bookingSheetFormData: [],
  isDashboard: false,
  isCaseAmendEnable: false,
  isCaseEditable : false,
  disableNavigationSection: false,
  caseNavigationLoader: true,
  sectionGroupLoader: true,
  headerInformation : null,  
  hubConnection:null,
  prevCaseId: null,
  isNavigationSourceALink : true,
  error: null,  
  popUpLoader: false,
  clickedFieldID: null,
  actionList: [ // this is just to test, will remove it later..
    {
      "label": "Create Case",
      "mappingId": "CreateCaseBtn",
      "action": "Submit",
      "isLocked": true,
      "actionId": 0
    },
    {
      "label": "Back",
      "mappingId": "",
      "action": "Submit",
      "isLocked": false,
      "actionId": 4
    },
    {
      "label": "Next",
      "mappingId": "",
      "action": "Submit",
      "isLocked": false,
      "actionId": 1
    },
    {
      "label": "Save",
      "mappingId": "SaveCaseBtn",
      "action": "Submit",
      "isLocked": false,
      "actionId": 2
    },
    {
      "label": "Save & Next",
      "mappingId": "SaveAndNextBtn",
      "action": "Submit",
      "isLocked": false,
      "actionId": 3
    }
  ],
  sections: [],
  isUserAuthenticated: true,
  dependentFieldList: null,
  caseSummaryDetails:[],
  isInsuranceVerificationDetailAmended:false,
  selectionLoader:false,
  showAlertDialog: false,
  saveFailureCount: 0,
  displayPatientMappingMessage: false,
  createCaseFailure: false,
  docSectionDetails: null,
  docUploadPopUpDetails: null,
  docSelectionContorls: null,
  isDocPopUpOpen: false,
  docSectionPopUpLoad: false,
  isPrevieAvailable: true
}

const ACTION_HANDLERS = {
  [actionTypes.CLEAR_CASE_DETAILS]: (state, action) => ({
    ...initialState,    
    sections:state && state.sections && state.sections.length>0 ? state.sections : [],
  }),
  [actionTypes.UPDATE_SECTION_COMPLETION_STATUS]: (state, action) => {
    const {sectionId, isSectionComplete} = action.payload;
    const newSections = state.sections.map(function(section){
      if(section.id === sectionId) {
        return {...section, isSectionComplete: isSectionComplete}
      }
      return section
    })
    return {
      ...state,    
      sections: newSections
    }
    
  },
  [actionTypes.UPDATE_CASE_AMENDMENT_BL_STATUS]: (state, action) => {
    return {
      ...state,
      isCaseAmendEnable: action.payload
    }
  },
  [actionTypes.UPDATE_SECTION_GROUP_LOADER_STATUS]: (state, action) => {
    return {
      ...state,
      sectionGroupLoader: action.payload,
      actionButtonLoaderStatus: false
    }
  },
  [actionTypes.ACTION_BUTTON_LOADER_STATUS]:(state, action)=>{
    return {
      ...state,
      actionButtonLoaderStatus: action.status,
      sectionGroupLoader: action.payload

    }
  },
  [actionTypes.UPDATE_NAVIGATION_LOADER_STATUS]: (state, action) => {
    return {
      ...state,
      caseNavigationLoader: action.payload
    }

  },
  [actionTypes.UPDATE_POPUP_LOADER_STATUS]: (state, action) => {
    return {
      ...state,
      popUpLoader: action.payload,
    }
  },
  [actionTypes.DISABLE_NAVIGATION_SECTION]: (state, action) => {
    return {
      ...state,
      disableNavigationSection: action.payload
    }

  },
  [actionTypes.GET_BOOKING_SHEET_SUCCESS]: (state, action) => {
    return {
      ...state,
      bookingSheetDetails: action.payload
    }
  },
  [actionTypes.GET_BOOKING_SHEET_FAILURE]: (state, action) => {
    return {
      ...state,
      bookingSheetDetails: [],
    }
  },
  [actionTypes.GET_SECTION_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      sectionDetails: action.payload,
      loadedSectionId: action.payload.sectionId,
    }
  },
  [actionTypes.GET_SECTION_DETAILS_FAILURE]: (state, action) => {
    return {
      ...state,
      sectionDetails: null,
    }
  },
  [actionTypes.UPDATE_BOOKING_SHEET_SECTION_LIST]: (state, action) => {
    return {
      ...state,
      sections: action.payload,
      caseNavigationLoader: action.caseNavigationLoader,
    }
  },

  [actionTypes.UPDATE_ASYNC_LOAD_FLAG]: (state, action) => {
    return {
      ...state,
      isAsyncLoad: action.payload
    }
  },

  [actionTypes.SET_CREATE_CASE]: (state, action) => {
    return {
      ...state,
      isCreateCaseState: action.payload
    }
  },

  [actionTypes.UPDATE_SECTION_ID]: (state, action) => {
    return {
      ...state,
      loadedSectionId: action.payload,
    }
  },

  [actionTypes.UPDATE_SECTION_NAME]: (state, action) => {
    return {
      ...state,
      loadedSectionName: action.payload,
    }
  },
  [actionTypes.DISPLAY_PATIENT_MAPPING_MESSAGE]: (state, action) => {
    return {
      ...state,
      displayPatientMappingMessage: action.payload,
    }
  },

  [actionTypes.UPDATE_CONTROL_VALUE]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload.replacedControl
    };
   
  },
  [actionTypes.XCONTROL_SECTION_ACTION]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload
    };
    
  },

  [actionTypes.ADD_DYNAMIC_FIELD_SUCCESS]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload
    };    
  },

  [actionTypes.SHOW_FORM_CONTROL]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload
    }
  },
  [actionTypes.HIDE_FORM_CONTROL]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload
    }
  },
  [actionTypes.PUSH_TO_TARGET]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload.replacedControl
    }
  },
  [actionTypes.DELETE_FROM_TARGET]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload.replacedControl
    }
  },
  [actionTypes.UPDATE_SECTION_STATUS]: (state, action) => {
    return {
      ...state,
      sections: action.payload,
    }
  },

  [actionTypes.UPDATE_BOOKINGSHEET_FORM_DATA]: (state, action) => {
    return {
      ...state,
      bookingSheetFormData: action.payload,
    }
  },

  [actionTypes.SAVE_SECTION_DETAILS_FAILURE]: (state, action) => {
    return {
      ...state,
      saveFailureCount: state.saveFailureCount + 1,
    }
  },

  [actionTypes.SAVE_SECTION_DETAILS_FAILURE_RESET_COUNT]: (state, action) => {
    return {
      ...state,
      saveFailureCount: 0,
    }
  },

  [actionTypes.SAVE_SECTION_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      saveFailureCount: 0,
    }
  },
  
  [actionTypes.CLEAR_FIELD_VALUE]: ({ sectionDetails, ...rest }, action) => {
    if (sectionDetails) {
      var replacedControl = sectionDetails;
      var fieldToClear = BLANK_VALUE;
      replacedControl.sectionGroup.forEach(element => {
        element.fields.forEach(field => {
          if (field.mappingId == action.payload) {
            fieldToClear = field.fieldTypeName;
          }
        });
      });
     
      replacedControl.sectionGroup.forEach(x => x.fields.filter(element => element.mappingId == action.payload).forEach(element => element.value = BLANK_VALUE));
     
      return {
        ...rest,
        sectionDetails: replacedControl
      }
    }
  },
  [actionTypes.UPDATE_IS_REQUIRED_VALIDATION]: (state, action) => {
    const { payload } = action;
    const { sectionDetails, ...rest } = state;

    if (sectionDetails) {
      // Update the sectionDetails if it exists
      return {
        ...rest,
        sectionDetails: payload
      };
    } else {
      // If sectionDetails is null, return the previous state
      return state;
    }
  },
  [actionTypes.SHOW_SECTION_GROUP]: ({ sectionDetails, ...rest }, action) => {
    if (sectionDetails) {
      var replacedControl = sectionDetails;
      action.payload.forEach(sectionGroupId => {
        replacedControl.sectionGroup.forEach(x => {
          if (x.sectionGroupId == parseInt(sectionGroupId)) {
            x.isVisible = true
            x.fields.forEach(field => {
              if (field.fieldTypeName != ACTIVEHIDDENFIELDS) {
                field.isVisible = true
              }
            })
          }
        })
      })
      return {
        ...rest,
        sectionDetails: replacedControl
      }
    }
  },

  [actionTypes.HIDE_SECTION_GROUP]: ({ sectionDetails, ...rest }, action) => {
    if (sectionDetails) {
      var replacedControl = sectionDetails;
      action.payload.forEach(sectionGroupId => {
        replacedControl.sectionGroup.forEach(x => {
          if (x.sectionGroupId == parseInt(sectionGroupId)) {
            x.isVisible = false
            x.fields.forEach(field => {
              field.isVisible = false
              if (field.additionalProperties && field.additionalProperties.DefaultValue != undefined) {
                field.value = field.additionalProperties.DefaultValue;
              }
              else {
                if (field.fieldTypeName == TEXT || field.fieldTypeName == ZIPCODE || field.fieldTypeName == PHONE_NUMBER) {
                  field.value = BLANK_VALUE;
                }
                else {
                  field.value = null;
                }
              }
            })
          }
        })
      })
      return {
        ...rest,
        sectionDetails: replacedControl
      }
    }
  },

  [actionTypes.CONTROL_SELECTION_UPDATE_SUCCESS]: (state, action) => {
    return {
      ...state,
      sectionDetails: action.payload.section,
      loadedSectionId: action.payload.section.sectionId,
      selection: action.payload.selections,
      sectionGroupLoader: action.sectionGroupLoader,
      actionButtonLoaderStatus: false,
      disableNavigationSection: false
    };
  },
  [actionTypes.CONTROL_SEARCHED_SELECTION_UPDATE_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadedSectionId: action.payload.sectionId,
      selection: action.payload.selections,
      selectionLoader:false
    };
  },
  [actionTypes.SELECTION_LOADER_STATUS_UPDATE]: (state, action) => {
    return {
      ...state,
      selectionLoader: action.payload
    };
  },
  
  [actionTypes.POPUP_CONTROL_SELECTION_UPDATE_SUCCESS]: (state, action) => {
    return {
      ...state,
      selection: action.payload.selections
    };
  },

  [actionTypes.CONTROL_SELECTION_RELOAD_SUCCESS]: (state, action) => {
    if (action.payload.selections) {
      var sections = state.selection
      var keys = Object.keys(action.payload.selections)
      var values = Object.values(action.payload.selections)
      for (var item = 0; item < keys.length; item++) {
        sections[keys[item]] = values[item];
      }
    }

    return {
      ...state,
      selection: sections,
    };
  },

  [actionTypes.UPDATE_ADDITIONAL_PROPS]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload
    };
  },
  [actionTypes.UPDATE_ADDITIONAL_PROPS_IN_POPUP]: ({ popupSectionDetails, ...rest }, action) => {
    return {
      ...rest,
      popupSectionDetails: action.payload
    };
  },

  [actionTypes.COPY_REFERENCE_DATA]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload
    }
  },
  [actionTypes.OVERRIDE_VALIDATION_ENGINE]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload
    }
  },

  [actionTypes.GET_SINGLE_SECTION_DATA]: ({ popupSectionDetails, ...rest }, action) => {
   
    return {
      ...rest,
      popupSectionDetails: [
        ...popupSectionDetails,
        action.payload
      ]
    };
  },
  [actionTypes.UPDATE_CONTROL_VAL_IN_POPUP]: ({ popupSectionDetails, ...rest }, action) => {
    return {
      ...rest,
      popupSectionDetails: action.payload
    }
  },
  [actionTypes.TOGGLE_DIALOG]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      sectionDetails: action.payload.replacedControl
    }
  },
  [actionTypes.UPDATE_CASE_VIEW]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      isDashboard: action.payload
    }
  },
  [actionTypes.UPDATE_CASE_ID]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      caseId: action.payload
    }
  },
  [actionTypes.IS_CREATE_CASE]: ({ sectionDetails, ...rest }, action) => {
    return {
      ...rest,
      isCreateCase: action.payload
    }
  },
  [actionTypes.GET_VIEW_DOCUMENT_DATA_SUCCESS]: ({ popupSectionDetails, ...rest }, action) => {  
   
    return {
      ...rest,
      popupSectionDetails: [
        ...popupSectionDetails,
        action.payload
      ]
    };
  },
  [actionTypes.GET_VIEW_DOCUMENT_DATA_FAILURE]: (state, action) => {
    return {
      ...state,
      popupSectionDetails: [],
    }
  },
  [actionTypes.GET_VIEW_DOCUMENT_FILESTREAM_SUCCESS]: (state, action) => {   
    return {
      ...state,
      viewDocumentDetail: action.payload
    };
  },
  [actionTypes.GET_VIEW_DOCUMENT_FILESTREAM_FAILURE]: (state, action) => {
    return {
      ...state,
      viewDocumentDetail: null,
    }
  },

  [actionTypes.UPDATE_HEADER_INFORMATION]:(state, action) =>
  {
    return {
      ...state,
      headerInformation: action.payload
    }
  },

  [actionTypes.UPDATE_CLIECKED_FIELD_ID]: (state, action) => {
    return {
      ...state,
      clickedFieldID: action.payload
    }
  },
  [actionTypes.UPDATE_IS_POPUP_OPEN]: (state, action) => {
    return {
      ...state,
      isPopupOpen: action.payload.status,
      fullScreenBl: action.payload.fullScreenBl,
    }
  },

  [actionTypes.UPDATE_CASE_EDITABLE_MODE]: (state, action) => {
    return {
      ...state,
      isCaseEditable: action.payload
    }
  },
  [actionTypes.NAVIGATION_SOURCE]: (state, action) => {
    return {
      ...state,
      navigationSource: action.payload
    }
  },
  [actionTypes.CLEAR_POPUP_STORE_DATA]: (state, action) => {
    return {
      ...state,
      viewDocumentDetail: null,
      popupSectionDetails: [],
      isPopupOpen: false,
      fullScreenBl: false
    }
  },

  [actionTypes.UPDATE_SIGNAL_R_LOCK_STATUS]: ({ sectionDetails, ...rest }, action) => {
    if (sectionDetails) {
      var replacedObject = sectionDetails;
      replacedObject.sectionLockBl = action.payload.sectionLockBl;
      replacedObject.sectionLockedByName = action.payload.sectionLockedByName;
      replacedObject["isShowSectionDataRefreshIcon"] = action.payload.isShowSectionDataRefreshIcon ? action.payload.isShowSectionDataRefreshIcon : false;
      replacedObject["sectionReleaseBl"] = action.payload.sectionReleaseBl ? action.payload.sectionReleaseBl : false;

      return {
        ...rest,
        sectionDetails: replacedObject
      }
    }
  },
  [actionTypes.UPDATE_SIGNAL_R_HUB_CONNECTION]:(state, action) =>
  {
    return {
      ...state,
      hubConnection: action.payload
    }
  }, 

  [actionTypes.UPDATE_IF_USER_ID_AUTH]: (state, action) =>
  {
    return{
      ...state,
      isUserAuthenticated : action.payload
    }
  },
  
  [actionTypes.UPDATE_ACTION_BUTTONS_PROPERTIES]: (state, action) =>
  {
    return{
      ...state,
      actionList : action.payload
    }
  },
  [actionTypes.UPDATE_SELECTED_INSURANCE_DEPENDENT_FIELD]: (state, action) => {
    return {
      ...state,
      dependentFieldList: action.payload
    }
  },
  [actionTypes.UPDATE_ADDITIONAL_PROPS_IN_POPUP]: ({ popupSectionDetails, ...rest }, action) => {
    return {
      ...rest,
      popupSectionDetails: action.payload
    };
  },
  [actionTypes.GET_CASE_SUMMARY_SUCCESS]: (state, action) => {
    return {
      ...state,
      caseSummaryDetails: action.payload, 
      error: null,
      sectionGroupLoader: false,
      isInsuranceVerificationDetailAmended: false     
    }
  },
  [actionTypes.GET_CASE_SUMMARY_FAILURE]: (state, action) => {
    return {
      ...state,
      caseSummaryDetails: [],
      error : action.payload, 
      sectionGroupLoader: false 
    }
  },
  [actionTypes.SAVE_VERIFY_INSURANCE_SUCCESS]: (state, action) => {
    return {
      ...state,      
      error: null,
      sectionGroupLoader: false,
      isInsuranceVerificationDetailAmended:  action?.payload?.CaseAmendedByOtherUser,      
    }
  },
  [actionTypes.SAVE_VERIFY_INSURANCE_FAILURE]: (state, action) => {
    return {
      ...state,   
      error : action.payload, 
      sectionGroupLoader: false 
    }
  },
  [actionTypes.UPDATE_DOCUMENT_ALERT_POPUP_STATUS]: (state, action) =>
  {
    return{
      ...state,
      showAlertDialog : action.payload
    }
  },
  [actionTypes.CREATE_CASE_FAILURE]: (state, action) =>
  {
    return{
      ...state,
      createCaseFailure : true,
      sectionGroupLoader: false
    }
  },
  [actionTypes.CREATE_CASE_FAILURE_RESET]: (state, action) =>
  {
    return{
      ...state,
      createCaseFailure : action.payload
    }
  },
  [actionTypes.UPDATE_DOC_SECTION_DETAILS]: (state, action) =>
  {
    return{
      ...state,
      docSectionDetails: action.payload
    }
  },
  [actionTypes.UPDATE_DOC_POPUP_DETAILS]: (state, action) =>
  {
    return{
      ...state,
      docUploadPopUpDetails: action.payload
    }
  },
  [actionTypes.UPDATE_DOC_POPUP_SELECTION_CONTROLS]: (state, action) =>
  {
    return{
      ...state,
      docSelectionContorls: action.payload
    }
  },
  [actionTypes.UPDATE_DOC_POP_UP_OPEN]: (state, action) =>
  {
    return{
      ...state,
      isDocPopUpOpen: action.payload
    }
  },
  [actionTypes.UPDATE_DOC_POP_UP_SECTION_LOAD]: (state, action) =>
  {
    return{
      ...state,
      docSectionPopUpLoad: action.payload
    }
  },
  [actionTypes.UPDATE_DOC_PREVIEW_STATUS]: (state, action) =>
  {
    return{
      ...state,
      isPrevieAvailable: action.payload
    }
  },
}


export default function caseDetails(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const getBookingSheet = state => state.caseDetails.bookingSheetDetails;
export const getSection = state => state.caseDetails.sectionDetails;
export const getPopupSectionDetails = state => state.caseDetails.popupSectionDetails;
export const getViewDocDetails = state => state.caseDetails.viewDocumentDetail;
export const getSectionsNavigation = state => state.caseDetails.sections;
export const getActionList = state => state.caseDetails.actionList;
export const getCaseView = state => state.caseDetails.isDashboard;
export const getSaveFailureCount = state => state.caseDetails.saveFailureCount;
export const caseAmendmentBl = state => state.caseDetails.isCaseAmendEnable;
export const getAllSelections = state => state.caseDetails["selection"];
export const getSelectionsForControl = (state, referenceId) =>
  state.caseDetails["selection"][referenceId]
    ? state.caseDetails["selection"][referenceId]
    : undefined;
export const getCaseNavigationLoader = state => state.caseDetails.caseNavigationLoader;
export const getSectionGroupLoader = state => state.caseDetails.sectionGroupLoader;
export const getCreateCaseState = state => state.caseDetails.isCreateCaseState;
export const getActionButtonLoaderStatus = state => state.caseDetails.actionButtonLoaderStatus;
export const getPopupLoader = state => state.caseDetails.popUpLoader;
export const getDisableNavigationSectionStatus = state => state.caseDetails.disableNavigationSection;
export const getHeaderInformation = state => state.caseDetails.headerInformation;
export const getIfcaseInEditMode = state => state.caseDetails.isCaseEditable;
export const getIspopOpen = state => state.caseDetails.isPopupOpen;
export const getClickedFieldId = state => state.caseDetails.clickedFieldID;
export const getNavigationSource = state => state.caseDetails.isNavigationSourceALink;
export const getCaseSummary = state => state.caseDetails.caseSummaryDetails;
export const getInsuranceVerificationDetailAmendedBl = state => state.caseDetails.isInsuranceVerificationDetailAmended;
export const getSelectionLoader = state => state.caseDetails.selectionLoader;
export const getCreateCaseFailure = state => state.caseDetails.createCaseFailure;
export const getDocumentSectionDetails = state => state.caseDetails.docSectionDetails;
export const getDocumentUploadPopUpDetails = state => state.caseDetails.docUploadPopUpDetails;
export const getDocumentSelectionControls = state => state.caseDetails.docSelectionContorls;
export const getDocPopUpOpen = state => state.caseDetails.isDocPopUpOpen;
export const getDocSectionPopUpLoad = state => state.caseDetails.docSectionPopUpLoad;
export const getPreviewAvailable = state => state.caseDetails.isPrevieAvailable;
