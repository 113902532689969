import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { formatDate } from '../../utils/fieldProperties/DateTime';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  }
}))


export default function DatePickerDashboard(props) {

  var { fieldId, error, helperText, label, value, format, margin, variant, onChange, ...other } = props
  const classes = useStyles()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value == (undefined || null) ? null : value}
        format={formatDate("MM/DD/YYYY")}
        margin={margin}
        mask="__/__/____"
        id={fieldId}
        name={fieldId}
        label={label}
        variant={variant}
        onChange={onChange}
        onBlur={onChange}
        error={error}
        helperText={helperText}
        className={classes.root}
        maxDate={new Date()}
        InputLabelProps={{
          shrink: value ? true : undefined,
        }}
       // inputVariant="outlined"
        renderInput={props => {           
          return <TextField {...props} className={classes.root} error={error} helperText={helperText}/>;
        }}
      />
     </LocalizationProvider>
  )
}
