import { calculateValue } from "../../CalculateValue"
import { getFieldValueById } from "../../GetFieldValueByID"
import { updateControlValue, addFields } from '../../../actions/CaseDetailsAction'
import { store } from '../../../store'
import { getFieldById } from '../../GetFieldById'
import { TARGET_FIELD_OTHER_FIELD_DEFAULT_VALUES, GLOBAL_VALUE_DOT } from '../../../constants/GlobalConstants';
import moment from "moment"

const execute = (control, AddOnLoadONChangeFlag = null) => {
    let rules = control.additionalProperties.OnClickAddValueToTargetFieldByCondition
    if (AddOnLoadONChangeFlag) {
        if (rules) {
            rules.forEach(rule => {
                var dependentFieldId = null;
                var checkResult = false
                rule.Condition.map(item => {
                    var fieldValueOperator = item.DependentFieldValueOperator;
                    var checkByFieldValue = item.DependentFieldValue;
                    if (item.DependentFieldId && item.DependentFieldId.length > 0) {
                        dependentFieldId = item.DependentFieldId[0];
                    }
                    var fieldValue = getFieldValueById(dependentFieldId);
                    if (fieldValue && fieldValueOperator && checkByFieldValue) {
                        checkResult = calculateValue(fieldValue, checkByFieldValue, fieldValueOperator);
                    }
                })
                if (checkResult) {
                    if (rule.SourceFieldTargetFieldValueMapping && rule.SourceFields.length > 0) {
                        var valuesToAdd = [];
                        var sourceValues = [];
                        rule.SourceFields.forEach(sourceField => {
                            var field = getFieldById(sourceField);
                            if (field && field != undefined) {
                                sourceValues.push(field);
                            }
                        })
                        var values = [];
                        var keys = Object.keys(rule.SourceFieldTargetFieldValueMapping)
                        var values = Object.values(rule.SourceFieldTargetFieldValueMapping)
                        for (var i = 0; i < keys.length; i++) {
                            sourceValues.forEach(sourceValue => {
                                if (sourceValue.value && (sourceValue.fieldTypeName == "SelectionKeyValue" || sourceValue.fieldTypeName == "Typeahead" || sourceValue.fieldTypeName == "Chips")) {
                                    var valueOne = Object.keys(sourceValue.value)
                                    var valueTwo = Object.values(sourceValue.value)
                                    for (var j = 0; j < valueOne.length; j++) {
                                        if (keys[i] == sourceValue.mappingId + GLOBAL_VALUE_DOT + valueOne[j]) {
                                            var object = {
                                                key: values[i],
                                                value: valueTwo[j]
                                            }
                                            valuesToAdd.push(object);
                                        }
                                    }
                                }
                                else if (sourceValue.value && (sourceValue.fieldTypeName != "SelectionKeyValue" && sourceValue.fieldTypeName != "Typeahead" && sourceValue.fieldTypeName != "Chips")) {
                                    if (keys[i] == sourceValue.mappingId) {
                                        var object = {
                                            key: values[i],
                                            value: sourceValue.value
                                        }
                                        valuesToAdd.push(object);
                                    }
                                }
                            })
                            var targetFieldValue = getFieldById(dependentFieldId);
                            var fieldValueOne = Object.keys(targetFieldValue.value)
                            var fieldValueTwo = Object.values(targetFieldValue.value)
                            for (var j = 0; j < fieldValueOne.length; j++) {
                                if (keys[i] == targetFieldValue.mappingId + GLOBAL_VALUE_DOT + fieldValueOne[j]) {
                                    var object = {
                                        key: values[i],
                                        value: fieldValueTwo[j]
                                    }
                                    valuesToAdd.push(object);
                                }
                            }

                            if (keys[i] == TARGET_FIELD_OTHER_FIELD_DEFAULT_VALUES) {
                                var defaultTargetFieldKey = Object.keys(values[i])
                                var defaultTargetFieldValue = Object.values(values[i])
                                for (var obj = 0; obj < defaultTargetFieldKey.length; obj++) {
                                    if (defaultTargetFieldValue[obj] == 'CurrentDateTime') {
                                        var field = getFieldById(defaultTargetFieldKey[obj]);
                                        if (field && field.additionalProperties) {
                                            var format = field.additionalProperties.Format;
                                            var object = {
                                                key: defaultTargetFieldKey[obj],
                                                value: moment(new Date()).format(format)
                                            }
                                            valuesToAdd.push(object);
                                        } 
                                    }else {
                                            var object = {
                                                key: defaultTargetFieldKey[obj],
                                                value: defaultTargetFieldValue[obj]
                                            }
                                            valuesToAdd.push(object);
                                        }                                    
                                }
                            }
                        }
                        store.dispatch(addFields(rule.TargetFieldId, null, valuesToAdd))
                    }
                }
            })
        }
    }
}


const OnClickAddValueToTargetFieldByCondition = {
    execute
}

export default OnClickAddValueToTargetFieldByCondition