import { store } from '../../../store'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import * as Constants from '../../../constants/GlobalConstants'

export const execute = (control) => {
    const fieldProps = control.additionalProperties
    let value
    if (control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
        value = control.value ? (control.value[fieldProps.DisplayColumnId] ? control.value[fieldProps.DisplayColumnId] : control.value) : (fieldProps.DefaultValue ? fieldProps.DefaultValue : null )
    } else {
        value = control.value
    }

    if (value || (control.fieldTypeName == "ActiveHiddenFields")) {
        if (fieldProps.HideTargetFieldIfFieldDoesNotHaveValue.length > 0) {
            fieldProps.HideTargetFieldIfFieldDoesNotHaveValue.forEach(fieldsAction => {
                var isPresent = fieldsAction.FieldValue.some(fieldVal => {
                    if (typeof value == 'string') {
                        return fieldVal == value
                    }
                    else if(typeof value == 'number'){
                        return fieldVal == value.toString();
                    }
                    else if (typeof value == 'object') {
                        if (Array.isArray(value)) {
                            return value.some(val => {
                                return val[fieldProps.DisplayColumnId] == fieldVal
                            })
                        } else if(!fieldProps.DisplayColumnId){
                            return value == fieldVal;
                        }
                        else {
                            return value && value[fieldProps.DisplayColumnId] == fieldVal;
                        }
                    }
                })
                if (!isPresent) {
                    fieldsAction.TargetFieldId.forEach(fieldId => {
                        store.dispatch(VisibilityControlAction.hideField(fieldId, null, control.parentFieldId))
                    })
                }
            })
        }
    }
    else {
        fieldProps.HideTargetFieldIfFieldDoesNotHaveValue.forEach(fieldsAction => {
            fieldsAction.TargetFieldId.forEach(fieldId => {
                store.dispatch(VisibilityControlAction.hideField(fieldId, null, control.parentFieldId))
            });
        });
    }
}

const HideTargetFieldIfFieldDoesNotHaveValue = {
    execute
}

export default HideTargetFieldIfFieldDoesNotHaveValue   