import { getFieldById } from '../../GetFieldById'
import RuleFactory from './RuleFactory';

const execute = (control, AddOnLoadONChangeFlag = null) => {
    if (AddOnLoadONChangeFlag) {
       
        const rule = control?.additionalProperties?.OnChangeExecuteAdditionalPropertiesOfTargetField;       
        rule && rule.map(targetFieldId=>{
            var controlCpy = getFieldById(targetFieldId);
            if(controlCpy?.additionalProperties){
                var keys = Object.keys(controlCpy.additionalProperties);

                for (var item = 0; item < keys.length; item++) {
                    var rule = RuleFactory.getApplicableRule(keys[item]);
                    //if rule is already available then execute that rule else put another condition
                    if (rule) {
                        rule.execute(controlCpy, true)
                    }
                }
            }

        });
       
    }
}

const OnChangeExecuteAdditionalPropertiesOfTargetField = {
    execute
}

export default OnChangeExecuteAdditionalPropertiesOfTargetField