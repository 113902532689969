import { store } from '../../../store'
import { updateControlValue } from "../../../actions/CaseDetailsAction"
import { DATA_TO_CLEAR } from "../../../constants/GlobalConstants";
import RuleFactory from './RuleFactory';


export const execute = (props, onLoadFlag) => {
    const fieldProps = props.additionalProperties
    const rules = fieldProps.OnClickUpdateTargetField;
    rules.map(configFields => {
        var keys = Object.keys(configFields)
        var values = Object.values(configFields)
        var controlCpy = { 
            ...props,
            additionalProperties: configFields,
        }
        for (var item = 0; item < keys.length; item++) {
            var rule = RuleFactory.getApplicableRule(keys[item])
            if (rule) {
                rule.execute(controlCpy, onLoadFlag)
            }
            else if (keys[item] == DATA_TO_CLEAR) {
                values[item].forEach(fieldId => {
                    store.dispatch(updateControlValue(fieldId, null));
                });
            }
        }
       
    })  
}

const OnClickUpdateTargetField = {
    execute
}

export default OnClickUpdateTargetField