import React, { useEffect, useState } from 'react';
import DataTable from '../controls/DataTable';
import { connect, useDispatch } from "react-redux";
import {dashboardSearchBl} from '../../actions/DashboardAction'

   function DashboardSeacrh({dashboardCases}) {
    const dispatch = useDispatch();

    return (
        <div>
            <DataTable />
        </div>
    )
}
const mapStateToProps = state => ({
    dashboardCases: state.dashboard.casesData
  }); 
export default connect(mapStateToProps, null)(DashboardSeacrh);
