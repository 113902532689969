import React from 'react'
import { IconProvider } from '../../providers/IconProvider'
import { DRAWER_DARK } from '../../res/values/Colors';
import makeStyles from '@mui/styles/makeStyles';
import { Button, IconButton, Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
    iconClass: {
        display: 'flex',
        alignItems: 'center',

    },
    icon: {
        color: "grey",
    },
    iconHover: {
        color: "grey",
        cursor: 'pointer',
        '&:hover': {
            borderRadius: '0px',
            boxShadow: 'rgba(0, 0, 0, 0.50) 2px 5px 7px'
        }
    },
    buttonLabel: {
        fontSize: "1rem",
        minWidth: "7rem",
        paddingLeft: "10px"
    },
    disabledButton: (props) => ({
        color: props.color ||"grey", // Custom color for disabled button, defaulting to green if not provided
        '&:hover': {
          backgroundColor: 'transparent', // Override hover effect
        },
      }),
}));

function ImageButton(props) {
    const { color, onClick, additionalProperties } = props
    const classes = useStyles({ color });
    var imgType = ''
    if (additionalProperties.Image) {
        imgType = additionalProperties.Image[0]
        var buttonText = additionalProperties.ImageButtonText != undefined && additionalProperties.ImageButtonText != null ? additionalProperties.ImageButtonText : null;
        var [Icon] = IconProvider(imgType)
        return (
            <Box display={'inline'} style={{ float: 'left' }}>
                <IconButton
                    onClick={onClick}
                    sx={{ color: color || DRAWER_DARK }}
                    disabled={props.disabled}
                >
                    <Icon />
                </IconButton>
            </Box>
        )
    } else {
        return <></>
    }
}

export default ImageButton
