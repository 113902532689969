import { store } from '../../../store'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import { getFieldById } from '../../GetFieldById'

export const execute = (control) => {
    const fieldProps = control.additionalProperties
    if (fieldProps.ShowTargetFieldIfAdditionalFieldHasValue.length > 0) {
        fieldProps.ShowTargetFieldIfAdditionalFieldHasValue.forEach(fieldsAction => {
            var inPopUp= fieldsAction.inpopup!= undefined && fieldsAction.inpopup ? fieldsAction.inpopup : false;
            if (fieldsAction.AdditionalFields.every(condition => {
                var nestedKeys = condition.AdditionalFieldId.split('.')
                var field = getFieldById(nestedKeys[0])
                var value = field && field.value
                if (value) {
                    var additionalNestedFieldVal = value
                    for (var i = 1; i < nestedKeys.length; i++) {
                        if (additionalNestedFieldVal) {
                            additionalNestedFieldVal = additionalNestedFieldVal[nestedKeys[i]]
                        }
                    }
                    value = additionalNestedFieldVal
                    return value == condition.AdditionalFieldValue
                } else {
                    return false
                }
            })) {

              fieldsAction.TargetFieldId &&   fieldsAction.TargetFieldId.forEach(fieldId => {
                    store.dispatch(VisibilityControlAction.showField(fieldId,inPopUp))
                })
            }
        })
    }
}

const ShowTargetFieldIfAdditionalFieldHasValue = {
    execute
}

export default ShowTargetFieldIfAdditionalFieldHasValue