import { updateFieldPermission } from "../../../actions/CaseDetailsAction";
import moment from 'moment';
import { 
    TIME, ALLOWED_TIME_FORMATES
} from "../../../constants/GlobalConstants";

const execute = control => {
    const rule = control.additionalProperties.DisableTargetFieldIfInvalidTime
   
    if (rule && control && control.value && control.value != '' && control.fieldTypeName == TIME) {
        var fieldValue = control.value;
        var timeToValidate = moment(fieldValue, ALLOWED_TIME_FORMATES, true).isValid();  //checking possible time formats                 
        if (!timeToValidate) { 
            rule.TargetFieldId.map(item => {
                store.dispatch(updateFieldPermission(item, rule.IfTrueSetPermissionTypeId));
            })          
        }
        else {          
            rule.TargetFieldId.map(item => {
                store.dispatch(updateFieldPermission(item, rule.IfFalseSetPermissionTypeId));
            })
        }
    }
}


const DisableTargetFieldIfInvalidTime = {
    execute
}

export default DisableTargetFieldIfInvalidTime
