import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Divider } from '@mui/material';

export default function NotificationItem(props) {
  const { notificationImage, notificationMessage, notificationTitle } = props
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <img src={notificationImage}
              alt=""
              height='50' width='50'
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={notificationTitle} secondary={notificationMessage} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  )
}
