import { store } from '../../../store';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import * as R from 'ramda';
import { getFieldValueById } from '../../GetFieldValueByID';
import { getFieldDetailsById } from '../../GetFieldDetailsById';
import { calculateValue } from '../../CalculateValue';
import { executeComplianceTracking } from '../../../services/CaseDetailsService';

const noInpatientValue = "0";
const inpatientValue = "1";

export const execute = (control, AddOnLoadONChangeFlag = null) => {
    const rule = control.additionalProperties?.ShowComplianceTextAndExecuteTracking;
    const fieldComplianceRule = rule?.ComputeComplianceValidity;
    const caseComplianceRule = rule?.ComputeComplianceByDependentFieldValue;
    const complianceTextRule = rule?.EvaluateAndShowText;
    const complianceTrackingRule = rule.ExecuteComplianceTracking;
    const caseCompliance = getFieldValueById(complianceTrackingRule.Condition[0].FieldsToCompare[0])

    if (AddOnLoadONChangeFlag) { // change in value
        computeFieldCompliance(fieldComplianceRule)
        computeCaseCompliance(caseComplianceRule)
        if (computeChangeInComplianceBl(complianceTrackingRule, caseCompliance)) {
            executeTracking(complianceTrackingRule);
        }
    }

    evaluateAndShowText(complianceTextRule)
}

const computeFieldCompliance = (fieldComplianceRule) => {
    let isFieldInpatient = null;
    const sourceFieldArray = fieldComplianceRule?.SourceFields;
    const targetFieldMapping = fieldComplianceRule?.TargetField;
    const getSourceField = R.find(R.propEq('Field'))(sourceFieldArray);
    const sourceFieldMapping = getSourceField?.Field;
    const sourceFieldValue = getSourceField?.Value;
    var path = [];
    path = sourceFieldMapping.split('.');
    var findFieldValue = getFieldDetailsById(path[0]);
    let getFieldValue = findFieldValue?.value;
    if (Array.isArray(getFieldValue)) { // CPT code
            const inpatientBl = !R.isNil(R.find(x => x[path[1]] == sourceFieldValue, getFieldValue));
            isFieldInpatient = inpatientBl ? sourceFieldValue : noInpatientValue;
    } else { // Admission type
        isFieldInpatient = getFieldValue ? getFieldValue[path[1]] === false ? noInpatientValue : inpatientValue : null
    }

    store.dispatch(updateControlValue(targetFieldMapping, isFieldInpatient))
}

const computeCaseCompliance = (caseComplianceRule) => {
    for(let i = 0; i < caseComplianceRule.length; i++) {
        const condition = caseComplianceRule[i];
        const isAMatch = condition.DependentFieldAndValues.map(field => {
            return getFieldValueById(field.DependentField) === field.DependentFieldValue;
        }).every(elem => elem === true)
        if(isAMatch) {
            store.dispatch(updateControlValue(condition.TargetField, condition.TargetFieldValue));
        } 
    }
}

const evaluateAndShowText = (complianceTextRule) => {
    let complianceText = "";
    complianceTextRule.DependentFieldAndValues.forEach(condition => {
        const conditionFieldValue = getFieldValueById(condition.DependentField)
        if(conditionFieldValue === condition.DependentFieldValue) {
            complianceText = complianceTextRule.DisplayText
        }
    })
    store.dispatch(updateControlValue(complianceTextRule.TargetFieldId, complianceText));
}

const computeChangeInComplianceBl = (complianceTrackingRule, currentCaseCompliance) => {
    return complianceTrackingRule.Condition.map(condition => {
        const newComplianceValue = getFieldValueById(condition.FieldsToCompare[0]);
        return calculateValue(newComplianceValue, currentCaseCompliance, condition.Operator)
    }).every(elem => elem === true)
}

const executeTracking = (complianceTrackingRule) => {
    const caseId = store.getState().caseDetails.caseId;
    const complianceValue = getFieldValueById(complianceTrackingRule.Fields.ComplianceBl)
    const complianceBl = complianceValue === inpatientValue ? true : complianceValue === noInpatientValue ? false : null
    
    let payload = {
        ...complianceTrackingRule.Fields.DefaultFieldsAndValues,
        CaseId: caseId,
        ComplianceBl: complianceBl,
        FieldAndValues: addTrackingFieldsToPayload(complianceTrackingRule)
    }

    executeComplianceTracking(caseId, payload);
}

const addTrackingFieldsToPayload = (complianceTrackingRule) => {
    let payloadFields = [];

    complianceTrackingRule.Fields.FieldAndValues?.forEach(fieldDetails => {
        const path = fieldDetails.FieldValue.split('.');
        let value = null
        if(path.length > 1) {
            const currentFieldValue = getFieldValueById(path[0])
            value = currentFieldValue ? "" + currentFieldValue : null;
        } else {
            const filterCondition = fieldDetails.FilterCondition;
            if (filterCondition) {
                value = getFieldValueById(filterCondition.SourceField)?.filter(function(elem){
                    const mappedFilterValue = filterCondition.FilterValue === "1" ? true : false;
                    return elem[filterCondition.ChildField] === mappedFilterValue;
                })?.map(elem => elem[fieldDetails.FieldValue])
                value = R.isEmpty(value) ? null : value.join()
            } else {
                value = getFieldValueById(fieldDetails.FieldValue)
            }
        }
        payloadFields.push({FieldName: fieldDetails.FieldName, FieldValue: value })
    })

    return payloadFields
}


const ShowComplianceTextAndExecuteTracking = {
    execute
}
export default ShowComplianceTextAndExecuteTracking