import { TOGGLE_DIALOG } from "../constants/ActionTypes"
import { getControlByFieldId } from "../utils/GetControlByFieldId"
import { getFieldById } from "../utils/GetFieldById"

// Remove from target
export const toggleDialog = (replacedControl) => {
    return {
        type: TOGGLE_DIALOG,
        payload: { replacedControl }
    }
}

export const showDialog = (mappingId, isShow, control) => {
    return async function (dispatch, getState) {
        const state = getState()
        var sectionDetails = state.caseDetails.sectionDetails
        if (sectionDetails) {
            var replacedControl = sectionDetails
            replacedControl.sectionGroup.forEach(x => {
               if(control?.parentFieldId != null)
                {
                    //let targetControl = x.fields.find(item => item.fieldId == fieldId);
                    control['showDialog'] = isShow
                    x.fields.push(control);
                }
                else
                {
                    let targetControl = x.fields.find(element => element.mappingId == mappingId)
                    if (targetControl) {
                         targetControl['showDialog'] = isShow
                    }
                }
                
            })
            dispatch(toggleDialog(replacedControl))
        }
    }
}

