import { NOTIFICATION_RECEIVED } from "../constants/ActionTypes";

const initialState = []

const ACTION_HANDLERS = {
    [NOTIFICATION_RECEIVED]: (state, action) => {
        return [...state,
        action.notification
        ]
    },
}

export default function notification(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}