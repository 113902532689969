import { copyRefData, reloadAndCopyReferenceData } from "../../../actions/ControlSelectionsAction"
import { store } from "../../../store"
import { calculateValue } from "../../CalculateValue"
import { getFieldValueById } from "../../GetFieldValueByID"

const execute = control => {
    let rules = control.additionalProperties.LoadReferenceDataByCondition
    rules.map(rule => {
        let referenceDataId = rule.LoadReferenceData
        if(rule.Condition.every(condition => {
            return calculateValue(condition.DependentFieldValue, getFieldValueById(condition.DependentFieldId), condition.DependentFieldValueOperator)
        })) {
            let refDataDependentFieldVal
            if(rule.DependentFields) {
                rule.DependentFields.map(fieldId => {
                    refDataDependentFieldVal = {
                        ...refDataDependentFieldVal,
                        [fieldId] : getFieldValueById(fieldId)
                    }
                })
                store.dispatch(reloadAndCopyReferenceData(control.mappingId, referenceDataId, refDataDependentFieldVal))
            }
            else {
                store.dispatch(copyRefData(control.mappingId, referenceDataId))
            }
        }
        
    })
    
}

const LoadReferenceDataByCondition = {
    execute
}

export default LoadReferenceDataByCondition