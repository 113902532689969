import moment from 'moment';
import {ALLOWED_DATE_FORMATES,DEFAULT_DATE_FORMATES} from '../../constants/GlobalConstants';

export const isSameDay = (predicateDate, subjectDate) =>
  moment(predicateDate).isSame(moment(subjectDate), 'day');

export const toDateString = date =>
  moment(date)
    .startOf('day')
    .toISOString();

export const formatDate =(dateformat) =>  { return ALLOWED_DATE_FORMATES.includes(dateformat)?dateformat: DEFAULT_DATE_FORMATES;};

export const timeFromDateTime = dateTime => moment(dateTime).format('HH:mm A');

export const formatTime = (time, timeFormat = 'HH:mm:ss', to = 'hh:mm A') =>
  moment(time, timeFormat, true).format(to);

export const timeToDateTime = (time, timeFormat = 'h:mm:ss A') =>
  moment(time, timeFormat, true);

export const isValidDate = d => {
  return d instanceof Date && !isNaN(d);
};

export const toDate = d =>
  d &&
  moment(d)
    .startOf('day')
    .format('YYYY-MM-DD');

export const currentDayStart = (date = new Date()) =>
  moment(date)
    .startOf('day')
    .toDate();

    
  export const  getFullAge = (dateOfBirth, diffFutureDate) => {
    var diffDate = diffFutureDate ? diffFutureDate : new Date()
    const today = moment(diffDate);
    const dob = moment(dateOfBirth);

    const years = today.diff(dob, 'year');
    dob.add(years, 'years');

    const months = today.diff(dob, 'months');
    dob.add(months, 'months');

    const days = today.diff(dob, 'days');

    const fullAge=years + ' years ' + months + ' months ' + days + ' days';

    return fullAge;
  }
