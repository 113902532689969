import VisibilityControlAction from "../../../actions/VisibilityControlAction"
import { store } from "../../../store"

const execute = control => {
    let isTrue = control.additionalProperties.HideWhenCaseStatusIsNotCancelled     
        var caseStatus=1; // TODO : need to get the case status from API
        if(isTrue && caseStatus==caseStatus) {  // TODO: Need to update the condition
            store.dispatch(VisibilityControlAction.hideField(control.mappingId))
        } 
}

const HideWhenCaseStatusIsNotCancelled = {
    execute
}

export default HideWhenCaseStatusIsNotCancelled