import {
  INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE, MULTI_SELECT
} from '../../../constants/GlobalConstants';
import { store } from '../../../store';
import { updateIsRequiredValidation } from '../../../actions/CaseDetailsAction';

export const inputSelectionMoreThanProvidedValue = (control, AddOnLoadONChangeFlag = null) => {
  var fieldValue = control.value ? control.value : null;
  if (control.fieldTypeName === MULTI_SELECT) {
    fieldValue = fieldValue ? Array.isArray(fieldValue) ? fieldValue : JSON.parse(fieldValue) : [];
    fieldValue = fieldValue && fieldValue.length > 0 ? fieldValue : null;
  }
  if (control && control.validation && AddOnLoadONChangeFlag) {
    var validation = control.validation
    var checkDependentFields = validation[INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE] ? validation[INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE] : null;
    var response = null;
    if (checkDependentFields != null) {
      checkDependentFields.forEach(checkDependent => {
        var providedValue = checkDependent.ProvidedValue ? checkDependent.ProvidedValue : null;
        if (fieldValue == null || fieldValue == providedValue) {
          response = {
            textFormat: null,
            status: false,
            message: "Invalid value!"
          };
          store.dispatch(updateIsRequiredValidation(control.mappingId, true, control.parentFieldId))
        } else {
          store.dispatch(updateIsRequiredValidation(control.mappingId, false, control.parentFieldId))
        }
      });
    }
  }
  return response;
}