import React from 'react';
import { TIFFViewer } from 'react-tiff';
import * as Colors from '../../res/values/Colors'

const TiffPreview = ({ file }) => {
    // Render tiff file 
    const RenderTiff = ({ fileUrl }) => {
        return (
            <TIFFViewer
              tiff={fileUrl}
              lang='en'
              buttonColor={Colors.GREY_BG}
            />
        )
    }

    return (
        <RenderTiff fileUrl={file} />
    )
}

export default React.memo(TiffPreview)
