import Controls from '../controls/Controls'
import MedtelFileUploader from '../controls/MedtelFileUploader';

export const controlByType = fieldType => {
  const type = fieldType.toLowerCase().trim();
  const controls = {
    text: [Controls.Input, { variant: 'outlined' }],
    embedhtml: [Controls.EmbedHtml],
    password: [Controls.Input, { variant: 'outlined' }],
    number: [Controls.Input, { variant: 'outlined', type: 'number' },],
    phonenumber: [Controls.Input, { variant: 'outlined', type: 'phonenumber' },],
    zipcode: [Controls.Input, { variant: 'outlined', type: 'zipcode' },],
    label: [Controls.Label, { variant: 'outlined' },],
    table: [Controls.TableControl, { variant: 'outlined' },],
    date: [Controls.DatePicker, { variant: 'outlined' }],
    time: [Controls.TimePicker, { variant: 'outlined' }],
    selection: [Controls.AutoSuggest, { variant: 'outlined' }],
    selectionkeyvalue: [Controls.AutoSuggest, { variant: 'outlined' }],
    typeahead: [Controls.AutoSuggest, { variant: 'outlined' }],
    checkbox: [Controls.Checkbox, {}],
    button: [Controls.Button, {}],
    buttons: [Controls.Button, {}],
    verifybutton: [Controls.Button, { color: 'success' }],
    cancelbutton: [Controls.Button, { variant: 'outlined' }],
    chips: [Controls.ChipHolder, { variant: 'outlined' }],
    imagebuttons: [Controls.ImageButton, {}],
    selectfile: [File, {}],
    div: [Controls.Div, {}],
    image: [Controls.Image],
    filepreview: [Controls.FilePreview],
    label2: [Controls.VerticalLabel, { variant: 'outlined' },],
    table2: [Controls.TrayTable, { variant: 'outlined' },],
    greenbutton: [Controls.GreenButton, {}],
    multiselect: [Controls.MultiSelect, { variant: 'outlined' }],
    pdfViewer: [Controls.PdfViewer],
    anchorlink: [Controls.AnchorLink],
  };
  return controls[type] || undefined;
};