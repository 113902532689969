import { store } from '../../../store'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import { getFieldById } from '../../GetFieldById';
import * as Constants from '../../../constants/GlobalConstants'

export const execute = (props) => {
    const fieldProps = props.additionalProperties
    if (fieldProps && fieldProps.IsHiddenWhenDependentFieldDoesNotHaveValue) {
        fieldProps.IsHiddenWhenDependentFieldDoesNotHaveValue.forEach(item => {
            let control = getFieldById(item.DependendFieldId)
            if (control) {
                if (control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
                    var value = control.value ? control.value[control.additionalProperties.DisplayColumnId] : null
                } else {
                    var value = control.value
                }
                if (value != item.DependentFieldValue) {
                    store.dispatch(VisibilityControlAction.hideField(props.mappingId))
                }
            }
        })
    }
}

const IsHiddenWhenDependentFieldDoesNotHaveValue = {
    execute
}

export default IsHiddenWhenDependentFieldDoesNotHaveValue   