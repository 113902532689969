import HideTargetFieldIfFieldDoesNotHaveValue from './HideTargetFieldIfFieldDoesNotHaveValue'
import ShowTargetFieldIfFieldHasValue from './ShowTargetFieldIfFieldHasValue';
import ShowImageOnTargetFieldIfFieldHasValue from './ShowImageIfFieldHasValue';
import OnChangeDataToClear from './FieldDataToClearValidation';
import CalculateAge from './CalculateAge';
import CalculateBMI from './CalculateBMI';
import CalculateBMIRatio from './CalculateBMIRatio';
import CalculateTotalHeight from './CalculateTotalHeight';
import * as Constants from "../../../constants/GlobalConstants";
import OnSelectAddValueToTargetField from './OnSelectAddValueToTargetField'
import OnClickAddValueToTargetFieldInChips from './OnClickAddValueToTargetFieldInChips'
import HideTargetFieldIfFieldHasValue from './HideTargetFieldIfFieldHasValue';
import OnSelectionShowTargetField from './OnSelectionShowTargetField';
import OnSelectionHideTargetField from './OnSelectionHideTargetField';
import OnCheckSetValuesFromOtherFields from './OnCheckSetValuesFromOtherFields';
import OnSelectionManageSectionGroups from './OnSelectionManageSectionGroups';
import MakeTargetFieldARequiredField from './MakeTargetFieldARequiredField';
import MakeTargetFieldNotARequiredField from './MakeTargetFieldNotARequiredField';
import OnSelectReloadReferenceDataInTargetFields from './OnSelectReloadReferenceDataInTargetFields';
import OnChangeSectionDataToClear from './OnChangeSectionDataToClear';
import ConfigurePropertiesOfTargetField from './ConfigurePropertiesOfTargetField';
import ShowTargetFieldWhenFieldValueAssignedToDependentField from './ShowTargetFieldWhenFieldValueAssignedToDependentField'
import PreventDuplicateEntryInTargetField from './PreventDuplicateEntryInTargetField';
import MakeTargetFieldARequiredFieldWhenFieldValueAssignedToDependentField from './MakeTargetFieldARequiredFieldWhenFieldValueAssignedToDependentField';
import IsHiddenWhenDependentFieldDoesNotHaveValue from './IsHiddenWhenDependentFieldDoesNotHaveValue';
import ChangeFieldPermissionByDependentFieldsValueByCondition from './ChangeFieldPermissionByDependentFieldsValueByCondition';
import OnClickExecuteServiceAPI from './OnClickExecuteServiceAPI';
import DisableFieldDueToNoValueInDependentFields from './DisableFieldDueToNoValueInDependentFields';
import DisableTargetFieldIfNoInputSelected from './DisableTargetFieldIfNoInputSelected';
import DisableTargetFieldIfNoValueInFields from './DisableTargetFieldIfNoValueInFields';
import DisableTargetFieldIfNoValueInFieldsByCondition from './DisableTargetFieldIfNoValueInFieldsByCondition';
import DisableTargetFieldIfNoValueByCondition from './DisableTargetFieldIfNoValueByCondition';
import IsVisibleWhen from './IsVisibleWhen';
import OnClickAddValueToTargetFieldByCondition from './OnClickAddValueToTargetFieldByCondition';
import MakeTargetFieldARequiredFieldOnSelection from './MakeTargetFieldARequiredFieldOnSelection';
import LoadReferenceData from './LoadReferenceData';
import LoadReferenceDataByCondition from './LoadReferenceDataByCondition';
import OnAddExecuteRules from './OnAddExecuteRules';
import OnClickAddValueToTargetField from './OnClickAddValueToTargetField';
import DoNotOverrideTargetFieldValidationsWhenFieldHasValue from './DoNotOverrideTargetFieldValidationsWhenFieldHasValue';
import OverrideTargetFieldValidationsWhenFieldHasValue from './OverrideTargetFieldValidationsWhenFieldHasValue';
import OnClickShowSectionAsPopup from './OnClickShowSectionAsPopup';
import OnClickClosePopup from './OnClickClosePopup';
import DisableTargetFieldIfFieldHasValue from './DisableTargetFieldIfFieldHasValue';
import VisibleWhenCaseStatusIsCancelled from './VisibleWhenCaseStatusIsCancelled';
import HideWhenCaseStatusIsNotCancelled from './HideWhenCaseStatusIsNotCancelled';
import DisableTargetFieldIfNoValue from './DisableTargetFieldIfNoValue';
import DisableTargetFieldIfFieldDateGreaterThanCurrentDate from './DisableTargetFieldIfFieldDateGreaterThanCurrentDate';
import CalculateDateAhead from './CalculateDateAhead';
import CompareOtherFieldValues from "./CompareOtherFieldValues";
import DisableTargetFieldByComparingOtherFieldValues from "./DisableTargetFieldByComparingOtherFieldValues";
import DisableTargetFieldIfInvalidValue from "./DisableTargetFieldIfInvalidValue";
import DisableTargetFieldIfInvalidTime from "./DisableTargetFieldIfInvalidTime";
import Format from './Format'
import OnSelectAddValueToTargetFieldByCondition from './OnSelectAddValueToTargetFieldByCondition'
import ShowTargetFieldIfAdditionalFieldHasValue from './ShowTargetFieldIfAdditionalFieldHasValue';
import HideTargetFieldIfAdditionalFieldDoesNotHaveValue from './HideTargetFieldIfAdditionalFieldDoesNotHaveValue';
import MakeTargetFieldARequiredFieldDueToAdditionalFieldValue from './MakeTargetFieldARequiredFieldDueToAdditionalFieldValue';
import DisableTargetFieldIfAdditionalFieldHasValue from './DisableTargetFieldIfAdditionalFieldHasValue';
import CalculatePatientAgeInYearsOnProcedureDate from './CalculatePatientAgeInYearsOnProcedureDate';
import CalculateDateDifferenceInDays from './CalculateDateDifferenceInDays';
import DisplayControlTextByCondition from './DisplayControlTextByCondition';
import ShowTargetFieldIfFieldHasAnyValue from './ShowTargetFieldIfFieldHasAnyValue';
import HideTargetFieldIfFieldDoesNotHaveAnyValue from './HideTargetFieldIfFieldDoesNotHaveAnyValue';
import DisplayFieldNameAndFieldValue from './DisplayFieldNameAndFieldValue';
import OnClickCopyContentFromTargetField from './OnClickCopyContentFromTargetField';
import OnClickOpenPrintDialog from './OnClickOpenPrintDialog';
import OnDeleteExecuteRules from './OnDeleteExecuteRules';
import OnClickShowSectionAsPopupWithDependentFields from './OnClickShowSectionAsPopupWithDependentFields';
import HideColumnOfTargetFieldWhenFieldDoesHaveValue from './HideColumnOfTargetFieldWhenFieldDoesHaveValue';
import ShowColumnOfTargetFieldWhenFieldHasValue from './ShowColumnOfTargetFieldWhenFieldHasValue';
import DisableTargetFieldByComparisonWithDependentField from './DisableTargetFieldByComparisonWithDependentField';
import AssignValueToTargetFieldDueToDependentField from './AssignValueToTargetFieldDueToDependentField';
import OnChangeDataToClearDueToDependentFieldValue from './OnChangeDataToClearDueToDependentFieldValue';
import ShowTargetFieldIfFieldAndDependentFieldHaveValue from './ShowTargetFieldIfFieldAndDependentFieldHaveValue';
import HideTargetFieldIfFieldAndDependentFieldDoesNotHaveValue from './HideTargetFieldIfFieldAndDependentFieldDoesNotHaveValue';
import ConcatenateFieldText from './ConcatenateFieldText';
import OnChangeExecuteAdditionalPropertiesOfTargetField from './OnChangeExecuteAdditionalPropertiesOfTargetField';
import ShowTargetFieldIfDependentFieldHasAnyValue from './ShowTargetFieldIfDependentFieldHasAnyValue';
import HideTargetFieldIfDependentFieldDoesNotHaveAnyValue from './HideTargetFieldIfDependentFieldDoesNotHaveAnyValue';
import OnClickShowPopup from './OnClickShowPopup';
import HideTargetFieldIfFieldDoesNotHaveValueInTable from './HideTargetFieldIfFieldDoesNotHaveValueInTable';
import ShowTargetFieldIfFieldHasValueInTable from './ShowTargetFieldIfFieldHasValueInTable';
import ShowTargetFieldIfAdditionalFieldHasValueInTable from './ShowTargetFieldIfAdditionalFieldHasValueInTable';
import HideTargetFieldIfAdditionalFieldHasValueInTable from './HideTargetFieldIfAdditionalFieldHasValueInTable';
import OnSelectReloadReferenceDataInTargetFieldInTable from './OnSelectReloadReferenceDataInTargetFieldInTable';
import DisableTargetFieldIfDependentFieldDoesNotHaveAnyValue from './DisableTargetFieldIfDependentFieldDoesNotHaveAnyValue';
import ShowComplianceTextAndExecuteTracking from './ShowComplianceTextAndExecuteTracking';
import DisableDeleteDueToDependentFieldValue from './DisableDeleteDueToDependentFieldValue'
import OnClickGenerateAndAddValueToTargetFieldByCondition from './OnClickGenerateAndAddValueToTargetFieldByCondition';
import DeleteFromTargetFieldByCondition from './DeleteFromTargetFieldByCondition';
import OnClickUpdateTargetField from './OnClickUpdateTargetField';

export const getApplicableRule = (ruleKey) => {
    var obj;
    switch (ruleKey) {
        case Constants.SHOW_FIELD_IF_FIELD_HAS_VALUE:
            obj = ShowTargetFieldIfFieldHasValue
            break
        case Constants.HIDE_FIELD_IF_FIELD_DOES_NOT_HAVE_VALUE:
            obj = HideTargetFieldIfFieldDoesNotHaveValue
            break
        case Constants.HIDE_TARGET_FIELD_IF_FIELD_HAS_VALUE:
            obj = HideTargetFieldIfFieldHasValue
            break
        case Constants.PREVENT_DUPLICATE_ENTRIES:
            //obj = PreventDuplicateEntries
            break
        case Constants.ON_SELECT_ADD_VALUE_TO_TARGET_FIELD:
            obj = OnSelectAddValueToTargetField
            break
        case Constants.ON_CHANGE_DATA_TO_CLEAR:
            obj = OnChangeDataToClear
            break
        case Constants.CALCULATE_AGE:
            obj = CalculateAge
            break
        case Constants.CALCULATE_BMI:
            obj = CalculateBMI
            break
        case Constants.CALCULATE_BMI_RATIO:
            obj = CalculateBMIRatio
            break
        // case Constants.IS_REQUIRED_WHEN:
        //     obj = IsRequiredWhen
        //     break
        case Constants.ON_SELECT_HIDE_TARGET_FIELD:
            obj = OnSelectionHideTargetField
            break
        case Constants.ON_SELECT_SHOW_TARGET_FIELD:
            obj = OnSelectionShowTargetField
            break
        case Constants.ON_CHECK_SET_VALUES_FROM_OTHER_FIELDS:
            obj = OnCheckSetValuesFromOtherFields
            break
        case Constants.CALCULATE_TOTAL_HEIGHT:
            obj = CalculateTotalHeight
            break
        case Constants.ON_SELECTION_MANAGE_SECTION_GROUPS:
            obj = OnSelectionManageSectionGroups
            break
        case Constants.MAKE_TARGET_FIELD_A_REQUIRED_FIELD:
            obj = MakeTargetFieldARequiredField
            break
        case Constants.MAKE_TARGET_FIELD_NOT_A_REQUIRED_FIELD:
            obj = MakeTargetFieldNotARequiredField
            break
        case Constants.ON_SELECT_RELOAD_REFERENCE_DATA_IN_TARGET_FIELD:
            obj = OnSelectReloadReferenceDataInTargetFields
            break
        case Constants.ON_CHANGE_SECTION_DATA_TO_CLEAR:
            obj = OnChangeSectionDataToClear
            break
        case Constants.CONFIGURE_INPUT_PROPERTIES_OF_TARGET_FIELD:
            obj = ConfigurePropertiesOfTargetField
            break
        case Constants.SHOW_TARGET_FIELD_WHEN_FIELD_VALUE_ASSIGNED_TO_DEPENDENT_FIELD:
            obj = ShowTargetFieldWhenFieldValueAssignedToDependentField
            break
        case Constants.PREVENT_DUPLICATE_ENTRY_IN_TARGET_FIELD:
            obj = PreventDuplicateEntryInTargetField
            break
        case Constants.MAKE_TARGET_FIELD_A_REQUIRED_FIELD_WHEN_FIELD_VALUE_ASSIGNED_DEPENDENT_FIELD:
            obj = MakeTargetFieldARequiredFieldWhenFieldValueAssignedToDependentField
            break
        case Constants.IS_HIDDEN_WHEN_DEPENDENT_FIELD_DOES_NOT_HAVE_VALUE:
            obj = IsHiddenWhenDependentFieldDoesNotHaveValue
            break
        case Constants.CHANGE_FIELD_PERMISSION_BY_DEPENDENT_FIELDS_VALUE_BY_CONDITION:
            obj = ChangeFieldPermissionByDependentFieldsValueByCondition
            break
        case Constants.ON_CLICK_EXECUTE_SERVICE_API:
            obj = OnClickExecuteServiceAPI
            break
        case Constants.DISABLE_FIELD_DUE_TO_NO_VALUE_IN_DEPENDENT_FIELDS:
            obj = DisableFieldDueToNoValueInDependentFields
            break
        case Constants.DISABLE_TARGET_FIELD_IF_NO_INPUT_SELECTED:
            obj = DisableTargetFieldIfNoInputSelected
            break
        case Constants.DISABLE_TARGET_FIELD_IF_NO_VALUE_IN_FIELDS:
            obj = DisableTargetFieldIfNoValueInFields
            break
        case Constants.DISABLE_TARGET_FIELD_IF_NO_VALUE_IN_FIELDS_BY_CONDITION:
            obj = DisableTargetFieldIfNoValueInFieldsByCondition
            break
        case Constants.DISABLE_FUTURE_DATE_KEY:
            obj = DisableTargetFieldIfFieldDateGreaterThanCurrentDate
            break
        case Constants.CALCULATE_DATE_AHEAD:
            obj = CalculateDateAhead
            break
        case Constants.COMPARE_OTHER_FIELD_VALUES:
            obj = CompareOtherFieldValues
            break
        case Constants.DISABLE_TARGET_FIELD_BY_COMPARING_OTHER_FIELD_VALUES:
            obj = DisableTargetFieldByComparingOtherFieldValues
            break
        case Constants.IS_VISIBLE_WHEN:
            obj = IsVisibleWhen
            break
        case Constants.ON_CLICK_ADD_VALUE_TO_TARGET_FIELD_BY_CONDITION:
            obj = OnClickAddValueToTargetFieldByCondition
            break
        case Constants.LOAD_REFERENCE_DATA:
            obj = LoadReferenceData
            break
        case Constants.LOAD_REFERENCE_DATA_BY_CONDITION:
            obj = LoadReferenceDataByCondition
            break
        case Constants.DISABLE_TARGET_FIELD_IF_NO_VALUE_BY_CONDITION:
            obj = DisableTargetFieldIfNoValueByCondition
            break
        case Constants.DO_NOT_OVERRIDE_TARGET_FIELD_VALIDATIONS_WHEN_FIELD_HAS_VALUE:
            obj = DoNotOverrideTargetFieldValidationsWhenFieldHasValue
            break
        case Constants.OVERRIDE_TARGET_FIELD_VALIDATIONS_WHEN_FIELD_HAS_VALUE:
            obj = OverrideTargetFieldValidationsWhenFieldHasValue
            break
        case Constants.MAKE_TARGET_FIELD_A_REQUIRED_FIELD_ON_SELECTION:
            obj = MakeTargetFieldARequiredFieldOnSelection
            break
        case Constants.ON_ADD_EXECUTE_RULES:
            obj = OnAddExecuteRules
            break
        case Constants.ON_CLICK_ADD_VALUE_TO_TARGET_FIELD:
            obj = OnClickAddValueToTargetField
            break
        case Constants.ON_CLICK_ADD_VALUE_TO_TARGET_FIELD_IN_CHIPS:
            obj = OnClickAddValueToTargetFieldInChips
            break
        case Constants.ON_CLICK_SHOW_SECTION_AS_POPUP:
            obj = OnClickShowSectionAsPopup
            break
        case Constants.ON_CLICK_CLOSE_POPUP:
            obj = OnClickClosePopup
            break
        case Constants.DISABLE_TARGET_FIELD_IF_FIELD_HAS_VALUE:
            obj = DisableTargetFieldIfFieldHasValue
            break
        case Constants.VISIBLE_WHEN_CASE_STATUS_IS_CANCELLED:
            obj = VisibleWhenCaseStatusIsCancelled
            break
        case Constants.HIDE_WHEN_CASE_STATUS_IS_NOT_CANCELLED:
            obj = HideWhenCaseStatusIsNotCancelled
            break
        case Constants.DISABLE_TARGET_FIELD_IF_NO_VALUE:
            obj = DisableTargetFieldIfNoValue
            break
        case Constants.DISABLE_TARGET_FIELD_IF_INVALID_VALUE:
            obj = DisableTargetFieldIfInvalidValue
            break
        case Constants.DISABLE_TARGET_FIELD_IF_INVALID_TIME:
            obj = DisableTargetFieldIfInvalidTime
            break
        case Constants.FORMAT:
            obj = Format
            break
        case Constants.ON_SELECT_ADD_VALUE_TO_TARGET_FIELD_BY_CONDITION:
            obj = OnSelectAddValueToTargetFieldByCondition
            break
        case Constants.SHOW_TARGET_FIELD_IF_ADDITIONAL_FIELD_HAS_VALUE:
            obj = ShowTargetFieldIfAdditionalFieldHasValue
            break
        case Constants.HIDE_TARGET_FIELD_IF_ADDITIONAL_FIELD_DOES_NOT_HAVE_VALUE:
            obj = HideTargetFieldIfAdditionalFieldDoesNotHaveValue
            break
        case Constants.MAKE_TARGET_FIELD_A_REQUIRED_FIELD_DUE_TO_ADDITIONAL_FIELD:
            obj = MakeTargetFieldARequiredFieldDueToAdditionalFieldValue
            break
        case Constants.DISABLE_TARGET_FIELD_IF_ADDITIONAL_FIELD_HAS_VALUE:
            obj = DisableTargetFieldIfAdditionalFieldHasValue
            break
        case Constants.CALCULATE_PATIENT_AGE_IN_YEARS_ON_PROCEDURE_DATE:
            obj = CalculatePatientAgeInYearsOnProcedureDate
            break
        case Constants.CALCULATE_DATE_DIFFERENCE_IN_DAYS:
            obj = CalculateDateDifferenceInDays
            break
        case Constants.DISPLAY_CONTROL_TEXT_BY_CONDITION:
            obj = DisplayControlTextByCondition
            break
        case Constants.SHOW_TARGET_FIELD_IF_FIELD_HAS_ANY_VALUE:
            obj = ShowTargetFieldIfFieldHasAnyValue
            break
        case Constants.HIDE_TARGET_FIELD_IF_FIELD_DOES_NOT_HAVE_ANY_VALUE:
            obj = HideTargetFieldIfFieldDoesNotHaveAnyValue
            break
        case Constants.DISPLAY_FIELD_NAME_AND_FIELD_VALUE:
            obj = DisplayFieldNameAndFieldValue
            break
        case Constants.ON_CLICK_COPY_CONTENT_FROM_TARGET_FIELD:
            obj = OnClickCopyContentFromTargetField
            break
        case Constants.ON_CLICK_OPEN_PRINT_DIALOG:
            obj = OnClickOpenPrintDialog
            break
        case Constants.ON_DELETE_EXECUTE_RULES:
            obj = OnDeleteExecuteRules
            break
        case Constants.ON_CLICK_UPDATE_TARGET_FIELD:
            obj = OnClickUpdateTargetField
            break
        case Constants.ON_CLICK_SHOW_SECTION_POP_UPWITH_DF:
            obj = OnClickShowSectionAsPopupWithDependentFields
            break
        case Constants.SHOW_IMAGE_IF_FIELD_HAS_VALUE:
            obj = ShowImageOnTargetFieldIfFieldHasValue
            break
        case Constants.HIDE_COLUMN_OF_TARGET_FIELD_WHEN_FIELD_DOES_NOT_HAVE_VALUE:
            obj = HideColumnOfTargetFieldWhenFieldDoesHaveValue
            break
        case Constants.SHOW_COLUMN_OF_TARGET_FIELD_WHEN_FIELD_HAS_VALUE:
            obj = ShowColumnOfTargetFieldWhenFieldHasValue
            break
        case Constants.DISABLE_TARGET_FIELD_BY_COMPARISON_WITH_DEPENDENT_FIELD:
            obj = DisableTargetFieldByComparisonWithDependentField
            break
        case Constants.ASSIGN_VALUE_DUE_TO_DEPENDENT_FIELD:
            obj = AssignValueToTargetFieldDueToDependentField
            break
        case Constants.ON_CHANGE_DATA_TO_CLEAR_DEPENDENT_FIELD:
            obj = OnChangeDataToClearDueToDependentFieldValue
            break
        case Constants.SHOW_TARGET_FIELD_AND_DEPENDENT_FIELD_HAVE_VALUE:
            obj = ShowTargetFieldIfFieldAndDependentFieldHaveValue
            break
        case Constants.HIDE_TARGET_FIELD_AND_DEPENDENT_FIELD_HAVE_VALUE:
            obj = HideTargetFieldIfFieldAndDependentFieldDoesNotHaveValue
            break
        case Constants.CONCATENATE_FIELD_TEXT:
            obj = ConcatenateFieldText
            break
        case Constants.ON_CHANGE_EXECUTE_ADDITIONAL_PROPERTIES_OF_TARGET_FIELD:
            obj = OnChangeExecuteAdditionalPropertiesOfTargetField
            break
        case Constants.SHOW_TARGET_FIELD_IF_DEPENDENT_FIELD_HAS_ANY_VALUE:
            obj = ShowTargetFieldIfDependentFieldHasAnyValue
            break
        case Constants.HIDE_TARGET_FIELD_IF_DEPENDENT_FIELD_DOES_NOT_HAVE_ANY_VALUE:
            obj = HideTargetFieldIfDependentFieldDoesNotHaveAnyValue
            break
        case Constants.ON_CLICK_SHOW_POPUP:
            obj = OnClickShowPopup
            break
        case Constants.HIDE_TARGET_FIELD_IF_FIELD_DOES_NOT_HAVE_VALUE_IN_TABLE:
            obj = HideTargetFieldIfFieldDoesNotHaveValueInTable
            break
        case Constants.SHOW_TARGET_FIELD_IF_FIELD_HAVE_VALUE_IN_TABLE:
            obj = ShowTargetFieldIfFieldHasValueInTable
            break
        case Constants.SHOW_TARGET_FIELD_IF_ADDITIONAL_FIELD_HAVE_VALUE_IN_TABLE:
            obj = ShowTargetFieldIfAdditionalFieldHasValueInTable
            break
        case Constants.HIDE_TARGET_FIELD_IF_ADDITIONAL_FIELD_HAVE_VALUE_IN_TABLE:
            obj = HideTargetFieldIfAdditionalFieldHasValueInTable
            break
        case Constants.ON_SELECT_RELOAD_REFERENCE_DATA_IN_TARGET_FIELD_IN_TABLE:
            obj = OnSelectReloadReferenceDataInTargetFieldInTable
            break
        case Constants.DISABLE_TARGET_FIELD_IF_DEPENDENT_FIELD_DOES_NOT_HAVE_ANY_VALUE:
            obj = DisableTargetFieldIfDependentFieldDoesNotHaveAnyValue
            break
        case Constants.SHOW_COMPLIANCE_TEXT_AND_EXECUTE_TRACKING:
            obj = ShowComplianceTextAndExecuteTracking
            break
        case Constants.DISABLE_DELETE_DUE_TO_DEPENDENT_FIELD_VALUE:
            obj = DisableDeleteDueToDependentFieldValue
            break
        case Constants.ON_CLICK_GENERATE_AND_ADD_VALUE_TO_TARGET_FIELD_BY_CONDITION:
            obj = OnClickGenerateAndAddValueToTargetFieldByCondition
            break
        case Constants.DELETE_FROM_TARGET_FIELD_BY_CONDITION:
            obj = DeleteFromTargetFieldByCondition
            break
    }
    return obj
}

const RuleFactory = {
    getApplicableRule
}

export default RuleFactory