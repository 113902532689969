import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { populateTarget } from '../../../actions/TypeaheadAction';
import { showField } from '../../../actions/VisibilityControlAction';
import { store } from '../../../store';
import { getFieldById } from '../../GetFieldById';
import moment from 'moment';
import { getFieldValueById } from '../../GetFieldValueByID';

export const execute = (control, AddOnLoadONChangeFlag = null) => {
    const additionalProps = control.additionalProperties
    if (AddOnLoadONChangeFlag) {
        store.dispatch(showField(additionalProps.OnClickAddValueToTargetFieldInChips.TargetFieldId))
        let value = {};
        additionalProps.OnClickAddValueToTargetFieldInChips.SourceFields.map(element => {
            value[element] = getFieldValueById(element);
        })
        if (value) {
            if (additionalProps.OnClickAddValueToTargetFieldInChips) {
                let dataToDispatch = prepareTargetData(additionalProps.OnClickAddValueToTargetFieldInChips.SourceFieldTargetFieldValueMapping, value)
                store.dispatch(populateTarget(additionalProps.OnClickAddValueToTargetFieldInChips.TargetFieldId, dataToDispatch))
            }
        }
    }
}

const prepareTargetData = (fieldMap, value) => {
    let mapppingKeys = Object.keys(fieldMap)
    let preparedData = {}
    mapppingKeys.map(key => {
        if (typeof fieldMap[key] == 'string') {
            preparedData[fieldMap[key]] = value[key]
        } else if (typeof fieldMap[key] == 'object') {
            let objectKeys = Object.keys(fieldMap[key])
            let objectValues = Object.values(fieldMap[key])
            for (var i = 0; i < objectKeys.length; i++) {
                if (objectValues[i] == "CurrentDateTime") {
                    var field = getFieldById(objectKeys[i]);
                    if (field && field.additionalProperties) {
                        var format = field.additionalProperties.Format;
                        preparedData[objectKeys[i]] = moment(new Date()).format(format);
                    }
                }
                else if (objectValues[i] != null && typeof (objectValues[i]) == 'object') {
                    if (Array.isArray(objectValues[i])) {
                        let str = "";
                        objectValues[i].map(item => {
                            if(str == ""){
                                str = str + preparedData[item];
                            }
                            else{
                                str = str + ' - ' + preparedData[item];
                            }                            
                        })
                        preparedData[objectKeys[i]] = str;
                    }
                }
                else {
                    preparedData[objectKeys[i]] = objectValues[i];
                }
            }
        }
    })
    return preparedData
}

const OnClickAddValueToTargetFieldInChips = {
    execute
}

export default OnClickAddValueToTargetFieldInChips