import { getSingleSectionDetails, updateIsPopUpOpen, updateSelectedInsuranceDependantFields } from "../../../actions/CaseDetailsAction";
import { showDialog } from "../../../actions/DialogAction";
import { getPopupSectionDetails } from "../../../reducers/CaseDetailsReducer";
import { store } from "../../../store";

const execute = (control, AddOnLoadONChangeFlag = null) => {
    if (AddOnLoadONChangeFlag) {
        const rule = control.additionalProperties.OnClickShowSectionAsPopupWithDependentFields
        store.dispatch(updateIsPopUpOpen(true));
        var sectionId = rule.SectionId;
        const popupSectionDetails = getPopupSectionDetails(store.getState())
        const isPreserveReferenceData = rule?.PreserveReferenceData ? true:false;
        if (popupSectionDetails.length <= 0 || !popupSectionDetails.find(e => {
            return e.sectionId == sectionId
        })) {
            store.dispatch(getSingleSectionDetails(control.mappingId, sectionId, isPreserveReferenceData))
        }
        //get these dependent fields on verify btn click on popup
        if(rule?.DependentFields !=null){
            store.dispatch(updateSelectedInsuranceDependantFields(rule.DependentFields));
        }
        store.dispatch(showDialog(control.mappingId, true, control))
    }
}

const OnClickShowSectionAsPopupWithDependentFields = {
    execute
}

export default OnClickShowSectionAsPopupWithDependentFields