import React, { useState, useEffect, useRef } from 'react';
import {getDocument} from 'pdfjs-dist';

export default function PdfViewer(props) {
    const { pdfUrl, width = "100%", height = "auto" } = props
    const [numPages, setNumPages] = useState(null);
    const [loading, setLoading] = useState(true);
    const canvasRef = useRef(null);
  
    useEffect(() => {
      const renderPdf = async () => {
        try {
          const loadingTask = getDocument(pdfUrl);
          const pdf = await loadingTask.promise;
          setNumPages(pdf.numPages);
          setLoading(false);
  
          renderFirstPage(pdf);
        } catch (error) {
          console.error('Error rendering PDF:', error);
          setLoading(false);
        }
      };
  
      renderPdf();
    }, [pdfUrl]);
  
    const renderFirstPage = async (pdf) => {
      const page = await pdf.getPage(1);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });
  
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
  
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      await page.render(renderContext).promise;
    };
  
    return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <p>Number of Pages: {numPages}</p>
            <canvas ref={canvasRef} style={{ width: width, height: height }} />
          </div>
        )}
      </div>
    );
};