import {
  NO_CONTENT_RECEIVED, REPORT_DOWNLOADED_MESSAGE, ERR_SOMETHING_WENT_WRONG
} from '../constants/GlobalConstants'
import AdminService from "../services/AdminService";
import { notify } from "./SnackbarAction";

import {
    GET_DATAUPLOAD_ORG_TENANTS_SUCCESS,
    GET_DATAUPLOAD_ORG_TENANTS_FAIL,
    GET_ADMIN_DATAUPLOAD_TYPES_SUCCESS,
    GET_ADMIN_DATAUPLOAD_TYPES_FAIL,
    SET_INITIAL_STAGE,
    UPDATE_ADMIN_FILE_UPLOAD_LOADER,    
    UPLOAD_ADMIN_DATA_SUCCESS,
    UPLOAD_ADMIN_DATA_FAIL,
    TOGGLE_PANEL,
    SET_ERROR,
    UPDATE_LOADING_STATUS,
    GET_MAIN_RUN_DATA_SUCCESS,
    GET_MAIN_RUN_DATA_FAIL
} from '../constants/ActionTypes';

export function getDataReport(datatypeId, orgId) {
    return async function (dispatch, getState) {
        try {
            const state = getState()
            dispatch(updateLoadingStatus(true));
            AdminService.getDataReport(datatypeId, orgId)
                .then(response => {
                    dispatch(updateLoadingStatus(false));
                    if (response.statusCode == 200) {
                        dispatch(notify("Report", REPORT_DOWNLOADED_MESSAGE, "success"));
                    }
                    else if (response.statusCode == 204) {
                        dispatch(notify("Report", NO_CONTENT_RECEIVED, "success"));
                    }
                    return response ? response.status : false;
                })
                .catch(error => {
                    dispatch(notify("Report", ERR_SOMETHING_WENT_WRONG, "warning"));
                    dispatch(updateLoadingStatus(false));
                });
        } catch (err) {
            updateLoadingStatus(false);
            dispatch(notify("Report", ERR_SOMETHING_WENT_WRONG, "warning"));
            dispatch(getCasesFail(err));
        }
    };
}

export function updateLoadingStatus(status) {
    return {
        type: UPDATE_LOADING_STATUS,
        loading: status,
    };
}

export function resetUploadSelections() {
    return {
        type: SET_INITIAL_STAGE
    }
}

export function setError(error) {
    return {
        type: SET_ERROR,
        payload: error
    }
}

export function toggleAccordianPanel(panelId) {
    return {
        type: TOGGLE_PANEL,
        payload: panelId
    }
}

export function updateLoaderState(flag) {
    return {
        type: UPDATE_ADMIN_FILE_UPLOAD_LOADER,
        payload: flag

    };
}

export function getOrgTenants() {
    return async function (dispatch, getState) {

        AdminService.getOrgTenants()
            .then(data => {
                dispatch(getOrgTenantsSuccess(data))
            }).catch((err) => {
                dispatch(getOrgTenantsFail(err));
            });
    };
}

export function getOrgTenantsSuccess(data) {
    return {
        type: GET_DATAUPLOAD_ORG_TENANTS_SUCCESS,
        payload: data,
    };
}

export function getOrgTenantsFail(data) {
    return {
        type: GET_DATAUPLOAD_ORG_TENANTS_FAIL,
        payload: data,
    };
}


export function getAdminDataUploadTypes() {
    return async function (dispatch, getState) {

        AdminService.getAdminDataUploadTypes()
            .then(data => {
                dispatch(getAdminDataUploadTypesSuccess(data))
            }).catch((err) => {
                dispatch(getAdminDataUploadTypesFail(err));
            });
    };
}

export function getAdminDataUploadTypesSuccess(data) {
    return {
        type: GET_ADMIN_DATAUPLOAD_TYPES_SUCCESS,
        payload: data,
    };
}

export function getAdminDataUploadTypesFail(data) {
    return {
        type: GET_ADMIN_DATAUPLOAD_TYPES_FAIL,
        payload: data,
    };
}


export function uploadDryRunData(fileData, fileUploadTypeId,tenantSelectedValue) {
    return async function (dispatch, getState) {

        AdminService.uploadDryRunData(fileData, fileUploadTypeId, tenantSelectedValue)
            .then(response => {
                var result = response?.responseData;
                if (result) {  
                     dispatch(uploadDryRunDataSuccess(result))
                } else {
                    dispatch(uploadDryRunDataFail(response?.error));
                }
            }).catch((err) => {
                dispatch(uploadDryRunDataFail(err));
            });
    };
}

export function uploadDryRunDataSuccess(data) {
    return {
        type: UPLOAD_ADMIN_DATA_SUCCESS,
        payload: data        
    };
}

export function uploadDryRunDataFail(data) {
    return {
        type: UPLOAD_ADMIN_DATA_FAIL,
        payload: data       
    };
}


export function uploadMainRunData(fileGuid,tenantSelectedValue) {
    return async function (dispatch, getState) {

        AdminService.uploadMainRunData(fileGuid,tenantSelectedValue)
            .then(response => {
                var result = response?.responseData;
                if (result) {  
                     dispatch(uploadMainRunDataSuccess(result))
                } else {
                    dispatch(uploadMainRunDataFail(response?.error));
                }                
            }).catch((err) => {
                dispatch(uploadMainRunDataFail(err));
            });
    };
}

export function uploadMainRunDataSuccess(data) {
    return {
        type: GET_MAIN_RUN_DATA_SUCCESS,
        payload: data,
    };
}

export function uploadMainRunDataFail(data) {
    return {
        type: GET_MAIN_RUN_DATA_FAIL,
        payload: data,
    };
}
