import { updateControlValue } from "../../../actions/CaseDetailsAction";
import { store } from "../../../store"
import { getFieldValueById } from "../../GetFieldValueByID";

const execute = (control) => {
    if (control.additionalProperties.PreventDuplicateEntryInTargetField) {
        let rule = control.additionalProperties.PreventDuplicateEntryInTargetField
        const values = getFieldValueById(rule.TargetFieldId)
        if (values) {
            let uniqueValues = Array.from(values.reduce((m, t) => m.set(t[rule.FieldToBeUniqueInTargetField], t), new Map()).values());
            store.dispatch(updateControlValue(rule.TargetFieldId, uniqueValues))
        }
    }
}

const PreventDuplicateEntryInTargetField = {
    execute
}

export default PreventDuplicateEntryInTargetField