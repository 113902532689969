import { dispatchSelectionControl } from "../../../actions/ControlSelectionsAction";
import { store } from "../../../store";

export const execute = (control, fieldValue) => {
    if (fieldValue) {
        const tableRowIndex = control.mappingId.replace(/^\D+/g, "");
        let rule = control.additionalProperties?.LoadReferenceDataByFilterInTables;
        let loadRreferenceDataId = rule.LoadReferenceData.replaceAll("[n]", tableRowIndex);
        let filterColumnId = rule.FilterColumnId;
        let selectionData = store.getState().caseDetails.selection;
        let primaryReferenceData = {};
        primaryReferenceData = selectionData[rule.PrimaryReferenceData];
        let updateSelection = [];
        let refDataObj = [];
        let filteredRefData = [];
        if (primaryReferenceData != undefined) {
            for (let i = 0; i < primaryReferenceData.length; i++) {
                let trayReasonObj = primaryReferenceData[i][filterColumnId] == fieldValue ? primaryReferenceData[i] : null;
                trayReasonObj && refDataObj.push(trayReasonObj);
            }
        }
        filteredRefData[loadRreferenceDataId] = refDataObj;
        updateSelection["selections"] = { ...selectionData, ...filteredRefData };
        store.dispatch(dispatchSelectionControl(updateSelection));
    }
}
const LoadReferenceDataByFilterInTables = {
    execute
}
export default LoadReferenceDataByFilterInTables