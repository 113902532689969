import { sectionRuleByType } from "./RuleMagamentFactory"

export const initializeEngine = (sectionDetails) => {

    if (sectionDetails && sectionDetails?.additionalProperties) {
        let additionalProps = JSON.parse(sectionDetails.additionalProperties);
        additionalProps.ExecuteRuleManagement && Object.keys(additionalProps.ExecuteRuleManagement).map(key => {
            var rule = sectionRuleByType(key)
            if (rule) {
                rule.execute(additionalProps.ExecuteRuleManagement)
            }
        })
    }
}

const ExecuteRuleManagement = {
    initializeEngine
}

export default ExecuteRuleManagement