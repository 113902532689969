import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        'font-size': '1rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': '400',
        'line-height': '1.5',
        'letter-spacing': '0.00938em',
        'width': '100%',
        color: '#121212'
    },
    labelTitle: {
        'font-size': '0.9rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-style': 'italic',
        'align-items': 'center',
        color: '#121212'

    }
}))

export default function VerticalLabel(props) {
    var { fieldId, name, value, disabled, ...other } = props;
    const classes = useStyles();
    const fieldProps = props?.additionalProperties;
    var dateTimeFormat = fieldProps?.Format;
    var displayVal = value && typeof value == 'object' ? value.name : value;
    if (fieldId == 'TrayProcedureTime' && dateTimeFormat != undefined) {
        displayVal = displayVal ? moment(displayVal, "HH:mm:ss").format(dateTimeFormat) : null;
    }

    if (displayVal == null) return (<div className='emptyField'></div>);
    return (
        displayVal && <div
            id={fieldId}
            disabled={disabled}>
            <label className={classes.labelTitle}> {props.fieldName}</label><br></br>
            <label className={classes.root}> {displayVal}</label>
        </div>
    )
}
