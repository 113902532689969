import { GET_USER_NOTIFICATION_PREFERENCES_FAILURE, GET_USER_NOTIFICATION_PREFERENCES_SUCCESS, UPDATE_USER_NOTIFICATION_PREFERENCES_SUCCESS } from "../constants/ActionTypes";
import UserProfileService from "../services/UserProfileService";
import * as R from 'ramda';


export const getUserNotificationPreferencesSuccess = (data) => {
    return {
        type: GET_USER_NOTIFICATION_PREFERENCES_SUCCESS,
        payload: data
    }
}

export const getUserNotificationPreferencesFailure = (data) => {
    return {
        type: GET_USER_NOTIFICATION_PREFERENCES_FAILURE,
        payload: data
    }
}



export function getNotificationPreferences() {
    return async function (dispatch, getState) {
        try {
            const state = getState();
            UserProfileService.getUserNotificationPreferences().then((response) => {
                const userPreferences = response.responseData
                dispatch(getUserNotificationPreferencesSuccess(userPreferences));
            })
        } catch (err) {
            dispatch(getUserNotificationPreferencesSuccess(err))
        }
    };
}


export const updateUserNotificationPreferencesSuccess = (preferences) => {
    return {
        type: UPDATE_USER_NOTIFICATION_PREFERENCES_SUCCESS,
        payload: preferences
    }
}

export function updateUserNotificationPreferences(notificationId, notificationName, notificationType="Email", filterType="Surgeon", value) {
    return async function (dispatch, getState) {
        try {
            const state = getState();
            const oldUserPreferences = state.userprofile.userNotificationPreferences
            const newUserPreferences = !R.isNil(oldUserPreferences) ? R.clone(oldUserPreferences) : [];
            const filterIndex = newUserPreferences?.findIndex(preference => preference.NotificationId === notificationId)
            const formattedValue = [{"Field": filterType, "FilterValues": value}]
            if (filterIndex >= 0) {
                newUserPreferences[filterIndex][notificationType].NotificationFilters = formattedValue
            } else {
                newUserPreferences.push({
                    NotificationId: notificationId,
                    NotificationName: notificationName,
                    [notificationType]: [{"Field": filterType, "FilterValues": formattedValue}]
                })
            }

            dispatch(updateUserNotificationPreferencesSuccess(newUserPreferences))
        } catch (err) {
            console.log(err)
        }
    };
}




export function saveUserNotificationPreferences() {
    return async function (dispatch, getState) {
        try {
            const state = getState();
            const userPreferences = state.userprofile.userNotificationPreferences
            UserProfileService.setUserNotificationPreferences(userPreferences).then((response) => {
                if (response) {
                    dispatch(getNotificationPreferences())
                }
            })

        } catch (err) {
            console.log(err)
        }
    };
}




