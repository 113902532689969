import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NYU from '../../res/images/NYU.jpg'
import Typography from '../controls/Typography'
import { useHistory } from 'react-router-dom'
import * as Colors from '../../res/values/Colors'
import * as Dimens from '../../res/values/Dimens'
import { connect, useDispatch } from 'react-redux'
import { isLoggedIn } from '../../utils/Auth'
import AuthAction from '../../actions/AuthActions'
import Logout from "../../services/Logout";
import LocalStorageProvider from '../../providers/LocalStorageProvider'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: Dimens.TENANT_CONTAINER_GAP
    },
    tenantCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    tenantSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column !important'
    },
    cardIcon: {
        borderRadius: '50%',
        height: Dimens.TENANT_CARD_ICON,
        width: Dimens.TENANT_CARD_ICON,
        padding: Dimens.TENANT_CARD_ICON_PADDING,
        border: `${Dimens.BORDER_WIDTH} solid ${Colors.TENANT_CARD_BORDER}`,
        verticalAlign: 'middle'
    },
    cardText: {
        verticalAlign: 'middle',
        margin: Dimens.TENANT_CARD_TEXT_MARGIN,
        textTransform: 'uppercase',
    },
    displayBanner: {
        display: 'flex',
        minHeight: '100vh',
        maxHeight: 'auto',
        backgroundSize: 'cover',
        backgroundImage: `linear-gradient(to right, ${Colors.TENANT_BANNER_DARK}, ${Colors.TENANT_BANNER_REGULAR})`
    },
    titleText: {
        fontSize: Dimens.TENANT_CARD_TITLE_FONT_SIZE,
        color: Colors.TENANT_TITLE,
        fontWeight: Colors.TENANT_TITLE_WEIGHT,
        margin: `${Dimens.TENANT_CARD_TITLE_MARGIN} !important`
    }
}))

function mapStateToProps(state) {
    return {
        tenantList: state.user.userData,
        selectedTenant: state.user.selectedTenant
    }
}

function SelectTenant({ tenantList, selectedTenant }) {
    const classes = useStyles();
    const dispatch = useDispatch()
    let history = useHistory()
    const handleTenantSelect = (tenant) => {
        dispatch(AuthAction.saveTenantInfoOnSelection(tenant))
        AuthAction.getUserInfo(); 
        history.push('/dashboard');   
    }

    useEffect(() => {
        if (isLoggedIn() && selectedTenant != null && selectedTenant != "") {
            let returnURL = LocalStorageProvider.getReturnURL()
            if (returnURL) {
                history.push(returnURL)
            } else {
                history.push('/dashboard')
            }   
        }
        if(tenantList.length==0){
            Logout();     
            history.push('/login')   
        }
    })

    return (
        <div>
            <Grid container>
                <Grid item md={6} lg={6} className={classes.displayBanner} />
                <Grid item md={6} lg={6} className={classes.tenantSection}>
                    <Typography className={classes.titleText}>
                        Let's Start
                    </Typography>
                    <div className={classes.container}
                    >
                        {tenantList.map((tenantTiles) => (

                            <div
                                key={tenantTiles.TenantId}
                                className={classes.tenantCard}
                                onClick={() => handleTenantSelect(tenantTiles)}
                            >
                                <img src={tenantTiles.TenantIconUrl}
                                    className={classes.cardIcon}
                                    alt=""
                                />
                                <Typography
                                    variant="h6"
                                    className={classes.cardText}
                                >
                                    {tenantTiles.TenantName}
                                </Typography>
                            </div>

                        ))}
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default connect(mapStateToProps)(SelectTenant)
