import { store } from '../../../store';
import VisibilityControlAction from '../../../actions/VisibilityControlAction';

export const execute = (control) => {
  const tableRowIndex = control.mappingId.replace(/^\D+/g, "");
  const rule = control.additionalProperties?.HideTargetFieldIfAdditionalFieldHasValueInTable;
  for (let i = 0; i < rule.length; i++) {
    let fieldId = rule[i].AdditionalFieldId;
    let tragetFiledValue = rule[i].AdditionalFieldValue[0];
    var fieldValue = null;
    if (control.value) {
      if (typeof (control.value) === 'object') {
        fieldValue = control.value[rule[i].AdditionalFieldId];
      }
      else {
        let selectionData = store.getState().caseDetails.selection['TrayDeliveryReasons'];
        let trayReasonValue = selectionData && selectionData.find(entry => entry.TrayDeliveryReasonId == control.value);
        fieldValue = trayReasonValue?.RequireReasonBl;
      }
    }

    if (fieldValue == tragetFiledValue || fieldValue == rule[i].AdditionalFieldValue[1]) {
      rule[i].TargetFieldId.length > 0 && rule[i].TargetFieldId.forEach(element => {
        let targetField = element.replaceAll("[n]", tableRowIndex);
        let path = [];
        path = targetField.split('.');
        store.dispatch(VisibilityControlAction.hideFieldInTayTable(path[1], true, path[0]));
      });
    }
  }
}
const HideTargetFieldIfAdditionalFieldHasValueInTable = {
  execute
}
export default HideTargetFieldIfAdditionalFieldHasValueInTable;