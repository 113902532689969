import {
  INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE
} from '../../../constants/GlobalConstants';

export const inputLengthEqualToProvidedValue = (control) => {
  let fieldValueLength = control.value ? control.value.length : 0;
  if (control && control.validation) {
    var validation = control.validation
    var checkFieldValidation = validation[INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE] ? validation[INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE] : null;
    var response = null;
    if (checkFieldValidation != null) {
      let checkRule = checkFieldValidation.some((rule) => {
        return parseInt(rule.ProvidedValue) == fieldValueLength
      })
      if (!checkRule) {
        response = {
          isRequired: true,
          status: false,
          message: "Invalid zip code!"
        };
      }
    }
  }
  return response;
}