import { combineReducers } from 'redux';

import user from './UserReducer';
import snackbar from './SnackbarReducer';
import notification from './NotificationReducer';
import caseDetails from './CaseDetailsReducer';
import controlSelections from './ControlSelectionsReducer';
import dashboard from './DashboardReducer';
import sectionAlert from './SectionAlertReducer'
import reports from './ReportsReducer'
import userprofile from './UserProfileReducer'
import dataUpload from './AdminReportsReducer'

//Global state
const appReducer = combineReducers({
    user,
    snackbar,
    notification,
    caseDetails,
    controlSelections,
    dashboard,
    sectionAlert,
    reports,
    userprofile,
    dataUpload
});

const rootReducer =  (state, action) => {   
    // Clear all data in redux store to initial.
    if(action.type === "DESTROY_SESSION")
       state = undefined;
    
    return appReducer(state, action);
 };

 export default rootReducer;