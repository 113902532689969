import React from 'react'
import { FormControl, 
    InputLabel, 
    Select as Selection, 
    MenuItem, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width:'100%'
    },
}));

export default function Select(props) {
    const classes = useStyles();
    const { name, label, value, error = false, onChange, selections = [],helperText } = props;

    const allSelection = (selections != null || selections != undefined) ?  Object.values(selections) : [];

    return (
        <FormControl variant="outlined" className={classes.formControl}
            {...(error && { error: true })}>
            <InputLabel>{label}</InputLabel>
            <Selection
                label={label}
                name={name}
                value={value}
                onChange={onChange}>
                <MenuItem value="">None</MenuItem>
                {
                    allSelection.map(
                        item => (<MenuItem key={item} value={item}>{item}</MenuItem>)
                    )
                }
            </Selection>
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl> 
    )
}
