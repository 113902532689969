import React from 'react';
import { Grid, Typography } from '@mui/material';
import Controls from '../controls/Controls';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import moment from 'moment';
import * as Constant from '../../constants/GlobalConstants';

export default function PacketTypeInformationRow(props) {
    const {deleteSelectedRow, selections, onInputChange, onDocTypeChange, values, disabledDelete, validateIncludes,
        signedDateDetails, docTypeDetails, pageRangeDetails, signedDateAdditionalProps
    } = props
    const disabledSignedDate = values.docType ? !values.docType.ExpirationTimeFrameBl : true;
    
    return (
        <Grid container spacing={2} mb={2}>
            <Grid item container spacing={2} mb={1} xs={12}>
                <Grid item xs={7}>
                    <Controls.AutoSuggest
                        label={docTypeDetails?.element?.fieldName}
                        onChange={(e, values) => {onDocTypeChange(values, "docType")}}
                        selections={selections}
                        additionalProperties={docTypeDetails?.additionalProperties}
                        value={values.docType}
                        error={validateIncludes.docInfo.status}
                        helperText={validateIncludes.docInfo.message}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controls.Input
                        label={pageRangeDetails?.element?.fieldName}
                        onChange={(e) => onInputChange(e.target.value, "pageRange")}
                        value={values.pageRange}
                        isRequired={false}
                        disabled={false}
                        maxLength={20}
                        error={validateIncludes.pageRange.status}
                        helperText={validateIncludes.pageRange.message}
                    />
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <IconButton
                        aria-label=""
                        size="small"
                        onClick={deleteSelectedRow}
                        disabled={disabledDelete}
                    >
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={11}>
                <Controls.DatePicker
                    label={signedDateDetails?.element?.fieldName}
                    fieldTypeName={signedDateDetails?.element?.fieldTypeName}
                    defaultFieldName={signedDateDetails?.element?.defaultFieldName}
                    disabled={disabledSignedDate}
                    fieldName={signedDateDetails?.element?.fieldName}
                    description={signedDateDetails?.element?.description}
                    additionalProperties={signedDateAdditionalProps}
                    onChange={(e) => {
                        let date = null
                        if(e !== null) {
                            date = moment(e).format(Constant.COMMON_DATE_FORMAT)
                        }
                        onInputChange(date, "signedDate")
                    }}
                    value={values.signedDate}
                    error={validateIncludes.signDate.status}
                    helperText={validateIncludes.signDate.message}
                />
                {values.signedDate && (
                    <Typography>{`${Constant.HNP_EXPIRE_DT_LBL} ${moment(values.signedDate, Constant.COMMON_DATE_FORMAT).add(30, 'days').format(Constant.COMMON_DATE_FORMAT)}`}</Typography>
                  )}
            </Grid>
        </Grid>
    )
}
