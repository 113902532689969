import React from 'react'
import { Chip as MuiChip } from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5)
  }
}))

export default function Chip(props) {
  const { label, icon, id, onDelete, disabled, variant, onClick, color } = props
  const classes = useStyles()
  return (
    <MuiChip
      className={classes.root}
      id={id}
      label={label}
      onDelete={() => onDelete(id)}
      variant="outlined"
      onClick={() => onClick()}
      color = {color}
      {...(disabled? {disabled} : null)}
    />
  )
}
