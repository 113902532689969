import * as Constants from "../../../constants/GlobalConstants";
import * as ValidateFields from "./ValidateFields";
import * as dateValidation from "./DateValidations";
import { checkFieldLengthOnDependentField } from './ValidateFieldLengthOnDependentField';
import { inputSelectionMoreThanProvidedValue } from './InputSelectionMoreThanProvidedValue';
import { inputSelectionMoreThanProvidedValueDueToDependentFieldValue } from './InputSelectionMoreThanProvidedValueDueToDependentFieldValue';
import CountOfTargetFieldValuesGreaterThanProvidedValue from './CountOfTargetFieldValuesGreaterThanProvidedValue'
import { inputLengthEqualToProvidedValue } from './InputLengthEqualToProvidedValue';
import { formatCheck } from './FormatCheck';
import { ValidTimeCheck } from './ValidTimeCheck';
import { inputValueEqualToProvidedValue } from './InputValueEqualToProvidedValue';
import { inputLengthGreaterThanProvidedValue } from "./InputLengthGreaterThanProvidedValue";
import { inputLengthEqualToProvidedValueDueToDependentFieldValue } from './InputLengthEqualToProvidedValueDueToDependentFieldValue';
import { dependentFieldInputLengthGreaterThanProvidedValueByCondition } from './DependentFieldInputLengthGreaterThanProvidedValueByCondition';
import { InputValueOfTargetFieldValueComparedToProvidedValue } from "./InputValueOfTargetFieldValueComparedToProvidedValue";
import { ValidFileTypeSelected } from "./ValidFileTypeSelected";
import { DateComparedToDependentField } from "./DateComparedToDependentField";


export const getApplicableValidation = (ruleKey, control, AddOnLoadONChangeFlag = null) => {
  var validateField = null;
  switch (ruleKey) {
    case Constants.INVALID_DATE_KEY:
      validateField = dateValidation.validateDate(control.value);
      break
    case Constants.AGE_120YEARVALIDATION:
      validateField = dateValidation.validateAge(control.value);
      break
    // case Constants.INPUT_LENGTH_ON_DEPENDENT_FIELD:
    //   validateField = checkFieldLengthOnDependentField(control);
    //   break
    case Constants.DATE_GREATER_THAN_CURRENT_DATE:
      validateField = dateValidation.disableFutureDate(control.value);
      break
    case Constants.DATE_LOWER_THAN_CURRENT_DATE:
      validateField = dateValidation.disablePastDate(control.value);
      break
    case Constants.INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE:
      validateField = inputSelectionMoreThanProvidedValue(control, AddOnLoadONChangeFlag);
      break
    case Constants.INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE_DUETO_DEPENDENT_FIELD:
      validateField = inputSelectionMoreThanProvidedValueDueToDependentFieldValue(control);
    case Constants.COUNT_OF_TARGET_FIELD_VALUES_GREATER_THAN_PROVIDED_VALUE:
      validateField = CountOfTargetFieldValuesGreaterThanProvidedValue.execute(control);
      break
    case Constants.INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE:
      validateField = inputLengthEqualToProvidedValue(control);
      break
    case Constants.INPUT_VALUE_EQUAL_TO_PROVIDED_VALUE:
      validateField = inputValueEqualToProvidedValue(control);
      break
    case Constants.INPUT_LENGTH_GREATER_THAN_PROVIDED_VALUE:
      validateField = inputLengthGreaterThanProvidedValue(control)
      break
    case Constants.INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE_DUE_TO_DEPENDENT_FIELD_VALUE:
      validateField = inputLengthEqualToProvidedValueDueToDependentFieldValue(control)
      break
    case Constants.DEPENDENT_FIELD_INPUT_LENGTH_GREATER_THAN_PROVIDED_VALUE_BY_CONDITION:
      validateField = dependentFieldInputLengthGreaterThanProvidedValueByCondition(control)
      break
    case Constants.FORMAT_CHECK:
      validateField = formatCheck(control);
      break
    case Constants.INPUT_VALUE_OF_TARGET_FIELD_VALUE_COMPARED_TO_PROVIDED_VALUE:
      validateField = InputValueOfTargetFieldValueComparedToProvidedValue(control)
      break
    case Constants.VALID_FILE_TYPE_SELECTED:
      validateField = ValidFileTypeSelected(control)
      break
    case Constants.DATE_COMPARED_TO_DEPENDENT_FIELD:
      validateField = DateComparedToDependentField(control)
      break
    case Constants.VALID_TIME_CHECK:
      validateField = ValidTimeCheck(control);
       break
   
  }
  if (validateField) return validateField;
}
const ValidationFactory = {
  getApplicableValidation
}
export default ValidationFactory