import React from 'react'
import { useSelector } from "react-redux";
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1)
    },
}))
export default function Alert() {

    const sectionAlerts = useSelector(
        state => state.sectionAlert.alerts
    )
        
    const classes = useStyles();
    return (
        <>
            {sectionAlerts && sectionAlerts.length > 0 && sectionAlerts.map((alert, index) => (
                <MuiAlert
                    key={index}
                    variant='outlined'
                    className={classes.root}
                    severity={alert.severity}
                    iconMapping={{ error: <ErrorIcon fontSize="inherit" /> }}>
                    <MuiAlertTitle style={{ color: alert.severity == 'error' ? 'red' : '' }}>{alert.message}</MuiAlertTitle>
                </MuiAlert>
            ))}
        </>
    );
}