import { store } from '../../../store'
import { updateIsRequiredValidation } from '../../../actions/CaseDetailsAction'
import { getFieldById } from '../../GetFieldById'

export const execute = (control) => {
    const fieldProps = control.additionalProperties;
    var rule = fieldProps.MakeTargetFieldARequiredFieldDueToAdditionalFieldValue;

    if (rule && rule.length > 0) {
        rule.forEach(fieldsAction => {
            var inPopUp = fieldsAction.inpopup != undefined && fieldsAction.inpopup ? fieldsAction.inpopup : false;

            if (fieldsAction.AdditionalFields.every(condition => {
                var nestedKeys = condition.AdditionalFieldId.split('.')
                var value
                if(nestedKeys!=null){
                 value = getFieldById(nestedKeys[0])?.value
                }
               
                if (value) {
                    var additionalNestedFieldVal = value
                    for (var index = 1; index < nestedKeys.length; index++) {
                        if (additionalNestedFieldVal) {
                            additionalNestedFieldVal = additionalNestedFieldVal[nestedKeys[index]]
                        }
                    }
                    value = additionalNestedFieldVal
                    return value == condition.AdditionalFieldValue
                } else {
                    return false
                }
            })) {
                fieldsAction.TargetFieldId && fieldsAction.TargetFieldId.forEach(fieldId => {
                    store.dispatch(updateIsRequiredValidation(fieldId, true, null, inPopUp))
                })
            }
            else {
                fieldsAction.TargetFieldId && fieldsAction.TargetFieldId.forEach(fieldId => {
                    store.dispatch(updateIsRequiredValidation(fieldId, false, null, inPopUp));
                });
                   
            }
        })
    }
}

const MakeTargetFieldARequiredFieldDueToAdditionalFieldValue = {
    execute
}

export default MakeTargetFieldARequiredFieldDueToAdditionalFieldValue