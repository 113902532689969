import React from 'react'
import { TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}))

export default function Input(props) {
    var { fieldId, name, label, value, error = false, isRequired, disabled, onChange, helperText, maxLength, ...other } = props;
    const classes = useStyles()
    return (
        <TextField
            id={fieldId}
            variant="outlined"
            label={label}
            name={name}
            value={value ? value : ''}
            onChange={onChange}
            error={error}
            type={other.fieldTypeName ? other.fieldTypeName.toLowerCase().trim() : 'text'}
            inputProps={{
                maxLength: maxLength ? maxLength : null,
                form: {
                    autocomplete: 'off',
                }
            }}
            helperText = {helperText}
            className={classes.root}
            disabled={disabled}
            {...(isRequired && !value && { error: true, helperText: helperText })}

        />
    )
}
