import React from 'react'

const TableA = () => {
    return (
        <div>
            TableA
        </div>
    )
}

export default TableA
