import { store } from '../../../store'
import XControlUtils from '../../X-ControlUtils'

export const execute = (control) => {
    if (control.value && control.value.trim() != '') {
        const rule = control.additionalProperties.ShowTargetFieldIfFieldHasAnyValue
        const tableRow = control.mappingId.replace(/^\D+/g, "")
        for (var targetField of rule.TargetFieldId) {
            var t = targetField.replaceAll("[n]", tableRow)
            store.dispatch(XControlUtils.modifyControl(t, 'isVisible', true, parseInt(tableRow)))
        }
    }
}

const ShowTargetFieldIfFieldHasAnyValue = {
    execute
}

export default ShowTargetFieldIfFieldHasAnyValue