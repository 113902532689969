import { store } from '../../../store';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { getFieldValueById } from '../../GetFieldValueByID';

export const execute = (props, value) => {
    let fieldProps = props.additionalProperties;
    if (fieldProps.CalculateTotalHeight) {
        var totalHeight = null
        var heightFeet = getFieldValueById(fieldProps.CalculateTotalHeight.DependentFieldId.HeightFeet);
        var heightInches = getFieldValueById(fieldProps.CalculateTotalHeight.DependentFieldId.HeightInches);

        heightFeet = (heightFeet!=null && heightFeet !='') ? parseInt(heightFeet) * 12 : 0;
        heightInches = (heightInches!=null && heightInches !='') ? parseInt(heightInches) : 0;

        totalHeight = heightFeet + heightInches;

        store.dispatch(updateControlValue(fieldProps.CalculateTotalHeight.TargetFieldId, totalHeight));
    }
}
const CalculateTotalHeight = {
    execute
}
export default CalculateTotalHeight