import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const AcknowledgementDialogBox = (props) => {
    const { open, handleClose } = props;
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle >
                    Already Acknowledged.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        The amendment has already been acknowledged.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={handleClose}>
                        OK
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AcknowledgementDialogBox
