import React, { useEffect, useState } from 'react'
import { Grid, Card, CircularProgress, StyledEngineProvider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styled, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import MedtelBanner from '../../res/images/medtel_banner.png'
import Controls from '../controls/Controls'
import { useForm, Form } from '../common/useForm'
import { useHistory, useLocation } from "react-router-dom";
import * as Colors from '../../res/values/Colors'
import * as Dimens from '../../res/values/Dimens'
import AuthActions from '../../actions/AuthActions'
import { useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { SURGICAL_DASHBOARD, LOGIN, TERMS_AND_CONDITION, FORGOT_PASSWORD, KEY_FAILED } from '../../constants/GlobalConstants';
import { LOGIN_MULTITENANTS,LOGIN_SINGLETENANT } from '../../constants/ActionTypes'
import { isLoggedIn } from '../../utils/Auth'
import LocalStorageProvider from '../../providers/LocalStorageProvider'
import { notify } from '../../actions/SnackbarAction'
import { Link } from 'react-router-dom'


const theme = createTheme();

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
  },
  bannerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  displayBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${MedtelBanner})`
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    width: '70%',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '35%',
    marginLeft: '50%',
    transform: 'translate(-50%, -40%)'
  },
  logo: {
    height: '15%',
    width: '35%'
  },

  muiCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: Dimens.BORDER_RADIUS,
    border: `${Dimens.BORDER_WIDTH} black solid`,
    padding: `${Dimens.LOGIN_CARD_PADDING_VERTICAL} ${Dimens.LOGIN_CARD_PADDING_HORIZONTAL}`,
  },
  form: {
    '& .MuiButton-root': {
      marginLeft: '50%',
      marginTop: "0.5em",
      transform: 'translate(-50%,0%)',
      borderRadius: Dimens.BORDER_RADIUS,
      padding: `${Dimens.LOGIN_BUTTON_PADDING_VERTICAL} ${Dimens.LOGIN_BUTTON_PADDING_HORIZONTAL}`,
    },
    '& .MuiButton-label': {
      textTransform: 'uppercase',
    },

    '& .MuiTextField-root': {
      width: '100%',
      marginTop: '1em'
    }
  },
  formTitle: {
    margin: Dimens.LOGIN_MARGIN_TOP,
    fontSize: Dimens.TEXT_SIZE_LARGE,
    marginTop: Dimens.LOGIN_MARGIN_TOP
  },
  formTerms: {
    fontSize: Dimens.TEXT_SIZE_REGULAR,
    marginTop: Dimens.LOGIN_MARGIN_TOP
  }
}))

function mapStateToProps(state) {
  return {
    component: state.user.component,
    time: state.user.time,
    tenantList: state.user.userData,
    error: state.user.error
  }
}

const initialLoginValues = {
  Email: '',
  Password: ''
}

function Login(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const validate = (fieldValues = values) => {

    let temp = { ...errors }
    let regex = /^$|^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;

    if ('Email' in fieldValues)
      temp.Email = regex.test(fieldValues.Email) ? "" : "Email is not valid.";

    setErrors({
      ...temp
    })

    if (fieldValues === values)
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange
  } = useForm(initialLoginValues, true, validate)

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    if (isLoggedIn()) {
      let returnURL = LocalStorageProvider.getReturnURL()
      if (returnURL && props.component === LOGIN_SINGLETENANT) {
        history.push(returnURL)
      } else if (props.component === LOGIN_MULTITENANTS) {
        handleUrlRedirection(returnURL)
      } else {
        history.push('/dashboard')
      }
      setLoading(true);
    } else if (props.component === KEY_FAILED) {
      if (props.error && props.error.error_description) {
        props.error.error_description = props.error.error_description || "Invalid Login"
        dispatch(notify(
          '',
          props.error.error_description
            .replaceAll('invalid_username_or_password', 'Invalid email and password combination')
            .replaceAll('user_account_locked', 'The account is locked. Please check your email for next steps or contact Medtel Administrator for resolution.'),
          'error'
        ),
          dispatch(AuthActions.resetComponent()),
          setLoading(false)
        )
      } else {
        dispatch(notify(
          '',
          'Invalid email and password combination',
          'error'
        ),
          dispatch(AuthActions.resetComponent()),
          setLoading(false)
        )
      }

    }
  }, [props.component])

  //Login form submit on enter or return key press
  function onEnterSubmit(event) {
    if(event.key === 'Enter' && location.pathname.toLowerCase() === '/login') {
      event.preventDefault();
      handleSubmit(event);
    }
  }

  //handle navigation to the target url post authentication
  function handleUrlRedirection(returnURL) {
    if (returnURL) {
      if (props.tenantList) {
        let queryParams = new URLSearchParams(returnURL);
        let keyValue = queryParams.get("key");

        //Decoding value of parameter named key(Coming as encypted)
        const decodedValueOfKey = atob(keyValue)

        const tempAnchor = document.createElement('a');
        tempAnchor.href = returnURL;

        // Remove all query parameters
        const { pathname, search } = tempAnchor;
        const modifiedUrl = `${pathname}${search ? '?' : ''}`;
        const returnURLWithoutQueryParams = modifiedUrl.toString();
        const tenantData = props.tenantList.filter((obj) => {
          return obj.TenantKey === decodedValueOfKey
        })

        //If matching tenant found
        if (tenantData.length > 0) {
          dispatch(AuthActions.saveTenantInfoOnSelection(tenantData[0]))
          AuthActions.getUserInfo();
          history.push(returnURLWithoutQueryParams);
        }
        else {
          history.push('/tenant')
        }
      }
    }
    else {
      history.push('/tenant')
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (!loading) {
      setLoading(true);
      dispatch(AuthActions.login(values));
    }
  }

  return (
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
    <div>
      <Grid container className={classes.root}>
        <Grid item md={6} lg={6} className="loginBannerContainer" >
          <div className="loginBannerImage" />
        </Grid>
        <Grid item xs={6} className="loginBannerContainer">
          <div className={classes.paper}>


            <Card className={classes.muiCard}
              elevation={0}
            >
              <Controls.Typography
                className={classes.formTitle}
              >
                {LOGIN}
              </Controls.Typography>
              <Form onKeyUp={onEnterSubmit} className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Controls.Input
                  size='small'
                  label="Email"
                  name="Email"
                  fieldTypeName="email"
                  value={values.Email}
                  onChange={handleInputChange}
                  error={errors.Email ? true : false}
                  helperText={errors.Email}
                />
                <Controls.Input
                  size='small'
                  label="Password"
                  fieldTypeName="password"
                  name="Password"
                  value={values.Password}
                  onChange={handleInputChange}
                  error={errors.Password ? true : false}
                  helperText={errors.Password}
                />
                <Controls.Button
                  label="Login"
                  size='small'
                  type="submit"
                  fieldId="loginButton"
                  disabled={loading}
                  loader={loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: '-30px' }}

                    />)}

                />
              </Form>
              <Controls.Typography
                paragraph
                className={classes.formTerms}
              >{TERMS_AND_CONDITION}</Controls.Typography>
            </Card>
            <Controls.Typography
              paragraph
            >
              <Link to='/forgotpassword'>{FORGOT_PASSWORD}</Link>
            </Controls.Typography>
          </div>
        </Grid>
      </Grid>
    </div>
    </ThemeProvider>
    </StyledEngineProvider>
  )
}
export default connect(mapStateToProps)(Login)
