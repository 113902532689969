import { store } from '../../../store'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import * as Constants from '../../../constants/GlobalConstants'
import { getFieldById } from '../../GetFieldById'

export const execute = (control) => {
    const fieldProps = control.additionalProperties
    let value
    if(control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
        value = control.value ? (control.value[fieldProps.DisplayColumnId] ? control.value[fieldProps.DisplayColumnId] : control.value) : (fieldProps.DefaultValue ? fieldProps.DefaultValue : null )
    } else {
        value = control.value
    }
    if (value) {
        if (fieldProps.ShowTargetFieldIfFieldAndDependentFieldHaveValue.length > 0) {
            fieldProps.ShowTargetFieldIfFieldAndDependentFieldHaveValue.forEach(rule => {
                var filedID=rule.DependentFieldId;
                var fieldValue = rule.DependentFieldValue;
                var field = getFieldById(filedID);
                var dependentFieldVal = field ? field.value : null
                if (rule.FieldValue.includes(value.toString()) && dependentFieldVal==fieldValue) {
                    rule.TargetFieldId.forEach(fieldId => {
                        store.dispatch(VisibilityControlAction.showField(fieldId))
                    })
                }
            })
        }
    }
}

const ShowTargetFieldIfFieldAndDependentFieldHaveValue = {
    execute
}

export default ShowTargetFieldIfFieldAndDependentFieldHaveValue