import { getFieldByMappingId } from '../../GetFieldByMappingId';
import LoadReferenceDataByFilterInTables from './LoadReferenceDataByFilterInTables';

export const execute = (control) => {
    const tableRowIndex = control.mappingId.replace(/^\D+/g, "");
    let rule = control.additionalProperties?.OnSelectReloadReferenceDataInTargetFieldInTable;
    let fieldValue = "";
    if (control.value) {
        if (typeof (control.value) === 'object') {
            fieldValue = control.value[control.additionalProperties.DisplayColumnId] ? control.value[control.additionalProperties.DisplayColumnId] : control.value;
        } else {
            fieldValue = control.value;
        }
        if (rule) {
            rule.TargetFieldId.map(field => {
                let targetField = field.replaceAll("[n]", tableRowIndex);
                LoadReferenceDataByFilterInTables.execute(getFieldByMappingId(targetField), fieldValue);
            })
        }
    }
}
const OnSelectReloadReferenceDataInTargetFieldInTable = {
    execute
}
export default OnSelectReloadReferenceDataInTargetFieldInTable;