import { store } from '../../../store';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { getFieldValueById } from '../../GetFieldValueByID';
import { getFullAge } from '../../fieldProperties/DateTime';
import moment from 'moment';

export const execute = (props) => {
    var fieldProps = props.additionalProperties
    var rule = fieldProps.CalculateDateDifferenceInDays
    var dependentFields = rule.DependentFieldId
    const pastDate = moment(getFieldValueById(dependentFields.PastDate));
    const futureDate = moment(getFieldValueById(dependentFields.FutureDate));
    var diffDays = futureDate.diff(pastDate, 'days');
    store.dispatch(updateControlValue(rule.TargetFieldId, diffDays));
}
const CalculateDateDifferenceInDays = {
    execute
}
export default CalculateDateDifferenceInDays