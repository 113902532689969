import moment from 'moment';
import { FORMAT_CHECK, ERR_INVALID_DATE,ERR_INVALID_DATE_FORMAT,ERR_INVALID_EMAIL, DATE, EMAIL_REGEX, EMAIL } from "../../../constants/GlobalConstants";
var response = null;


export const formatCheck = (control) => {
    if (control) {
        if (control.fieldTypeName == DATE) {
            var fieldValue = control.value ? control.value : null;
            if (control && control.validation) {
                var validation = control.validation
                var checkDependentFields = validation[FORMAT_CHECK] ? validation[FORMAT_CHECK] : null;
                var response = null;
                if (checkDependentFields != null) {
                    checkDependentFields.map(rule => {
                        var dateToValidate = moment(fieldValue).format(rule.Format);
                        if (dateToValidate && dateToValidate == ERR_INVALID_DATE) {
                            response = {
                                status: false,
                                isButtonDisable: false,
                                message: ERR_INVALID_DATE_FORMAT
                            };
                            return response;
                        }
                    })
                }
            }
        }
        if (control.fieldTypeName == EMAIL) {
            var pattern = new RegExp(EMAIL_REGEX);
            var fieldValue = control.value ? control.value : null;
            if (!pattern.test(fieldValue)) {
                response = {
                    status: false,
                    isButtonDisable: false,
                    message: ERR_INVALID_EMAIL   
                };
                return response;
            }         
           
        }
    }
}