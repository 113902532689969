import { store } from '../../../store';
import { showField, hideField } from '../../../actions/VisibilityControlAction';

export const execute = (props) => {
    const fieldProps = props.additionalProperties
    const rule = fieldProps.DisplayFieldNameAndFieldValue
   // if (rule) {
        if (props.value) {
            store.dispatch(showField(props.mappingId))
        }
    // } else {
    //     store.dispatch(hideField(props.mappingId))
    // }
}

const DisplayFieldNameAndFieldValue = {
    execute
}

export default DisplayFieldNameAndFieldValue