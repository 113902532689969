const execute = (rule, values) => {

    var selectables;
    rule.map(transformRule => {
        if (transformRule.ConcatenateFields) {  
            selectables = concatenateFields(transformRule.ConcatenateFields, values)
        }
    })
    return selectables
}



const concatenateFields = (rule, values) => {
    var keys = Object.keys(values)
    rule.FieldsToConcatenate.sort(function (a, b) {
        return a.SequenceNo - b.SequenceNo;
    })
    var selectables = []
    keys.map(key => {
        var label = ''
        rule.FieldsToConcatenate.map((field, index) => {
            if (values[key][field.FieldId] && values[key][field.FieldId].trim() != '') {
                if (index != 0) {
                    label += rule.ContentSeparator + values[key][field.FieldId]
                } else {
                    label += values[key][field.FieldId]
                }
            }
        })
        selectables.push({ key: key, label: label, fieldValue: values[key] })
    })
    return selectables
}

const CustomizedFieldValue = {
    execute
}

export default CustomizedFieldValue