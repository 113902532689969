import moment from 'moment';
import {
    TEXT, SELECTION, SELECTION_KEY_VALUE, DATE, PHONE_NUMBER, ZIPCODE, BLANK_VALUE, ERR_INVALID_DATE
} from '../../../constants/GlobalConstants';
import ValidationEngine from './ValidationEngine';

export const checkTargetButtonDisable = (field) => {
    var targetButtonsresponse = {
        buttonStatus: true,
        targetButtons: null,
        mappingIds: null
    };

    const additionProperty = field.additionalProperties ? field.additionalProperties : null;
    if (additionProperty) {
        if (field.fieldTypeName == TEXT || field.fieldTypeName == PHONE_NUMBER || field.fieldTypeName == ZIPCODE) {
            if (additionProperty.DisableTargetFieldIfNoValue != undefined) {
                if (field.value == null || field.value == BLANK_VALUE) {
                    targetButtonsresponse.targetButtons = additionProperty.DisableTargetFieldIfNoValue.TargetFieldId;
                    targetButtonsresponse.buttonStatus = true
                }
                else {
                    targetButtonsresponse.mappingIds = additionProperty.DisableTargetFieldIfNoValue.TargetFieldId;
                }
            }
        }
        if (field.fieldTypeName == SELECTION_KEY_VALUE) {
            if (additionProperty.DisableTargetFieldIfNoInputSelected != undefined) {
                if (field.value == null || field.value == BLANK_VALUE) {
                    targetButtonsresponse.targetButtons = additionProperty.DisableTargetFieldIfNoInputSelected.TargetFieldId;
                    targetButtonsresponse.buttonStatus = true
                }
                else {
                    targetButtonsresponse.mappingIds = additionProperty.DisableTargetFieldIfNoInputSelected.TargetFieldId;
                }
            }
        }
        if (field.fieldTypeName == DATE) {
            if (additionProperty.DisableTargetFieldIfNoValue != undefined) {
                if (field.value == null || field.value == BLANK_VALUE) {
                    targetButtonsresponse.targetButtons = additionProperty.DisableTargetFieldIfNoValue.TargetFieldId;
                    targetButtonsresponse.buttonStatus = true
                }
                else {
                    targetButtonsresponse.mappingIds = additionProperty.DisableTargetFieldIfNoValue.TargetFieldId;
                }
            }
            if (additionProperty.DisableTargetFieldIfInvalidDate != undefined) {
                if (field.value == ERR_INVALID_DATE) {
                    targetButtonsresponse.targetButtons = additionProperty.DisableTargetFieldIfInvalidDate.TargetFieldId;
                    targetButtonsresponse.buttonStatus = true
                } else {
                    targetButtonsresponse.mappingIds = additionProperty.DisableTargetFieldIfInvalidDate.TargetFieldId;
                }
            }
            if (additionProperty.DisableTargetFieldIfFieldDateGreaterThanCurrentDate != undefined && (field.value != null && field.value != "")) {
                var isFutureDate = ValidationEngine.initializeValidationEngine(field, 'flag');
                if (isFutureDate != undefined) {
                    targetButtonsresponse.targetButtons = additionProperty.DisableTargetFieldIfFieldDateGreaterThanCurrentDate.TargetFieldId;
                    targetButtonsresponse.buttonStatus = true
                }
                else {
                    targetButtonsresponse.mappingIds = additionProperty.DisableTargetFieldIfFieldDateGreaterThanCurrentDate.TargetFieldId;
                }
            }
            if (additionProperty.DisableTargetFieldIfFieldAndCurrentDateDifferenceMoreThan120Years != undefined && (field.value != null && field.value != "")) {
                var isMoreThan120 = ValidationEngine.initializeValidationEngine(field, 'flag');
                if (isMoreThan120 != undefined && isMoreThan120.status == false) {
                    targetButtonsresponse.targetButtons = additionProperty.DisableTargetFieldIfFieldAndCurrentDateDifferenceMoreThan120Years.TargetFieldId;
                    targetButtonsresponse.buttonStatus = true
                }
                else {
                    targetButtonsresponse.mappingIds = additionProperty.DisableTargetFieldIfFieldAndCurrentDateDifferenceMoreThan120Years.TargetFieldId;
                }
            }
            if (additionProperty.DisableTargetFieldIfFieldDateLowerThanCurrentDate != undefined && (field.value != null && field.value != "")) {
                var isPastDate = ValidationEngine.initializeValidationEngine(field, 'flag');
                if (isPastDate != undefined && isPastDate.status == false) {
                    targetButtonsresponse.targetButtons = additionProperty.DisableTargetFieldIfFieldDateLowerThanCurrentDate.TargetFieldId;
                    targetButtonsresponse.buttonStatus = true
                }
                else {
                    targetButtonsresponse.mappingIds = additionProperty.DisableTargetFieldIfFieldDateLowerThanCurrentDate.TargetFieldId;
                }
            }
            if (additionProperty.DisableTargetFieldByComparisonWithDependentField != undefined && (field.value != null && field.value != BLANK_VALUE)) {
                var response = ValidationEngine.initializeValidationEngine(field, 'flag');
                if (response != undefined) {
                    targetButtonsresponse.targetButtons = additionProperty.DisableTargetFieldByComparisonWithDependentField.TargetFieldId;
                    targetButtonsresponse.buttonStatus = true
                } else {
                    targetButtonsresponse.mappingIds = additionProperty.DisableTargetFieldByComparisonWithDependentField.TargetFieldId;
                }
            }


        }
    }
    return targetButtonsresponse;
}

const ValidationButtonDisable = {
    checkTargetButtonDisable
}

export default ValidationButtonDisable