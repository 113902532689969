import { GET_USERPROFILE_INFO_URL, GET_USER_NOTIFICATION_PREFERENCES_URL, SET_USER_NOTIFICATION_PREFERENCES_URL } from "../constants/Locations";
import request from "../providers/RestServiceProvider";
import * as Locations from "../constants/Locations";



export const getUserProfileInfo = async () => {
    return await request({
        url: GET_USERPROFILE_INFO_URL,
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    });
};


export const getUserNotificationPreferences = async () => {
    return await request({
        url: GET_USER_NOTIFICATION_PREFERENCES_URL,
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    });
};



export const setUserNotificationPreferences = (userPreferences) => {
    return request({
        url: SET_USER_NOTIFICATION_PREFERENCES_URL,
        data: userPreferences,
        method: 'PUT',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response) {
                return { responseData: response }
            }
        } else {
            return { responseData: response }
        }
    })
}



export const getReferenceDataByMappingId = async (requestPayload) => {
    return await request({
        url: Locations.GET_REFERENCE_URL,
        method: 'POST',
        data: requestPayload
    }).then((response) => {
        if (response.status === 'Success') {
            return {
                responseData: response.payload,
            };
        } else {
            return {
                responseData: response.payload,
            };
        }
    });
};





const UserProfileService = {
    getUserProfileInfo,
    getUserNotificationPreferences,
    setUserNotificationPreferences
}

export default UserProfileService;