import { store } from '../../../store'
import { getFieldById } from '../../GetFieldById'
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';
import { LBL_EQUAL, LBL_EQUAL_TO, LBL_GREATER_THAN_EQUAL, LBL_LESS_THAN_EQUAL, ERR_INVALID_DATE } from '../../../constants/GlobalConstants';

export const execute = (control) => {
    const fieldProps = control.additionalProperties;
    var rule = fieldProps.DisableTargetFieldIfNoValueInFieldsByCondition;

    if (rule && rule.length > 0) {

        rule.forEach(fieldsAction => {
            var inPopUp = fieldsAction.inpopup != undefined && fieldsAction.inpopup ? fieldsAction.inpopup : false;
            var permissionDisabled = fieldsAction.IfTrueSetPermissionTypeId;
            var permissionEnabled = fieldsAction.IfFalseSetPermissionTypeId;

            fieldsAction.Condition && fieldsAction.Condition.forEach(item => {

                var dependentFieldIdArr = item.DependentFieldId.split('.');
                var fieldOneobj
                if (dependentFieldIdArr != null) {
                    fieldOneobj = getFieldById(dependentFieldIdArr[0])?.value //o index val-DocumentTypeId
                }

                var isConditionMatch = false;

                if (fieldOneobj) {
                    var fieldTwoValue = fieldOneobj[dependentFieldIdArr[1]]; //1 index-ExpirationTimeFrameBl                    

                    //check operator dynamically
                    if (item.Operator == LBL_EQUAL_TO && fieldTwoValue == item.DependentFieldValue) {
                        isConditionMatch = true;
                    }
                    else if (item.Operator == LBL_GREATER_THAN_EQUAL && fieldTwoValue >= item.DependentFieldValue) {
                        isConditionMatch = true;
                    }
                    else if (item.Operator == LBL_LESS_THAN_EQUAL && fieldTwoValue <= item.DependentFieldValue) {
                        isConditionMatch = true;
                    }
                }

                var isNeedToDisableTargetButton = false;
                if (isConditionMatch) {
                    //disable target field if any field in fields-array does not have selected value
                    fieldsAction.Fields && fieldsAction.Fields.forEach(id => {
                        var fieldObj = getFieldById(id);
                        if (!fieldObj.value) {
                            isNeedToDisableTargetButton = true;
                        } else if (fieldObj.value == ERR_INVALID_DATE) {
                            isNeedToDisableTargetButton = true;
                        }

                    });
                }

                if (isNeedToDisableTargetButton) {
                    fieldsAction.TargetFieldId && fieldsAction.TargetFieldId.forEach(fieldId => {
                        store.dispatch(updateFieldPermission(fieldId, permissionDisabled));
                    });
                }

            })

        });

    }
}

const DisableTargetFieldIfNoValueInFieldsByCondition = {
    execute
}

export default DisableTargetFieldIfNoValueInFieldsByCondition