import request from "../providers/RestServiceProvider";
import * as Constants from "../constants/GlobalConstants";
import * as Locations from "../constants/Locations";
import LocalStorageProvider from "../providers/LocalStorageProvider";
import qs from "qs";


export async function authenticate(values) {
  return await request({
    url: `${Locations.KEY_BASE_URL}${Locations.KEY_LOGIN_URL}`,
    method: "POST",
    data: values,
  }).then((response) => {
    if (response.status === "Success") {
      if (response.payload != null) {
        if (
          response.payload.tenantResponse != null &&
          response.payload.tenantResponse.length > 1
        ) {
          return {
            status: response.status,
            component: Constants.KEY_TENANT_LIST_PAGE,
            responseData: response.payload,
          };
        } else {
          return {
            status: response.status,
            component: Constants.KEY_DASHBOARD_PAGE,
            responseData: response.payload,
          };
        }
      }
    } else {
      return {
        status: response.status,
        component: Constants.KEY_FAILED,
        responseData: response.payload,
      };
    }
  });
}

export const getUserInfoByToken = async (access_token) => {
  return await request({
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    url: `${Locations.KEY_BASE_URL}${Locations.USERINFO_URL}`,
    method: "GET",
  }).then((response) => {
    if (response.status=="Success") {
      return {
        status: "Success",
        responseData: response,
      };
    } else {
      return {
        status: "Failed",
        responseData: response,
      };
    }
  });
};

export const getTenantUserInfo = async () => {
  return await request({
    url: Locations.GET_TENANT_USERINFO_URL,
    method: "GET",
  }).then((response) => {
    if (response) {
      return {
        status: "Success",
        responseData: response.payload,
      };
    } else {
      return {
        status: "Failed",
        responseData: response.payload,
      };
    }
  });
};

export const getAllTenants = async () => {
  return await request({
    url: Locations.GET_ALLTENANTS_URL,
    method: "GET"
  }).then((response) => {

    if (response) {
      return {
        status: "Success",
        responseData: response.payload,
      };
    } else {
      return {
        status: "Failed",
        responseData: response.payload,
      };
    }
  });
};

export const getIdentityAccessToken = async (values) => {
  var request_data = {
    grant_type: Constants.OAUTH_GRANT_TYPE,
    username: values.Email,
    password: values.Password,
    scope: Constants.OAUTH_SCOPE,
    client_id: Constants.OAUTH_CLIENT_ID,
    client_secret: Constants.OAUTH_CLIENT_SECRET    
  };

  return await request({
    "content-type": "application/x-www-form-urlencoded",
    url: `${Locations.KEY_BASE_URL}${Locations.KEY_LOGIN_URL}`,
    method: "POST",
    data: qs.stringify(request_data),
  }).then((response) => {
    if (response.access_token && response.access_token != null) {
      return {
        status: "Success",
        component: Constants.KEY_DASHBOARD_PAGE,
        responseData: response,
      };
    }
  }).catch(err => {
    return {
      status: 'Failed',
      component: Constants.KEY_FAILED,
      responseData: err.data,
    }
  });
};

export const getIdentityRefreshAccessToken = async (values) => {
  var request_data = {
    grant_type: Constants.REFRESHTOKEN_GRANT_TYPE,
    refresh_token: values.refresh_token,
    client_id: Constants.OAUTH_CLIENT_ID,
    client_secret: Constants.OAUTH_CLIENT_SECRET    
  };

  return await request({
    "content-type": "application/x-www-form-urlencoded",
    url: `${Locations.KEY_BASE_URL}${Locations.KEY_REFRESH_LOGIN_URL}`,
    method: "POST",
    data: qs.stringify(request_data),
  }).then((response) => {
    if (response.access_token && response.access_token != null) {
      return {
        status: "Success",
        component: Constants.KEY_DASHBOARD_PAGE,
        responseData: response,
      };
    }
  }).catch(err => {
    return {
      status: 'Failed',
      component: Constants.KEY_FAILED,
      responseData: err.data,
    }
  });
};


export const getAccessToken = async (values) => {
  return await request({
    url: `${Locations.KEY_BASE_URL}${Locations.KEY_GET_ACCESS_TOKEN_URL}`,
    method: "POST",
    data: values,
  }).then((response) => {
    if (response.status === "Success") {
      if (response.payload) {
        return {
          responseData: response.payload,
        };
      }
    }
  });
};

export const userLogout = async (sessionType) => {  
  var access_token= LocalStorageProvider.getAuthToken();

  return await request({
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    url: `${Locations.KEY_BASE_URL}${Locations.KEY_LOGOUT_URL}` + '?sessionType=' + sessionType ,
    method: "GET",
  }).then((response) => {
    if (response && response.status === 'Success') {
      console.log('Logout successfully')
    } else{
      console.log(response);
    }
  }).catch(err => {
    console.log("logout identity fail", err);
  }); 
  
};


const LoginService = {
  getIdentityAccessToken,
  getIdentityRefreshAccessToken,
  getUserInfoByToken,
  authenticate,
  getAccessToken,
  getTenantUserInfo,
  getAllTenants,
  userLogout  
};

export default LoginService;
