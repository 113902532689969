import React, { Component } from 'react';


class MedtelFileUploader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      file: null,
      type: null
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      type: event.target.files[0].type
    })
  }
  render() {
    if (this.state.type) {
      if (this.state.type.includes('image')) {
        return (
          <div>
            <input type="file" onChange={this.handleChange} />
            <img src={this.state.file} width={200} alt="Preview" />
          </div>
        )
      } else if (this.state.type.includes('pdf')) {
        return (
          <div>
            <input type="file" onChange={this.handleChange} />
            <embed className="embed-content" style={{ width: '100%', height: '85%' }} type="application/pdf" src={this.state.file} />
          </div>
        )
      }
    } else {
      return (
        <div>
          <input type="file" onChange={this.handleChange} />
        </div>
      )
    }


  }
}
export default MedtelFileUploader
