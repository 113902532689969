import React from 'react'
import { IconProvider } from '../../providers/IconProvider'
import { ICON_RED } from '../../res/values/Colors';
import { ICON_GR } from '../../res/values/Colors';

function Image(props) {
    const { color, onClick, additionalProperties } = props
    const greenTick = ["Approved","Successfully Transmitted"];
    const redTick = ["Rejected", "Failed Transmitting"];
    let iconColor;
    var imgType = ''
    if (additionalProperties.Image) {
        if(greenTick.includes(props.value)) { imgType = additionalProperties.Image[1]; iconColor = ICON_GR;}
        else if(redTick.includes(props.value)) { imgType = additionalProperties.Image[0] ; iconColor = ICON_RED;}

        if(imgType != null && imgType != undefined && imgType != '')
        {
            var [Icon] = IconProvider(imgType)
            if (props.disabled) {
                return (
                    <div>
                        <Icon 
                        style={{
                            color: iconColor,
                        }} />
                    </div>
                )
            }
            else {
                return (
                    <div onClick={onClick}>
                        <Icon style={{
                            color: color || iconColor,
                        }} />
                    </div>
                )
            }
    }
    else {
        return <></>
    }
    } else {
        return <></>
    }
}

export default Image
