import { LOGIN_FAILED, LOGIN_MULTITENANTS, LOGIN_SINGLETENANT, SAVE_SELECTED_TENANT, RESET_COMPONENT, EXPIRE_TOKEN_STATUS } from '../constants/ActionTypes';
import { KEY_FAILED } from '../constants/GlobalConstants';

const initialState = {
  userData: [],
  component: '',
  error: {},
  accessToken: '',
  refreshToken: '',
  tokenType: '',
  expiresIn: '',
  selectedTenant: '',
  time: '',
  expireTokenStatus: false
}

const ACTION_HANDLERS = {
  [RESET_COMPONENT]: (state, action) => {
    return {
      ...state,
      component: '',

    }
  },
  [LOGIN_FAILED]: (state, action) => {
    return {
      ...state,
      component: KEY_FAILED,
      error: action.payload,
      time: action.time,
    }
  },
  [LOGIN_MULTITENANTS]: (state, action) => {
    return {
      ...state,
      userData: action.payload,
      component: LOGIN_MULTITENANTS
    }
  },
  [LOGIN_SINGLETENANT]: (state, action) => {
    return {
      ...state,
      userData: action.payload,
      component: LOGIN_SINGLETENANT,
      accessToken: action.payload.access_token,
      refreshToken: action.payload.refresh_token,
      tokenType: action.payload.token_type,
      expiresIn: action.payload.expiresIn
    }
  },

  [SAVE_SELECTED_TENANT]: (state, action) => {
    return {
      ...state,
      selectedTenant: action.payload
    }
  },
  [EXPIRE_TOKEN_STATUS]: (state, action) => {
    return {
      ...state,
      expireTokenStatus: action.expireTokenStatus
    }
  },
};


export default function user(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const loginUserData = state => state.user.userData;
export const getSelectedTenant = state => state.user.selectedTenant;

