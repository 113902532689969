import { store } from '../../../store'
import * as Constants from '../../../constants/GlobalConstants';
import VisibilityControlAction from '../../../actions/VisibilityControlAction'

export const execute = (control,onChange) => {
    if (control) {
        const rule = control.additionalProperties.HideColumnOfTargetFieldWhenFieldDoesHaveValue;
        var fieldValue = null;
        if (control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
            fieldValue = control.value ? control.value[control.additionalProperties.DisplayColumnId] : null; 
        } else {
            fieldValue = control.value
        }
        if (fieldValue != rule[0].FieldValue) {         
            store.dispatch(VisibilityControlAction.showHideTableControl(rule[0].TargetFieldId,rule[0].Column,false,onChange))
        }        
    }
}

const HideColumnOfTargetFieldWhenFieldDoesHaveValue = {
    execute
}

export default HideColumnOfTargetFieldWhenFieldDoesHaveValue