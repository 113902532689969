import { store } from '../../../store';
import { updateControlLabel } from '../../../actions/CaseDetailsAction';
import { calculateValue } from '../../CalculateValue'

export const execute = (props) => {
    const fieldProps = props.additionalProperties
    const rule = fieldProps.DisplayControlTextByCondition
    if (rule) {
        rule.map(e => {
            if (e.Condition.every(condition => {
                return calculateValue(props.value, condition.DependentFieldValue, condition.FieldValueOperator)
            })) {
                store.dispatch(updateControlLabel(props.mappingId, e.ControlText, props.parentFieldId))
            }
        })
    }
}

const DisplayControlTextByCondition = {
    execute
}

export default DisplayControlTextByCondition