import React, { useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Typography
} from '@mui/material';
import { Form } from './useForm'
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import SectionGroup from './SectionGroupComponent';
import { BoldTextByTagName } from '../../utils/CommonFunctions';
import { Box, CircularProgress } from '@mui/material'
const useStyles = makeStyles(theme => ({
  dialogActionContainer: {
    'justify-content': 'flex-start',
    'margin-left': '14px'
  },
  headerTitle: {
    backgroundColor: '#17689e',
    color: '#fff'
  }
}))

function mapStateToProps(state) {
  return {
    popupDialogContent: state?.caseDetails?.popupSectionDetails,
  }
}

function TrayPopupComponent(props) {

  const { open = false, section, dialogTitle, dialogContentText, maxWidth, isShowOkButton = false, isShowCancelButton = false,
    onCloseDialog, onConfirmDialog, confirmButtonText, confirmButtonColor } = props;
  const popupDialogContent = props?.popupDialogContent.find(e => e.sectionId == section);
  const classes = useStyles()

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth || 'md'}
      >
        <DialogTitle className={classes.headerTitle}>{dialogTitle}</DialogTitle>
        <Divider />
        <DialogContent>
          {!popupDialogContent && <Box style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={24} />
          </Box>}
          <DialogContentText>{BoldTextByTagName(dialogContentText)}</DialogContentText>
          <Form>{dialogContentBody(popupDialogContent)}</Form>
        </DialogContent>
        <DialogActions className={classes.dialogActionContainer}>
          <div>
            {isShowCancelButton &&
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onCloseDialog()
                  }}
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;
              </>
            }
            {isShowOkButton &&
              <Button
                variant="contained"
                color={confirmButtonColor || "primary"}
                onClick={() => {
                  onConfirmDialog()
                }}
              >
                {confirmButtonText || 'Ok'}
              </Button>
            }
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

const renderSectionHeader = (sectionGroupName) => {
  if (sectionGroupName) return (<Typography variant="h6" style={{ fontSize: '1rem', color: '#555A53', fontWeight: 'bold' }}>{sectionGroupName}</Typography>);
}
const dialogContentBody = (popUpContent) => {
  if (popUpContent) {
    var sectionGroupLenght = popUpContent.sectionGroup ? popUpContent.sectionGroup.length : 0;
    return popUpContent.sectionGroup.map(group => {
      return (
        group.isVisible && <>
          {sectionGroupLenght > 1 && renderSectionHeader(group.sectionGroupName)}
          <SectionGroup
            group={group}
            key={group.sectionGroupId}
            label={group.sectionGroupName}
            entitlementKey={group.description}
            isPopup={true}
          />
          <Divider style={{ margin: '25px 0 25px 0' }} />
        </>
      )
    })
  }
  else {
    return <></>
  }
};

export default connect(mapStateToProps)(TrayPopupComponent)