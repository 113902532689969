import React, { useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  CircularProgress
} from '@mui/material';
import { Form } from '../common/useForm'
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import SectionGroupComponentDialogBoxUploadDoc from './SectionGroupComponentDialogBoxUploadDoc';
import sanitize from 'sanitize-html';

const useStyles = makeStyles(theme => ({
  dialogActionContainer: {
    'justify-content': 'flex-start',
    'margin-left': '14px'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  closeIcon: {
    marginRight: '14px'
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

function mapStateToProps(state) {
  return {
    popupDialogContent: state?.caseDetails?.docUploadPopUpDetails,
    fullScreen: state?.caseDetails?.fullScreenBl,
    isSectionLoaded: state?.caseDetails?.docSectionPopUpLoad,
  }
}

function UploadDocumentDialogBoxComponent(props) {
  const { open = false, dialogTitle, dialogContentText, maxWidth, isShowOkButton = false, isShowCancelButton = false,
    onCloseDialog, onConfirmDialog, confirmButtonText, confirmButtonColor, fullScreen, popupDialogContent, caseId, isSectionLoaded, ref } = props;
  const classes = useStyles()

  return (
    <>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle className={classes.title}>{isSectionLoaded && popupDialogContent?.sectionHeader?.sectionName}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText dangerouslySetInnerHTML={{ __html: dialogContentText ? sanitize(dialogContentText) : "" }} />
          <Form>
            {isSectionLoaded ? <SectionGroupComponentDialogBoxUploadDoc
              onCloseDialog={onCloseDialog}
              popupDialogContent={props?.popupDialogContent}
              caseId={caseId}
            /> : <div className={classes.progressContainer}><CircularProgress size={30}/></div>}
          </Form>
        </DialogContent>
        <DialogActions className={classes.dialogActionContainer}>
          <div>
            {isShowCancelButton &&
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onCloseDialog()
                  }}
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;
              </>
            }
            {isShowOkButton &&
              <Button
                variant="contained"
                color={confirmButtonColor || "primary"}
                onClick={() => {
                  onConfirmDialog()
                }}
              >
                {confirmButtonText || 'Ok'}
              </Button>
            }
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps)(UploadDocumentDialogBoxComponent)
