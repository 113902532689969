import { store } from '../../../store';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { getFieldById } from '../../GetFieldById'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import {
    LBL_EQUAL, LBL_EQUAL_TO, LBL_GREATER_THAN, LBL_LESS_THAN, LBL_GREATER_THAN_EQUAL, LBL_LESS_THAN_EQUAL,
    COMMON_DATE_FORMAT, BLANK_VALUE
} from '../../../constants/GlobalConstants';
import moment from 'moment';


export const execute = (props) => {
    var fieldProps = props.additionalProperties;
    const stateData = store.getState();
    //will compare and set error text according
    var rule = fieldProps.CompareOtherFieldValues;

    //{"InitialDate":"DocumentSignedDate", "ExpirationTimeFrameInDays":"DocumentTypeId.ExpirationTimeFrameInDays"} 
    if (rule && rule.OtherFields && rule.OtherFields.Field1 && rule.OtherFields.Field2 && rule.OtherFields.ComparisonOperator) {
        var errorMessage = "";
        var OtherFields = rule.OtherFields
        var variableDefaultFieldobj = rule.VariableDefaultText;
        var fieldOneObj = getFieldById(OtherFields.Field1); //DocumentExpirationDate
        var fieldTwoObj = "" //getFieldById(OtherFields.Field2); //HFProcedureDate

        //Get hidden control value of current section from store
        if (stateData && stateData.caseDetails && stateData.caseDetails.sectionDetails && stateData.caseDetails.sectionDetails.sectionGroup) {
            stateData.caseDetails.sectionDetails.sectionGroup.forEach(item => {
                item && item.fields && item.fields.length > 0 && item.fields.forEach(fielditem => {
                    if (fielditem.mappingId == OtherFields.Field2) {
                        fieldTwoObj = fielditem;
                    }
                });
            });
        }
        
        var inPopUp = rule.inpopup != undefined && rule.inpopup ? rule.inpopup : false;
        if (fieldOneObj && fieldOneObj.value && fieldTwoObj && fieldTwoObj.value) {
            var fieldOneValue = moment(fieldOneObj.value,COMMON_DATE_FORMAT,true);
            var fieldTwoValue = moment(fieldTwoObj.value,COMMON_DATE_FORMAT,true);
            if (OtherFields.ComparisonOperator == LBL_EQUAL
                && moment(fieldTwoValue).isSame(fieldOneValue) === true) {
                errorMessage = getErrorMessage(variableDefaultFieldobj);
                // update text in UploadDocumentAlertLbl label
                store.dispatch(VisibilityControlAction.showField(rule.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(rule.TargetFieldId, errorMessage || rule.OnInvalidValue));

            }
            else if (OtherFields.ComparisonOperator == LBL_GREATER_THAN
                && moment(fieldTwoValue).isAfter(fieldOneValue) === true) {
                errorMessage = getErrorMessage(variableDefaultFieldobj);
                // update text in UploadDocumentAlertLbl label
                store.dispatch(VisibilityControlAction.showField(rule.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(rule.TargetFieldId, errorMessage || rule.OnInvalidValue));

            }
             else if (OtherFields.ComparisonOperator == LBL_GREATER_THAN_EQUAL
                && moment(fieldTwoValue).isSameOrAfter(fieldOneValue) === true) {
                errorMessage = getErrorMessage(variableDefaultFieldobj);
                // update text in UploadDocumentAlertLbl label
                store.dispatch(VisibilityControlAction.showField(rule.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(rule.TargetFieldId, errorMessage || rule.OnInvalidValue));

            }
            else if (OtherFields.ComparisonOperator == LBL_LESS_THAN
                && moment(fieldTwoValue).isBefore(fieldOneValue) === true) {
                errorMessage = getErrorMessage(variableDefaultFieldobj);
                // update text in UploadDocumentAlertLbl label
                store.dispatch(VisibilityControlAction.showField(rule.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(rule.TargetFieldId, errorMessage || rule.OnInvalidValue));
            } 
            else if (OtherFields.ComparisonOperator == LBL_LESS_THAN_EQUAL
                && moment(fieldTwoValue).isSameOrBefore(fieldOneValue) === true) {
                errorMessage = getErrorMessage(variableDefaultFieldobj);
                // update text in UploadDocumentAlertLbl label
                store.dispatch(VisibilityControlAction.showField(rule.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(rule.TargetFieldId, errorMessage || rule.OnInvalidValue));
            } 
            else {
                store.dispatch(VisibilityControlAction.hideField(rule.TargetFieldId, inPopUp))
                store.dispatch(updateControlValue(rule.TargetFieldId, BLANK_VALUE));              
            }
        }

    }

}

function getErrorMessage(variableDefaultFieldobj) {
    var errorMessage = BLANK_VALUE;
    if (variableDefaultFieldobj && variableDefaultFieldobj.DefaultText
        && variableDefaultFieldobj.DependentFieldId) {

        errorMessage = variableDefaultFieldobj.DefaultText;

        var tempDependentFieldIdArray = variableDefaultFieldobj.DependentFieldId;

        tempDependentFieldIdArray && tempDependentFieldIdArray.forEach((item, index) => {
            //"DependentFieldId":["DocumentTypeId.DocumentTypeName","DocumentTypeId.ExpirationTimeFrameInDays"]
            var tempKeysArray = item.split('.');
            var tempObj = tempKeysArray[0] ? getFieldById(tempKeysArray[0]) : null;
            if (tempObj && tempObj.value) {
                var value = tempObj.value[tempKeysArray[1]];
                errorMessage = errorMessage.replace("<F" + (index + 1) + ">", value).replace("<F" + (index + 1) + ">", value);

            }
        })

    }
    return errorMessage;
}

const CompareOtherFieldValues = {
    execute
}
export default CompareOtherFieldValues