import { SHOW_ALERT, HIDE_ALERT, CLEAR_ALERT, SESSION_WARNING_POPUP} from "../constants/ActionTypes"

const showSectionAlert = (name, severity, message, position=null) => {
    return dispatch => {
        const showSectionAlert = (name, severity, message) => {
            return {
                type: SHOW_ALERT,
                payload: {
                    name, 
                    severity,
                    message,
                    time: new Date(),
                    position
                }
            }
        }
        dispatch(showSectionAlert(name, severity, message,position))
    }
}

const hideSectionAlert = (alertName) => {
    return dispatch => {
        const hideSectionAlert = (alertName) => {
            return {
                type: HIDE_ALERT,
                payload: alertName
            }
        }
        dispatch(hideSectionAlert(alertName))
    }
}

const clearSectionAlert = () => {
    return dispatch => {
        const clearSectionAlert = () => {
            return {
                type: CLEAR_ALERT
            }
        }
        dispatch(clearSectionAlert())
    }
}


export const sessionToggleDialog = (status) => {
    return {
        type: SESSION_WARNING_POPUP,
        payload: status
    }
}

const SectionAlertAction = {
    showSectionAlert,
    hideSectionAlert,
    clearSectionAlert
}

export default SectionAlertAction