import { store } from '../../../store';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
import { getFieldById } from '../../GetFieldById'
import { COMMON_DATE_FORMAT } from '../../../constants/GlobalConstants';


export const execute = (props) => {
    var fieldProps = props.additionalProperties
    var rule = fieldProps.ConcatenateFieldText
    if (rule && props.mappingId) {
        var concatenateText = "";
        rule && rule.map(item => {
            if (item.Type == "FreeText") {
                concatenateText += item.Value;
            }
            else if (item.Type == "DependentField" && item.DependentFieldId == "PatientFullName") {
                const state = store.getState();
                var headerInformation = state.caseDetails.headerInformation;                         
                concatenateText += `<b> ${headerInformation.patientFullName }</b>`;               
            }
        });
     
        store.dispatch(updateControlValue(props.mappingId, concatenateText));
    }
}

const ConcatenateFieldText = {
    execute
}
export default ConcatenateFieldText