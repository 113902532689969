
export function isNumeric(str) {
    return /^[0-9]+$/.test(str);
  }

    export function BoldTextByTagName(text){
    if (text && text.indexOf('<b>') != -1) { 
    const start = `<b>`;
    const end = `</b>`;
    const startText = text.split(start)[0];
    const middleText = text.split(start)[1].split(end)[0];
    const endText = text.split(end)[1];
    return (
        <>
            <label>{startText}<b>{middleText}</b>{endText}</label>
        </>
    )
    }
    else{
      return text;
    }
  }