import { store } from '../../../store'
import { getFieldValueById } from '../../GetFieldValueByID';
import { calculateValue } from '../../CalculateValue';
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';

export const execute = (props) => {
    const fieldProps = props.additionalProperties
    let fieldMappingId = props.mappingId;
    if (fieldProps) {
        if (fieldProps.ChangeFieldPermissionByDependentFieldsValueByCondition.length > 0) {
            var ischeckCondition = false;
            fieldProps.ChangeFieldPermissionByDependentFieldsValueByCondition.forEach(fieldsCondition => {
                if (ischeckCondition) { return; }
                var checkCondition = fieldsCondition.Condition;
                var checkAllDependentFields = fieldsCondition.DependentFields;
                var ifPermissionTypeIdFalse = fieldsCondition.DependentFieldValueMissingIfFalse.PermissionTypeId;
                var ifPermissionTypeIdTrue = fieldsCondition.DependentFieldsValueMissingIfTrue.PermissionTypeId;
                checkCondition.forEach(configFields => {
                    var checkResult = "";
                    var fieldValueOperator = configFields.DependentFieldValueOperator;
                    var checkByFieldValue = configFields.DependentFieldValue;
                    var fieldValue = getFieldValueById(configFields.DependentFieldId);
                    if (fieldValue && fieldValueOperator && checkByFieldValue) {
                        checkResult = calculateValue(fieldValue, checkByFieldValue, fieldValueOperator);
                        if (checkResult) {

                            checkAllDependentFields.forEach(checkAllFields => {
                                if (ischeckCondition) { return; }
                                var fieldValue = getFieldValueById(checkAllFields);
                                if (!fieldValue) {
                                    ischeckCondition = true;
                                    return;
                                }
                            });
                            if (ischeckCondition) {
                                store.dispatch(updateFieldPermission(fieldMappingId, ifPermissionTypeIdTrue));
                            } else {
                                store.dispatch(updateFieldPermission(fieldMappingId, ifPermissionTypeIdFalse));
                            }
                        }
                    }
                })

            })
        }
    }
}

const ChangeFieldPermissionByDependentFieldsValueByCondition = {
    execute
}

export default ChangeFieldPermissionByDependentFieldsValueByCondition   