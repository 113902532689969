import React from 'react'
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormHelperText from '@mui/material/FormHelperText';


const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    error: {
        color: "#d32f2f",
        "& .MuiIconButton-label": {
            color: "#d32f2f"
        },
        "& .MuiFormControlLabel-label.Mui-disabled": {
            color: "#d32f2f"
        }
    }
}))

export default function Checkbox(props) {

    const { fieldId, name, label, value, onChange, disabled, isRequired, error = false, helperText } = props;
    const classes = useStyles();
    const fieldProps = props?.additionalProperties;
    var hideText = fieldProps?.DisplayFieldName;
    var checkboxLabel = label;
    if (hideText === false) {
        checkboxLabel = "";
    }

    return (
        <div className={classes.root}>
            <FormControlLabel
                className={error ? classes.error : null}
                control={
                    <MuiCheckbox
                        checked={(value && (value == '1' || value == 'True' || value == 'true')) ? true : false}
                        onChange={onChange}
                        color="primary"
                        name={name}
                        id={fieldId}
                        disabled={disabled}
                    />
                }
                label={checkboxLabel}
            />
            {/* <FormHelperText error={error}>{helperText}</FormHelperText>  TODo*/}
        </div>
    )
}
