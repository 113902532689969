import { getSingleSectionDetails, getViewDocumentPoupData, getViewDocumentFileStream,updateIsPopUpOpen } from "../../../actions/CaseDetailsAction";
import { showDialog } from "../../../actions/DialogAction";
import { getPopupSectionDetails } from "../../../reducers/CaseDetailsReducer";
import { store } from "../../../store";
import caseDetailsService from '../../../services/CaseDetailsService';
import {
    LBL_VIEW_DOCUMENT
  } from '../../../constants/GlobalConstants';

const execute = (control, AddOnLoadONChangeFlag = null) => {
    if (AddOnLoadONChangeFlag) {
        const viewDocument = control && control.value && control.fieldName == LBL_VIEW_DOCUMENT;
        store.dispatch(updateIsPopUpOpen(true, viewDocument));
        const rule = control.additionalProperties.OnClickShowSectionAsPopup
        var sectionId = rule.SectionId;
        const popupSectionDetails = getPopupSectionDetails(store.getState())
        if (popupSectionDetails.length <= 0 || !popupSectionDetails.find(e => {
            return e.sectionId == sectionId
        })) {
            if (rule && rule.UseSectionCall) {                               
                if (viewDocument) {
                    //sectionId == 19, 34
                    var downloadDoccUrl = control.value;                 
                    var docId = downloadDoccUrl.replace("/download", "").replace("/view", "").split("document/")[1];
                    var state = store.getState();                    
                    store.dispatch(getViewDocumentPoupData(control.mappingId, sectionId, state.caseDetails.caseId, docId));
                    store.dispatch(getViewDocumentFileStream(downloadDoccUrl));
                } else {
                    var state = store.getState();
                    caseDetailsService.getSectionDetailsBySectionIdCaseId(sectionId, state.caseDetails.caseId);
                }
            }
            else {
                store.dispatch(getSingleSectionDetails(control.mappingId, sectionId))
            }
        }
        store.dispatch(showDialog(control.mappingId, true, control))
    }
}

const OnClickShowSectionAsPopup = {
    execute
}

export default OnClickShowSectionAsPopup