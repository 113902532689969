import { GET_USER_NOTIFICATION_PREFERENCES_FAILURE, GET_USER_NOTIFICATION_PREFERENCES_SUCCESS, SET_USER_NOTIFICATION_PREFERENCES_SUCCESS, UPDATE_USER_NOTIFICATION_PREFERENCES_SUCCESS } from "../constants/ActionTypes";


const initialState = {
    userNotificationPreferences: [],

};

const ACTION_HANDLERS = {

    [GET_USER_NOTIFICATION_PREFERENCES_SUCCESS]: (state, action) => ({
        ...state,
        userNotificationPreferences: action.payload
    }),

    [GET_USER_NOTIFICATION_PREFERENCES_FAILURE]: (state, action) => ({
        ...state,
        userNotificationPreferences: [],
    }),

    [UPDATE_USER_NOTIFICATION_PREFERENCES_SUCCESS]: (state, action) => ({
        ...state,
        userNotificationPreferences: action.payload,
    }),





};

export default function userprofile(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;

}



// Private Selectors
export const getUserNotificationPreferences = state => state.userprofile;

