export const inputLengthGreaterThanProvidedValue = (control) => {
    let fieldValueLength = control.value ? control.value.trim().length : 0
    if (control && control.validation) {
        var validation = control.validation
        var response = null;
        if(validation.InputLengthGreaterThanProvidedValue) {
            let checkRule = validation.InputLengthGreaterThanProvidedValue.some((rule) => {
                return parseInt(rule.ProvidedValue) < fieldValueLength
              })
              if (!checkRule) {
                response = {
                  isRequired: true,
                  status: false,
                  message: ""
                };
              }
        }
        return response
    }
}