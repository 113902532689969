import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "../constants/ActionTypes";

export const notify = (title, message, severity) => {
    const notification = (title, message, severity) => {
        return {
            type: OPEN_SNACKBAR,
            title,
            message,
            severity,
            time: new Date()
        }
    }
    return dispatch => {
        dispatch(notification(title, message, severity))
    }
}

export const closeNotification = () => {
    let message = {}
    const notification = message => {
        message.isOpen = false
        return {
            type: CLOSE_SNACKBAR,
            payload: message
        }
    }
    return dispatch => {
        dispatch(notification(message))
    }
}


