import React from 'react'
import { Button as MuiButton, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { getPopupLoader } from '../../reducers/CaseDetailsReducer';


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none',
    }
}))

export default function GreenButton(props) {

    const { label, size, color, variant, onClick, index, type, disabled, sx, loader,fieldId } = props
    const classes = useStyles();
    const popupLoaderStatus = useSelector(getPopupLoader);
    var isExecuteApi = false;
    const buttonListForShowingLoader = ["SaveProductAndTrayBtn","DocFinalRejectBtn","DocFileUploadUploadBtn","DeleteDocumentYesBtn","VerifyBtn","TrayPopupCloseBtn"];

    if (buttonListForShowingLoader.includes(props.fieldId) ) {

        if (props.additionalProperties.OnClickClosePopup != undefined && props.additionalProperties.OnClickClosePopup.length > 0) {
            isExecuteApi = props.additionalProperties.OnClickClosePopup[0].OnClickExecuteServiceAPI ? true : false;

        }
    }



    return (
        <MuiButton
        style={{
            left: '-30%',
            borderRadius:'4px'     
        }}
            size={size || "large"}
            color={color || "success"}
            variant= {variant || 'contained'}
            onClick={onClick}
            index={index}
            type={type}
            id={fieldId}
            disabled={(popupLoaderStatus && isExecuteApi) || disabled}
            classes={{ root: classes.root, label: classes.label }}>
            {label}
            {loader}
            {popupLoaderStatus && isExecuteApi && <CircularProgress
                size={24}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />}
        </MuiButton>
    )
}
