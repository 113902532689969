import { store } from '../../../store'
import { getFieldById } from '../../GetFieldById'
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';
import { ERR_INVALID_DATE } from '../../../constants/GlobalConstants';
import * as dateValidation from "../ValidationRules/DateValidations";

export const execute = (props) => {
    const fieldProps = props.additionalProperties
    if (fieldProps && fieldProps.DisableTargetFieldIfNoValueInFields) {
        var permissionDisabled = fieldProps.DisableTargetFieldIfNoValueInFields.IfTrueSetPermissionTypeId;
        var permissionEnabled = fieldProps.DisableTargetFieldIfNoValueInFields.IfFalseSetPermissionTypeId;
        var inPopUp = fieldProps.DisableTargetFieldIfNoValueInFields.inpopup != undefined
            && fieldProps.DisableTargetFieldIfNoValueInFields.inpopup ? fieldProps.DisableTargetFieldIfNoValueInFields.inpopup : false;
        var isNeedToDisableTargetButton = false;
        //disable target field if any field in field-array does not have selected value
        fieldProps.DisableTargetFieldIfNoValueInFields.Fields.forEach(id => {
            var fieldObj = getFieldById(id);
            //TODO:Need to remove hardcoded fields
            const tempDocumentSignedDate="DocumentSignedDate";
            const tempDocumentSignatureDate="DocumentSignatureDate";
            const tempDocumentTypeId="DocumentTypeId";
            if ((fieldObj.mappingId == tempDocumentSignedDate || fieldObj.mappingId == tempDocumentSignatureDate) && !fieldObj.value) {
                var tempObj = getFieldById(tempDocumentTypeId)
                //signeddate is mandatory for H&P
                if (tempObj && tempObj.value && tempObj.value.DocumentTypeId && tempObj.value.ExpirationTimeFrameBl && !fieldObj.value) {
                    isNeedToDisableTargetButton = true;
                }
            }
           else if ((fieldObj.mappingId == tempDocumentSignedDate || fieldObj.mappingId == tempDocumentSignatureDate) && fieldObj.value) {
            var tempObj = getFieldById(fieldObj.mappingId);
            if(tempObj && tempObj.value){
                var validateField = dateValidation.disableFutureDate(tempObj.value);    
                if(tempObj.value == ERR_INVALID_DATE || (validateField && !validateField.status))  {
                    isNeedToDisableTargetButton = true;
                }                      
            }
            }
            else if (!fieldObj.value) {
                isNeedToDisableTargetButton = true;
            } else if (fieldObj.value == ERR_INVALID_DATE) {
                isNeedToDisableTargetButton = true;
            }

        })

        fieldProps.DisableTargetFieldIfNoValueInFields.TargetFieldId 
        && fieldProps.DisableTargetFieldIfNoValueInFields.TargetFieldId.forEach(fieldId => {
            if (isNeedToDisableTargetButton) {                
                store.dispatch(updateFieldPermission(fieldId, permissionDisabled));
            } 
            else {
                store.dispatch(updateFieldPermission(fieldId, permissionEnabled));
            }
        })
    }
}

const DisableTargetFieldIfNoValueInFields = {
    execute
}

export default DisableTargetFieldIfNoValueInFields