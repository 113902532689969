import {
  INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE_DUETO_DEPENDENT_FIELD
} from '../../../constants/GlobalConstants';
import { getFieldValueById } from '../../GetFieldValueByID';

export const inputSelectionMoreThanProvidedValueDueToDependentFieldValue = (control) => {
  var fieldValue = control.value ? control.value : null;
  if (control && control.validation) {
    var validation = control.validation
    var checkDependentFields = validation[INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE_DUETO_DEPENDENT_FIELD] ? validation[INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE_DUETO_DEPENDENT_FIELD] : null;
    var response = null;
    if (checkDependentFields != null) {
      checkDependentFields.forEach(checkDependent => {
        var povidedValue = checkDependent.ProvidedValue ? checkDependent.ProvidedValue : null;
        var dependentFieldId = checkDependent.DependentFieldId ? checkDependent.DependentFieldId : null;
        var dependentFieldValue = checkDependent.DependentFieldValue ? checkDependent.DependentFieldValue : null;
        var checkFieldValue = getFieldValueById(dependentFieldId);        
       if ((checkFieldValue == dependentFieldValue) && (fieldValue == null || fieldValue == povidedValue)) {
            response = {
            isRequired: true,
            status: false,
            message: "Invalid value!"
          };
        }
      });
    }
  }
  return response;
}