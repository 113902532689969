import { getFieldValueById } from "../../../../GetFieldValueByID"
import moment from 'moment';

export const execute = (rule) => {
    var dependentFields = rule.Alerts.filter(item => item.Name == "ProcedureSchedulingAlert");
    const procedureDate = moment(getFieldValueById(dependentFields[0].DependentFields.Field1));
    const caseCreatedDate = moment(getFieldValueById(dependentFields[0].DependentFields.Field2));
    const dayDiffToCheck = dependentFields[0].DependentFields.Field3;

    if(procedureDate != null && caseCreatedDate != null && dayDiffToCheck != null) {
        let dayDiff = procedureDate.startOf('day').diff(caseCreatedDate.startOf('day'),'days');
        if (dayDiff <= parseInt(dayDiffToCheck)) {
            return true
        }
    }
    return false
}

const ProcedureSchedulingAlert = {
    execute
}

export default ProcedureSchedulingAlert