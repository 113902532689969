export const fieldFieldRecursivelyInChildControl = (element, fieldID) => {
  if (element.mappingId == fieldID) {
    return element;
  }
  else if (element.childControls.length > 0) {
    for (let i = 0; i < element.childControls.length; i++) {
      const item = element.childControls[i];
      if (Array.isArray(item)) {
        for (let itemIdx = 0; itemIdx < item.length; itemIdx++) {
          const control = item[itemIdx];
          var found = fieldFieldRecursivelyInChildControl(control, fieldID);
          if (found) return found;
        }
      }
      else {
        var found = fieldFieldRecursivelyInChildControl(item, fieldID);
        
        if (found) return found;
      }
    }
  }
}