import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getLoadingStatus, getItemsPerPage, getPageNumber, getTotalCases } from '../../reducers/ReportsReducer';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import { Link } from 'react-router-dom';
import * as Constants from '../../constants/GlobalConstants'
import makeStyles from '@mui/styles/makeStyles';
import * as R from 'ramda';
import * as Colors from '../../res/values/Colors';
import withStyles from '@mui/styles/withStyles';
import TablePaginationExtended from './TablePaginationExtended';

const useStyles = makeStyles(theme => ({
    redFont: {
        color: '#d32f2f'
    },
    greenFont: {
        color: '#179e21'
    },
    tableFont: {
        wordBreak: 'break-word',
        '-webkit-hyphens': 'auto',
        '-moz-hyphens': 'auto',
        hyphens: 'auto'
    },
    dataTableContainer: {
        maxHeight: `calc(100vh - ${theme.spacing(32)})`
    },
    dataTableHeader: {
        backgroundColor: Colors.CAT_TABLE_HEADER
    },
})
);

const TableCellHead = withStyles((theme) => ({
    root: {
        backgroundColor: Colors.CAT_TABLE_HEADER,
        color: theme.palette.common.white,
        minWidth: 80
    },
}))(TableCell);

export default function InpatientAdmitTypeDataTable(props) {
    const { data } = props;
    const loading = useSelector(getLoadingStatus)
    const pageNumber = useSelector(getPageNumber)
    const itemsPerPage = useSelector(getItemsPerPage)
    const totalCases = useSelector(getTotalCases)
    const classes = useStyles()

    const RenderTableBody = (props) => {
        const { row } = props;
        let complianceStatusMessage = ""
        if (row.CaseComplianceStatusBl === true) {
            complianceStatusMessage = Constants.COMPLIANT
        } else if (R.isEmpty(row.AdmissionTypeName)) {
            complianceStatusMessage = Constants.INCOMPLETE
        } else {
            complianceStatusMessage = Constants.NON_COMPLIANT
        }
        return (
            <>
                <TableRow hover  >
                    <TableCell component="tb" scope="row" align="center">
                        {row.PatientName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.PrimarySurgeonName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.HospitalName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureUnitName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        <Link to={`case/${parseInt(row.CaseId)}/PatientInformation`}>
                            {row.CaseId}
                        </Link>
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.ProcedureDate.slice(0, 10)}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.AdmissionTypeName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center">
                        {row.SelectedCPTCodeName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="center" className={row.CaseComplianceStatusBl == 1 ? classes.greenFont : classes.redFont}>
                        {complianceStatusMessage}
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <Paper elevation={2} sx={{ width: '100%', overflow: 'hidden', marginBottom: 2 }}>
            <TableContainer className={classes.dataTableContainer}>
                <Table stickyHeader aria-label="sticky table" classes={classes.tableFont} size="small">
                    <TableHead className={classes.dataTableHeader}>
                        <TableRow>
                            <TableCellHead align='center'>{Constants.LBL_PATIENT_NAME}</TableCellHead>
                            <TableCellHead align='center'>{Constants.LBL_PROVIDER}</TableCellHead>
                            <TableCellHead align='center'>{Constants.LBL_SURGICAL_LOCATION}</TableCellHead>
                            <TableCellHead align='center'>{Constants.LBL_PROCEDURE_UNIT}</TableCellHead>
                            <TableCellHead align='center'>{Constants.LBL_CASE_ID}</TableCellHead>
                            <TableCellHead align='center'>{Constants.LBL_PROCEDURE_DATE}</TableCellHead>
                            <TableCellHead align='center'>{Constants.LBL_ADMISSION_TYPE}</TableCellHead>
                            <TableCellHead align='center'>{Constants.LBL_INPATIENT_CODE}</TableCellHead>
                            <TableCellHead align='center'>{Constants.LBL_COMPLIANCE_STATUS}</TableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ?
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan="9" >
                                    <Loader loading={loading} />
                                </TableCell>
                            </TableRow>
                            : ((data && data.length !== 0) ? data
                                .map((row, i) => (<RenderTableBody row={row} />)) :
                                (<TableRow>
                                    <TableCell component="th" scope="row" colSpan="9" >
                                        {Constants.LBL_NO_CASE_FOUND}
                                    </TableCell>
                                </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePaginationExtended
                totalCases={totalCases}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
            />
        </Paper>
    );
}