import { SHOW_ALERT, HIDE_ALERT, CLEAR_ALERT, SESSION_WARNING_POPUP } from "../constants/ActionTypes";

const initialState = {
  alerts : [],
  sessionAlert: false,

}

const ACTION_HANDLERS = {
  [SHOW_ALERT]: (state, action) => {
    if(state.alerts.filter(e => {
      return e.name == action.payload.name
    }).length <=0) {

      let alertArr= [...state.alerts]
      if(action?.payload?.position == 0)
        alertArr.unshift(action.payload) //adding element top of screen
      else
       alertArr=[...state.alerts, action.payload]
      return {
        ...state,
        alerts : alertArr
      }
    } else {
      return state
    }
  },
  [HIDE_ALERT]: (state, action) => {
    return {
      ...state,
       alerts : state.alerts && state.alerts.filter(alert => {
        return alert.name != action.payload
      })
    }

  },
  [CLEAR_ALERT]: (state, action) => {
    return {
      ...state,
       alerts : []
    }

  },
  [SESSION_WARNING_POPUP]: (state, action) => {
    return {
      ...state,
       sessionAlert : action.payload
    }

  }
}
export default function sectionAlert(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}


export const getSessionAlertStatus = state => state.sectionAlert.sessionAlert;