import React, { useEffect } from 'react'
import { Paper, Card, Typography, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Cancelled from '../../res/images/cancelled.png'
import Complete from '../../res/images/completed.png'
import Incomplete from '../../res/images/incomplete.png'
import classNames from 'classnames'
import Alert from '../../res/images/pending_epic_update.png'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Closed from '../../res/images/closed.png'
import { connect, useSelector } from 'react-redux';
import { dashboardCaseData, startDate, endDate, getLoading, dashboardToggle, dashboardMonthCaseData } from '../../reducers/DashboardReducer';
import { getDashboardCases } from '../../actions/DashboardAction';
import * as CaseStatuses from '../../constants/CaseStatuses'
import {  DASHBOARD_DEFAULT_PAGE_NUMBER, DASHBOARD_DEFAULT_ITEM_PER_PAGE } from '../../constants/GlobalConstants';


const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '0.875rem'
    },
    statusSnapshot: {
        position: 'relative',
        margin: 'auto',
        width: 250,
        whiteSpace: 'nowrap',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    filterCardIcon: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        verticalAlign: 'middle',
    }
}))

function CaseStatusSnapshot({ dashboardCases, dashboardToggle, monthView, getDashboardCases, startDate, endDate }) {
    const classes = useStyles();

    var allCaseCounts = dashboardToggle ? monthView : dashboardCases

    var totalCase = allCaseCounts && allCaseCounts.TotalRecords ? allCaseCounts.TotalRecords : 0;
    var completeCase = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.Complete : 0;
    var incompleteCase = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.Incomplete : 0;
    var cancelledCase = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.Cancelled : 0;
    var alert = allCaseCounts && allCaseCounts.CaseStatusesCount != null && allCaseCounts.CaseStatusesCount.Alert ? allCaseCounts.CaseStatusesCount.Alert : 0;
    var loading = useSelector(getLoading)
    const headerList = [
        {
            'title': 'All Cases',
            'count': totalCase,
            'caseStatusId': CaseStatuses.ALL_CASES, 
        },
        {
            'title': 'Complete',
            'icon': Complete,
            'count': completeCase,
            'caseStatusId': CaseStatuses.COMPLETE, 
        },
        {
            'title': 'Incomplete',
            'icon': Incomplete,
            'count': incompleteCase,
            'caseStatusId': CaseStatuses.INCOMPLETE,
        },
        {
            'title': 'Cancelled',
            'icon': Cancelled,
            'count': cancelledCase,
            'caseStatusId': CaseStatuses.CANCELLED,
        },
        {
            'title': 'Alert',
            'icon': Alert,
            'count': alert,
            'caseStatusId': CaseStatuses.ALERT,
        }
    ]

    return (
        <Paper elevation={0} square className={classes.root}>
            <div className={classes.statusSnapshot}>
                {headerList.map((filterTiles) => (
                    <React.Fragment>
                        {loading ? <LinearProgress /> : null}
                        <div 
                            style={{ 
                                align: 'center',
                                margin: '12px',
                                verticalAlign: 'middle',
                                display: 'flex'
                            }}
                        >
                            {(filterTiles.icon !== undefined) ?
                                <img src={filterTiles.icon} alt="" className={classes.filterCardIcon} />
                                : <div className={classes.filterCardIcon} />
                            }
                            <Typography
                                style={{
                                    marginLeft: '8px',
                                    display: 'inline-block',
                                    color: 'black'
                                }}
                            >{filterTiles.title} - {filterTiles.count}</Typography>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </Paper >
    )
}


const mapStateToProps = state => ({
    dashboardCases: dashboardCaseData(state),
    dashboardToggle: dashboardToggle(state),
    monthView: dashboardMonthCaseData(state),
    startDate: startDate(state),
    endDate: endDate(state)
});
export default connect(mapStateToProps, { getDashboardCases })(CaseStatusSnapshot);
