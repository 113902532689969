import React from 'react'
import { Button as MuiButton, CircularProgress, Link, Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useSelector } from 'react-redux';
import { getPopupLoader } from '../../reducers/CaseDetailsReducer';


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none',
    }
}))

const DocLink = withStyles({
    root: {
      "&[disabled]": {
        color: "grey",
        cursor: "default",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none"
        }
      }
    }
  })(Link);

export default function AnchorLink(props) {

    const { label, size, color, variant, onClick, index, type, disabled, sx, loader, fieldId, value, startIcon = "", hide } = props
    const classes = useStyles();
    const popupLoaderStatus = useSelector(getPopupLoader);
    var isExecuteApi = false;
    const buttonListForShowingLoader = ["DocFinalRejectBtn", "DocFileUploadUploadBtn", "DeleteDocumentYesBtn", "VerifyBtn", "TrayPopupCloseBtn"];

    if (buttonListForShowingLoader.includes(props.fieldId)) {

        if (props.additionalProperties.OnClickClosePopup != undefined && props.additionalProperties.OnClickClosePopup.length > 0) {
            isExecuteApi = props.additionalProperties.OnClickClosePopup[0].OnClickExecuteServiceAPI ? true : false;

        }
    }



    return (
        <>
        {!hide && (
            <Box display={'inline'}>
            <DocLink
                component="button"
                onClick={onClick}
                disabled={disabled}
            >
                <Typography variant="body1" fontWeight={"400"}>
                    {label}
                </Typography>
            </DocLink>
        </Box>
        )}
        </>
    )
}
