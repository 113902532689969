import { XCONTROL_SECTION_ACTION } from "../constants/ActionTypes"

export function xControlSectionAction(replacedSection) {
    return {
        type: XCONTROL_SECTION_ACTION,
        payload: replacedSection,
    }
}
const modifyControl = (xPath, controlFieldId, modifiableData, rowIndex) => {
    var path = []
    var controlMappingId = ''
    if (xPath.includes('.')) {
        path = xPath.split('.')
        controlMappingId = path[0]
    } else {
        controlMappingId = xPath
    }
    return async function (dispatch, getState) {
        const state = getState()
        var sectionDetails = state.caseDetails.sectionDetails
        if (sectionDetails) {
            var replacedControl = sectionDetails;
            replacedControl.sectionGroup.forEach(x => x.fields.filter(element => element.mappingId == controlMappingId)
                .forEach(element => {
                    var control = element
                    if (path.length <= 0) {
                        control[controlFieldId] = modifiableData
                    } else {
                        var counter = 0
                        const updateChildControl = (obj) => {
                            // console.log('obj', obj)
                            if (obj)                                
                            counter++
                            if (obj) {
                                if (counter < path.length) {
                                    var tmp
                                    if (obj.fieldTypeName == 'Table') {
                                        tmp = obj.childControls.length > rowIndex && obj.childControls[rowIndex].find(o => {
                                            return o.mappingId == path[counter]
                                        })
                                    } else {
                                        tmp = obj.childControls.find(o => {
                                            return o.mappingId == path[counter]
                                        })
                                    }

                                    updateChildControl(tmp)
                                    // console.log('tem', tmp)

                                } else {
                                    obj[controlFieldId] = modifiableData
                                }
                            }
                        }
                        updateChildControl(control)
                    }
                }));
            dispatch(xControlSectionAction(replacedControl))
        }
    }

}

const XControlUtils = {
    modifyControl
}

export default XControlUtils