import { store } from '../../../store'
import XControlUtils from '../../X-ControlUtils'

export const execute = (control) => {
        if (!control.value || control.value.trim() == '') {
            const rule = control.additionalProperties.HideTargetFieldIfFieldDoesNotHaveAnyValue
            const tableRow = control.mappingId.replace(/^\D+/g, "")
            for (var targetField of rule.TargetFieldId) {
                var t = targetField.replaceAll("[n]", tableRow)
                store.dispatch(XControlUtils.modifyControl(t, 'isVisible', false, parseInt(tableRow)))
            }
        }
}

const HideTargetFieldIfFieldDoesNotHaveAnyValue = {
    execute
}

export default HideTargetFieldIfFieldDoesNotHaveAnyValue