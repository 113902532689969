import request from "../providers/RestServiceProvider";
import {
    GET_ALLTENANTS_URL,
    GET_DATA_UPLOAD_TYPES_URL,
    DATA_UPLOAD_URL,
    REPORT_DOWNLOAD_URL,
    GET_PROCEDURE_LOCATION_URL
} from '../constants/Locations';
import { ERR_SOMETHING_WENT_WRONG } from '../constants/GlobalConstants'



const downloadFileFromResponse = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const getDataReport = async (dataTypeID, OrgIds) => {
    // Updated the URL by creating Admin base URL
    return await request({
        url: REPORT_DOWNLOAD_URL + dataTypeID + "?surgicalLocationIds=" + OrgIds,
        method: "GET",
    }).then((response) => {
        if (response.data) {
            var fileName = JSON.parse(response.headers['content-disposition'].split('filename=')[1].split(';')[0].trim());
            var filetype = response.headers['content-type'];
            fileName && filetype && downloadFileFromResponse({
                data: response.data,
                fileName: fileName,
                fileType: filetype,
            })
            return {
                status: "Success",
                statusCode: response.status,
            };
        } else {
            return {
                status: "Failed",
                statusCode: response.status,
            };
        }
    }).catch(error => console.log(error));
};

export const getDataTypes = async () => {
    return await request({
        url: GET_DATA_UPLOAD_TYPES_URL,
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    });
};

export const getOrganizationList = async () => {
    return await request({
        url: GET_PROCEDURE_LOCATION_URL,
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    });
};

export const getOrgTenants = async () => {
    return await request({
        url: GET_ALLTENANTS_URL,
        method: 'GET',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    selections: response.payload
                }
            }
        }
    })
}

export const getAdminDataUploadTypes = async () => {
    return await request({
        url: GET_DATA_UPLOAD_TYPES_URL,
        method: 'GET',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    selections: response.payload
                }
            }
        }
    })
}

export const uploadDryRunData = async (fileData, fileUploadTypeId, tenantSelectedValue) => {
    return await request(
        {
            url: DATA_UPLOAD_URL + fileUploadTypeId,
            data: fileData,
            method: 'POST',
        },
        {
            "TenantKey": tenantSelectedValue
        })
        .then((response) => {
            if (response.payload) {
                return {
                    responseData: response.payload
                }
            }
            else
                return {
                    error: ERR_SOMETHING_WENT_WRONG
                };

        })
        .catch(err => {
            if (err && err?.data?.payload) {
                return {
                    error: err?.data?.payload?.message
                };
            }
            else if (err?.status == 500) {
                return {
                    error: ERR_SOMETHING_WENT_WRONG
                };
            }
            console.error(err)
        });
}

export const uploadMainRunData = async (fileGuid, tenantSelectedValue) => {
    return await request(
        {
            url: DATA_UPLOAD_URL + `${fileGuid}/FinalUpload`,
            method: 'PUT',
        },
        {
            "TenantKey": tenantSelectedValue
        })
        .then((response) => {

            if (response.payload) {
                return {
                    responseData: response.payload
                }
            }
            else
                return {
                    error: ERR_SOMETHING_WENT_WRONG
                };

        })
        .catch(err => {
            if (err && err?.data?.payload) {
                return {
                    error: err?.data?.payload?.message
                };
            }
            else if (err?.status == 500) {
                return {
                    error: ERR_SOMETHING_WENT_WRONG
                };
            }
            console.error(err)
        });
}


const AdminService = {
    getDataReport,
    getDataTypes,
    getOrganizationList,
    getOrgTenants,
    getAdminDataUploadTypes,
    uploadDryRunData,
    uploadMainRunData
};

export default AdminService;