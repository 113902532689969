import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import UnautorizedIcon from "../../res/images/img_unauthorized.png";

const UserAuthWarningBox = (props) => {

    const { open, onConfirm, dialogTitle, dialogDescription } = props;

    const titleStyle = {
        color: 'red',
        fontSize: '20px',
        fontFamily: 'roboto'
    }

    const divisionStyles = {
        display: 'inline-block',
        padding: '5px',
        verticalAlign: 'middle'
    };

    const descriptionStyle = {
        color: 'red',
        fontSize: '18px',
        fontFamily: 'roboto'
    };

    const buttonStyle = {
        color: 'white',
        margin: '15px',
        backgroundColor: '#3f51b5'
    }

    return (
        <>
            <Dialog open={open}>
                <DialogTitle style={titleStyle}>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={descriptionStyle} >
                        <div style={divisionStyles}>
                            <img src={UnautorizedIcon} height='30px' width='30px' />
                        </div>
                        <div style={divisionStyles}>
                            {dialogDescription}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-start' }}>
                    <Button onClick={onConfirm} style={buttonStyle} > OK </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UserAuthWarningBox
