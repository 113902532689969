import React from 'react'

export default function Div(props) {

    const { additionalProperties } = props
    var divColor = "";
    if (props.value != null) {
        if (props.value == "1") {
            divColor = additionalProperties.SetColorByFieldValue.filter(item => item.FieldValue == "1")[0].SetColor
        }
        else if (props.value == "0") {
            divColor = additionalProperties.SetColorByFieldValue.filter(item => item.FieldValue == "0")[0].SetColor
        }
    }
    else {
        divColor = "#ffffff";
    }

    return (
        <div style={{ width: "-webkit-fill-available", height: "300px", backgroundColor: divColor }} >
        </div>
    )
}
