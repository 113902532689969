import moment from "moment"
import { populateTarget } from "../../../actions/TypeaheadAction";
import { store } from "../../../store";
import { getFieldById } from "../../GetFieldById";

const execute = (control, AddOnLoadONChangeFlag = null) => {
    let rules = control.additionalProperties.OnClickGenerateAndAddValueToTargetFieldByCondition;

    if (AddOnLoadONChangeFlag) {
        if (rules) {
            rules.forEach(rule => {
                rule.Condition.map(item => {
                    let checkByFieldValue = item.DependentFieldValue;
                    let dependentFieldId = item.DependentFieldId;
                    let targetFieldId = rule.TargetFieldId;
                    let nestedKeys = dependentFieldId.split('.');

                    if (nestedKeys && nestedKeys.length > 1) {
                        let selectedProcedureData = getFieldById(nestedKeys[0]);                   

                        if (selectedProcedureData.value && checkByFieldValue === true) {
                            let selectedCPTCodes = selectedProcedureData.value.CPTCodeIds;

                            //Getting the SelectedCPTCodeIDs against the procedure
                            let completeCPTCodesList = store.getState().caseDetails.selection.CPTCodes;

                            //Add that to the CPTCode to the SelectedCPTCodeList control
                            const matchAndFilterSelectedCPTCodes = completeCPTCodesList.filter((obj) => {
                                return selectedCPTCodes.includes(obj.CPTCodeId);
                            })
                            sourceFieldTargetFieldValueMapping(matchAndFilterSelectedCPTCodes, targetFieldId);
                        }
                    }
                })
            })
        }
    }
}

const sourceFieldTargetFieldValueMapping = (matchedAndFilteredSelectedCPTCodes, targetFieldId) => {
    matchedAndFilteredSelectedCPTCodes.forEach(obj => {
        let dataToDispatch = {
            "CaseCPTCodeId": null,
            "SelectedCPTCodeAddedDateTime": moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
            "SelectedCPTCodeId": obj.CPTCodeId,
            "SelectedCPTCodeInPatientBl": obj.InPatientBl,
            "SelectedCPTCodeName": obj.CPTCodeDisplayValue,
            "SelectedCPTCodeNotAvailableAtHospitalBl": false,
            "SelectedCPTCodePrimaryBl": false,
            "SelectedIMOCPTCodeBl": true
        }
        store.dispatch(populateTarget(targetFieldId, dataToDispatch));
    })
}

const OnClickGenerateAndAddValueToTargetFieldByCondition = {
    execute
}

export default OnClickGenerateAndAddValueToTargetFieldByCondition