import VisibilityControlAction from "../../../actions/VisibilityControlAction"
import { store } from "../../../store"
import { getFieldValueById } from "../../GetFieldValueByID"

const execute = control => {
    let rules = control.additionalProperties.IsVisibleWhen
    rules.map(rule => {
        let dependentFieldValue = getFieldValueById(rule.DependendFieldId)
        if(dependentFieldValue == rule.DependentFieldValue) {
            store.dispatch(VisibilityControlAction.showField(control.mappingId))
        }
    })
}

const IsVisibleWhen = {
    execute
}

export default IsVisibleWhen