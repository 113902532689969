import { store } from '../../../store'
import { getFieldById } from '../../GetFieldById'
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';

export const execute = (control) => {
    const fieldProps = control.additionalProperties;
    var rule = fieldProps.DisableTargetFieldIfAdditionalFieldHasValue;

    if (rule && rule.length > 0) {
        rule.forEach(fieldsAction => {
            var inPopUp = fieldsAction.inpopup != undefined && fieldsAction.inpopup ? fieldsAction.inpopup : false;
            var permissionDisabled = fieldsAction.IfTrueFieldPermissionId;
            var permissionEnabled = fieldsAction.IfFalseFieldPermissionId;

            if (fieldsAction.AdditionalFields.every(item => {
                var nestedKeys = item.AdditionalFieldId.split('.')
                var value
                if (nestedKeys != null) {
                    value = getFieldById(nestedKeys[0])?.value
                }

                if (value) {
                    var additionalNestedFieldVal = value
                    for (var index = 1; index < nestedKeys.length; index++) {
                        if (additionalNestedFieldVal) {
                            additionalNestedFieldVal = additionalNestedFieldVal[nestedKeys[index]]
                        }
                    }
                    value = additionalNestedFieldVal
                    return value == item.AdditionalFieldValue
                } else {
                    return false
                }
            })) {
                fieldsAction.TargetFieldId && fieldsAction.TargetFieldId.forEach(fieldId => {
                    store.dispatch(updateFieldPermission(fieldId, permissionDisabled));
                })
            }
            else {
                fieldsAction.TargetFieldId && fieldsAction.TargetFieldId.forEach(fieldId => {
                    store.dispatch(updateFieldPermission(fieldId, permissionEnabled));
                })
            }
        })
    }
}

const DisableTargetFieldIfAdditionalFieldHasValue = {
    execute
}

export default DisableTargetFieldIfAdditionalFieldHasValue