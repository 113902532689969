import { store } from '../../../store';
import { updateFieldPermission,updateDisableStatus } from '../../../actions/CaseDetailsAction';
import { getFieldById } from '../../GetFieldById'
import {
    LBL_EQUAL, LBL_EQUAL_TO, LBL_GREATER_THAN, LBL_LESS_THAN, LBL_GREATER_THAN_EQUAL, LBL_LESS_THAN_EQUAL,
    COMMON_DATE_FORMAT, BLANK_VALUE
} from '../../../constants/GlobalConstants';
import moment from 'moment';

export const execute = (props) => {
    var fieldProps = props.additionalProperties
    var rule = fieldProps.DisableTargetFieldByComparisonWithDependentField
    
    if (rule && rule.DependentField && rule.ComparisonOperator) {
        var isNeedToDisableTargetButtons = false;

        var permissionDisabled = rule.IfTrueSetPermissionTypeId; //5
        var permissionEnabled = rule.IfFalseSetPermissionTypeId; //6
        var currentFieldValue = props.value;       //PreOP-date
        var dependentFieldObj = getFieldById(rule.DependentField); //HFProcedureDate       

        if (currentFieldValue && dependentFieldObj && dependentFieldObj.value) {
            var formatedCurrentFieldValue = moment(currentFieldValue).format(COMMON_DATE_FORMAT);
            var dependentFieldValue = moment(dependentFieldObj.value).format(COMMON_DATE_FORMAT);
            if ((rule.ComparisonOperator == LBL_EQUAL || rule.ComparisonOperator == LBL_EQUAL_TO)
                && moment(formatedCurrentFieldValue).isSame(dependentFieldValue) === true) {
                isNeedToDisableTargetButtons = true;

            } else if (rule.ComparisonOperator == LBL_GREATER_THAN
                && moment(formatedCurrentFieldValue).isAfter(dependentFieldValue) === true) {
                isNeedToDisableTargetButtons = true;

            }
            else if (rule.ComparisonOperator == LBL_GREATER_THAN_EQUAL
                && moment(formatedCurrentFieldValue).isSameOrAfter(dependentFieldValue) === true) {
                isNeedToDisableTargetButtons = true;

            }
            else if (rule.ComparisonOperator == LBL_LESS_THAN
                && moment(formatedCurrentFieldValue).isBefore(dependentFieldValue) === true) {
                isNeedToDisableTargetButtons = true;
            }
            else if (rule.ComparisonOperator == LBL_LESS_THAN_EQUAL
                && moment(formatedCurrentFieldValue).isSameOrBefore(dependentFieldValue) === true) {
                isNeedToDisableTargetButtons = true;
            }
            //action buttons does not come from booking sheet so need to handle directly
            var actionsList = [];
            if (isNeedToDisableTargetButtons) {

                rule.TargetFieldId && rule.TargetFieldId.forEach(fieldMappingId => {
                    // store.dispatch(updateFieldPermission(fieldId, permissionDisabled));                  
                    var item = {
                        Id: fieldMappingId,
                        status: true
                    }
                    actionsList.push(item);
                })
                store.dispatch(updateDisableStatus(actionsList));
            } else {
                rule.TargetFieldId && rule.TargetFieldId.forEach(fieldMappingId => {
                    // store.dispatch(updateFieldPermission(fieldId, permissionEnabled));
                    var item = {
                        Id: fieldMappingId,
                        status: false
                    }
                    actionsList.push(item);
                });
                store.dispatch(updateDisableStatus(actionsList));
            }
        }

    }

}

const DisableTargetFieldByComparisonWithDependentField = {
    execute
}
export default DisableTargetFieldByComparisonWithDependentField