import * as Constants from '../../../constants/GlobalConstants'
import { calculateValue } from '../../CalculateValue'
import { getFieldValueById } from '../../GetFieldValueByID'
import VisibilityControlAction from '../../../actions/VisibilityControlAction'
import { store } from '../../../store'

const execute = control => {
    const additionalProperties = control.additionalProperties
    let rules = additionalProperties.ShowTargetFieldWhenFieldValueAssignedToDependentField
    if (rules) {
        rules.map(rule => {
            if (rule.DependentFieldValue.some(x => {
                let fieldValue
                if (control.fieldTypeName === Constants.SELECTION || control.fieldTypeName === Constants.SELECTION_KEY_VALUE || control.fieldTypeName === Constants.TYPEAHEAD) {
                    fieldValue = control.value ? control.value[additionalProperties.DisplayColumnId] : null
                } else {
                    fieldValue = control.value
                }

                return fieldValue == x
            })) {
                let value = getFieldValueById(rule.DependentFieldId)
                if (value) {
                    var isPresent = value.some(val => {
                        return rule.DependentFieldValue.some(dependentVal => {
                            return calculateValue(rule.DependentFieldChildId ? val[rule.DependentFieldChildId] : val, dependentVal, rule.DependentFieldValueOperator)
                        })
                    })
                    if (isPresent) {
                        rule.TargetFieldId.map(fieldToVisualize => {
                            store.dispatch(VisibilityControlAction.showField(fieldToVisualize))
                        })
                    }
                }
            }
        })
    }
}

const ShowTargetFieldWhenFieldValueAssignedToDependentField = {
    execute
}

export default ShowTargetFieldWhenFieldValueAssignedToDependentField