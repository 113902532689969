
export const selectText  = (node) => {
    var node = document.getElementById(node);
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      console.warn("Could not select text in node: Unsupported browser.");
    }
}

export const copyTextToClipBoard=(fieldId)=>{
    selectText(fieldId);
    document.execCommand("copy");
    document.getSelection().removeAllRanges()
  }