import {
  DEPENDENT_FIELD_INPUT_LENGTH_GREATER_THAN_PROVIDED_VALUE_BY_CONDITION
} from '../../../constants/GlobalConstants';
import { getFieldById } from '../../GetFieldById';
import { calculateValue } from '../../CalculateValue';
import { store } from '../../../store';
import { updateIsRequiredValidation } from "../../../actions/CaseDetailsAction";

export const dependentFieldInputLengthGreaterThanProvidedValueByCondition = (control) => {
  var response = null;
  var fieldValue = control.value ? control.value : null;
  if (!fieldValue && control && control.validation) {
    var validation = control.validation
    var checkDependentFields = validation[DEPENDENT_FIELD_INPUT_LENGTH_GREATER_THAN_PROVIDED_VALUE_BY_CONDITION] ? validation[DEPENDENT_FIELD_INPUT_LENGTH_GREATER_THAN_PROVIDED_VALUE_BY_CONDITION] : null;
    if (checkDependentFields != null) {
      var ischeckCondition = false;
      checkDependentFields.forEach(checkDependent => {
        if (ischeckCondition) { return true; }
        var povidedValue = checkDependent.InputLengthCondition.ProvidedValue ? checkDependent.InputLengthCondition.ProvidedValue : null;
        var dependentFieldId = checkDependent.InputLengthCondition.DependentFieldId ? checkDependent.InputLengthCondition.DependentFieldId : null;
        var fieldValueOperator = checkDependent.InputLengthCondition.DependentFieldValueOperator ? checkDependent.InputLengthCondition.DependentFieldValueOperator : null;
        var checkFieldValue = getFieldById(dependentFieldId);
        var fieldValue = checkFieldValue.value != undefined ? checkFieldValue.value.length : 0;
        var checkResult = calculateValue(fieldValue, povidedValue, fieldValueOperator);
        if (checkResult) {
          ischeckCondition = true;
        } else {
          ischeckCondition = false;
          return true;
        }
      });
      if (ischeckCondition) {
        store.dispatch(updateIsRequiredValidation(control.mappingId, false));
      } else {
        store.dispatch(updateIsRequiredValidation(control.mappingId, true));
      }
    }
  }
  return response;
}