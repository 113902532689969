import { replaceTarget } from "../../../actions/TypeaheadAction";
import { store } from "../../../store";
import { getFieldByMappingId } from "../../GetFieldByMappingId";

const execute = (control, AddOnLoadONChangeFlag = null) => {
    let rules = control.additionalProperties.DeleteFromTargetFieldByCondition

    if (AddOnLoadONChangeFlag) {
        if (rules) {
            const tableRowIndex = control.mappingId.replace(/^\D+/g, "");
            rules.forEach(rule => {
                rule.Condition.map(item => {
                    let fieldId = item.DependentFieldId;
                    let dependentFieldId;
                    if (tableRowIndex) {
                        dependentFieldId = fieldId.replaceAll("[n]", tableRowIndex);
                    }
                    if (dependentFieldId.includes('.')) {
                        var path = [];
                        path = dependentFieldId.split('.');

                        var getFieldValue = getFieldByMappingId(path[1], path[0]);
                        if (getFieldValue && getFieldValue?.value != null) {
                            rule.DeleteFromTargetFieldByCondition.map(item => {
                                let targetField = item.TargetField;
                                let fieldToCompareInTargetField = item.FieldToCompareInTargetField;
                                let deleteRowCountForEachInput = item.DeleteRowCountForEachInput;
                                var getTargetFieldValueForTargetField = getFieldByMappingId(targetField);
                                if (getTargetFieldValueForTargetField && getTargetFieldValueForTargetField.value != null) {
                                    item.ConditionToDelete.map(obj => {
                                        let conditionDependentFieldId = obj.DependentFieldId;
                                        if (conditionDependentFieldId.includes('.')) {
                                            var path = [];
                                            path = conditionDependentFieldId.split('.');
                                            const result = removeSubsequentDuplicates(getTargetFieldValueForTargetField.value, fieldToCompareInTargetField, path[1], getFieldValue?.value)
                                            store.dispatch(replaceTarget(targetField, result))
                                        }
                                    })
                                }
                            })
                        }
                    }
                })
            })
        }
    }
}

function removeSubsequentDuplicates(array, fieldToCompareInTargetField, path, valuesToRemove) {

    //Set to keep track of encountered values
    let foundIds = new Set();

    let remainingItems = [];

    for (let item of array) {
        if (valuesToRemove.includes(item[fieldToCompareInTargetField]) && item[path] === true && !foundIds.has(item[fieldToCompareInTargetField])) {
            foundIds.add(item[fieldToCompareInTargetField]);
        } else {
            remainingItems.push(item);
        }
    }

    return remainingItems;
}

const DeleteFromTargetFieldByCondition = {
    execute
}

export default DeleteFromTargetFieldByCondition