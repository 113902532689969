import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DataReport from './DataReport';
import DataUpload from './DataUpload';
import UserAuthWarningBox from '../case/UserAuthWarningBox';
import { AUTHORIZATION_ERROR_MESSAGE_DATAUPLOAD, LBL_DATA_UPLOAD, LBL_DATA_REPORT } from '../../constants/GlobalConstants'

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(12),
        background: '#fff',
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(14)})`,
        paddingRight: '10px'
    },

}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`admin-data-tabpanel-${index}`}
            aria-labelledby={`admin-data-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function setTabId(index) {
    return {
        id: `admin-data-tab-${index}`,
        'aria-controls': `admin-data-tabpanel-${index}`,
    };
}

const DataManagementTabs = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    //get permissions from store
    const permissions = props.dashboardFilterData;
    const [dataManagementUserAction, setDataManagementUserAction] = useState(true);


    useEffect(() => {
        const useraction = permissions?.userActions;
        useraction && useraction.forEach((element) => {
            if (element.userActionName == "Perform Admin Task") {
                setDataManagementUserAction(element.allowedActionBl);
            }
        })        
    }, [permissions]);


    const onTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const redirectToDashboard = () => {
        props.history.push('/dashboard');
    }

    const UserAuthWarningPopup = (
        <UserAuthWarningBox
            open={!dataManagementUserAction}
            dialogTitle="Unauthorized!"
            dialogDescription={AUTHORIZATION_ERROR_MESSAGE_DATAUPLOAD}
            onConfirm={redirectToDashboard}
        />
    )


    return (
        <div className={classes.container}>
            {UserAuthWarningPopup}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={onTabChange}
                        aria-label="Data Upload & Data Report"
                    >
                        <Tab label={LBL_DATA_UPLOAD} {...setTabId(0)} />
                        <Tab label={LBL_DATA_REPORT} {...setTabId(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <DataUpload />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <DataReport />
                </TabPanel>
            </Box>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        dashboardFilterData: state.dashboard.dashboardFiltersData
    }
}

export default connect(mapStateToProps)(DataManagementTabs)
