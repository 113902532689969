import { Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import ReportsRightSection from './ReportsRightSection';
import ReportsLeftNav from './ReportsLeftNav';
import { useSelector } from 'react-redux';
import { getSurgicalLocationSelections } from '../../reducers/DashboardReducer';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(12),
        background: '#fff',
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(15)})`,
    }
}))

const Reports = () => {
    const classes = useStyles();
    const surgicalLocation = useSelector(getSurgicalLocationSelections);
    var isShowPreSurgicalTestingatHospital = false;
    var isShowPreAdmissionTestingAtHospitalTab = false;
    if (surgicalLocation != null) {
        const preSurgicalTabAvailableHospitalList = JSON.parse(process.env.REACT_APP_WQ_PresurgicalTestingAtHospital_Org);
        const preAdmissionTabAvailableHospitalList = JSON.parse(process.env.REACT_APP_WQ_PreadmissionTestingAtHospital_Org);
        let surgicalLocationList = surgicalLocation.selections;
        let preSurgicalFilterObj = surgicalLocationList && surgicalLocationList.find(item => preSurgicalTabAvailableHospitalList.includes(item.surgicalLocationId));
        let preAdmissionFilterObj = surgicalLocationList && surgicalLocationList.find(item => preAdmissionTabAvailableHospitalList.includes(item.surgicalLocationId));
        isShowPreSurgicalTestingatHospital = preSurgicalFilterObj && Object.keys(preSurgicalFilterObj).length > 0 ? true : false;
        isShowPreAdmissionTestingAtHospitalTab = preAdmissionFilterObj && Object.keys(preAdmissionFilterObj).length > 0 ? true : false;
    }

    return (
        <Box className={classes.root}>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} padding='0 !important'>
                    {surgicalLocation != null && <ReportsLeftNav
                        isShowPreSurgicalTestingatHospital={isShowPreSurgicalTestingatHospital}
                        isShowPreAdmissionTestingAtHospitalTab={isShowPreAdmissionTestingAtHospitalTab}
                    />}
                </Grid>
                <Grid item xs={12}>
                    <ReportsRightSection />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Reports
