//COMMON
export const BORDER_WIDTH='1px'
export const BORDER_RADIUS='25px'
export const TEXT_SIZE_REGULAR='0.7rem'
export const TEXT_SIZE_LARGE='1rem'
export const TEXT_SIZE_SMALL='0.6rem'
export const BULLET_DIMENSION = '18px'

//SELECT TENANT
export const TENANT_CARD_MARGIN = '16px'
export const TENANT_CARD_PADDING = '8px'
export const TENANT_CARD_ICON = '80px'
export const TENANT_CARD_ICON_PADDING = '8px'
export const TENANT_CARD_TITLE_FONT_SIZE = '32px'
export const TENANT_CARD_TITLE_MARGIN = '16px'
export const TENANT_CARD_TEXT_MARGIN = '8px'
export const TENANT_CONTAINER_GAP = '30px'

//LOGIN
export const LOGIN_HEADLINE_LETTER_SPACING = '5px'
export const LOGIN_MARGIN_TOP = '16px'
export const LOGIN_BUTTON_PADDING_VERTICAL = '5px'
export const LOGIN_BUTTON_PADDING_HORIZONTAL = '64px'
export const LOGIN_CARD_PADDING_HORIZONTAL = '16px'
export const LOGIN_CARD_PADDING_VERTICAL = '8px'

//Home
export const CONTENT_PADDING_LEFT = "88px"
export const CONTENT_PADDING_TOP = "72px"

//Case Navigation
export const CASE_NAVIGATION_PAPER_PADDING = '16px'
export const CASE_NAVIGATION_BULLET_MARGIN = '16px'
export const CASE_NAVIGATION_ITEM_PADDING = '8px'

//Work Queue
export const OPTION_BTN_WIDTH = '340px'
export const HEADLINE_MARGIN_BOTTOM = '15px'
export const POPPER_ZINDEX = 3
export const POPPER_WIDTH = '380px'
export const LBL_BADGE_PADDING = '4px 0'
