import React, { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from '../utils/Auth';
import LocalStorageProvider from '../providers/LocalStorageProvider';
import { getDashboardFilters, getDashboardPrimarySurgeons, getDashboardSurgicalLocations } from '../actions/DashboardAction'
import { store } from '../store'
import SessionTimeout from './SessionTimeout';
import { DASHBOARD_FILTER_CONTROLS, PATIENT_INFORMATION_PAGE_URL, REP_CONFIRMATION, RETURN_URL } from '../constants/GlobalConstants';
import { getSearchFiltersReferenceData } from '../actions/ReportsAction';
import { isDashboardBl } from '../reducers/DashboardReducer';


function Authentication(props) {
  let location = useLocation()
  const stateData = store.getState();
  const dispatch = useDispatch();
  const dahsboardSearchBl = useSelector(isDashboardBl);
  const isDashboard = ['/dashboard', '/'].includes(location.pathname);

  if (isLoggedIn()) {

    //call session timeout component
    SessionTimeout.sessionTimeout();
    !dahsboardSearchBl && isDashboard && dispatch(getSearchFiltersReferenceData(DASHBOARD_FILTER_CONTROLS));

    //Call mandatory data if store is null
    if (stateData.dashboard.dashboardFiltersData == null) {
      dispatch(getDashboardFilters());
    }
    if (stateData.dashboard.primarySurgeonList == null) {
      dispatch(getDashboardPrimarySurgeons());
    }
    if (stateData.dashboard.surgicalLocationList == null) {
      dispatch(getDashboardSurgicalLocations());
    }
    return props.children

  } else {
    let searchText = location.search ? location.search.toUpperCase() : null;
    if (location && location.pathname && location.pathname.indexOf(REP_CONFIRMATION) != -1) {
      LocalStorageProvider.setReturnURL(location.pathname + location.search);
    } else if (location && location.search && searchText.indexOf(RETURN_URL) != -1) {
      LocalStorageProvider.setReturnURL(location.pathname + PATIENT_INFORMATION_PAGE_URL + location.search);
      return <Redirect to="/login" />
    } else {
      return <Redirect to="/login" />
    }
  }
  return props.children

}

export default (Authentication)
