import { store } from '../../../store';
import { getFieldByMappingId } from '../../GetFieldByMappingId';
import VisibilityControlAction from '../../../actions/VisibilityControlAction';

export const execute = (control) => {
  const tableRowIndex = control.mappingId.replace(/^\D+/g, "");
  const rule = control.additionalProperties?.ShowTargetFieldIfFieldHasValueInTable;
  for (let i = 0; i < rule.length; i++) {
    let item = rule[i];
    let fieldId = item.SourceFieldId;
    let tragetFiledValue = item.FieldValue;
    var fieldValue = null;
    var dependentFieldId = fieldId.replaceAll("[n]", tableRowIndex);
    if (dependentFieldId.includes('.')) {
      let path = [];
      path = dependentFieldId.split('.');
      var controlItem = getFieldByMappingId(path[1], path[0]);
      if (controlItem.value) {
        if (typeof (controlItem.value) === 'object' && controlItem.value[controlItem.additionalProperties.DisplayColumnId]) {
          fieldValue = controlItem.value[controlItem.additionalProperties.DisplayColumnId];
        }
        else {
          fieldValue = controlItem.value;
        }
      }
    }
    if (fieldValue == tragetFiledValue) {
      item.TargetFieldId.length > 0 && item.TargetFieldId.forEach(element => {
        let targetField = element.replaceAll("[n]", tableRowIndex);
        let path = [];
        path = targetField.split('.');
        store.dispatch(VisibilityControlAction.showFieldInTayTable(path[1], true, path[0]));
      });
    }
  }
}
const ShowTargetFieldIfFieldHasValueInTable = {
  execute
}
export default ShowTargetFieldIfFieldHasValueInTable;