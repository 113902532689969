import { SELECTION, SELECTION_KEY_VALUE, TYPEAHEAD } from '../constants/GlobalConstants';
import { store } from '../store';


export const getFieldValueById = (FieldID) => {
    const state = store.getState();
    var bookingSheetControl = state.caseDetails.bookingSheetDetails;
    var fieldValue;
    if (bookingSheetControl) {
        bookingSheetControl.forEach(section => {
            section.sectionGroup.forEach(group => {
                group.fields.forEach(control => {
                    if (control.mappingId === FieldID) {
                        if (control.fieldTypeName === SELECTION || control.fieldTypeName === SELECTION_KEY_VALUE || control.fieldTypeName === TYPEAHEAD) {
                            if (control.value) {
                                if (typeof control.value == 'object') {
                                    fieldValue = control.value[control.additionalProperties.DisplayColumnId]
                                } else {
                                    fieldValue = control.value
                                }
                            }
                        } else {
                            fieldValue = control.value
                        }
                    }
                })
            })
        })
    }
    if ((fieldValue == null || fieldValue == undefined) && state.caseDetails.sectionDetails.sectionGroup.length > 0) 
     {
        state.caseDetails.sectionDetails.sectionGroup.forEach(group => {
            group.fields.forEach(control => {
                if (control.mappingId === FieldID) {
                    if (control.fieldTypeName === SELECTION || control.fieldTypeName === SELECTION_KEY_VALUE || control.fieldTypeName === TYPEAHEAD) {
                        if (control.value) {
                            if (typeof control.value == 'object') {
                                fieldValue = control.value[control.additionalProperties.DisplayColumnId]
                            } else {
                                fieldValue = control.value
                            }
                        }
                    } else {
                        fieldValue = control.value
                    }
                }
            })
        })
    }

    if ((fieldValue == null || fieldValue == undefined) && state.caseDetails.popupSectionDetails.length > 0) {
        state.caseDetails.popupSectionDetails.forEach(section =>
            section.sectionGroup.forEach(group => {
                group.fields.forEach(control => {
                    if (control.mappingId === FieldID) {
                        if (control.fieldTypeName === SELECTION || control.fieldTypeName === SELECTION_KEY_VALUE || control.fieldTypeName === TYPEAHEAD) {
                            if (control.value) {
                                if (typeof control.value == 'object') {
                                    fieldValue = control.value[control.additionalProperties.DisplayColumnId]
                                } else {
                                    fieldValue = control.value
                                }
                            }
                        } else {
                            fieldValue = control.value
                        }
                    }
                })
            })
        )
    }
    return fieldValue;
}