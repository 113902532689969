import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const MultiSelect = (props) => {
  const { fieldId, label, error = false, value, onSelect, disabled } = props;
  var { selections = [] } = props;
  if (!selections) {
    selections = [];
  }
  const additionalProperties = props.additionalProperties ? props.additionalProperties : null;
  const referenceDataKey = additionalProperties?.LoadReferenceData;
  const requiredBl = props.isRequired;
  var fieldValue = value ? Array.isArray(value) ? value : JSON.parse(value) : [];
  const defaultValue = [];
  for (let index = 0; index < fieldValue.length; index++) {
    let displayColumnId = fieldValue[index][additionalProperties.DisplayColumnId];
    let itemIndex = selections && selections.findIndex((item) => item[additionalProperties.DisplayColumnId] == displayColumnId);
    defaultValue.push(selections[itemIndex]);
  }
  var hasValue = fieldValue && fieldValue.length > 0 ? fieldValue : false;
  return (
    selections && <Autocomplete
      multiple
      disabled={disabled}
      id={fieldId}
      options={selections}
      getOptionLabel={(options) => options[additionalProperties?.DisplayColumnName]}
      defaultValue={defaultValue}
      filterSelectedOptions
      onChange={onSelect}
      renderInput={(params) => <TextField {...params} variant="outlined" value={value} label={label} error={(requiredBl && !hasValue)} />}
    />
  );
}

export default MultiSelect;