import { store } from '../../../store'
import { getFieldValueById } from '../../GetFieldValueByID';
import OnClickAddValueToTargetFieldByCondition from './OnClickAddValueToTargetFieldByCondition';
import OnClickAddValueToTargetField from './OnClickAddValueToTargetField';
import { updateControlValue, addFields, updateIsRequiredValidation } from '../../../actions/CaseDetailsAction'
import * as Constants from "../../../constants/GlobalConstants";
import RuleFactory from './RuleFactory';

export const execute = (props, isOnLoad) => {
    const fieldProps = props.additionalProperties
    let fieldMappingId = props.mappingId;
    let parentFieldId = props.parentFieldId;
    if (fieldProps, isOnLoad) {
        var matches = fieldMappingId.match(/(\d+)/);
        currentIndex = '';
        if (matches) {
            var currentIndex = matches[0];
        }
        if (fieldProps.OnAddExecuteRules.length > 0) {
            fieldProps.OnAddExecuteRules.sort((a, b) => {
                a.SequenceNo - b.SequenceNo
            }).forEach(configFields => {
                var keys = Object.keys(configFields)
                var values = Object.values(configFields)
                var controlCpy = {
                    ...props,
                    additionalProperties: configFields,
                }
                for (var item = 0; item < keys.length; item++) {
                    var rule = RuleFactory.getApplicableRule(keys[item])
                    if (rule) {
                        rule.execute(controlCpy, isOnLoad)
                    }
                    else if (keys[item] == Constants.DATA_TO_CLEAR) {
                        values[item].forEach(fieldId => {
                            var getFieldId = '';
                            if (currentIndex) {
                                getFieldId = fieldId + parseInt(currentIndex);
                            } else {
                                getFieldId = fieldId;
                            }
                            store.dispatch(updateControlValue(getFieldId, '', parentFieldId));
                        })
                    }
                    else if (keys[item] == Constants.MAKE_TARGET_FIELD_NOT_REQUIRED) {
                        values[item].forEach(fieldId => {
                            store.dispatch(updateIsRequiredValidation(fieldId, false))
                        });
                    }

                }
            })
        }
    }
}

const OnAddExecuteRules = {
    execute
}

export default OnAddExecuteRules