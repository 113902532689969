import { store } from '../../../store'
import {updateControlValue} from '../../../actions/CaseDetailsAction'
import {SELECTION_KEY_VALUE} from '../../../constants/GlobalConstants';

export const execute = (props, AddOnLoadONChangeFlag = null) => {
    let fieldProps = props.additionalProperties;
    if (AddOnLoadONChangeFlag) {
        if (fieldProps?.OnChangeDataToClear?.length > 0) {
            fieldProps.OnChangeDataToClear.forEach(fieldId => {
                store.dispatch(updateControlValue(fieldId, null));
            })
        }
    }
}

const OnChangeDataToClear = {
    execute
}

export default OnChangeDataToClear