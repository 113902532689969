import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterFiledValue, getFilterReferenceData } from '../../reducers/ReportsReducer';
import { Grid, styled, Box } from '@mui/material';
import Button from '@mui/material/Button';
import { setSearchFiltersData } from '../../actions/ReportsAction';
import {
    SEARCH_FILTER_CONTROL_LIST
} from '../../constants/GlobalConstants';
import * as R from 'ramda';

const useStyles = makeStyles(theme => ({
    button: {
        textTransform: 'capitalize',
        height: 40,
        boxShadow: 'none',
        borderRadius: 8,
    },
    buttonBorder: {
        border: '1px solid #1976d2',
        marginLeft: 8
    }
}))

const FiltersDisplay = (props) => {
    const dispatch = useDispatch();
    var searchFilterObject = useSelector(getFilterFiledValue);
    const searchFilterReferenceData = useSelector(getFilterReferenceData);
    const {displayFilters, filterMd, filterLg} = props;
    const searchFilterControls = SEARCH_FILTER_CONTROL_LIST;
    const selectionLimit = 10;

    const getSelections = (options, displayId) => {
        const filter = R.find((item) => item.Field === displayId, searchFilterObject);
        const selectedList = R.pathOr([], ["Values"], filter)

        let defaultValue = [];
        selectedList.forEach(displayColumnId => {
            let item = options.find((item) => item[displayId] == displayColumnId);
            item && defaultValue.push(item);
        })
        return defaultValue;
    }

    const updateSelections = (values, fieldID) => {
        const updatedFilters = R.clone(searchFilterObject);
        const selectedIds = values.map(elem => elem[fieldID].toString());

        const filterIndex = updatedFilters.findIndex((item) => item.Field == fieldID);
        if (filterIndex >= 0) {
            updatedFilters[filterIndex].Values = selectedIds;
        } else {
            updatedFilters.push({Field: fieldID, Values: selectedIds});
        }

        dispatch(setSearchFiltersData(updatedFilters));
    }

    return displayFilters.map((element, index) => {
        let filterControl = searchFilterControls.find(item => item.Field == element.Field);
        const displayLabel = element.displayLabel ? element.displayLabel : filterControl.displayLabel;

        if (!filterControl) return <></>;

        const displayId = filterControl.displayId;
        const options = R.pathOr([], [filterControl.Field], searchFilterReferenceData);
        const selections = filterControl ? getSelections(options, displayId) : [];

        return (
            <Grid item md={filterMd} lg={filterLg} xs={12} id={index}>
                {options.length > 0 &&
                    (<Autocomplete
                        size="small"
                        multiple
                        filterSelectedOptions
                        limitTags={selectionLimit}
                        clearOnEscape={true}
                        id={filterControl.Field}
                        options={options}
                        getOptionLabel={(options) => options[filterControl.displayName] || ""}
                        value={selections}
                        onChange={(_, values) => updateSelections(values, displayId)}
                        renderInput={(params) => <TextField {...params} label={displayLabel} variant="outlined" />}
                    />)
                }
            </Grid>
        )
    })
}

const FilterControls = (props) => {
    const searchFilterReferenceData = useSelector(getFilterReferenceData);
    var { displayFilters, applyFilter, resetFilter, loading, applyLabel, resetLabel, filterMd, filterLg } = props;
    const classes = useStyles();
    const loaded = searchFilterReferenceData && displayFilters;
    if(!loaded) return <></>
    return (
        <>
            <Grid container spacing={1} paddingBottom={2}>
                <FiltersDisplay displayFilters={displayFilters} filterMd={filterMd} filterLg={filterLg}/>
                <Grid item>
                    <Box>
                        <Button disabled={loading} variant="contained" onClick={applyFilter} className={classes.button}>{applyLabel}</Button>
                        <Button disabled={loading} variant="outlined" onClick={resetFilter} className={[classes.button, classes.buttonBorder]} >{resetLabel}</Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
export default FilterControls;
