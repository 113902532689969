import VisibilityControlAction from "../../../actions/VisibilityControlAction"
import { store } from "../../../store"

const execute = control => {
    let isTrue = control.additionalProperties.VisibleWhenCaseStatusIsCancelled     
        var caseStatus=1; // TODO : need to get the case status from API
        if(isTrue && caseStatus!=caseStatus) {  // TODO: Need to update the condition
            store.dispatch(VisibilityControlAction.showField(control.mappingId))
        }  
}

const VisibleWhenCaseStatusIsCancelled = {
    execute
}

export default VisibleWhenCaseStatusIsCancelled