import { Button, Dialog, DialogActions, DialogContent, DialogContentText, CircularProgress } from '@mui/material'
import React from 'react'

const CaseDialogBox = (props) => {

    const { open, onConfirm, onCancel, dialogTitle, dialogDescription, confirmDialog, loading } = props;
    return (
        <>
            <Dialog
                open={open}
                onClose={onCancel}>
                <DialogContent>
                    <DialogContentText style={{ color: 'black', fontSize:'18px', fontFamily: 'roboto' }}>
                        {dialogDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-start' }}>
                    <Button disabled={loading} onClick={onConfirm} style={{ color: 'white', margin: '15px', backgroundColor: '#3f51b5' }}>{loading ? <CircularProgress size={24}/> : (confirmDialog || "OK")}</Button>
                   {onCancel ? <Button onClick={onCancel} style={{ color: 'white', margin: '15px', backgroundColor: '#3f51b5' }}>CANCEL</Button>:null}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CaseDialogBox
