import { updateFieldPermission } from "../../../actions/CaseDetailsAction";
import { getSelectionsForControl } from '../../../reducers/CaseDetailsReducer';
import { store } from "../../../store";
import { SELECT_FILE } from "../../../constants/GlobalConstants";

const execute = control => {
    const rule = control.additionalProperties.DisableTargetFieldIfInvalidValue
    const stateData = store.getState();
    if (rule && control && control.value && control.value != '' && control.fieldTypeName == SELECT_FILE) {
        var fileObject = control.value;
        var referenceDataId = control.additionalProperties.LoadReferenceData;       
        var documentSupportedFileTypes = getSelectionsForControl(stateData, referenceDataId);

        if (!ValidateFile(fileObject, documentSupportedFileTypes)) {
            rule.TargetFieldId.map(item => {
                store.dispatch(updateFieldPermission(item, rule.IfTrueSetPermissionTypeId));
            })
        } else {          
            rule.TargetFieldId.map(item => {
                store.dispatch(updateFieldPermission(item, rule.IfFalseSetPermissionTypeId));
            })
        }
    }
}

function ValidateFile(fileObject, documentSupportedFileTypes) {
    var isValidFile = false;
    if (fileObject) {
        var fileName = fileObject.name;
        if (fileName.length > 0) {
            for (var index = 0; index < documentSupportedFileTypes.length; index++) {
                var sCurExtension = documentSupportedFileTypes[index].DocumentSupportedFileTypeName;
                if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    isValidFile = true;
                    break;
                }
            }
        }
    }

    return isValidFile;
}

const DisableTargetFieldIfInvalidValue = {
    execute
}

export default DisableTargetFieldIfInvalidValue
