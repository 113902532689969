import { store } from '../../../store';
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';

export const execute = (control) => {
    let fieldMappingId = control.mappingId;
    let rules = control.additionalProperties.DisableDeleteDueToDependentFieldValue
    let value = control.value
    let permissionDisabled = rules.IfTrueSetPermissionTypeId;

    //If SelectedIMOCPTCodeBl is false , then defaultPermissionTypeId is being set
    let defaultPermission = control.defaultPermissionTypeId

    if (value && value.length > 0) {
        rules.Condition.forEach(element => {
            let dependentFieldId = element.DependentFieldId
            let dependentFieldValue = element.DependentFieldValue

            value.forEach(obj => {
                if (obj[dependentFieldId] === dependentFieldValue) {
                    //store.dispatch(updateFieldPermission(fieldMappingId, permissionDisabled));
                }
                else {
                    //store.dispatch(updateFieldPermission(fieldMappingId, defaultPermission));
                }
            });
        });
    }
}

const DisableDeleteDueToDependentFieldValue = {
    execute
}

export default DisableDeleteDueToDependentFieldValue