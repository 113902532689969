import moment from "moment";
import { updateControlValue } from "../../../actions/CaseDetailsAction";
import { store } from "../../../store";

const execute = (control, value, AddOnLoadONChangeFlag = null) => {
    const fieldProps = control.additionalProperties;
    var controlValue = control.value ? Array.isArray(control.value) ? control.value : JSON.parse(control.value) : [];
    controlValue = controlValue && controlValue.length > 0 ? controlValue : [];
    var updatedFieldValue = [];
    for (let i = 0; i < value.length; i++) {
        const displayColumnId = value[i][fieldProps.DisplayColumnId];
        let matchedValue = findInControlValue(controlValue, fieldProps.DisplayColumnId, displayColumnId);
        if (!matchedValue) {
            var currentFieldValue = value[i];
            let rules = control.additionalProperties.OnSelectAddValueToTargetFieldInTable;
            if (AddOnLoadONChangeFlag) {
                if (rules) {
                    let dataToDispatch = prepareTargetData(rules.SourceFieldTargetFieldValueMapping, currentFieldValue);
                    updatedFieldValue.push(dataToDispatch);
                }
            }
        } else {
            matchedValue && updatedFieldValue.push(matchedValue);
        }
    }
    store.dispatch(updateControlValue(control.mappingId, updatedFieldValue.length > 0 ? updatedFieldValue : null, control.parentFieldId));
}
const findInControlValue = (data, filterName, displayColumnId) => {
    for (let item of data) {
        if (item[filterName] == displayColumnId) {
            return item;
        }
    }
    return false;
}

const prepareTargetData = (fieldMap, value) => {
    let mapppingKeys = Object.keys(fieldMap);
    let preparedData1 = [];
    let preparedData = {};
    mapppingKeys.map(key => {
        var val = null;
        val = value[key];
        if (typeof fieldMap[key] == 'string') {
            preparedData[fieldMap[key]] = val;
        } else {
            let objectKeys = Object.keys(fieldMap[key]);
            let objectValues = Object.values(fieldMap[key]);
            for (var i = 0; i < objectKeys.length; i++) {
                if (objectValues[i] == "CurrentDateTime") {
                    preparedData[objectKeys[i]] = moment(new Date()).format("MM/DD/YYYY h:mm:ss A");
                }
                else {
                    preparedData[objectKeys[i]] = objectValues[i];
                }
            }
        }
    })
    return preparedData;
}

const OnSelectAddValueToTargetFieldInTable = {
    execute
}
export default OnSelectAddValueToTargetFieldInTable;