import React, { useState, useEffect } from 'react'
import { PropTypes as PT } from 'prop-types';
import { TableBody, TableCell, TableHead, TableRow, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Delete } from '@mui/icons-material';
import { IconButton , Grid} from '@mui/material';
import styled from 'styled-components';
import { Table } from '@mui/material';
import { controlByType } from '../common/Controls';
import FieldComponent from '../common/FieldComponent';
import { PERMISSION_TYPE_ID_DISABLE_FIELD } from '../../constants/GlobalConstants';
import RuleEngine from '../../utils/ruleEngine/AdditionPropertyRules/RuleEngine';

export const StyledTableContainer = styled.div`
  && {
    width: 100%;
   
  }
`;
export const StyledTable = styled(Table)`
  && {
    && {
      width: 100%;
    }
  }
`;
export const EmptyMessageContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  padding: 10px;
  text-align: center;
`;

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundImage: `linear-gradient(to right, #17689e, #2b94c9)`,

  },
  headerFont: {
    color: '#fff' //ToDo: this is just to tes, will remove it later
  }
}));

export default function TableControl(props) {

  // Render the table body
  const renderTableBody = (props) => {
    return props.childControls.map((item, index) => {
      if (index != 0) {
        return (<TableRow key={index}>
          {renderTableRow(item, index, props)}
        </TableRow>
        )
      }
    });
  };    

  //to get Control.
  const getControl = (control) => {
    let controlValue = controlByType(control.fieldTypeName);
    if (controlValue != undefined) {
      let [Control] =
        controlValue;
      return Control
    }
  }

  // Renders a notification that the table is empty
  const renderEmptyNotification = (props) => {

    if (props.childControls && props.childControls.length < 2) {
      // Render empty notification
      return (
        <EmptyMessageContainer>
          <Typography variant="subtitle1">
            {'No values found for this table.'}
          </Typography>
        </EmptyMessageContainer>
      );
    }
  };


  //Render the childControls of table fields..
  const renderChildControls = (childControls, col, props, index) => {
    const rule = col.additionalProperties.MultiFieldContentSeparatorUsingLine
    var breakLine = false
    var displayLine = false
    if (rule) {
      if (rule.SeparateEachFieldContentUsingNewLine == 'True') {
        breakLine = true
      }
      if (rule.DisplayLine == 'True') {
        displayLine = true
        breakLine = true
      }
    }
    var viewCounter = 0
    return childControls.map((element,subIndex) => {
      let { fieldId, isVisible, defaultFieldName, value, requiredBl, additionalProperties, mappingId, permissionTypeId,
        ...otherProps } = element;
        
      //let Control = getControl(element);
      if (element.fieldTypeName != "ActiveHiddenFields") {
        if (isVisible) {
          viewCounter++
          var showDivider = false
          if (displayLine && viewCounter != 1 && (element.fieldTypeName == 'Label' && (element.value && element.value.trim() != ''))) {
            showDivider = true
          }
          return (
            // <div
            //   style={{ display: breakLine ? 'block' : 'inline-block', 'paddingInline':'1px' }}
            // >
            <div key={subIndex} style={{paddingBottom:'0px'}}>
              {showDivider ? <Divider /> : null}

              <FieldComponent
                key={mappingId}
                control={element}
                fieldType={element.fieldType}
                isRequired={element.requiredBl}
                // disabled={this.props.disabled}
                validateSectionControls={(element) => props.validateSectionControls(element)}
                index={index}

              />
              </div>
            // </div>
          )
        } else {
          return (<></>)
        }

      }

    })
  }

  // Render a regular row of raw data
  const renderTableRow = (item, index, props) => {
    return item.map(header => {
      if (header.childControls && header.childControls.length > 0) {
       let dataStyle = {
          tableCell: {
            width : header.additionalProperties.ControlSize * 10 +'%',
            paddingInline:"12px"
          }
        }
       
        if (header.fieldTypeName != "ActiveHiddenFields" && header.isVisible) {
          return (
    
            <TableCell key={`${header.fieldId}_${index}`} style={dataStyle.tableCell}>
          
              {renderChildControls(header.childControls, header, props, index)}
            
            </TableCell>
          )
        }
      }
      else {
        let { fieldId, defaultFieldName, value, requiredBl, additionalProperties, mappingId, permissionTypeId,
          ...otherProps } = header;
        //let Control = getControl(header);
        
         let styleSize={
          tableCell: {
            width : header.additionalProperties.ControlSize * 10 +'%',
            paddingInline:"12px",
          
           
          }
        }
        if (header.fieldTypeName != "ActiveHiddenFields" && header.isVisible){  
          //Table cell padding properties = ["normal","checkbox","none","default"]  
          return (
            <TableCell padding="normal" key={`${header.fieldId}_${index}`} style={styleSize.tableCell}>
       
              <FieldComponent
                key={mappingId}
                control={header}
                fieldType={header.fieldType}
                isRequired={header.requiredBl}
                // disabled={this.props.disabled}
                validateSectionControls={(header) => props.validateSectionControls(header)}
                index={index}
              />
           
              {/* } */}
            </TableCell>
          );
        }
        if (header.additionalProperties.ControlSize > 0 && !header.isVisible){ 
          return (
            <TableCell padding="normal" key={`${header.fieldId}_${index}`} style={styleSize.tableCell}>
             {/* To add blank cell in table  */}
            </TableCell>
          );
        }
      }
    });
  }

  // Render the table headers
  const renderTableHeaders = (props, classes) => {
    return props.ChildCount.map(header => {
      if (header.fieldTypeName != "ActiveHiddenFields" && header.isVisible)
        return (
          <TableCell className={classes.headerFont} key={header.fieldId}>{header.defaultFieldName}</TableCell>
        )
    })
  }

  var { fieldId, name, value, disabled, defaultFieldName, onChange, onClick, onDelete, ...other } = props;
  const classes = useStyles();
  var displayHeader = props.additionalProperties.TableHeader ? false : true;
  return (
    <StyledTableContainer
      padding="dense"
      className="table-control-container"
    >
      {defaultFieldName && <Typography variant="subtitle2">{defaultFieldName}</Typography>}
      <StyledTable>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {displayHeader && renderTableHeaders(props, classes)}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableBody(props)}
        </TableBody>
      </StyledTable>
      {renderEmptyNotification(props)}
    </StyledTableContainer>
  );
}