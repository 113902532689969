import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './store.js';
import Login from './components/auth/Login'
import TenantSelect from './components/auth/TenantSelect';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Snackbar from './components/controls/Snackbar';
import registerServiceWorker from './serviceWorker';
import ResetPassword from './components/forgotpassword/ResetPassword';
import ForgotPassword from './components/forgotpassword/ForgotPassword';
import RepConfirmation from "./components/case/RepConfirmation";




ReactDOM.render(
  <Provider store={store}>
    <Snackbar />
    <Router>
      <Switch>
        <Route exact path='/login' component={Login}></Route>

        <Route exact path='/forgotpassword' component={ForgotPassword}></Route>
        <Route exact path='/ResetPassword' component={ResetPassword}></Route>
        <Route exact path='/tenant' component={TenantSelect}></Route>
        <Route exact path="/product/repConfirmation" component={RepConfirmation}></Route>
        <Route path="/" component={App} />


      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)

registerServiceWorker()
