import { getFieldById } from '../../GetFieldById';
import LoadReferenceData from './LoadReferenceData';

export const execute = (props, onLoadFlag) => {
    if (onLoadFlag) {
        let additionalProperties = props.additionalProperties
        if (additionalProperties.OnSelectReloadReferenceDataInTargetFields) {
            additionalProperties.OnSelectReloadReferenceDataInTargetFields.map(targetField => {
                LoadReferenceData.execute(getFieldById(targetField), true,true)
            })
        }
    }
}

const OnSelectReloadReferenceDataInTargetFields = {
    execute
}

export default OnSelectReloadReferenceDataInTargetFields