import { store } from '../../../store';
import VisibilityControlAction from '../../../actions/VisibilityControlAction'

const execute = (control) => {
    let additionalProperties = control.additionalProperties
    const state = store.getState()
    let value = control.value ? control.value[additionalProperties.DisplayColumnId] : null
    if (additionalProperties.OnSelectionManageSectionGroups) {
        additionalProperties.OnSelectionManageSectionGroups.forEach(rule => {
            if (value) {
                if (rule.FieldValue.includes(value.toString())) {
                    store.dispatch(VisibilityControlAction.showHideSectionGroup(rule))
                }
            }
            else {
                if (rule.FieldValue.includes(value)) {
                    store.dispatch(VisibilityControlAction.showHideSectionGroup(rule))
                }
            }
        })
    }
}

const OnSelectionManageSectionGroups = {
    execute
}

export default OnSelectionManageSectionGroups