import { store } from '../../../store'
import { getFieldValueById } from '../../GetFieldValueByID';
import {
    executeServiceAPI, executeUploadDocumnetAPI, executeEHRServiceAPI, executeCancelCaseAPI, executeDownloadDocAPI,
    executeDeleteDocAPI, executeApproveAPI, executeRejectDocumentAPI, executeRetryFaxAPI, updatePopupLoaderStatus,
     executeActivityTrackingAPI, saveSection, saveTrayWithSection
} from '../../../actions/CaseDetailsAction';
import { StraightenOutlined } from '@mui/icons-material';
import { getFieldById } from '../../GetFieldById';
import { getFieldDetailsById } from '../../GetFieldDetailsById';
import { getControlByFieldId } from '../../GetControlByFieldId';
import * as Constants from "../../../constants/GlobalConstants";


export const execute = (props, onLoadFlag) => {
    if (props.parentFieldId != null) sessionStorage.setItem("parentId", props.parentFieldId);
    const parentId = getFieldById(props?.mappingId);
    const fieldProps = props.additionalProperties
    var serviceAPIName = fieldProps.OnClickExecuteServiceAPI.ServiceAPIName;
    var setRespons = fieldProps.OnClickExecuteServiceAPI.SetResponse ? fieldProps.OnClickExecuteServiceAPI.SetResponse : null;

    if (fieldProps && serviceAPIName == "UploadDocument") {
        if (Object.keys(fieldProps.OnClickExecuteServiceAPI).length > 0) {
            store.dispatch(updatePopupLoaderStatus(true))
            var fieldData = [];
            if (fieldProps.OnClickExecuteServiceAPI.DependentFields) {
                fieldProps.OnClickExecuteServiceAPI.DependentFields.forEach(configFields => {
                    var fieldValue = getFieldValueById(configFields);
                    var checkFieldValue = {};
                    checkFieldValue[configFields] = fieldValue;
                    fieldData.push(checkFieldValue);
                })
                if (serviceAPIName) {
                    store.dispatch(executeUploadDocumnetAPI(serviceAPIName, fieldData, setRespons));
                }
            }
        }
    }
    else if (fieldProps && serviceAPIName == "LoadEMRTemplateWithCaseData") {
        var fieldData = fieldProps.OnClickExecuteServiceAPI.DependentFields;
        var serviceAPIName = fieldProps.OnClickExecuteServiceAPI.ServiceAPIName;
        if (serviceAPIName) {
            store.dispatch(executeEHRServiceAPI(serviceAPIName, fieldData, setRespons));
        }
    }
    else if (fieldProps && serviceAPIName == "CancelCase") {
        var fieldData = fieldProps.OnClickExecuteServiceAPI.DependentFields;
        var serviceAPIName = fieldProps.OnClickExecuteServiceAPI.ServiceAPIName;
        if (serviceAPIName) {
            store.dispatch(executeCancelCaseAPI(serviceAPIName, fieldData, setRespons));
        }
    }
    else if (fieldProps && serviceAPIName == "DownloadDocument") {
        if (onLoadFlag) {
            store.dispatch(executeDownloadDocAPI(props.value));
        }
    }
    else if (fieldProps && serviceAPIName == "DeleteDocument") {
        store.dispatch(updatePopupLoaderStatus(true))
        if (onLoadFlag) {
            if (Object.keys(fieldProps.OnClickExecuteServiceAPI).length > 0) {
                var fieldData = [];
                fieldData.push(store.getState().caseDetails.caseId);
                if (fieldProps.OnClickExecuteServiceAPI.DependentFields) {
                    fieldProps.OnClickExecuteServiceAPI.DependentFields.forEach(configFields => {
                        let currentIndex = props.parentContextId.match(/\d+/)[0];
                        let parentField = getFieldDetailsById(props.parentContextId).parentFieldId;
                        let fieldValue = getFieldById(configFields + currentIndex, parentField)?.value;
                        fieldData.push(fieldValue);
                    })
                    if (serviceAPIName) {
                        store.dispatch(executeDeleteDocAPI(fieldData));
                    }
                }
            }
        }
    }
    else if (fieldProps && serviceAPIName == "ViewDocument") {
        if (onLoadFlag) {
            store.dispatch(executeViewDocAPI(props.value));
        }
    }
    else if (fieldProps && serviceAPIName == "DocumentApproved") {
        if (onLoadFlag) {
            var fieldData = fieldProps.OnClickExecuteServiceAPI.DependentFields;
            var serviceAPIUrl = props.value;
            store.dispatch(executeApproveAPI(serviceAPIUrl, fieldData, setRespons));
        }
    }
    else if (fieldProps && serviceAPIName == "RejectDocument") {
        store.dispatch(updatePopupLoaderStatus(true))
        var rejectData = new FormData();
        var docRejectionReason;
        var apiUrl = getFieldById(props.parentContextId);
        rejectData.append("CaseId", store.getState().caseDetails.caseId);
        if (onLoadFlag && fieldProps.OnClickExecuteServiceAPI.DependentFields) {
            fieldProps.OnClickExecuteServiceAPI.DependentFields.forEach(configFields => {
                if (configFields == "DocRejectionReason") {
                    docRejectionReason = getFieldById(configFields).value;
                    rejectData.append("DocumentRejectionReason", docRejectionReason);
                }
                else {
                    let thenum = props.parentContextId.match(/\d+/)[0]
                    rejectData.append("DocumentId", parseInt(getFieldById(configFields + thenum, sessionStorage.getItem("parentId"))?.value));
                }
            });
        }
        store.dispatch(executeRejectDocumentAPI(rejectData));
    }
    else if (fieldProps && serviceAPIName == "FaxRetry") {
        var retryFaxData = []
        retryFaxData.push(store.getState().caseDetails.caseId);
        if (onLoadFlag && fieldProps.OnClickExecuteServiceAPI.DependentFields) {
            fieldProps.OnClickExecuteServiceAPI.DependentFields.forEach(configFields => {
                let currentIndex = props.mappingId.match(/\d+/)[0];
                let parentField = getFieldDetailsById(props.parentFieldId).parentFieldId;
                retryFaxData.push(getFieldById(configFields + currentIndex, parentField));
            });
            console.log(retryFaxData);
            store.dispatch(executeRetryFaxAPI(retryFaxData[0], retryFaxData[2].value));
        }
    }
    else if (fieldProps && serviceAPIName == "ExecuteActivityTracking" && onLoadFlag) {
        var serviceAPIName = fieldProps.OnClickExecuteServiceAPI.ServiceAPIName;
        var dependentFields = fieldProps.OnClickExecuteServiceAPI.DependentFields; //"DependentFields":["CaseId"]       
        var additonalFields = fieldProps.OnClickExecuteServiceAPI.AdditonalFields; //"AdditonalFields":{"ActivityType":"PrintCaseSummary"}
        if (serviceAPIName) {
            store.dispatch(executeActivityTrackingAPI(serviceAPIName, dependentFields, additonalFields));
        }
    }
    else if (fieldProps && serviceAPIName == "SaveCase") {  
        store.dispatch(updatePopupLoaderStatus(true));      
        const actionId = Constants.ACTION_BUTTON_SAVE_ID;
        const sectionId = store.getState().caseDetails.loadedSectionId;        
        store.dispatch(saveSection(sectionId, actionId));
    }
    else if (fieldProps && serviceAPIName == "SaveCaseProductAndTrays") {  
        store.dispatch(updatePopupLoaderStatus(true));      
        const actionId = Constants.ACTION_BUTTON_SAVE_ID;
        const sectionId = store.getState().caseDetails.loadedSectionId;        
        store.dispatch(saveTrayWithSection(sectionId, actionId));
    }
    else {
        if (Object.keys(fieldProps.OnClickExecuteServiceAPI).length > 0) {
            var fieldData = [];
            fieldProps.OnClickExecuteServiceAPI.DependentFields.forEach(configFields => {
                var fieldValue = getFieldValueById(configFields);
                var checkFieldValue = {};
                checkFieldValue[configFields] = fieldValue;
                fieldData.push(checkFieldValue);
            })
            var serviceAPIName = fieldProps.OnClickExecuteServiceAPI.ServiceAPIName;
            if (serviceAPIName && onLoadFlag) {
                store.dispatch(executeServiceAPI(serviceAPIName, fieldData, setRespons));
            }
        }
    }
}

const OnClickExecuteServiceAPI = {
    execute
}

export default OnClickExecuteServiceAPI