import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
  root: {
      width: '100%',
      zIndex: "120020"
  }
}))


const AutoSuggestDashboard = (props) => {
  const { selections, fieldId, label, value, onChange } = props;
  const classes = useStyles()

  if(fieldId == "PrimarySurgeonId"){
  return (
    <Autocomplete
      value={value}
      clearOnEscape={true}
      className={classes.root}
      id={"PrimarySurgeonId"}
      options={selections}
      getOptionLabel={(options) => options['surgeonName'] || ""}
      isOptionEqualToValue={(options, value) => value && options['surgeonId'] == value['surgeonId']}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
    />
  );
  }
  if(fieldId == "SurgicalLocationId"){
    return (
      <Autocomplete
        value={value}
        clearOnEscape={true}
        className={classes.root}
        id={"SurgicalLocationId"}
        options={selections}
        getOptionLabel={(options) => options['surgicalLocationName'] || ""}
        isOptionEqualToValue={(options, value) => value && options['surgicalLocationId'] == value['surgicalLocationId']}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} variant="outlined" value={value} label={label} />}
      />
    );
    }
}

export default AutoSuggestDashboard;