import moment from 'moment';
import {
    LBL_EQUAL, LBL_EQUAL_TO, LBL_GREATER_THAN, LBL_LESS_THAN, LBL_GREATER_THAN_EQUAL, LBL_LESS_THAN_EQUAL,
    COMMON_DATE_FORMAT, BLANK_VALUE, ERR_INVALID_DATE, ERR_INVALID_DATE_FORMAT, DATE, DATE_COMPARED_TO_DEPENDENT_FIELD
} from '../../../constants/GlobalConstants';
import { getFieldById } from '../../GetFieldById'
var response = null;


export const DateComparedToDependentField = (control) => {
    if (control) {
        if (control.fieldTypeName == DATE) {
            var fieldValue = control.value ? control.value : null;
            if (control && control.validation && fieldValue) {
                var validation = control.validation
                var ruleObj = validation[DATE_COMPARED_TO_DEPENDENT_FIELD] ? validation[DATE_COMPARED_TO_DEPENDENT_FIELD] : null;
                var response = null;
                if (ruleObj != null) {
                    var dependentFieldDateObj = getFieldById(ruleObj.DependentFieldId);
                    const dependentFieldDate = moment(dependentFieldDateObj.value).format(COMMON_DATE_FORMAT); //HFProcedureDate
                    var formatedCurrentFieldValue = moment(fieldValue).format(COMMON_DATE_FORMAT); //PreOP Date

                    if (formatedCurrentFieldValue && formatedCurrentFieldValue == ERR_INVALID_DATE) {
                        response = {
                            status: false,
                            isButtonDisable: false,
                            message: ERR_INVALID_DATE_FORMAT
                        };
                    }
                    else if ((ruleObj.ComparisonOperator == LBL_EQUAL || ruleObj.ComparisonOperator == LBL_EQUAL_TO)
                        && moment(formatedCurrentFieldValue).isSame(dependentFieldDate) == !ruleObj.ExpectedResponse) {
                        response = {
                            status: false,
                            isButtonDisable: false,
                            message: ruleObj.DialogMessage.DefaultText
                        };

                    }
                    else if (ruleObj.ComparisonOperator == LBL_GREATER_THAN
                        && moment(formatedCurrentFieldValue).isAfter(dependentFieldDate) == !ruleObj.ExpectedResponse) {
                        response = {
                            status: false,
                            isButtonDisable: false,
                            message: ruleObj.DialogMessage.DefaultText
                        };

                    }
                    else if (ruleObj.ComparisonOperator == LBL_GREATER_THAN_EQUAL
                        && moment(formatedCurrentFieldValue).isSameOrAfter(dependentFieldDate) == !ruleObj.ExpectedResponse) {
                        response = {
                            status: false,
                            isButtonDisable: false,
                            message: ruleObj.DialogMessage.DefaultText
                        };

                    }
                    else if (ruleObj.ComparisonOperator == LBL_LESS_THAN
                        && moment(formatedCurrentFieldValue).isBefore(dependentFieldDate) == !ruleObj.ExpectedResponse) {
                        response = {
                            status: false,
                            isButtonDisable: false,
                            message: ruleObj.DialogMessage.DefaultText
                        };
                    }
                    else if (ruleObj.ComparisonOperator == LBL_LESS_THAN_EQUAL
                        && moment(formatedCurrentFieldValue).isSameOrBefore(dependentFieldDate) == !ruleObj.ExpectedResponse) {
                        response = {
                            status: false,
                            isButtonDisable: false,
                            message: ruleObj.DialogMessage.DefaultText
                        };
                    }
                    else {
                        response = null;
                    }

                    return response;

                }
            }
        }

    }
}