import React, { useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Divider,
    Grid,
    Button as MuiButton
} from '@mui/material';
import Loader from '../common/Loader';
import makeStyles from '@mui/styles/makeStyles';
import { Link as MuiLink } from '@mui/material';
import { useHistory } from "react-router-dom";
import Controls from '../controls/Controls'
import DialogBoxComponent from '../../components/common/DialogBoxComponent';
import {
    LBL_CLOSE, LBL_VERIFY, LBL_OK, LBL_CASEID_WITH_COLON_AND_SPACE, LBL_DIALOG_CONFIRM_CONTENT_TEXT,
    LBL_DIALOG_TITLE_CONFIRM_BOX, LBL_DIALOG_ALERT_CONTENT_TEXT  
  } from '../../constants/GlobalConstants';
  import sanitize from "sanitize-html";


const useStyles = makeStyles(theme => ({
    root: {
        'font-size': '1rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': '400',
        'line-height': '1.5',
        'letter-spacing': '0.00938em',
        'width': '100%'
    },
    dialogTitleBgColor: {
        backgroundColor: '#1976d2',
        color: 'white',
    },
    userNameLabelStyle: {
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': 'normal',
        'font-size': '1.200rem',
        'line-height': 1.43,
        'letter-spacing': '0.01071em'
    },
    caseIdLabelStyle: {
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': 'normal',
        'font-size': '0.800rem',
        'line-height': 1.43,
        'letter-spacing': '0.01071em'
    },
    viewCaseLinkColor: {
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': 'normal',
        'font-size': '0.800rem',
        'line-height': 1.43,
        'letter-spacing': '0.01071em',
        'text-decoration': 'underline',
        'color': 'white'
    },
    dialogTitleLeftDiv: {
        float: 'left',
        display: 'flex',
        flexDirection: 'column'
    },
    dialogTitleRightDiv: {
        float: 'right'
    },
    dialogActionContainer: {
        justifyContent: 'left',
        marginLeft: '10px'
    },
    gridRowItemAlignemnt: {
        display: 'flex',
        flexDirection: 'row'
    },
    gridColumnItemAlignemnt: {
        display: 'flex',
        flexDirection: 'column'
    },
    dividerMargins: {
        paddingTop: '11px',
        paddingBottom: '11px'
    },
    gridFieldLabel: {
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': 'bold',
        'font-size': '1rem',
        'line-height': 1.43,
        'letter-spacing': '0.01071em',
    },
    gridFieldValue: {
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': 'normal',
        'font-size': '1rem',
        'line-height': 1.43,
        'letter-spacing': '0.01071em',
        'wordWrap': 'break-word'
    },
    verifyButton: {
        textTransform: 'none',
    },
    notVerifiedText: {
        color: 'red',
        'font-size': '0.800rem',
    },
    statusText: {
        'font-size': '0.900rem',
    },
    leftContainerBackgroundColor: {
        backgroundColor: 'rgb(240,250,255)',       
        'border-width': 0,
        'border-style': 'solid',
        'border-color': 'rgba(0, 0, 0, 0.12)',
        'border-bottom-width': 'thin',
        'border-left-width': 'thin'
    },
    muiDialogContentRootOverride: {
        paddingTop: '0px'
    },
    muiGridItemOverride: {
        paddingTop: '21px'
    },
    dividerLeftAlignment:{
        paddingLeft:'0px !important'
    }

}))


function CaseSummaryDialogBoxComponent(props) {

    const { open = false, dialogTitle, dialogContentText, maxWidth, caseId,
        caseSummaryDetails, sectionGroupLoader, userActions, isInsuranceVerificationDetailAmendedBl } = props;
    const { size, color, variant, onCloseCaseSummaryPopup, onCaseSummaryVerifyConfirm, onAlertDialogOkClick } = props;
    const patientName = caseSummaryDetails?.PatientName;
    const isShowPrimaryInsuranceVerifyButton = userActions && userActions.find(element => element.userActionId == 7)?.allowedActionBl;
    const isShowSecondryInsuranceVerifyButton = userActions && userActions.find(element => element.userActionId == 8)?.allowedActionBl;
    const classes = useStyles()
    let history = useHistory()
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);    
    const [verifyInsuranceRequestJson, setVerifyInsuranceRequestJson] = React.useState(null);
    const dialogConfirmContentText= `${LBL_DIALOG_CONFIRM_CONTENT_TEXT} <b>${patientName}</b>?`
    

    const onViewCaseDetailsLinkClick = (caseId) => {
        const navigationURL = `case/${parseInt(caseId)}/Financial`;
        history.push(navigationURL);
    };

    const onVerifyButtonClick=(insuranceVerifyRequestData)=>{ 
        setOpenConfirmDialog(true);
        setVerifyInsuranceRequestJson(insuranceVerifyRequestData);
    }
    const onCloseConfirmDialog = () => {   
        setOpenConfirmDialog(false)     
    };

    const onAlertDialogOk = () => { 
        onAlertDialogOkClick();   
    };

     const onConfirmDialog = () => {        
            setOpenConfirmDialog(false);
            onCaseSummaryVerifyConfirm(verifyInsuranceRequestJson);
     
    };

    const VerifyInsuranceConfirmationBox=(
        <DialogBoxComponent
        open={openConfirmDialog}
        dialogTitle={LBL_DIALOG_TITLE_CONFIRM_BOX}      
        dialogContentText={dialogConfirmContentText}
        maxWidth={'sm'}
        isShowOkButton={true}
        isShowCancelButton={true}
        confirmButtonText = {LBL_VERIFY}
        onCloseDialog={onCloseConfirmDialog}
        onConfirmDialog={onConfirmDialog}
        confirmButtonColor = {'success'}

      /> 
    )

    const VerifyInsuranceAlertBox=(
        <DialogBoxComponent
        open={isInsuranceVerificationDetailAmendedBl}
        dialogTitle={LBL_DIALOG_TITLE_CONFIRM_BOX}      
        dialogContentText={LBL_DIALOG_ALERT_CONTENT_TEXT}
        maxWidth={'sm'}
        isShowOkButton={true}
        isShowCancelButton={false}   
        confirmButtonText = {LBL_OK}   
        onConfirmDialog={onAlertDialogOk}

      /> 
    )

    return (
        <>
            {VerifyInsuranceAlertBox}
            {VerifyInsuranceConfirmationBox}
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={maxWidth || 'md'}
            >
                <DialogTitle disableTypography className={classes.dialogTitleBgColor}>

                    <div className={classes.dialogTitleLeftDiv}>
                    <span className={classes.userNameLabelStyle} dangerouslySetInnerHTML={{ __html: patientName? sanitize(patientName):"" }} />
                    <span className={classes.caseIdLabelStyle}>{LBL_CASEID_WITH_COLON_AND_SPACE + caseId}</span>
                    </div>
                    <div className={classes.dialogTitleRightDiv}>
                        <MuiLink className={classes.viewCaseLinkColor} onClick={() => { onViewCaseDetailsLinkClick(caseId) }} href="#" underline="always" component="button">{'View Full Case>'}</MuiLink>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent className={classes.muiDialogContentRootOverride}>
                    {/* <DialogContentText>{dialogContentText}</DialogContentText> */}
                    {sectionGroupLoader ?
                        <div> <Loader loading={sectionGroupLoader} /></div> :
                        <>{dialogContentBody(classes, onVerifyButtonClick, isShowPrimaryInsuranceVerifyButton, isShowSecondryInsuranceVerifyButton, caseSummaryDetails)}</>}
                </DialogContent>
                <DialogActions className={classes.dialogActionContainer}>
                    <Button
                        size={size || "medium"}
                        color={color || "primary"}
                        variant={variant || 'contained'}
                        onClick={onCloseCaseSummaryPopup}
                    >
                        {LBL_CLOSE}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}



const dialogContentBody = (classes,onVerifyButtonClick, isShowPrimaryInsuranceVerifyButton, isShowSecondryInsuranceVerifyButton, caseSummaryDetails) => {
    if (caseSummaryDetails) {
        const caseSummaryTemplate = caseSummaryDetails?.HtmlTemplate;
        const primaryInuranceDetails = caseSummaryDetails?.PrimaryInuranceDetails;
        const secondryInuranceDetails = caseSummaryDetails?.SecondryInuranceDetails;
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={7}>
                    <div
                        id={'case-summary-template-div'}
                        className={classes.root}
                    > 
                    <div id = "EMRSummary" dangerouslySetInnerHTML={{ __html: caseSummaryTemplate? sanitize(caseSummaryTemplate,{ allowedAttributes: { '*': ['class'] } }):"Loading..." }} />

                    </div>
                </Grid>
                <Grid item xs={5} className={`${classes.leftContainerBackgroundColor}`}>
                    <div className={classes.muiGridItemOverride}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {getVerifyOrNonVerifyHtmlTemplate(classes, onVerifyButtonClick, isShowPrimaryInsuranceVerifyButton, 'Primary Insurance', primaryInuranceDetails)}
                            {getFieldTemplateByData(classes, primaryInuranceDetails.DisplayFields)}
                            {primaryInuranceDetails?.IsPatientHasSecondaryInsurance &&
                                <Grid item xs={12} className={classes.dividerLeftAlignment}>                                    
                                        <div className={classes.dividerMargins}>
                                            <Divider />
                                        </div>                                  
                                </Grid>
                            }
                            {primaryInuranceDetails?.IsPatientHasSecondaryInsurance &&
                                getVerifyOrNonVerifyHtmlTemplate(classes, onVerifyButtonClick, isShowSecondryInsuranceVerifyButton, 'Secondary Insurance', secondryInuranceDetails)
                            }

                            {primaryInuranceDetails?.IsPatientHasSecondaryInsurance &&
                                getFieldTemplateByData(classes, secondryInuranceDetails.DisplayFields)
                            }

                        </Grid>
                    </div>
                </Grid>

            </Grid>
        )
    } else {
        console.log("Case summary html template issue.")
        return "";
    }
};

const getFieldTemplateByData = (classes, displayFields) => {
    if (displayFields) {

        return displayFields.map((fieldObj, index) => {
            return (
                <Grid
                    item
                    md={parseInt(fieldObj.ControlSize)}
                    lg={parseInt(fieldObj.ControlSize)}
                    xs={12}                   
                    key={fieldObj.FieldLabel+index}
                    >
                    <div className={classes.gridColumnItemAlignemnt}>
                        <div className={classes.gridFieldLabel}>{fieldObj.FieldLabel}</div>
                        <div className={classes.gridFieldValue} dangerouslySetInnerHTML={{ __html: fieldObj.FieldValue? sanitize(fieldObj.FieldValue):"" }} />                  
                  </div>
                </Grid>
            )
        });
    }
    else {
        return <></>;
    }
}

const getVerifyOrNonVerifyHtmlTemplate = (classes, onVerifyButtonClick, isUserhaveVerifyBtnAccess, insuranceType, caseData) => {
   
    if (caseData?.VerifiedBl) {
        return (<Grid item xs={12} className={classes.gridRowItemAlignemnt}>
            <Grid item xs={12}>
                <div className={classes.gridColumnItemAlignemnt}>
                    <div className={classes.gridFieldLabel}>{insuranceType}</div>
                    {caseData?.IsShowVerifyUnverifyArea &&
                        <div className={classes.gridFieldValue}>
                            <Controls.Label
                                name={"verifiedInsuranceLbl"}
                                value={`Status: <green_lbl>Verified</green_lbl> by ${caseData?.VerifiedByName} ${caseData?.VerifiedOn}`} />
                        </div>
                    }
                </div>
            </Grid>
        </Grid>)
    }
    else {
        const insuranceVerifyRequestData =  {
            "CaseId": ""+caseData?.CaseId,         
            "InsuranceType": ""+ caseData?.InsuranceType,         
            "InsuranceId": ""+ caseData?.InsuranceTypeId,            
            "EnrollmentId": ""+ caseData?.EnrollmentId,          
            "EnrollmentTxnId": ""+ caseData?.EnrollmentTxnId,           
            "InsuranceTxnId": ""+ caseData?.InsuranceTxnId,         
            "CaseLastSavedTxnId": ""+ caseData?.CaseLastSavedTxnId,   
            }
        return (
            <>
                {caseData?.IsShowVerifyUnverifyArea &&
                    <Grid item xs={12} className={classes.gridRowItemAlignemnt}>
                        <Grid item xs={6}>
                            <div className={classes.gridColumnItemAlignemnt}>
                                <div className={classes.gridFieldLabel}>{insuranceType}</div>
                                <div className={classes.gridFieldValue}><span className={classes.statusText}>Status:</span>&nbsp;<span className={classes.notVerifiedText}>Not Verified</span></div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                           { isUserhaveVerifyBtnAccess &&
                                <div><MuiButton className={classes.verifyButton}
                                    size={"small"}
                                    color={"success"}
                                    variant={'contained'}
                                    id={caseData?.InsuranceTypeId}
                                    onClick={() => {
                                        console.log("Verify btn clicked")
                                         onVerifyButtonClick(insuranceVerifyRequestData)
                                         }}
                                >Verify
                                </MuiButton>
                                </div>
                           }
                        </Grid>
                    </Grid>
                }
            </>
        )
    }
}

export default CaseSummaryDialogBoxComponent