import * as React from 'react';
import Chip from '@mui/material/Chip';
import { Grid } from '@mui/material';
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Controls from '../controls/Controls';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminDataUploadTypes, getDataReport } from '../../actions/AdminReportsAction';
import AdminService from "../../services/AdminService";
import { getLoadingStatus } from '../../reducers/ReportsReducer';
import { Button as MuiButton, CircularProgress } from "@mui/material";
import { getAdminDataUploadTypesData } from '../../reducers/AdminReportsReducer';

const useStyles = makeStyles(theme => ({
  container: {
    background: '#fff',
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(12)})`,
    paddingRight: '10px'
  },
  accordingSubContainer: {
    paddingRight: '20px'
  },
  chipContainer: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}))
const displayBlock = "block";
const displayNone = "none";

export default function DataReport(props) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [getOrganizationList, setOrganizationList] = React.useState([]);
  const loading = useSelector(getLoadingStatus);
  const adminDataUploadTypesList = useSelector(getAdminDataUploadTypesData);
  const organizationList = [];
  React.useEffect(() => {
    adminDataUploadTypesList.length == 0 && dispatch(getAdminDataUploadTypes());
    AdminService.getOrganizationList()
      .then((response) => {
        response?.responseData && setOrganizationList(response.responseData);
      });
  }, []);

  React.useEffect(() => {
    if (!loading) {
      setDataTypeSelectedValue(null);
      setOrgSelectedValue(null);
      setChipData([]);
    }
  }, [loading]);

  const [orgSelectedValue, setOrgSelectedValue] = React.useState(null);
  const [dataTypeSelectedValue, setDataTypeSelectedValue] = React.useState(null);
  const [chipData, setChipData] = React.useState([]);
  const [orgSelectedIds, setOrgnizationIds] = React.useState([]);
  const handleDelete = (chipToDelete) => () => {
    setChipData((chipData) => chipData.filter((chip) => chip.organizationId !== chipToDelete.organizationId));
    if (chipData.length == 0 || chipData.length == 1) {
      setDataTypeSelectedValue(null);
      setOrgSelectedValue(null);
      setChipData([]);
    };
    const newRequestList = orgSelectedIds.filter((item) => item !== chipToDelete.organizationId);
    setOrgnizationIds(newRequestList);
  };
  const onOrgSelection = (e, items) => {
    setOrgSelectedValue(items["organizationId"]);
    const x = chipData.find(item => item.organizationId === items["organizationId"]);
    if (!x) {
      chipData.push({ organizationId: items["organizationId"], organizationName: items["organizationName"] });
      orgSelectedIds.push(items["organizationId"]);
      setChipData(chipData);
      setOrgnizationIds(orgSelectedIds);
    }
    setDataTypeSelectedValue(null);
  }
  const ondataTypeSelection = (e, items) => {
    setDataTypeSelectedValue(items["adminDataUploadTypeId"]);
  }
  const submitReport = () => {
    dispatch(getDataReport(dataTypeSelectedValue, orgSelectedIds));
    setOrgnizationIds([]);
  }

  return (
    <div className={classes.accordingSubContainer}>
      <Accordion expanded={true} >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            'background-image': 'linear-gradient(#17689e, #2b94c9)',
            'color': 'white'
          }}
        >
          <Typography>Data Report Selection</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controls.AutoSuggest
                multiple={true}
                fieldId={'HealthOrgnization'}
                name={'HealthOrgnization'}
                label={'Select Health Organization'}
                onChange={(e, values) => { onOrgSelection(e, values) }}
                selections={getOrganizationList}
                additionalProperties={{ "DisplayColumnId": "organizationId", "DisplayColumnName": "organizationName" }}
              />
            </Grid>
            <Grid item xs={9} container direction="row">
              {chipData.map((data) => {
                let icon;

                return (
                  <div className={classes.chipContainer} key={data.organizationId}>
                    <Chip
                      variant='outlined'
                      icon={icon}
                      label={data.organizationName}
                      onDelete={handleDelete(data)}
                      className={classes.chip}
                    />
                  </div>
                );
              })}
            </Grid>
            <Grid item xs={3} style={{ display: chipData.length == 0 ? displayNone : displayBlock }}>
              <Controls.AutoSuggest
                fieldId={'DataTypes'}
                name={'DataTypes'}
                label={'Select Data Types'}
                value={dataTypeSelectedValue}
                onChange={(e, values) => { ondataTypeSelection(e, values) }}
                selections={adminDataUploadTypesList}
                additionalProperties={{ "DisplayColumnId": "adminDataUploadTypeId", "DisplayColumnName": "adminDataUploadTypeName" }}
              />
            </Grid>
            <Grid item xs={9} />
            <Grid item xs={3} style={{ display: dataTypeSelectedValue == null ? displayNone : displayBlock }}>
              <MuiButton
                size="large"
                color="primary"
                variant="contained"
                onClick={submitReport}
                id="generateReport"
                disabled={loading}
              >
                {loading && <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />}  Generate Report
              </MuiButton>
            </Grid>
            <Grid item xs={9} />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div >
  );
}
