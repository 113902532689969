import LegalGuardianFormNotSubmitted from "./LegalGuardianFormNotSubmitted";
import ProcedureSchedulingAlert from "./ProcedureWithin24Hours";

export const checkListByType = checkListName => {
    const type = checkListName.trim();
    const sectionRule = {
        ProcedureSchedulingAlert: ProcedureSchedulingAlert,
        LegalGuardianFormNotSubmitted: LegalGuardianFormNotSubmitted,
    };
    return sectionRule[type] || undefined;
}