
import React, { useEffect, useState } from 'react';
import { sessionToggleDialog } from '../actions/SectionAlertAction';
import {  useDispatch } from 'react-redux';
import LocalStorageProvider from "../providers/LocalStorageProvider"
import { SIGNOUT_TIME_IN_MS } from '../constants/GlobalConstants';

const sessionTimeout = () => {
    const signoutTime = SIGNOUT_TIME_IN_MS;  //i.e.,1800000
    const warningTime = signoutTime - 6000;  //i.e.,1740000
    const dispatch= useDispatch();
    const events = ['load','click','scroll','keypress','mousemove'];

    let warnTimeout;
    let logoutTimeout;
 
    const logout = () => {
        LocalStorageProvider.deleteUserInfo();
        dispatch(sessionToggleDialog(true))
    }
  
    const warn = () => {
      LocalStorageProvider.deleteUserInfo();
      dispatch(sessionToggleDialog(true))
      
      };


    const setTimeouts = () => {
        warnTimeout = setTimeout(warn, warningTime);
        logoutTimeout = setTimeout(logout, signoutTime);
    };

    const clearTimeouts = () => {
        if (warnTimeout) clearTimeout(warnTimeout);
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const resetTimeout = () => {                
        clearTimeouts();
        setTimeouts();      
        
    };

    useEffect(() => {
        
       events.map(eventName=>{
         window.addEventListener(eventName, resetTimeout);
       });           
      

        setTimeouts();
        return () => {
            events.map(eventName=>{
                window.removeEventListener(eventName, resetTimeout);
                clearTimeouts();
            });
        }
    },[]);
   
}

const SessionTimeout={
    sessionTimeout,
}
export default SessionTimeout;