import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import UserInfo from './UserInfo';
import UserPreferences from './UserPreferences';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(12),
        background: '#fff',
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(12)})`,
    }
}))



const UserProfile = () => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={3} md={3} lg={3}>
                    <UserInfo />
                </Grid>
                <Grid item xs={9} md={9} lg={9}>
                    <UserPreferences />
                </Grid>
            </Grid>
        </div>
    )
}

export default UserProfile
