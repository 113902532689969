import Alerts from "./Alerts";

export const sectionRuleByType = sectionRuleName => {
    const type = sectionRuleName.trim();
    const sectionRule = {
        Alerts: Alerts,
    };
    return sectionRule[type] || undefined;
}

