import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, Select, MenuItem, Grid, Divider, Typography } from '@mui/material';
import Controls from '../controls/Controls'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUserNotificationPreferences } from '../../reducers/UserProfileReducer';
import { getNotificationPreferences, updateUserNotificationPreferences } from '../../actions/UserProfileAction';
import * as R from 'ramda';
import { getReferenceDataByMappingId } from '../../services/UserProfileService';


const useStyles = makeStyles({
    row: {
        margin: 20,
    },
    headline: {
        color: "#2b94c9 ",
        textWeight: "bold",
        marginBottom: "12px"
    },
});

function NotificationSelectionDropdown (props) {
    const dispatch = useDispatch();
    const { notificationId, notificationName, selections, value } = props;
    const handleChange = (e) => {
        const { target: { value }} = e;
        let formattedValue = value;
        const selectedItem = value[value.length-1];
        if (selectedItem === "NO_PROVIDERS") {
            formattedValue = ["NO_PROVIDERS"];
        } else if (selectedItem === "ALL_PROVIDERS") {
            formattedValue = ["ALL_PROVIDERS"];
        } else {
            formattedValue = value.filter(elem => elem !== "ALL_PROVIDERS" && elem !== "NO_PROVIDERS")
        }
        dispatch(updateUserNotificationPreferences(notificationId, notificationName, "Email", "Surgeon", formattedValue))
    }

    const finalSelections = R.isEmpty(selections) ? [] : [
        {SurgeonId: "ALL_PROVIDERS", SurgeonName: "All Providers"},
        {SurgeonId: "NO_PROVIDERS", SurgeonName: "No Providers"},
        ...selections
    ]


    // default is No Providers
    let finalValue = ["NO_PROVIDERS"]
    if (!R.isEmpty(value) && !R.isNil(value)) {
        finalValue = value
            .map(e => finalSelections.find((selection) => e === selection.SurgeonId)?.SurgeonId)
            .filter(elem => !R.isNil(elem));
    } 

    return <FormControl variant="outlined" sx={{width: "100%"}}>
             <Select
                 value={finalValue}
                 onChange={handleChange}
                 multiple
                 size={'small'}
                 >
                 {finalSelections.map(
                    item => (
                    <MenuItem 
                        key={item.SurgeonId} 
                        id={item.SurgeonId} 
                        value={item.SurgeonId}
                    >
                        {item.SurgeonName}
                    </MenuItem>)
                 )}
             </Select>
         </FormControl> 
}

export default function UserPreferences() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userProfile = useSelector(getUserNotificationPreferences)
    const [surgeonList, setSurgeonList] = useState([]);

    useEffect(() => {
        async function getSurgeonList() {
            const payload = [{Id: "PrimarySurgeonList", additionalData: null}];
            const response = await getReferenceDataByMappingId(payload);
            if (response) {
                setSurgeonList(response?.responseData?.PrimarySurgeonList)
            }
        }
        getSurgeonList()
    }, [])
    
    const notificationPreferences = userProfile.userNotificationPreferences ? userProfile.userNotificationPreferences  : null;

    React.useEffect(() => {
        dispatch(getNotificationPreferences())
    }, [])





    return (
        <>
            <Controls.Typography variant="h6" className={classes.headline}>Email Preferences</Controls.Typography>
            {notificationPreferences.map(function(row){
                const {NotificationName, Email, NotificationId} = row;
                const notificationFilters = Email?.NotificationFilters
                return (
                    Email != null && <React.Fragment key={NotificationId}>
                        <Grid container className={classes.row}>
                            <Grid item xs={4} sx={{display: 'flex', alignItems: 'center'}}><Typography variant="subtitle1">{NotificationName}</Typography></Grid>
                            <Grid item xs={5}>{<NotificationSelectionDropdown 
                                notificationName="Email" 
                                notificationId={NotificationId} 
                                value={notificationFilters ? notificationFilters[0]?.FilterValues : []}
                                selections={surgeonList}
                            />}</Grid>
                        </Grid>
                        <Divider />
                    </React.Fragment>
                )
            })
            }
        </>
    );
}