import { hideField } from '../../../actions/VisibilityControlAction';
import { getSelectionsForControl } from '../../../reducers/CaseDetailsReducer';
import { store } from '../../../store';
import { getFieldById } from '../../GetFieldById';
import OnSelectReloadReferenceDataInTargetFields from './OnSelectReloadReferenceDataInTargetFields';

const execute = (control) => {
    let additionalProperties = control.additionalProperties
    const state = store.getState()
    if (additionalProperties.OnSelectionHideTargetField) {
        additionalProperties.OnSelectionHideTargetField.map(rule => {
            let control = getFieldById(rule.TargetFieldId)
            if (control) {
                var displayColumnId = control.additionalProperties.LoadReferenceData;
                if (additionalProperties.OnSelectReloadReferenceDataInTargetFields) {
                    setTimeout(function () { //Start the timer
                        let controlSelectionVals = getSelectionsForControl(state, displayColumnId)
                        if (rule.TargetFieldAvailableValueCount) {
                            if (!controlSelectionVals || controlSelectionVals.length === parseInt(rule.TargetFieldAvailableValueCount)) {
                                store.dispatch(hideField(rule.TargetFieldId))
                            }
                        }
                    }.bind(), 1)
                }
            }
        })
    }
}

const OnSelectionHideTargetField = {
    execute
}

export default OnSelectionHideTargetField