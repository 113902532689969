import React from 'react'
import { Typography as MuiTypography } from '@mui/material'

function Typography(props) {
    return (
        <MuiTypography
            {...props}
            disabled={props.disabled}
        >
            {props.children}
        </MuiTypography>
    )
}

export default Typography
