import { calculateValue } from "../../CalculateValue"
import { getFieldValueById } from "../../GetFieldValueByID"
import { updateControlValue, addFields } from '../../../actions/CaseDetailsAction'
import { store } from '../../../store'
import { getFieldById } from '../../GetFieldById'
import { TARGET_FIELD_OTHER_FIELD_DEFAULT_VALUES, GLOBAL_VALUE_DOT } from '../../../constants/GlobalConstants';
import moment from "moment"
import LocalStorageProvider from "../../../providers/LocalStorageProvider"

const execute = (control, AddOnLoadONChangeFlag = null) => {
    const fieldProps = control.additionalProperties
    let rule = fieldProps.OnClickAddValueToTargetField;
    if (AddOnLoadONChangeFlag) {
        if (rule) {
            if (rule.SourceFieldTargetFieldValueMapping && rule.SourceFields.length > 0) {
                var valuesToAdd = [];
                var sourceValues = [];
                // check if mappind id has index start
                var controlIndex = control.mappingId;
                var matches = controlIndex.match(/(\d+)/);
                currentIndex = '';
                if (matches) {
                    var currentIndex = matches[0];
                }
                // check if mappind id has index start
                rule.SourceFields.forEach(sourceField => {
                    var getFieldId = '';
                    if (currentIndex) {
                        getFieldId = sourceField + parseInt(currentIndex);
                    } else {
                        getFieldId = sourceField;
                    }
                    var field = getFieldById(getFieldId, control.parentFieldId);
                    if (field && field != undefined) {
                        sourceValues.push(field);
                    }
                })
                var values = [];
                var keys = Object.keys(rule.SourceFieldTargetFieldValueMapping)
                var values = Object.values(rule.SourceFieldTargetFieldValueMapping)
                for (var i = 0; i < keys.length; i++) {
                    sourceValues.forEach(sourceValue => {
                        if (sourceValue.value && (sourceValue.fieldTypeName == "SelectionKeyValue" || sourceValue.fieldTypeName == "Typeahead" || sourceValue.fieldTypeName == "Chips")) {
                            var valueOne = Object.keys(sourceValue.value)
                            var valueTwo = Object.values(sourceValue.value)
                            for (var j = 0; j < valueOne.length; j++) {
                                if (keys[i] == sourceValue.mappingId + GLOBAL_VALUE_DOT + valueOne[j]) {
                                    var object = {
                                        key: values[i],
                                        value: valueTwo[j]
                                    }
                                    valuesToAdd.push(object);
                                }
                            }
                        }
                        else if (sourceValue.value && (sourceValue.fieldTypeName != "SelectionKeyValue" && sourceValue.fieldTypeName != "Typeahead" && sourceValue.fieldTypeName != "Chips")) {
                          let keyFieldId='';
                          let valueField='';
                            if (currentIndex) {
                                keyFieldId = keys[i]+parseInt(currentIndex);
                                valueField = values[i]+parseInt(currentIndex)
                            } else {
                                keyFieldId = keys[i];
                                valueField = values[i];
                            }
                            if (keyFieldId == sourceValue.mappingId) {
                                var object = {
                                    key:  values[i],
                                    value: sourceValue.value
                                }
                                valuesToAdd.push(object);
                            }
                        }
                    })
                    if (keys[i] == TARGET_FIELD_OTHER_FIELD_DEFAULT_VALUES) {
                        var defaultTargetFieldKey = Object.keys(values[i])
                        var defaultTargetFieldValue = Object.values(values[i])
                        for (var obj = 0; obj < defaultTargetFieldKey.length; obj++) {
                            if (defaultTargetFieldValue[obj] == 'CurrentDateTime') {
                                var field = getFieldById(defaultTargetFieldKey[obj]);
                                if (field && field.additionalProperties) {
                                    var format = field.additionalProperties.Format;
                                    var object = {
                                        key: defaultTargetFieldKey[obj],
                                        value: moment(new Date()).format(format)
                                    }
                                    valuesToAdd.push(object);
                                } else {
                                    var object = {
                                        key: defaultTargetFieldKey[obj],
                                        value: defaultTargetFieldValue[obj]
                                    }
                                    valuesToAdd.push(object);
                                }
                            } else if (defaultTargetFieldValue[obj] == 'LoggedInUserName') {
                                var object = {
                                    key: defaultTargetFieldKey[obj],
                                    value: LocalStorageProvider.getLoginUserDisplayName()
                                }
                                valuesToAdd.push(object);
                            } else if (defaultTargetFieldValue[obj] == 'LoggedInUserId') {
                                var object = {
                                    key: defaultTargetFieldKey[obj],
                                    value: LocalStorageProvider.getLoginUserID()
                                }
                                valuesToAdd.push(object);
                            }else {
                                var object = {
                                    key: defaultTargetFieldKey[obj],
                                    value: defaultTargetFieldValue[obj]
                                }
                                valuesToAdd.push(object);
                            }
                        }
                    }
                }
                var targetField="";
                var targetParentField=null;
                if (currentIndex) {
                    targetField = rule.TargetFieldId + parseInt(currentIndex);
                    targetParentField = control.parentFieldId;
                } else {
                    targetField = rule.TargetFieldId;
                }
                store.dispatch(addFields(targetField, null, valuesToAdd,targetParentField))
            }
        }
    }
}


const OnClickAddValueToTargetField = {
    execute
}

export default OnClickAddValueToTargetField