import { store } from '../../../store';
import round from 'lodash/round';
import { getFieldValueById } from '../../GetFieldValueByID';
import { updateControlValue } from '../../../actions/CaseDetailsAction';
export const execute = (props, value) => {
    let fieldProps = props.additionalProperties;
    if (fieldProps.CalculateBMI) {
        var BMI = null;
        var heightFeet = getFieldValueById(fieldProps.CalculateBMI.DependentFieldId.HeightFeet);
        var heightInches = getFieldValueById(fieldProps.CalculateBMI.DependentFieldId.HeightInches);
        var weight = getFieldValueById(fieldProps.CalculateBMI.DependentFieldId.Weight);
        var OnInvalidValue=fieldProps.CalculateBMI.OnInvalidValue;

         heightFeet = (heightFeet!=null && heightFeet !='') ? parseInt(heightFeet)*12 : 0;
         heightInches =  (heightInches!=null && heightInches !='') ? parseInt(heightInches):0;
         weight =  (weight!=null && weight !='')? parseFloat(weight):0;
        
         var totalHeight = heightFeet + heightInches;
         BMI = round((weight / (totalHeight * totalHeight)) * 703, 2);
         if(BMI == "Infinity"){
            BMI = OnInvalidValue; 
        }
        
        store.dispatch(updateControlValue(fieldProps.CalculateBMI.TargetFieldId, BMI));
    }
}
const CalculateBMI = {
    execute
}
export default CalculateBMI